import { combineReducers } from 'redux';
import message from './message.reducer';
import settings from "./settings.reducer";
import navbar from "./navbar.reducer";
import navigation from "./navigation.reducer";
import dialog from "./dialog.reducer";
var baseReducers = combineReducers({
    dialog: dialog,
    message: message,
    navbar: navbar,
    navigation: navigation,
    settings: settings
});
export default baseReducers;
