var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import UserAvatar from 'components/UserAvatar/UserAvatar';
import history from 'store/history';
import DeclineSportClubUserJoinRequestButton from '../DeclineSportClubUserJoinRequestButton/DeclineSportClubUserJoinRequestButton';
import СonfirmSportClubUserJoinRequestButton from '../СonfirmSportClubUserJoinRequestButton/СonfirmSportClubUserJoinRequestButton';
var ClubUserJoinRequestsSearchResultListItem = function (_a) {
    var userProfile = _a.userProfile, handleConfirmUserJoinRequest = _a.handleConfirmUserJoinRequest, handleDeclineUserJoinRequest = _a.handleDeclineUserJoinRequest;
    return (_jsx("div", __assign({ className: "flex xs:py-8 md:py-10 decoration-0" }, { children: _jsxs("div", __assign({ className: "flex items-center w-full" }, { children: [_jsx(UserAvatar, { style: "xs:w-48 xs:h-48 xs:text-16", profilePhoto: { url: userProfile.userProfilePhotoUrl || '' }, firstName: userProfile.firstName }, void 0), _jsx("div", __assign({ className: "w-full flex flex-col" }, { children: _jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row space-x-10 justify-between" }, { children: [_jsxs("div", __assign({ onClick: function () {
                                    history.push({
                                        pathname: '/profile/' + userProfile.userName,
                                        state: { fromAthletesSearchPage: true }
                                    });
                                }, className: "flex xs:text-14 md:text-16 font-medium px-10 py-2 cursor-pointer items-center" }, { children: [userProfile.firstName, " ", userProfile.lastName] }), void 0), _jsxs("div", __assign({ className: "space-x-10" }, { children: [_jsx(СonfirmSportClubUserJoinRequestButton, { userId: userProfile.userId, handleConfirmRequest: handleConfirmUserJoinRequest }, void 0), _jsx(DeclineSportClubUserJoinRequestButton, { userId: userProfile.userId, handleDeclineRequest: handleDeclineUserJoinRequest }, void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default ClubUserJoinRequestsSearchResultListItem;
