import axios from 'axios';
import inMemoryJwt from '../../utils/inMemoryJwt';
import Utils from '../../utils/Utils';

class jwtService extends Utils.EventEmitter {
	init() {
		this.handleAuthentication();
	}

	handleAuthentication = () => {
		const isLogin = !!inMemoryJwt.getToken();
		this.emit('onAutoLogin', isLogin);
	};

	handleOnLogin = () => {
		const isLogin = !!inMemoryJwt.getToken();
		this.emit('onLogin', isLogin);
	};

	handleOnLogout = () => {
		this.emit('onLogout');
	};

	setSession = (access_token) => {
		if (access_token) {
			inMemoryJwt.setToken(access_token);
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
		} else {
			inMemoryJwt.eraseToken();
			delete axios.defaults.headers.common['Authorization'];
		}
	};
}

const instance = new jwtService();

export default instance;
