import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import PageCarded from 'components/PageLayouts/carded/PageCarded';
import SidebarContent from '../../layouts/shared-components/SidebarContent/SidebarContent';
import settingsNavigationConfig from './settingsNavigationConfig';
var SettingsPage = function (props) {
    return (_jsx(PageCarded, { classes: {
            root: 'w-full',
            header: 'hidden',
            topBg: 'hidden',
            content: 'flex flex-col',
            sidebar: 'xs:px-8 md:px-12 xl:px-24'
        }, content: _jsxs(_Fragment, { children: [" ", props.children, " "] }, void 0), rightSidebarContent: _jsx(SidebarContent, { navigationConfigItems: settingsNavigationConfig, mainPathname: "settings" }, void 0), mobileSidebarContent: true }, void 0));
};
export default SettingsPage;
