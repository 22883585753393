var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_NOTIFICATION_SUBTYPE } from 'common/graphql/mutations/notification/update-notification-subtype';
import { NOTIFICATION_SUBTYPES } from 'common/graphql/queries/notification/notification-subtypes';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { notificationSubTypeUpdateValidationSchema } from 'common/validationSchemas/notificationSubTypeValidationSchema';
import { NotificationTemplateSelect } from 'components/Select/NotificationTemplateSelect';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
export var NotificationSubTypeListItemEditMode = function (_a) {
    var notificationSubType = _a.notificationSubType, setIsEditMode = _a.setIsEditMode;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var updateNotificationSubType = function (data) {
        return useMutation(UPDATE_NOTIFICATION_SUBTYPE, {
            variables: {
                updateNotificationSubTypeData: __assign({ id: notificationSubType.id }, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: NOTIFICATION_SUBTYPES
                },
            ]
        });
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: "\u041F\u043E\u0434\u0442\u0438\u043F \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u044F \u043E\u0431\u043D\u043E\u0432\u043B\u0451\u043D",
            severity: SnackbarSeverity.SUCCESS
        }));
        setIsEditMode(false);
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(updateNotificationSubType, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        handleExecuteMutation(__assign({}, data));
    };
    var formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: notificationSubType.description,
            defaultTemplateId: notificationSubType.defaultTemplate.id,
            templateIds: notificationSubType.templates.map(function (o) { return o.id; })
        },
        validationSchema: notificationSubTypeUpdateValidationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    return (_jsxs("form", __assign({ className: "flex flex-row items-center justify-between w-full", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-20 w-full" }, { children: [_jsx(TextField, { className: "max-w-400", label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435", autoFocus: true, multiline: true, name: "description", value: formik.values['description'], onChange: handleChangeWrapper, error: Boolean(formik.errors['description']), helperText: formik.errors['description'] }, void 0), _jsx(NotificationTemplateSelect, { multiple: false, selectedOptionIds: [formik.values['defaultTemplateId']], setSelectedOptions: function (data) {
                            formik.setFieldValue('defaultTemplateId', data[0].id);
                        } }, void 0), _jsx(NotificationTemplateSelect, { multiple: true, selectedOptionIds: formik.values['templateIds'], setSelectedOptions: function (data) {
                            formik.setFieldValue('templateIds', data.map(function (o) { return o.id; }));
                        } }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(Button, __assign({ type: "reset", color: "secondary", onClick: function () {
                            setIsEditMode(false);
                        } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)] }), void 0));
};
