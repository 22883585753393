export var requestParamsFactory = {
    GET: function () { return ({
        method: 'GET',
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    }); },
    POST: function (payload) {
        if (payload === void 0) { payload = {}; }
        return ({
            method: 'POST',
            data: payload,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    PUT: function (payload) {
        if (payload === void 0) { payload = {}; }
        return ({
            method: 'PUT',
            data: payload,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    DELETE: function (payload) { return ({
        method: 'DELETE',
        data: payload,
        withCredentials: true,
        headers: {
        //'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        }
    }); }
};
