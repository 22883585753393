var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Drawer } from '@mui/material';
import clsx from 'clsx';
import React, { useImperativeHandle, useState } from 'react';
import PageCardedSidebarContent from './PageCardedSidebarContent';
function PageCardedSidebar(props, ref) {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var classes = props.classes;
    useImperativeHandle(ref, function () { return ({
        toggleSidebar: handleToggleDrawer
    }); });
    var handleToggleDrawer = function () {
        setIsOpen(!isOpen);
    };
    return (_jsxs(React.Fragment, { children: [props.mobile && (_jsx(Box, __assign({ className: clsx(classes.leftSidebar, 'mobile-sidebar xs:flex md:hidden w-full') }, { children: _jsx(PageCardedSidebarContent, __assign({}, props), void 0) }), void 0)), props.variant === 'permanent' && (_jsx(Drawer, __assign({ variant: "permanent", className: clsx(classes.sidebarWrapper, props.variant, 'bg-bg-container-outer-lt dark:bg-bg-container-outer-dk xs:hidden md:flex'), open: isOpen, classes: {
                    paper: clsx(classes.sidebar, props.variant, props.position === 'left' ? classes.leftSidebar : classes.rightSidebar)
                } }, { children: _jsx(PageCardedSidebarContent, __assign({}, props), void 0) }), void 0))] }, void 0));
}
export default React.forwardRef(PageCardedSidebar);
{
    /*           <Hidden mdUp={props.variant === 'permanent'}>
                <Drawer
                    variant="temporary"
                    anchor={props.position}
                    open={isOpen}
                    onClose={(ev) => handleToggleDrawer()}
                    classes={{
                        root: clsx(classes.sidebarWrapper, props.variant),
                        paper: clsx(classes.sidebar, props.variant,
                            props.position === 'left' ? classes.leftSidebar : classes.rightSidebar)
                    }}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                    container={props.rootRef.current}
                    BackdropProps={{
                        classes: {
                            root: classes.backdrop
                        }
                    }}
                    style={{ position: 'absolute' }}
                    onClick={(ev) => handleToggleDrawer()}
                >
                    <PageCardedSidebarContent {...props} />
                </Drawer>
            </Hidden> */
}
