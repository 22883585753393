var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import history from 'store/history';
var TournamentStandingsListItem = function (_a) {
    var _b;
    var standings = _a.standings;
    var t = useTranslation().t;
    var onPreviewButtonClick = function () {
        history.push("/standings-settings/".concat(standings.id));
    };
    return (_jsx("div", __assign({ className: "flex border-1 solid rounded-8 xs:p-8 md:p-10 \n                xs:mb-8 md:mb-10 decoration-0 border-border-lt dark:border-border-dk" }, { children: _jsxs("div", __assign({ className: "flex flex-col space-y-20 w-full" }, { children: [_jsxs("div", __assign({ className: "flex  flex-row items-center justify-between pr-10" }, { children: [_jsx("div", __assign({ className: "xs:text-14 md:text-16 font-semibold px-8 py-2" }, { children: standings.displayName }), void 0), _jsx("div", __assign({ className: "flex flex-row items-center max-w-288" }, { children: _jsx(Button, __assign({ type: "button", onClick: onPreviewButtonClick }, { children: t('Изменить') }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row xs:space-x-0 xs:space-y-20 sm:space-y-0 sm:space-x-20" }, { children: [_jsxs("div", __assign({ className: "flex flex-col px-8" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: [t('Тип участников'), ":\u00A0"] }), void 0), _jsx("div", { children: standings.participantType }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: [t('Виды спорта'), ":\u00A0"] }), void 0), _jsx("div", { children: ((_b = standings.sports) === null || _b === void 0 ? void 0 : _b.length) > 0
                                                ? standings.sports.map(function (sport) { return t(sport.displayName); }).join(', ')
                                                : '-' }, void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-col px-8" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: [t('Настройки приёма тренировок'), ":\u00A0"] }), void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-5 pt-10" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: [t('Начало приёма'), ":\u00A0"] }), void 0), _jsxs("div", { children: [" ", new Date(standings.activitySettings.activityStartDate).toLocaleString()] }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: [t('Окончание прёма'), ":\u00A0"] }), void 0), _jsxs("div", { children: [" ", new Date(standings.activitySettings.activityEndDate).toLocaleString()] }, void 0)] }), void 0), standings.activitySettings.activityStartDateSinceParticipantJoined && (_jsx("div", __assign({ className: "flex" }, { children: _jsx("p", { children: t('Тренировки принимаются только с момента вступления пользователя в турнир') }, void 0) }), void 0)), standings.activitySettings.activityStartDateSinceUserJoinedParticipantEntity && (_jsx("div", __assign({ className: "flex" }, { children: _jsx("p", { children: t('Тренировки принимаются только с момента вступления пользователя в команду') }, void 0) }), void 0))] }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default TournamentStandingsListItem;
