import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';


const LoginLayout = (props) => {
  
    const loginTheme = useSelector(({ base }) => base.settings.loginTheme);

    return (
        <ThemeProvider theme={loginTheme}>
            <Box id='content-container login'
            className= {props.className ||  'h-full flex sm:justify-center lg:justify-end sm:p-16 lg:p-24'}>
                {props.children}
            </Box>
        </ThemeProvider>
    );
}

export default LoginLayout;