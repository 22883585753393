/**
 * Тип действия, которое можно совершить с фичей, прикрепленной к турниру
 * формат: ИмяФичиТипДействия
 */
export var TournamentFeatureActionType;
(function (TournamentFeatureActionType) {
    /**
     * Рассчитать данные фичи для тренировки(ок) повторно
     */
    TournamentFeatureActionType["CommonCalculateActivityFeatureData"] = "CommonCalculateActivityFeatureData";
    /**
     *  получить сводную информацию о виртуальных очках пользователя / команды и бустерах
     */
    TournamentFeatureActionType["VirtualPointsGetSummary"] = "VirtualPointsGetSummary";
    /**
     *  узнать стоимость бустеров для команды (с учётом модификаторов стоимости)
     */
    TournamentFeatureActionType["VirtualPointsGetTeamBoosterPrices"] = "VirtualPointsGetTeamBoosterPrices";
    /**
     * покупка бустера
     */
    TournamentFeatureActionType["VirtualPointsBuyTeamBooster"] = "VirtualPointsBuyTeamBooster";
    /**
     * активация бустера вручную
     */
    TournamentFeatureActionType["VirtualPointsActivateTeamBooster"] = "VirtualPointsActivateTeamBooster";
    /**
     * деактивация бустера вручную
     */
    TournamentFeatureActionType["VirtualPointsDeActivateTeamBooster"] = "VirtualPointsDeActivateTeamBooster";
})(TournamentFeatureActionType || (TournamentFeatureActionType = {}));
