var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Tooltip } from '@mui/material';
import clsx from 'clsx';
var ActivitiesFilterItem = function (_a) {
    var children = _a.children, filterBy = _a.filterBy, sport = _a.sport, chahgeFilterParams = _a.chahgeFilterParams, buttonText = _a.buttonText, tooltip = _a.tooltip;
    return (_jsx("div", __assign({ className: "p-2" }, { children: buttonText ? (_jsx(Button, __assign({ className: clsx('tw-filter-icon', filterBy.length === 0 && "selected"), variant: "activityFilterButton", onClick: function () { return chahgeFilterParams(); } }, { children: buttonText }), void 0)) : (_jsx(Tooltip, __assign({ title: tooltip }, { children: _jsx("div", __assign({ className: clsx("tw-filter-icon", sport && filterBy.includes(sport) && "selected"), onClick: function () { return chahgeFilterParams(sport); } }, { children: children }), void 0) }), void 0)) }), void 0));
};
export default ActivitiesFilterItem;
