import React from 'react';

function RightSideLayout1(props)
{
    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default RightSideLayout1;
