var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RunningWoman from 'assets/images/running_woman';
import SnowboardMan from 'assets/images/snowabord-man';
import { useTranslation } from 'react-i18next';
var EmptyDataTemplate = function (_a) {
    var text = _a.text;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "tw-empty-data-template-component" }, { children: [_jsx(SnowboardMan, { className: "tw-snowboard-man" }, void 0), _jsx("div", __assign({ className: "tw-text" }, { children: t(text) }), void 0), _jsx(RunningWoman, { className: "tw-running-woman" }, void 0)] }), void 0));
};
export default EmptyDataTemplate;
