var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import NotificationInputListItem from './NotificationInputListItem';
export var NotificationInputsList = function (_a) {
    var notificationInputs = _a.notificationInputs;
    return (_jsx("div", __assign({ className: "flex flex-col space-y-10 pt-20" }, { children: notificationInputs && (notificationInputs === null || notificationInputs === void 0 ? void 0 : notificationInputs.length) > 0 ? (_jsx("div", { children: _.orderBy(notificationInputs, function (o) { return o.updatedAt; }, 'desc').map(function (notificationInput) {
                return (_jsx(NotificationInputListItem, { notificationInput: notificationInput }, notificationInput.id));
            }) }, void 0)) : (_jsx("div", { children: "\u0418\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u043D\u044B\u0445 \u0442\u0435\u043A\u0441\u0442\u043E\u0432 \u0434\u043B\u044F \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u0439 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E" }, void 0)) }), void 0));
};
