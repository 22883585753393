var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useStravaUnBindMutation } from 'common/hooks/useStravaUnBindMutation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
var StravaUnBind = function (_a) {
    var serviceBindingId = _a.serviceBindingId;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var location = useHistory().location;
    var onStravaBindError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onStravaBindSuccess = function () {
        dispatch(openSnackbar({
            message: t('Strava аккаунт успешно откреплен.'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var _b = useDynamicMutation(useStravaUnBindMutation, onStravaBindSuccess, onStravaBindError), handleExecuteMutation = _b.handleExecuteMutation, loading = _b.loading;
    var handleUnBind = function () {
        handleExecuteMutation({ serviceBindingId: serviceBindingId });
    };
    return (_jsx(_Fragment, { children: !loading && (_jsx(_Fragment, { children: _jsx(Link, __assign({ className: "font-medium ml-4", to: "#", onClick: handleUnBind }, { children: t('Отвязать аккаунт') }), void 0) }, void 0)) }, void 0));
};
export default StravaUnBind;
