var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Card, CardContent } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordVerification } from "store/actions";
import InputNewPasswordForm from "./InputNewPasswordForm/InputNewPasswordForm";
import ResetPasswordErrorForm from "./ResetPasswordErrorForm/ResetPasswordErrorForm";
import { withRouter } from "react-router-dom";
import LoginLayout from "main/layouts/layout/components/LoginLayout";
var ResetPasswordPage = function (props) {
    var dispatch = useDispatch();
    var resetPassword = useSelector(function (_a) {
        var auth = _a.auth;
        return auth.resetPassword;
    });
    var match = props.match;
    var key = match.params.key;
    var id = match.params.verificationId;
    var height = (resetPassword.isIdValid && id && key) ? '36rem' : '22rem';
    useEffect(function () {
        if (id) {
            dispatch(resetPasswordVerification(id));
        }
    }, [dispatch, id]);
    return (_jsx(LoginLayout, { children: _jsx(Card, __assign({ sx: {
                width: { xs: '100%', sm: '38rem', md: '40rem', lg: '42rem' },
                height: { xs: '100%', sm: height },
                marginRight: { lg: '10rem' },
                marginTop: { sm: '5rem' },
                borderRadius: { xs: 0, sm: '1rem' },
                backgroundColor: function (theme) { return theme.palette.background.opacityContent; }
            } }, { children: _jsx(CardContent, __assign({ sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    m: '2rem'
                } }, { children: resetPassword.isVerified &&
                    (resetPassword.isIdValid && id && key ? (_jsx(InputNewPasswordForm, { verificationId: id, verificationKey: key }, void 0)) : (_jsx(ResetPasswordErrorForm, {}, void 0))) }), void 0) }), void 0) }, void 0));
};
export default withRouter(ResetPasswordPage);
