var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var ImpulseIcon = function (props) {
    var _a = props.width, width = _a === void 0 ? '44' : _a, _b = props.height, height = _b === void 0 ? '44' : _b, _c = props.color, color = _c === void 0 ? 'white' : _c;
    return (_jsxs("svg", __assign({ width: width, height: height, viewBox: "0 0 24 24", fill: color, xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "5", width: "4", height: "24", rx: "2", fill: color }, void 0), _jsx("path", { d: "M0 7.64697C0 6.5424 0.895431 5.64697 2 5.64697V5.64697C3.10457 5.64697 4 6.5424 4 7.64697V16.3529C4 17.4574 3.10457 18.3529 2 18.3529V18.3529C0.895431 18.3529 0 17.4574 0 16.3529V7.64697Z", fill: color }, void 0), _jsx("rect", { x: "10", y: "2.82324", width: "4", height: "11.7647", rx: "2", fill: color }, void 0), _jsx("rect", { x: "20", y: "6.11768", width: "4", height: "8", rx: "2", fill: color }, void 0), _jsx("rect", { x: "15", y: "7.5293", width: "4", height: "14.5882", rx: "2", fill: color }, void 0)] }), void 0));
};
export default ImpulseIcon;
