var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
var GroupLabel = function (_a) {
    var classes = _a.classes, label = _a.label, logo = _a.logo;
    return (_jsx("div", __assign({ className: clsx(classes === null || classes === void 0 ? void 0 : classes.root, 'group-label flex items-center') }, { children: (logo || label) && (_jsxs("div", __assign({ className: "flex xs:px-8 sm:px-24 xs:w-110 sm:w-144 " }, { children: [logo && _jsx("div", __assign({ className: clsx('group-label-logo pr-5') }, { children: logo }), void 0), label && (_jsx("div", __assign({ className: clsx(classes === null || classes === void 0 ? void 0 : classes.label, 'group-label-text sx:text-xs sm:text-sm font-600') }, { children: label }), void 0))] }), void 0)) }), void 0));
};
export default GroupLabel;
