var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Paper, Radio, styled, TextField } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { DELETE_USER_ADDRESS } from 'common/graphql/mutations/user/address/delete-user-address';
import { UPDATE_USER_ADDRESS } from 'common/graphql/mutations/user/address/update-user-address';
import { USER_ADDRESSES } from 'common/graphql/queries/user/address/user-addresses';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
var AddressPaper = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({ padding: theme.spacing(2) }, theme.typography.body2), { textAlign: 'center' }));
});
var UserAddressListItem = function (_a) {
    var userAddress = _a.userAddress;
    var _b = useState(false), isEditMode = _b[0], setIsEditMode = _b[1];
    var _c = useState(false), isDataChanged = _c[0], setIsDataChanged = _c[1];
    var _d = useState({
        id: userAddress.id,
        isCurrent: userAddress.isCurrent,
        house: userAddress.house,
        city: userAddress.city,
        flat: userAddress.flat,
        postalCode: userAddress.postalCode,
        street: userAddress.street,
        region: userAddress.region
    }), addressData = _d[0], setAddressData = _d[1];
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var updateUserAddress = function (data) {
        return useMutation(UPDATE_USER_ADDRESS, {
            variables: {
                updateUserAddressData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER_ADDRESSES
                },
            ]
        });
    };
    var deleteUserAddress = function (id) {
        return useMutation(DELETE_USER_ADDRESS, {
            variables: {
                id: id
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER_ADDRESSES
                },
            ]
        });
    };
    var onDeleteSuccess = function () {
        dispatch(openSnackbar({
            message: "\u0410\u0434\u0440\u0435\u0441 \u0443\u0434\u0430\u043B\u0435\u043D",
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onUpdateSuccess = function () {
        dispatch(openSnackbar({
            message: "\u0410\u0434\u0440\u0435\u0441 \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D",
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteDeleteAddressMutation = useDynamicMutation(deleteUserAddress, onDeleteSuccess, onError).handleExecuteMutation;
    var handleExecuteUpdateAddressMutation = useDynamicMutation(updateUserAddress, onUpdateSuccess, onError).handleExecuteMutation;
    var openDeleteUserAddressRequestDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.DeleteUserAddressRequest,
            dialogProps: {
                handleDeleteAddress: handleDeleteAddress
            }
        }));
    };
    var onDeleteAddressButtonClick = function () {
        openDeleteUserAddressRequestDialog();
    };
    var handleDeleteAddress = function () {
        handleExecuteDeleteAddressMutation(userAddress.id);
    };
    var handleSetActive = function (value) {
        handleExecuteUpdateAddressMutation(__assign(__assign({}, addressData), { isCurrent: true }));
    };
    var handleSave = function () {
        if (isDataChanged)
            handleExecuteUpdateAddressMutation(__assign({}, addressData));
        setIsEditMode(false);
    };
    return (_jsx(AddressPaper, __assign({ variant: "outlined", square: false, className: "flex xs:flex-col sm:flex-row" }, { children: _jsxs("div", __assign({ className: "flex flex-1 flex-col space-y-20" }, { children: [_jsxs("div", __assign({ className: "flex  flex-row items-center justify-between" }, { children: [_jsxs("div", __assign({ className: "flex flex-row items-center" }, { children: [_jsx(Radio, { checked: userAddress.isCurrent, onChange: function (event) { return handleSetActive(event.target.value); } }, void 0), userAddress.isCurrent && _jsx("p", { children: t('Актуальный') }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex items-center flex-row space-x-20" }, { children: [!isEditMode && (_jsx(IconButton, __assign({ className: "h-14 w-14 p-0", onClick: function () { return setIsEditMode(true); } }, { children: _jsx(EditIcon, {}, void 0) }), void 0)), isEditMode && (_jsx(Button, __assign({ type: "primary sm", onClick: function () { return handleSave(); } }, { children: t('Сохранить') }), void 0)), _jsx(IconButton, __assign({ className: "h-16 w-16 p-0", onClick: onDeleteAddressButtonClick }, { children: _jsx(DeleteIcon, {}, void 0) }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row xs:space-x-0 sm:space-x-10 xs:space-y-10 sm:space-y-0" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(TextField, { variant: "standard", disabled: !isEditMode, label: t('Индекс'), value: addressData.postalCode, onChange: function (event) {
                                        setIsDataChanged(true);
                                        setAddressData(function (prevValue) { return (__assign(__assign({}, prevValue), { postalCode: event.target.value })); });
                                    } }, void 0), _jsx(TextField, { variant: "standard", disabled: !isEditMode, label: t('Регион'), value: addressData.region, onChange: function (event) {
                                        setIsDataChanged(true);
                                        setAddressData(function (prevValue) { return (__assign(__assign({}, prevValue), { region: event.target.value })); });
                                    } }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(TextField, { variant: "standard", disabled: !isEditMode, label: t('Город'), value: addressData.city, onChange: function (event) {
                                        setIsDataChanged(true);
                                        setAddressData(function (prevValue) { return (__assign(__assign({}, prevValue), { city: event.target.value })); });
                                    } }, void 0), _jsx(TextField, { variant: "standard", disabled: !isEditMode, label: t('Улица'), value: addressData.street, onChange: function (event) {
                                        setIsDataChanged(true);
                                        setAddressData(function (prevValue) { return (__assign(__assign({}, prevValue), { street: event.target.value })); });
                                    } }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(TextField, { variant: "standard", disabled: !isEditMode, label: t('Дом'), value: addressData.house, onChange: function (event) {
                                        setIsDataChanged(true);
                                        setAddressData(function (prevValue) { return (__assign(__assign({}, prevValue), { house: event.target.value })); });
                                    } }, void 0), _jsx(TextField, { variant: "standard", disabled: !isEditMode, label: t('Квартира'), value: addressData.flat, onChange: function (event) {
                                        setIsDataChanged(true);
                                        setAddressData(function (prevValue) { return (__assign(__assign({}, prevValue), { flat: event.target.value })); });
                                    } }, void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default UserAddressListItem;
