import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { USER_SPORT_EVENT_PERMISSIONS } from 'common/graphql/queries/sportEvent/userSportEventPermissions';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import PageCarded from 'components/PageLayouts/carded/PageCarded';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import SidebarContent from '../../layouts/shared-components/SidebarContent/SidebarContent';
import sportEventSettingsNavigationConfig from './sportEventSettingsNavigationConfig';
var SportEventSettingsPage = function (props) {
    var _a, _b;
    var dispatch = useDispatch();
    var sportEventId = useParams().sportEventId;
    var _c = useState(undefined), canAdminEvent = _c[0], setCanAdminEvent = _c[1];
    var _d = useLazyQuery(USER_SPORT_EVENT_PERMISSIONS), getUserSportEventPermissions = _d[0], _e = _d[1], userSportEventPermissionsData = _e.data, loading = _e.loading, error = _e.error;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var isAdmin = !!((_b = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles) === null || _a === void 0 ? void 0 : _a.map(function (role) { return role.name; })) === null || _b === void 0 ? void 0 : _b.filter(function (roleName) { return ['Admin', 'SuperAdmin'].includes(roleName); })[0]);
    useEffect(function () {
        if (sportEventId) {
            if (isAdmin) {
                setCanAdminEvent(true);
            }
            else {
                getUserSportEventPermissions();
            }
        }
    }, [sportEventId]);
    useEffect(function () {
        if ((userSportEventPermissionsData === null || userSportEventPermissionsData === void 0 ? void 0 : userSportEventPermissionsData.userSportEventPermissions) && sportEventId) {
            var permissions = userSportEventPermissionsData.userSportEventPermissions;
            if (permissions.adminsEvents.includes(sportEventId) || permissions.ownsEvents.includes(sportEventId))
                setCanAdminEvent(true);
        }
    }, [userSportEventPermissionsData]);
    useEffect(function () {
        if (canAdminEvent === false) {
            dispatch(openSnackbar({
                message: 'У вас нет прав на управление этим событием',
                severity: SnackbarSeverity.ERROR
            }));
        }
    }, [canAdminEvent]);
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(PageCarded, { classes: {
            root: 'w-full',
            header: 'hidden',
            topBg: 'hidden',
            content: 'flex flex-col',
            sidebar: 'xs:px-8 md:px-12 xl:px-24'
        }, breadcrumbs: _jsx(Breadcrumbs, {}, void 0), content: canAdminEvent && _jsx(_Fragment, { children: props.children }, void 0), rightSidebarContent: canAdminEvent && (_jsx(SidebarContent, { navigationConfigItems: sportEventSettingsNavigationConfig, mainPathname: 'sport-event-settings/' + sportEventId }, void 0)), mobileSidebarContent: true }, void 0));
};
export default SportEventSettingsPage;
