import { makeVar } from '@apollo/client';
export var tournamentStandingsPageVar = makeVar({
    tournamentsNameList: [],
    userTournamentsNameList: [],
    tournamentNameInput: {},
    ageGroupInput: undefined,
    athletNameInput: '',
    genderInput: undefined,
    myTournamentsCheckbox: false
});
