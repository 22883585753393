var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TournamentNominationListItemWinnersInfo } from './TournamentNominationListItemWinnersInfo';
var TournamentNominationTooltip = function (_a) {
    var nominationDescription = _a.nominationDescription;
    var _b = useState(false), openTooltip = _b[0], setOpenTooltip = _b[1];
    var isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    return (_jsx(Tooltip, __assign({ className: "hover:cursor-pointer", onMouseEnter: function () { return !isMobile && setOpenTooltip(true); }, onMouseLeave: function () { return setOpenTooltip(false); }, open: openTooltip, enterTouchDelay: 0, placement: "top-start", leaveTouchDelay: 10000, leaveDelay: 700, title: _jsx(Fragment, { children: nominationDescription }, void 0) }, { children: _jsx(InfoOutlinedIcon, { onClick: function () { return setOpenTooltip(!openTooltip); }, fontSize: "small", color: "warning" }, void 0) }), void 0));
};
var TournamentNominationListItem = function (_a) {
    var tournamentNominationWinners = _a.tournamentNominationWinners, nomination = _a.nomination, standingsParticipantType = _a.standingsParticipantType, isPreview = _a.isPreview, handleClose = _a.handleClose;
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var tournamentNomination = tournamentNominationWinners.tournamentNomination;
    return nomination ? (_jsxs("div", __assign({ className: "flex items-start w-full" }, { children: [_jsx(UserAvatar, { style: "xs:w-68 xs:h-68 xs:text-20 md:w-88 md:h-88 md:text-24", firstName: tournamentNomination.displayName, profilePhoto: tournamentNomination.profilePhoto }, void 0), _jsxs("div", __assign({ className: "w-full h-full flex flex-col justify-start" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-5 items-center" }, { children: [_jsx("p", __assign({ className: "flex xs:text-14 md:text-16 font-semibold px-8 py-2 dark:text-white" }, { children: i18n.language === 'en'
                                    ? tournamentNomination.displayNameEn || tournamentNomination.displayName
                                    : tournamentNomination.displayName }), void 0), _jsx(TournamentNominationTooltip, { nominationDescription: i18n.language === 'en'
                                    ? tournamentNomination.descriptionEn ||
                                        tournamentNomination.description ||
                                        nomination.description
                                    : tournamentNomination.description || nomination.description }, void 0)] }), void 0), tournamentNominationWinners && isPreview !== undefined ? (_jsx(TournamentNominationListItemWinnersInfo, { isPreview: isPreview, tournamentNominationWinners: tournamentNominationWinners, tournamentNomination: tournamentNomination, nomination: nomination, standingsParticipantType: standingsParticipantType, handleClose: handleClose }, void 0)) : (_jsx("div", __assign({ className: "px-8 pt-10 dark:text-white" }, { children: _jsx("p", { children: t('Победители и лидеры не определены') }, void 0) }), void 0))] }), void 0)] }), void 0)) : null;
};
export default TournamentNominationListItem;
