import * as Yup from 'yup';
var standingsColumnValidationSchema = Yup.object().shape({
    columnName: Yup.string()
        .trim()
        .min(3, "\u0421\u043B\u0438\u0448\u043A\u043E\u043C \u043A\u043E\u0440\u043E\u0442\u043A\u043E\u0435 \u043D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435.\n\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043E\u0442 3 \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432")
        .max(64, "\u0421\u043B\u0438\u0448\u043A\u043E\u043C \u0434\u043B\u0438\u043D\u043D\u043E\u0435 \u043D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435.\n\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u043E 64 \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432")
        .required('Не указано наименование'),
    columnType: Yup.object().required('Не указан тип столбца')
});
export default standingsColumnValidationSchema;
