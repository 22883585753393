var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery, useSubscription } from '@apollo/client';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Badge } from '@mui/material';
import { NotificationStatus } from 'common/enums/notification/notification-status.enum';
import { NOTIFICATION } from 'common/graphql/queries/notification/notification';
import { USER_POP_UP_UI_NOTIFICATIONS_COUNT } from 'common/graphql/queries/notification/user-pop-up-ui-notifications-count';
import { NOTIFICATION_CREATED } from 'common/graphql/subscriptions/notification-created';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var UserNotificationsIconBadge = function (_a) {
    var setNotificationsMenu = _a.setNotificationsMenu;
    var dispatch = useDispatch();
    var notificationMenuClick = function (event) {
        setNotificationsMenu(event.currentTarget);
    };
    var _b = useLazyQuery(USER_POP_UP_UI_NOTIFICATIONS_COUNT, {
        variables: {
            status: NotificationStatus.isNotified
        },
        fetchPolicy: 'network-only'
    }), getNotificationsCount = _b[0], data = _b[1].data;
    useEffect(function () {
        getNotificationsCount();
    }, []);
    var _c = useLazyQuery(NOTIFICATION), getNotification = _c[0], newNotificationData = _c[1].data;
    var createdNotification = useSubscription(NOTIFICATION_CREATED).data;
    useEffect(function () {
        var _a, _b;
        if ((_a = createdNotification === null || createdNotification === void 0 ? void 0 : createdNotification.notificationCreated) === null || _a === void 0 ? void 0 : _a.id) {
            getNotification({
                variables: {
                    id: (_b = createdNotification === null || createdNotification === void 0 ? void 0 : createdNotification.notificationCreated) === null || _b === void 0 ? void 0 : _b.id
                }
            });
            getNotificationsCount();
        }
    }, [createdNotification]);
    useEffect(function () {
        var _a;
        var lastNotificationId = localStorage.getItem("lastNotificationId");
        if (newNotificationData &&
            (newNotificationData === null || newNotificationData === void 0 ? void 0 : newNotificationData.notification) &&
            lastNotificationId !== newNotificationData.notification.id) {
            localStorage.setItem("lastNotificationId", newNotificationData.notification.id);
            if ('serviceWorker' in navigator && Notification.permission === 'granted') {
                navigator.serviceWorker.ready.then(function (registration) {
                    var _a;
                    return registration.showNotification((_a = newNotificationData.notification.content) === null || _a === void 0 ? void 0 : _a.messageTitleOrSubject, {
                        data: {
                            url: "".concat(registration.scope, "notification/").concat(newNotificationData.notification.id)
                        }
                    });
                });
            }
            else {
                dispatch(openSnackbar({
                    message: (_a = newNotificationData.notification.content) === null || _a === void 0 ? void 0 : _a.messageTitleOrSubject,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));
            }
        }
    }, [newNotificationData]);
    return (_jsx("div", __assign({ className: "flex items-center pr-10 hover:cursor-pointer", onClick: notificationMenuClick }, { children: _jsx(Badge, __assign({ badgeContent: (data === null || data === void 0 ? void 0 : data.userPopUpUINotificationsCount) || 0, color: "error" }, { children: _jsx(NotificationsActiveIcon, { color: "primary" }, void 0) }), void 0) }), void 0));
};
export default UserNotificationsIconBadge;
