var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import Cycle from 'assets/icons/activities/Cycle';
import Hike from 'assets/icons/activities/Hike';
import Run from 'assets/icons/activities/Run';
import Ski from 'assets/icons/activities/Ski';
import Swim from 'assets/icons/activities/Swim';
import clsx from 'clsx';
import { Sports } from 'common/enums/sport/sports.enum';
import { useTranslation } from 'react-i18next';
import HelperFunctions from 'utils/HelperFunctions';
import ActivitiesFilterItem from './components/ActivitiesFilterItem/ActivitiesFilterItem';
var ActivitiesFilter = function (_a) {
    var className = _a.className, filterBy = _a.filterBy, setFilterBy = _a.setFilterBy, _b = _a.isMultiple, isMultiple = _b === void 0 ? true : _b, sports = _a.sports;
    var t = useTranslation().t;
    var getSportDetailsBySportName = HelperFunctions.getSportDetailsBySportName;
    var activitiesFilterItemsBySports = function (sports) {
        return sports.map(function (sport) {
            getSportDetailsBySportName(sport.name);
        });
    };
    var activitiesFilterItems = sports
        ? activitiesFilterItemsBySports(sports)
        : [
            { activityType: Sports.Run, children: _jsx(Run, {}, void 0), tooltip: t('Бег') },
            { activityType: Sports.Ride, children: _jsx(Cycle, {}, void 0), tooltip: t('Велосипед') },
            { activityType: Sports.Walking, children: _jsx(Hike, {}, void 0), tooltip: t('Ходьба') },
            { activityType: Sports.Swimming, children: _jsx(Swim, {}, void 0), tooltip: t('Плавание') },
            { activityType: Sports.Skiing, children: _jsx(Ski, {}, void 0), tooltip: t('Лыжи') },
        ];
    var chahgeFilterParams = function (sport) {
        if (isMultiple) {
            if (sport && filterBy.includes(sport)) {
                //switch off remove param from array
                var result = filterBy.filter(function (param) { return param != sport; });
                setFilterBy(__spreadArray([], result, true));
            }
            else {
                //switch on
                sport ? setFilterBy(__spreadArray(__spreadArray([], filterBy, true), [sport], false)) : setFilterBy([]);
            }
        }
        else {
            sport ? setFilterBy([sport]) : setFilterBy(Sports.Run);
        }
    };
    return (_jsx("div", __assign({ className: clsx(className, 'flex flex-wrap') }, { children: activitiesFilterItems.map(function (item, index) {
            return (_jsx(ActivitiesFilterItem, { filterBy: filterBy, chahgeFilterParams: chahgeFilterParams, children: item === null || item === void 0 ? void 0 : item.children, sport: item === null || item === void 0 ? void 0 : item.activityType, tooltip: item === null || item === void 0 ? void 0 : item.tooltip }, index));
        }) }), void 0));
};
export default ActivitiesFilter;
