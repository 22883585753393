var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@apollo/client';
import { UPDATE_ORGANIZATION } from 'common/graphql/mutations/organization/update-organization';
import { ORGANIZATION } from 'common/graphql/queries/organization/organization';
export function useUpdateOrganizationMutation(organizationVariables) {
    var _a;
    var profilePhoto;
    var organizationData = {};
    var organizationId = organizationVariables === null || organizationVariables === void 0 ? void 0 : organizationVariables.id;
    for (var key in organizationVariables) {
        if (key !== 'profilePhoto' && key !== 'removeProfilePhoto') {
            // ключи
            organizationData = __assign(__assign({}, organizationData), (_a = {}, _a[key] = organizationVariables[key], _a));
        }
    }
    if (organizationVariables === null || organizationVariables === void 0 ? void 0 : organizationVariables.profilePhoto) {
        profilePhoto = organizationVariables.profilePhoto;
    }
    if (organizationVariables === null || organizationVariables === void 0 ? void 0 : organizationVariables.removeProfilePhoto) {
        profilePhoto = null;
    }
    return useMutation(UPDATE_ORGANIZATION, {
        variables: __assign({ updateOrganizationData: __assign({}, organizationData) }, (typeof profilePhoto !== undefined && { profilePhoto: profilePhoto })),
        refetchQueries: [
            {
                query: ORGANIZATION,
                variables: {
                    id: organizationId
                }
            },
        ]
    });
}
