var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var SPORT_CLUB_USER_JOIN_REQUESTS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tquery sportClubUserJoinRequestsSearch($sportClubId: String!) {\n\t\tsportClubUserJoinRequestsSearch(sportClubId: $sportClubId) {\n\t\t\tuserId\n\t\t\tsportClubId\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tuserName\n\t\t\tuserProfilePhotoId\n\t\t\tuserProfilePhotoUrl\n\t\t}\n\t}\n"], ["\n\tquery sportClubUserJoinRequestsSearch($sportClubId: String!) {\n\t\tsportClubUserJoinRequestsSearch(sportClubId: $sportClubId) {\n\t\t\tuserId\n\t\t\tsportClubId\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tuserName\n\t\t\tuserProfilePhotoId\n\t\t\tuserProfilePhotoUrl\n\t\t}\n\t}\n"])));
var templateObject_1;
//firstName
// lastName
// userName
// userProfilePhotoId
// userProfilePhotoUrl
// isAdmin
// isOwner
