var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import GoogleIconV2 from 'assets/logos/GoogleIconV2';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { googleClientId } from 'common/socialClientIdsSetup';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { googleSignIn, openSnackbar } from 'store/actions';
var GoogleSignIn = function () {
    var dispatch = useDispatch();
    var handleLogin = function (googleData) {
        if (googleData.code) {
            dispatch(googleSignIn(googleData));
        }
        else {
            dispatch(openSnackbar({
                message: 'Ошибка получения данных от Google, вероятно вы не предоставили нужные права доступа.',
                severity: SnackbarSeverity.ERROR
            }));
        }
    };
    var SCOPES = 'profile email ' +
        'https://www.googleapis.com/auth/user.birthday.read ' +
        'https://www.googleapis.com/auth/user.gender.read ' +
        'https://www.googleapis.com/auth/user.phonenumbers.read';
    return (_jsx(GoogleLogin, { clientId: googleClientId, onSuccess: handleLogin, onFailure: handleLogin, responseType: "code", cookiePolicy: 'single_host_origin', scope: SCOPES, render: function (renderProps) { return (_jsx(IconButton, __assign({ sx: { width: '6rem' }, onClick: renderProps.onClick }, { children: _jsx(GoogleIconV2, {}, void 0) }), void 0)); } }, void 0));
};
export default GoogleSignIn;
