import {combineReducers} from 'redux';
import auth from './auth';
import base from './base';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        base,
        ...asyncReducers
    });

export default createReducer;
