var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { NotificationTargetTypes } from 'common/enums/notification/notification-target-types.enum';
import { NotificationTypes } from 'common/enums/notification/notification-types.enum';
import { SEND_NOTIFICATIONS } from 'common/graphql/mutations/notification/send-notification';
import { NOTIFICATION_DISPATCH_INFO_LIST } from 'common/graphql/queries/notification/notification-dispatch-info-list';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { notificationDispatchInfoCreateValidationSchema } from 'common/validationSchemas/notificationDispatchInfoCreateValidationSchema';
import { CommunityAutocomplete } from 'components/Autocomplete/CommunityAutocomplete';
import { NotificationInputAutocomplete } from 'components/Autocomplete/NotificationInputAutocomplete';
import { SportClubAutocomplete } from 'components/Autocomplete/SportClubAutocomplete';
import { SportEventAutocomplete } from 'components/Autocomplete/SportEventAutocomplete';
import { TournamentAutocomplete } from 'components/Autocomplete/TournamentsAutocomplete';
import { TournamentTeamAutocomplete } from 'components/Autocomplete/TournamentsTeamsAutocomplete';
import { UserAutocomplete } from 'components/Autocomplete/UserAutocomplete';
import NotificationTargetTypeSelect from 'components/Select/NotificationTargetTypeSelect';
import NotificationTypeSelect from 'components/Select/NotificationTypeSelect';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var NotificationDispatchInfoAddNew = function () {
    var _a = useState(false), isAddNew = _a[0], setIsAddNew = _a[1];
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _b = useState([]), userIds = _b[0], setUserIds = _b[1];
    var _c = useState(''), tournamentId = _c[0], setTournamentId = _c[1];
    var _d = useState('autocomplete'), radioValue = _d[0], setRadioValue = _d[1];
    var sendNotifications = function (data) {
        return useMutation(SEND_NOTIFICATIONS, {
            variables: {
                sendNotificationsData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: NOTIFICATION_DISPATCH_INFO_LIST
                },
            ]
        });
    };
    var handleReset = function () {
        setIsAddNew(false);
        formik.resetForm();
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: "\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0430 \u0437\u0430\u043F\u0443\u0449\u0435\u043D\u0430",
            severity: SnackbarSeverity.SUCCESS
        }));
        handleReset();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(sendNotifications, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        var newData = __assign({}, data);
        if ((userIds === null || userIds === void 0 ? void 0 : userIds.length) > 0) {
            newData['userIds'] = userIds;
        }
        handleExecuteMutation(__assign({}, newData));
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var formik = useFormik({
        initialValues: {
            target: NotificationTargetTypes.distinctUsers,
            notificationType: NotificationTypes.byEmail,
            emailDelayPerMessage: 2000,
            isIgnoreUserDisabledNotifications: false,
            isDispatch: true,
            notificationInputId: undefined,
            targetId: undefined
        },
        validationSchema: notificationDispatchInfoCreateValidationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsx(Box, __assign({ sx: { width: '100%' } }, { children: isAddNew ? (_jsxs("form", __assign({ className: "flex flex-col space-y-10 pt-20", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx(NotificationTypeSelect, { value: formik.values['notificationType'], setValue: function (value) {
                        formik.setFieldValue('notificationType', value);
                    } }, void 0), _jsx(NotificationInputAutocomplete, { selectedValueId: formik.values['notificationInputId'] || '', setSelectedOptions: function (data) {
                        formik.setFieldError('notificationInputId', undefined);
                        formik.setFieldValue('notificationInputId', data.id);
                    } }, void 0), _jsx(TextField, { label: t('Промежуток времени между отправкой, миллисекунды'), type: "number", value: formik.values['emailDelayPerMessage'], onChange: handleChangeWrapper, error: !!formik.errors['emailDelayPerMessage'], helperText: formik.errors['emailDelayPerMessage'] }, void 0), _jsxs("div", __assign({ className: "flex xs:flex-col md:flex-row xs:space-y-10 xs:space-x-0 md:space-x-10 md:space-y-0" }, { children: [_jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { checked: formik.values['isDispatch'] || false, onChange: function (e) {
                                    formik.setFieldError('isDispatch', undefined);
                                    formik.setFieldValue('isDispatch', e.target.checked);
                                } }, void 0), label: "\u0421\u043E\u0437\u0434\u0430\u0432\u0430\u0442\u044C \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443" }, void 0), _jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { checked: formik.values['isIgnoreUserDisabledNotifications'] || false, onChange: function (e) {
                                    formik.setFieldError('isIgnoreUserDisabledNotifications', undefined);
                                    formik.setFieldValue('isIgnoreUserDisabledNotifications', e.target.checked);
                                } }, void 0), label: "\u0418\u0433\u043D\u043E\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043E\u0442\u043A\u043B\u044E\u0447\u0435\u043D\u043D\u044B\u0435 \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u043C" }, void 0)] }), void 0), _jsx(NotificationTargetTypeSelect, { value: formik.values['target'], setValue: function (value) {
                        formik.setFieldValue('target', value);
                    } }, void 0), formik.values['target'] === NotificationTargetTypes.distinctUsers && (_jsxs(_Fragment, { children: [_jsx(FormControl, { children: _jsxs(RadioGroup, __assign({ row: true, value: radioValue, onChange: function (event) {
                                    setRadioValue(event.target.value);
                                } }, { children: [_jsx(FormControlLabel, { value: "autocomplete", control: _jsx(Radio, {}, void 0), label: "\u041D\u0430\u0439\u0442\u0438 \u0438 \u0432\u044B\u0431\u0440\u0430\u0442\u044C" }, void 0), _jsx(FormControlLabel, { value: "idsList", control: _jsx(Radio, {}, void 0), label: "\u0423\u043A\u0430\u0437\u0430\u0442\u044C \u0441\u043F\u0438\u0441\u043E\u043A ID" }, void 0)] }), void 0) }, void 0), radioValue === 'autocomplete' && (_jsx(UserAutocomplete, { setSelectedOptions: function (data) {
                                var _a;
                                setUserIds(__spreadArray(__spreadArray([], userIds, true), [(_a = data === null || data === void 0 ? void 0 : data[0]) === null || _a === void 0 ? void 0 : _a.userId], false));
                            } }, void 0)), radioValue === 'idsList' && (_jsx(TextField, { multiline: true, label: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0441\u043F\u0438\u0441\u043E\u043A ID \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439 \u0447\u0435\u0440\u0435\u0437 \u0437\u0430\u043F\u044F\u0442\u0443\u044E", name: "userIds", value: userIds.join(','), onChange: function (event) {
                                setUserIds((event.target.value || '').replace(/(\r\n|\n|\r)/gm, '').split(','));
                            } }, void 0))] }, void 0)), formik.values['target'] === NotificationTargetTypes.sportEventUsers && (_jsx(SportEventAutocomplete, { selectedValueId: formik.values['targetId'] || '', setSelectedOptions: function (data) {
                        formik.setFieldError('targetId', undefined);
                        formik.setFieldValue('targetId', data.id);
                    } }, void 0)), formik.values['target'] === NotificationTargetTypes.sportClubUsers && (_jsx(SportClubAutocomplete, { selectedValueId: formik.values['targetId'] || '', setSelectedOptions: function (data) {
                        formik.setFieldError('targetId', undefined);
                        formik.setFieldValue('targetId', data.id);
                    } }, void 0)), formik.values['target'] === NotificationTargetTypes.communityUsers && (_jsx(CommunityAutocomplete, { selectedValueId: formik.values['targetId'] || '', setSelectedOptions: function (data) {
                        formik.setFieldError('targetId', undefined);
                        formik.setFieldValue('targetId', data.id);
                    } }, void 0)), formik.values['target'] === NotificationTargetTypes.tournamentUsers && (_jsx(TournamentAutocomplete, { selectedValueId: formik.values['targetId'] || '', setSelectedOptions: function (data) {
                        formik.setFieldError('targetId', undefined);
                        formik.setFieldValue('targetId', data.id);
                    } }, void 0)), formik.values['target'] === NotificationTargetTypes.teamUsers && (_jsxs(_Fragment, { children: [_jsx(TournamentAutocomplete, { selectedValueId: tournamentId, setSelectedOptions: function (data) {
                                setTournamentId(data.id);
                            } }, void 0), tournamentId && (_jsx(TournamentTeamAutocomplete, { tournamentId: tournamentId, selectedValueId: formik.values['targetId'] || '', setSelectedOptions: function (data) {
                                formik.setFieldError('targetId', undefined);
                                formik.setFieldValue('targetId', data.teamId);
                            } }, void 0))] }, void 0)), _jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(Button, __assign({ type: "reset", color: "secondary", onClick: function () { return handleReset(); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Начать отправку оповещений') }), void 0)] }), void 0)] }), void 0)) : (_jsx(Button, __assign({ type: "submit", onClick: function () { return setIsAddNew(true); } }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0)) }), void 0));
};
export default NotificationDispatchInfoAddNew;
