var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { Divider, List } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import NavVerticalCollapse from './vertical/NavVerticalCollapse';
import NavVerticalItem from './vertical/NavVerticalItem';
import NavVerticalLink from './vertical/NavVerticalLink';
var NavigationItems = function (props) {
    var navigation = props.navigation, bottomNavigation = props.bottomNavigation, active = props.active, dense = props.dense, navbarOpen = props.navbarOpen;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var verticalNav = (_jsx("div", __assign({ className: "nav-items h-full flex flex-col justify-between" }, { children: userProfile &&
            [navigation, bottomNavigation].map(function (navigationConfig, index) { return (_jsx(React.Fragment, { children: _jsx(List, __assign({ sx: { my: 0, pt: 0, whiteSpace: 'nowrap' } }, { children: navigationConfig.map(function (item) { return (_jsxs(Box, __assign({ className: 'pt-2', sx: { margin: '6px 0 4px 0' } }, { children: [(item.type === 'item' || item.type === 'profileItem') && (_jsx(NavVerticalItem, { navbarOpen: navbarOpen, item: item, nestedLevel: 0, addUserName: item.type === 'profileItem', active: active, dense: dense }, void 0)), item.type === 'link' && (_jsx(NavVerticalLink, { navbarOpen: navbarOpen, item: item, nestedLevel: 0, active: active, dense: dense }, void 0)), item.type === 'collapse' && (_jsx(NavVerticalCollapse, { navbarOpen: navbarOpen, item: item, nestedLevel: 0, active: active }, void 0)), item.type === 'divider' && _jsx(Divider, { sx: { mb: 1, mx: 1.5 } }, void 0)] }), item.id)); }) }), void 0) }, index)); }) }), void 0));
    if (navigation.length > 0 || bottomNavigation.length > 0) {
        return verticalNav;
    }
    else {
        return null;
    }
};
export default React.memo(NavigationItems);
