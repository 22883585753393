var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from '@mui/material';
import { VirtualPointsBoosterTypesReadableRu, } from 'common/enums/feature/virtual-points-booster-types.enum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
var PointsWithBoosterApplied = function (_a) {
    var boosterApplied = _a.boosterApplied, points = _a.points;
    var _b = useState(false), openPointsWithBoosterAppliedTooltip = _b[0], setOpenPointsWithBoosterAppliedTooltip = _b[1];
    var t = useTranslation().t;
    return (_jsx(Tooltip, __assign({ onMouseOver: function () { return setOpenPointsWithBoosterAppliedTooltip(true); }, onMouseLeave: function () { return setOpenPointsWithBoosterAppliedTooltip(false); }, onClose: function () { return setOpenPointsWithBoosterAppliedTooltip(false); }, open: openPointsWithBoosterAppliedTooltip, title: t("\u041F\u0440\u0438\u043C\u0435\u043D\u0435\u043D\u043D\u044B\u0439 \u0431\u0443\u0441\u0442\u0435\u0440") + ': ' + "".concat(t(VirtualPointsBoosterTypesReadableRu[boosterApplied])) }, { children: _jsx("p", __assign({ className: "text-green" }, { children: points ? points.toFixed(1) : '-' }), void 0) }), void 0));
};
export default PointsWithBoosterApplied;
