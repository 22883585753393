var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
function PolarIcon(props) {
    return (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M23.9633 10.3404C24.5191 17.4805 18.69 23.7752 11.6303 23.994C4.16883 24.2261 -1.23045 17.6792 0.243295 10.3404H23.9631M23.2226 7.07637C21.4798 2.57439 17.1633 -0.120115 12.4334 0.00411744C7.76324 0.125149 3.5028 2.93149 1.38532 7.07637H23.2226Z", fill: "#E71933" }, void 0) }), void 0));
}
export default PolarIcon;
