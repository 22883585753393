var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { ActivityValidationStates } from 'common/enums/activity/activity-validation-states.enum';
import { ActivityValidationTypes } from 'common/enums/activity/activity-validation-types.enum';
import { ACTIVITY_VALIDATIONS } from 'common/graphql/queries/activity-validations/activityValidations';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import _ from 'lodash';
import ActivityValidationResultListItem from './ActivityValidationResultListItem/ActivityValidationResultListItem';
var ActivityValidationsPage = function () {
    var _a;
    var _b = useQuery(ACTIVITY_VALIDATIONS, {
        variables: {
            validationType: ActivityValidationTypes.Manual,
            validationState: ActivityValidationStates.PENDING
        }
    }), data = _b.data, loading = _b.loading;
    var orderValidationsByPriority = function (activityValidations) {
        return _.orderBy(activityValidations, ['validationRequestPriority', 'createdAt'], ['desc', 'desc']);
    };
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u0412\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u0438 \u0442\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043E\u043A, \u043E\u0436\u0438\u0434\u0430\u044E\u0449\u0438\u0435 \u0440\u0430\u0441\u0441\u043C\u043E\u0442\u0440\u0435\u043D\u0438\u044F" }), void 0) }), void 0), content: _jsx("div", __assign({ className: "tw-page-container-shadow" }, { children: (data === null || data === void 0 ? void 0 : data.activityValidations) && ((_a = data === null || data === void 0 ? void 0 : data.activityValidations) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (_jsx("div", { children: orderValidationsByPriority(data.activityValidations).map(function (activityValidation) {
                    return (_jsx(ActivityValidationResultListItem, { activityValidation: activityValidation }, activityValidation.id));
                }) }, void 0)) : (_jsx("div", { children: "\u0412\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u0438 \u0432 \u0441\u0442\u0430\u0442\u0443\u0441\u0435 \u043E\u0436\u0438\u0434\u0430\u043D\u0438\u044F \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B" }, void 0)) }), void 0), innerScroll: false }, void 0));
};
export default ActivityValidationsPage;
