var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
var AddStandingsColumnButton = function (_a) {
    var standingsParticipantType = _a.standingsParticipantType, standingsParentType = _a.standingsParentType, standingsSports = _a.standingsSports, standingsId = _a.standingsId;
    var dispatch = useDispatch();
    var onAddStandingsColumnButtonClick = function () {
        // dispatch(
        // 	openSnackbar({
        // 		message: 'Подключение Strava аккаунта запущено.',
        // 		severity: SnackbarSeverity.SUCCESS,
        // 	}),
        // );
        dispatch(openDialog({
            dialogType: DialogTypes.AddStandingsColumn,
            dialogProps: {
                standingsParticipantType: standingsParticipantType,
                standingsParentType: standingsParentType,
                standingsSports: standingsSports,
                standingsId: standingsId
            }
        }));
    };
    return (_jsxs(Button, __assign({ className: "cursor-pointer", variant: "text", color: "secondary", onClick: onAddStandingsColumnButtonClick }, { children: [_jsx(AddCircleIcon, { className: "mr-8", style: { fontSize: 20, fontWeight: 100 } }, void 0), "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0442\u043E\u043B\u0431\u0435\u0446"] }), void 0));
};
export default AddStandingsColumnButton;
