var standingsSettingsNavigationConfig = [
    {
        id: 'main',
        title: 'Основные настройки',
        handle: 'main'
    },
    {
        id: 'columns',
        title: 'Настройка столбцов',
        handle: 'columns'
    },
    {
        id: 'activity-settings',
        title: 'Настройка приёма тренировок',
        handle: 'activity-settings'
    },
    {
        id: 'sports-settings',
        title: 'Настройка видов спорта',
        handle: 'sports-settings'
    },
    {
        id: 'privacy',
        title: 'Настройка приватности',
        handle: 'privacy'
    },
    {
        id: 'freeze',
        title: 'Фиксация данных',
        handle: 'freeze'
    },
];
export default standingsSettingsNavigationConfig;
