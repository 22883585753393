var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { DialogActions, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_ACTIVITY_DUPLICATES } from 'common/graphql/mutations/activity/updateActivity/updateActivityDuplicates';
import { FULL_ACTIVITY } from 'common/graphql/queries/activity/fullActivity';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import activityValidationSchema from 'common/validationSchemas/activityValidationSchema';
import Button from 'components/Buttons/Button';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var UpdateActivityDuplicatesDialog = function (_a) {
    var _b;
    var activity = _a.activity, handleClose = _a.handleClose;
    var defaultFormState = {
        duplicatesActivityId: ((_b = activity.duplicatesActivity) === null || _b === void 0 ? void 0 : _b.id) || null
    };
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var updateActivityDuplicates = function (data) {
        return useMutation(UPDATE_ACTIVITY_DUPLICATES, {
            variables: {
                activityId: activity.id,
                duplicatesActivityId: (data === null || data === void 0 ? void 0 : data.duplicatesActivityId) || null
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: FULL_ACTIVITY,
                    variables: {
                        id: activity.id
                    }
                },
            ]
        });
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t("\u0414\u0430\u043D\u043D\u044B\u0435 \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u044B"),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(updateActivityDuplicates, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        handleExecuteMutation(__assign({}, data));
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: activityValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-5 p-0 pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Изменить данные дублирования') }), void 0), _jsx(TextField, { className: "", label: t('ID дублируемой тренировки'), name: "duplicatesActivityId", value: formik.values['duplicatesActivityId'], onChange: handleChangeWrapper, error: Boolean(formik.errors['duplicatesActivityId']), helperText: t(formik.errors['duplicatesActivityId'] || '') }, void 0), _jsxs("div", __assign({ className: "text-grey" }, { children: [_jsx("p", __assign({ className: "text-red" }, { children: t('Внимание!') }), void 0), _jsx("p", { children: t('duplicateWarning1') }, void 0), _jsx("p", { children: t('duplicateWarning2') }, void 0)] }), void 0)] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm" }, { children: t('Подтвердить') }), void 0)] }), void 0)] }), void 0));
};
export default UpdateActivityDuplicatesDialog;
