var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { NotificationStatus } from 'common/enums/notification/notification-status.enum';
import { MARK_NOTIFICATION_AS_READ } from 'common/graphql/mutations/notification/mark-notification-as-read';
import { USER_POP_UP_UI_NOTIFICATIONS_COUNT } from 'common/graphql/queries/notification/user-pop-up-ui-notifications-count';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import NotificationNotRead from 'components/Badge/NotificationNotRead/NotificationNotRead';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import history from 'store/history';
import HelperFunctions from 'utils/HelperFunctions';
var UserRecentNotificationsPopoverContentNotificationListItem = function (_a) {
    var _b, _c;
    var notification = _a.notification, handleMenuClose = _a.handleMenuClose;
    var _d = useTranslation(), t = _d.t, i18n = _d.i18n;
    var dispatch = useDispatch();
    var getFancyDate = HelperFunctions.getFancyDate;
    var markAsRead = function (data) {
        return useMutation(MARK_NOTIFICATION_AS_READ, {
            variables: {
                id: data
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER_POP_UP_UI_NOTIFICATIONS_COUNT,
                    variables: {
                        status: NotificationStatus.isNotified
                    }
                },
            ]
        });
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(markAsRead, undefined, onError).handleExecuteMutation;
    var handleClickMarkAsRead = function (data) {
        handleExecuteMutation(data);
    };
    return ((_b = notification.content) === null || _b === void 0 ? void 0 : _b.messagePreview) ? (_jsxs(Card, __assign({ sx: { width: 320 } }, { children: [_jsx(CardActionArea, __assign({ onClick: function () {
                    history.push("/notification/".concat(notification.id));
                    handleMenuClose();
                } }, { children: _jsx(CardContent, { children: _jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsx("div", __assign({ className: "break-words" }, { children: parse((_c = notification.content) === null || _c === void 0 ? void 0 : _c.messagePreview) }), void 0), _jsx("div", __assign({ className: "flex w-full justify-end" }, { children: _jsx("p", __assign({ className: "text-12 text-grey" }, { children: getFancyDate(notification.createdAt, i18n.language) }), void 0) }), void 0)] }), void 0) }, void 0) }), void 0), notification.status !== NotificationStatus.isRead && (_jsx(CardActions, { children: _jsxs("div", __assign({ className: "flex w-full justify-between" }, { children: [_jsx("div", __assign({ className: "tw-link-btn text-13", onClick: function () {
                                handleClickMarkAsRead(notification.id);
                            } }, { children: t('Пометить как прочитанное') }), void 0), _jsx(NotificationNotRead, {}, void 0)] }), void 0) }, void 0)), notification.status === NotificationStatus.isRead && (_jsx(CardActions, { children: _jsx("p", __assign({ className: "text-grey text-12" }, { children: t('Прочитано') }), void 0) }, void 0))] }), void 0)) : null;
};
export default UserRecentNotificationsPopoverContentNotificationListItem;
