var tournamentSettingsNavigationConfig = [
    {
        id: 'tournament-profile',
        title: 'Профайл турнира',
        handle: 'tournament-profile'
    },
    {
        id: 'tournament-dates-settings',
        title: 'Даты проведения',
        handle: 'tournament-dates-settings'
    },
    {
        id: 'tournament-sports-settings',
        title: 'Виды спорта',
        handle: 'tournament-sports-settings'
    },
    {
        id: 'tournament-participation-settings',
        title: 'Управление участниками',
        handle: 'tournament-participation-settings'
    },
    {
        id: 'tournament-standings-settings',
        title: 'Управление турнирными таблицами',
        handle: 'tournament-standings-settings'
    },
    {
        id: 'tournament-feature-settings',
        title: 'Управление турнирными фичами',
        handle: 'tournament-feature-settings'
    },
];
export default tournamentSettingsNavigationConfig;
