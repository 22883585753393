import * as Yup from 'yup';
import { Gender } from '../enums/common/gender';
var userValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(3)
        .max(128, 'Слишком длинное наименование. Пожалуйста, введите от 3 до 128 символов')
        .required('Не указано наименование'),
    displayName: Yup.string()
        .trim()
        .min(3)
        .max(128, 'Слишком длинное название. Пожалуйста, введите от 3 до 128 символов')
        .required('Не указано название'),
    text: Yup.string()
        .trim()
        .min(3)
        .max(32, 'Слишком длинный текст ссылки. Пожалуйста, введите от 3 до 32 символов')
        .required('Не указан текст ссылки'),
    firstName: Yup.string()
        .trim()
        .min(1)
        .max(128, 'Слишком длинное имя. Пожалуйста, введите до 128 символов')
        .required('Не указано имя'),
    lastName: Yup.string()
        .trim()
        .min(1, 'Не указана фамилия')
        .max(128, 'Слишком длинная фамилия. Пожалуйста, введите до 128 символов')
        .required('Не указана фамилия'),
    userName: Yup.string()
        .max(20, 'Слишком длинный никнейм. Пожалуйста, введите до 20 символов')
        .required('Не указан никнейм')
        .matches(/^([a-zA-Z0-9_]+)$/, 'Никнейм может состоять из латинских букв, цифр и подчеркивания')
        .matches(/^([a-zA-Z0-9_]{5,})$/, 'Минимальная длина никнейма 5 символов')
        .matches(/^(?:[0-9_]*[a-zA-Z]){3}/, 'Никнейм должен содержать не менее 3 латинских букв'),
    url: Yup.string()
        .max(20, 'Слишком длинное имя. Пожалуйста, введите до 20 символов')
        .required('Не указано имя')
        .matches(/^([a-zA-Z0-9_]+)$/, 'Имя может состоять из латинских букв, цифр и подчеркивания')
        .matches(/^([a-zA-Z0-9_]{3,})$/, 'Минимальная длина имени 3 символов')
        .matches(/^(?:[0-9_]*[a-zA-Z]){3}/, 'Имя должно содержать не менее 3 латинских букв'),
    about: Yup.string().trim().max(400, 'Слишком много информации. Пожалуйста, введите до 400 символов'),
    description: Yup.string().trim().max(400, 'Слишком много информации. Пожалуйста, введите до 400 символов'),
    contacts: Yup.string().trim().max(400, 'Слишком много информации. Пожалуйста, введите до 400 символов'),
    additionalInfo: Yup.string().trim().max(512, 'Слишком много информации. Пожалуйста, введите до 512 символов'),
    acceptenceCondition: Yup.string().trim().max(512, 'Слишком много информации. Пожалуйста, введите до 512 символов'),
    participationCondition: Yup.string()
        .trim()
        .max(512, 'Слишком много информации. Пожалуйста, введите до 512 символов'),
    exclusionCondition: Yup.string().trim().max(512, 'Слишком много информации. Пожалуйста, введите до 512 символов'),
    email: Yup.string()
        .min(6, 'Некорректный email')
        .max(60, 'Некорректный email')
        .email('Некорректный email')
        .required('Некорректный email'),
    birthDate: Yup.date()
        .max(new Date(new Date().setFullYear(new Date().getFullYear() - 13)), 'Приложением Impulse могут пользоваться только пользователи старше 13 лет.')
        .min(new Date(1920, 12), 'Вы не так стары.')
        .required('Не указана дата рождения.')
        .nullable()
        .typeError('Неверный формат даты.'),
    oldPassword: Yup.string().min(8, 'Пароль должен содержать не менее 8 символов').required('Не указан пароль.'),
    password: Yup.string()
        .required('Не указан пароль')
        .min(8, 'Пароль должен содержать не менее 8 символов')
        .max(20, 'Пароль должен содержать не более 20 символов')
        .matches(/^[A-Za-z\d@$!%*#?&~`"№;@$!:^()-_=+{}[\]\.,/|'%*#?&><]{8,}$/, 'Содержится недопустимый символ или пробел')
        .matches(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d~`"№;@$!:^()-_=+{}[\]\.,/|'%*#?&><]{8,}$/, 'Пароль должен состоять из латинских букв, содержать заглавную букву и цифру'),
    confirmPassword: Yup.string()
        .required('Подтвердите новый пароль.')
        .oneOf([Yup.ref('password'), null], 'Пароли не совпадают.'),
    gender: Yup.mixed().oneOf(Object.values(Gender)).required('Укажите пол'),
    phoneNumber: Yup.string().matches(/^(\+7|)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/, 'Неправильный формат. Корректный формат: +79998886655'),
    telegramId: Yup.number().min(1).max(9999999999999),
    //InputVerificationKey register
    key: Yup.string()
        .required('Укажите проверочный код')
        .min(6, 'Проверочный код должен содержать 6 цифр')
        .max(6, 'Проверочный код должен содержать 6 цифр')
        .matches(/^\d+$/, 'Код должен содержать только цифры')
});
export default userValidationSchema;
