var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { DialogActions, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { CREATE_SUPPORT_REQUEST } from 'common/graphql/mutations/support-request/create-support-request';
import { SUPPORT_REQUESTS } from 'common/graphql/queries/support-request/support-requests';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import supportRequestSchema from 'common/validationSchemas/supportRequestSchema';
import Button from 'components/Buttons/Button';
import { useFormik } from 'formik';
import linkifyHtml from 'linkify-html';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var CreateSupportRequestDialog = function (_a) {
    var userId = _a.userId, handleClose = _a.handleClose;
    var defaultFormState = {
        description: '',
        text: ''
    };
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var createSupportRequest = function (data) {
        return useMutation(CREATE_SUPPORT_REQUEST, {
            variables: {
                createSupportRequestData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SUPPORT_REQUESTS,
                    variables: {
                        requestedByUserId: userId
                    }
                },
            ]
        });
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t("\u041E\u0431\u0440\u0430\u0449\u0435\u043D\u0438\u0435 \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u0441\u043E\u0437\u0434\u0430\u043D\u043E \u0443\u0441\u043F\u0435\u0448\u043D\u043E!"),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(createSupportRequest, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        var options = { defaultProtocol: 'https' };
        handleExecuteMutation({
            description: (data === null || data === void 0 ? void 0 : data.description) || '',
            text: linkifyHtml((data === null || data === void 0 ? void 0 : data.text) || '', options)
        });
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: supportRequestSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-5 p-0 pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Создать обращение в поддержку') }), void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-40" }, { children: [_jsx(TextField, { className: "", label: t('Краткое описание'), name: "description", value: formik.values['description'], onChange: handleChangeWrapper, error: Boolean(formik.errors['description']), helperText: t(formik.errors['description'] || '') }, void 0), _jsx(TextField, { className: "w-full", label: t('Подробное описание'), name: "text", value: formik.values['text'], onChange: handleChangeWrapper, error: Boolean(formik.errors['text']), helperText: t(formik.errors['text'] || ''), multiline: true }, void 0)] }), void 0)] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm" }, { children: t('Создать') }), void 0)] }), void 0)] }), void 0));
};
export default CreateSupportRequestDialog;
