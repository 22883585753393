var _a, _b, _c;
export var VirtualPointsBoosterTypes;
(function (VirtualPointsBoosterTypes) {
    VirtualPointsBoosterTypes["runInsteadOfRiding"] = "runInsteadOfRiding";
    VirtualPointsBoosterTypes["doubleRidePoints"] = "doubleRidePoints";
    VirtualPointsBoosterTypes["swimmingImprovement"] = "swimmingImprovement";
    VirtualPointsBoosterTypes["gentlemenDay"] = "gentlemenDay";
    VirtualPointsBoosterTypes["morningExercises"] = "morningExercises";
    VirtualPointsBoosterTypes["ironman"] = "ironman";
})(VirtualPointsBoosterTypes || (VirtualPointsBoosterTypes = {}));
export var VirtualPointsBoosterTypesReadableRu = (_a = {},
    _a[VirtualPointsBoosterTypes.runInsteadOfRiding] = 'Бег вместо велосипеда',
    _a[VirtualPointsBoosterTypes.doubleRidePoints] = 'Супер-ускорение на велопробег',
    _a[VirtualPointsBoosterTypes.swimmingImprovement] = 'Ускорение на плавание',
    _a[VirtualPointsBoosterTypes.gentlemenDay] = 'Джентльменский день',
    _a[VirtualPointsBoosterTypes.morningExercises] = 'Ранние пташки',
    _a[VirtualPointsBoosterTypes.ironman] = 'Железный человек',
    _a);
export var VirtualPointsBoosterTypesDescriptionReadableRu = (_b = {},
    _b[VirtualPointsBoosterTypes.runInsteadOfRiding] = 'runInsteadOfRiding',
    _b[VirtualPointsBoosterTypes.doubleRidePoints] = 'doubleRidePoints',
    _b[VirtualPointsBoosterTypes.swimmingImprovement] = 'swimmingImprovement',
    _b[VirtualPointsBoosterTypes.gentlemenDay] = 'gentlemenDay',
    _b[VirtualPointsBoosterTypes.morningExercises] = 'morningExercises',
    _b[VirtualPointsBoosterTypes.ironman] = 'ironman',
    _b);
export var VirtualPointsBoosterTypesProfilePhotos = (_c = {},
    _c[VirtualPointsBoosterTypes.runInsteadOfRiding] = 'https://storage.yandexcloud.net/impulse-public/booster-images/runInsteadOfRiding.png',
    _c[VirtualPointsBoosterTypes.doubleRidePoints] = 'https://storage.yandexcloud.net/impulse-public/booster-images/doubleRidePoints.png',
    _c[VirtualPointsBoosterTypes.swimmingImprovement] = 'https://storage.yandexcloud.net/impulse-public/booster-images/swimmingImprovement.png',
    _c[VirtualPointsBoosterTypes.gentlemenDay] = 'https://storage.yandexcloud.net/impulse-public/booster-images/gentlemenDay.png',
    _c[VirtualPointsBoosterTypes.morningExercises] = 'https://storage.yandexcloud.net/impulse-public/booster-images/morningExercises.png',
    _c[VirtualPointsBoosterTypes.ironman] = 'https://storage.yandexcloud.net/impulse-public/booster-images/ironman.png',
    _c);
