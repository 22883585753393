var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@mui/styles';
var useStyles = makeStyles(function (theme) { return ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    divider: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        width: '100%'
    },
    content: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        fontSize: 16,
        color: theme.palette.divider
    }
}); });
var DividerWithText = function (_a) {
    var content = _a.content;
    var classes = useStyles();
    return (_jsxs("div", __assign({ className: classes.container }, { children: [_jsx("div", { className: classes.divider }, void 0), _jsx("span", __assign({ className: classes.content }, { children: content }), void 0), _jsx("div", { className: classes.divider }, void 0)] }), void 0));
};
export default DividerWithText;
