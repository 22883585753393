export var Nominations;
(function (Nominations) {
    Nominations["goalReached"] = "goalReached";
    Nominations["earliestGoalReached"] = "earliestGoalReached";
    Nominations["largestDistance"] = "largestDistance";
    Nominations["lowestTempo"] = "lowestTempo";
    Nominations["largestPositiveElevation"] = "largestPositiveElevation";
    Nominations["highestPointsScore"] = "highestPointsScore";
    Nominations["standingsColumnValueGoalReached"] = "standingsColumnValueGoalReached";
    Nominations["customManual"] = "customManual";
})(Nominations || (Nominations = {}));
