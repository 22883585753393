var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsForm from '../components/SettingsForm/SettingsForm';
import UserAddressAddNew from './UserAddressAddNew';
import UserAddresses from './UserAddresses';
var UserAddressSettings = function () {
    var t = useTranslation().t;
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: t('Мои адреса') }, void 0), content: _jsxs(Box, __assign({ sx: { width: '100%' }, className: "flex flex-col space-y-20 pt-10" }, { children: [_jsx("div", { children: t('useAddressesText') }, void 0), _jsx(UserAddressAddNew, {}, void 0), _jsx(UserAddresses, {}, void 0)] }), void 0) }, void 0));
};
export default UserAddressSettings;
