var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { NotificationTypes } from 'common/enums/notification/notification-types.enum';
var NotificationTypeSelect = function (_a) {
    var setValue = _a.setValue, value = _a.value;
    var options = [
        { label: 'Email', value: NotificationTypes.byEmail },
        { label: 'UI/Mobile', value: NotificationTypes.popUpUi },
    ];
    return (_jsxs(FormControl, __assign({ className: "w-full" }, { children: [_jsx(InputLabel, { children: 'Тип оповещения' }, void 0), _jsx(Select, __assign({ label: 'Тип оповещения', value: value, onChange: function (e) {
                    setValue(e.target.value);
                }, input: _jsx(OutlinedInput, { className: "\n\t\tbg-bg-container-inner-lt \n\t\tdark:bg-bg-container-inner-dk  \n\t\tdark:text-white", label: 'Тип оповещения' }, void 0) }, { children: options.map(function (option) { return (_jsx(MenuItem, __assign({ value: option.value }, { children: option.label }), option.value)); }) }), void 0)] }), void 0));
};
export default NotificationTypeSelect;
