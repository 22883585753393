import { jsx as _jsx } from "react/jsx-runtime";
import backgroundImg from 'assets/images/impulse_main_img.jpg';
import authRoles from 'common/authRoles';
import LoginPage from './LoginPage';
export var loginPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: "url(".concat(backgroundImg, ")"),
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                    backgroundPosition: { xs: '20%', md: '20%', lg: 'center center' }
                },
                navbar: {
                    display: false
                },
                toolbar: {
                    display: true,
                    displayLogo: true,
                    style: "",
                    transparent: true
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/login',
            component: function () { return _jsx(LoginPage, {}, void 0); }
        },
    ]
};
