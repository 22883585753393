import { dark, light } from './colors';
import { red, grey } from '@mui/material/colors';
/* darkYellow: '#e0ad57',
    mainYellow: '#FFCB74', */

const colors = {
	opacityGrey: '#31313161',
	white: '#ffffff',

	darkGrey: 'rgb(32 32 32)',
	mainGrey: '#2F2F2F',
	secondaryGrey: '#a6a6a6',
	lightGrey: '#f7f7f7',

	darkYellow: '#d0ab02',
	mainYellow: '#dbb400',
	lightYellow: '#e8c007',

	stravaOrange: '#FC4D03',
	stravaOrangeDarken: '#d54001',
	polarBlack: '#2C2C2C',
	polarBlackDarken:'#0c0c0c'

};

const darkColors = {
	mainBackground: '#262626',
	contentBackground: '#313131',
	toolbarBackground: 'rgb(66 66 66)',
	textField: 'rgb(66 66 66)',
	disabled: 'rgb(156, 163, 175)',
	actionIconColor: 'rgb(255,255,255)',
};

const lightText = {
	primary: 'rgb(17, 24, 39)',
	secondary: 'rgb(107, 114, 128)',
	disabled: 'rgb(149, 156, 169)',
	light: 'rgb(255,255,255)',
};

const darkText = {
	primary: 'rgb(255,255,255)',
	secondary: 'rgb(229, 231, 235)',
	disabled: 'rgb(156, 163, 175)',
};

const themesConfig = {
	default: {
		palette: {
			mode: 'light',
			type: 'light',
			text: lightText,

			primary: {
				light: colors.lightYellow,
				main: colors.mainYellow,
				dark: colors.darkYellow,
			},
			secondary: {
				light: colors.lightGrey,
				main: colors.mainGrey,
				dark: colors.darkGrey,
			},

			background: {
				paper: colors.white,
				root: colors.lightGrey,
				toolbar: colors.white,
				navbar: colors.darkGrey,
				content: colors.white,
				textField: colors.lightGrey,
				opacityContent: colors.opacityGrey,
			},
			button: {
				containePrimaryButtonText: colors.darkGrey,
				outlinedSecondaryBorderColor: colors.secondaryGrey,
				containedSecondaryButtonBackground: colors.darkGrey,
			},
            icon: {
                main:colors.darkGrey,
                secondary: colors.secondaryGrey,
                action: colors.mainYellow
            },
			border: grey[400],
			actionIcon: colors.darkGrey,
			error: red,
			stravaOrange: colors.stravaOrange,
			stravaOrangeDarken: colors.stravaOrangeDarken,
			polarBlack: colors.polarBlack,
			polarBlackDarken: colors.polarBlackDarken
		},
		status: {
			danger: 'orange',
		},
	},

	defaultDark: {
		palette: {
			mode: 'dark',
			type: 'dark',
			text: darkText,
			stravaRed: 'FC4D03',
			primary: {
				light: colors.lightYellow,
				main: colors.mainYellow,
				dark: colors.darkYellow,
			},
			background: {
				paper: colors.darkGrey, //меню в мобильной версии
				root: colors.darkGrey,
				toolbar: colors.mainGrey,
				navbar: colors.darkGrey,
				content: '#262626',
				textField: darkColors.textField,
				opacityContent: colors.opacityGrey,
			},
			secondary: {
				light: colors.lightGrey,
				main: colors.lightGrey,
				dark: colors.lightGrey,
			},
			button: {
				containePrimaryButtonText: colors.lightGrey,
				containeSecondaryButtonText: colors.lightGrey,
				containedSecondaryButtonBackground: colors.secondaryGrey,
				outlinedSecondaryBorderColor: colors.lightGrey,
			},

            //Todo
            icon: {
                main:colors.darkGrey,
                secondary: colors.secondaryGrey,
                action: colors.white
            },
			actionIcon: darkColors.actionIconColor,
			error: red,
			divider: 'rgb(255,255,255)',
			stravaOrange: colors.stravaOrange,
			stravaOrangeDarken: colors.stravaOrangeDarken,
			polarBlack: colors.polarBlack,
			polarBlackDarken: colors.polarBlackDarken
		},
		status: {
			danger: 'orange',
		},
	},
};

export default themesConfig;
