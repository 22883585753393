var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import GroupContent from '../GroupContent/GroupContent';
import GroupLabel from '../GroupLabel/GroupLabel';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 1 100%'
    }
}); });
var SettingsGroup = function (props) {
    var t = useTranslation().t;
    var settingsGroup = props.settingsGroup, handleChangeData = props.handleChangeData, data = props.data;
    var classes = useStyles(props);
    return (_jsx("div", __assign({ className: clsx(classes.root, 'settings-group') }, { children: _jsxs("div", __assign({ className: clsx(classes.contentWrapper) }, { children: [_jsx(GroupLabel, { label: t(settingsGroup.title || ''), logo: settingsGroup.logo }, void 0), _jsx(GroupContent, { settingsGroup: settingsGroup, handleChangeData: handleChangeData, data: data }, void 0)] }), void 0) }), void 0));
};
export default SettingsGroup;
