var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTranslation } from 'react-i18next';
export var ParticipationSettingsRadioButtonsGroupValue;
(function (ParticipationSettingsRadioButtonsGroupValue) {
    ParticipationSettingsRadioButtonsGroupValue["allUsersCanJoin"] = "allUsersCanJoin";
    ParticipationSettingsRadioButtonsGroupValue["joinViaRequest"] = "joinViaRequest";
})(ParticipationSettingsRadioButtonsGroupValue || (ParticipationSettingsRadioButtonsGroupValue = {}));
var ParticipationSettingsRadioButtonsGroup = function (_a) {
    var value = _a.value, name = _a.name, onChange = _a.onChange;
    var t = useTranslation().t;
    return (_jsx(FormControl, { children: _jsxs(RadioGroup, __assign({ "aria-labelledby": "controlled-radio-buttons-group", name: name, value: value, onChange: onChange }, { children: [_jsx(FormControlLabel, { value: ParticipationSettingsRadioButtonsGroupValue.allUsersCanJoin, control: _jsx(Radio, {}, void 0), label: t('Любой пользователь может вступить в клуб') }, void 0), _jsx(FormControlLabel, { value: ParticipationSettingsRadioButtonsGroupValue.joinViaRequest, control: _jsx(Radio, {}, void 0), label: t('Вступление в клуб через подачу заявки на вступление') }, void 0)] }), void 0) }, void 0));
};
export default ParticipationSettingsRadioButtonsGroup;
