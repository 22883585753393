import React from "react";
import {createGenerateClassName, jssPreset, StylesProvider} from "@mui/styles";
import {create} from 'jss';
import jssExtend from 'jss-extend';


export default function JssExtendPlugin(props){
    const {children} = props;
    return (
        <StylesProvider jss={jss} generateClassName={generateClassName}>
            {children}
        </StylesProvider>
    );
}

const generateClassName = createGenerateClassName();

export const jss = create({
    ...jssPreset(),
    plugins       : [...jssPreset().plugins, jssExtend()],
    insertionPoint: document.getElementById('jss-insertion-point'),
});