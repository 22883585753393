var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
export var NotificationSubTypeListItemReadMode = function (_a) {
    var _b, _c;
    var notificationSubType = _a.notificationSubType, setIsEditMode = _a.setIsEditMode;
    var t = useTranslation().t;
    var handleEditButtonClick = function () {
        setIsEditMode(true);
    };
    return (_jsxs("div", __assign({ className: "flex flex-col space-y-20 w-full" }, { children: [_jsxs("div", __assign({ className: "flex  flex-row items-center w-full justify-between pr-10" }, { children: [_jsx("div", __assign({ className: "xs:text-14 md:text-16 font-semibold px-8 py-2" }, { children: notificationSubType.name }), void 0), _jsx("div", __assign({ className: "flex flex-row items-center max-w-288" }, { children: _jsx(Button, __assign({ type: "button", onClick: handleEditButtonClick }, { children: t('Изменить') }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row xs:space-x-0 xs:space-y-20 sm:space-y-0 sm:space-x-20" }, { children: [_jsxs("div", __assign({ className: "flex flex-col px-8" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Описание:', "\u00A0"] }), void 0), _jsxs("div", { children: [" ", notificationSubType.description] }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Шаблон по умолчанию:', "\u00A0"] }), void 0), _jsxs("div", { children: [" ", (_b = notificationSubType.defaultTemplate) === null || _b === void 0 ? void 0 : _b.name] }, void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex flex-col px-8" }, { children: ((_c = notificationSubType.templates) === null || _c === void 0 ? void 0 : _c.length) > 0 && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Доступные шаблоны:', "\u00A0"] }), void 0), _jsx("div", { children: notificationSubType.templates.map(function (template) {
                                        return (_jsx(Tooltip, __assign({ title: template.description || 'Упс! У этого шаблона нет описания...' }, { children: _jsxs("div", __assign({ className: "flex flex-row space-x-5" }, { children: [_jsx("p", { children: template.name }, void 0), _jsx(InfoOutlinedIcon, { className: "h-20 w-20 show-full-info-button mr-8", color: "primary" }, void 0)] }), void 0) }), template.id));
                                    }) }, void 0)] }), void 0)) }), void 0)] }), void 0)] }), void 0));
};
