var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddClubAdminRoleCheckbox from 'main/pages/ClubSettingsPage/clubParticipants/components/AddClubAdminRoleCheckbox/AddClubAdminRoleCheckbox';
import AddClubOwnerRoleCheckbox from 'main/pages/ClubSettingsPage/clubParticipants/components/AddClubOwnerRoleCheckbox/AddClubOwnerRoleCheckbox';
import { useState } from 'react';
var AddClubParticipantRoleDialog = function (_a) {
    var currentUserRole = _a.currentUserRole, userId = _a.userId, handleChangeIsOwnerRole = _a.handleChangeIsOwnerRole, handleChangeIsAdminRole = _a.handleChangeIsAdminRole, isOwner = _a.isOwner, isAdmin = _a.isAdmin;
    var _b = useState(isAdmin), isUserAdmin = _b[0], setIsUserAdmin = _b[1];
    var _c = useState(isOwner), isUserOwner = _c[0], setIsUserOwner = _c[1];
    return (_jsxs("div", __assign({ className: "flex flex-col pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0440\u043E\u043B\u044F\u043C\u0438" }), void 0), _jsxs("div", { children: [_jsx(AddClubOwnerRoleCheckbox, { name: 'isOwnerRole', value: isUserOwner, userId: userId, onChange: function (e, userId) {
                            handleChangeIsOwnerRole(e, userId);
                            setIsUserOwner(!isOwner);
                        }, disabled: currentUserRole === 'Admin' ? true : currentUserRole === 'Owner' ? false : true }, void 0), _jsx(AddClubAdminRoleCheckbox, { name: 'isAdminRole', value: isUserAdmin, userId: userId, onChange: function (e, userId) {
                            handleChangeIsAdminRole(e, userId);
                            setIsUserAdmin(!isUserAdmin);
                        }, disabled: currentUserRole === 'Admin' ? true : currentUserRole === 'Owner' ? false : true }, void 0)] }, void 0)] }), void 0));
};
export default AddClubParticipantRoleDialog;
