import React from 'react';
import clsx from 'clsx';
import Scrollbars from '../../../../components/Scrollbars/Scrollbars';
import { makeStyles } from '@mui/styles';
import Navigation from '../../shared-components/Navigation';
import { Box } from '@mui/material';

const useStyles = makeStyles({
	content: {
		overflowX: 'hidden',
		overflowY: 'auto',
		'-webkit-overflow-scrolling': 'touch',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 40px, 100% 10px',
		backgroundAttachment: 'local, scroll',
		height: '100%',
	},
});

function NavbarLayout(props) {
	const classes = useStyles();

	return (
		<Box
			sx={{ display: 'flex', height: '100%', overflow: 'hidden', flexDirection: 'column', flexGrow: 1 }}
			className={props.className}
		>
			{/* <Scrollbars className={clsx(classes.content)}> */}
				<Navigation navbarOpen={props.navbarOpen} layout="vertical" />
			{/* </Scrollbars> */}
		</Box>
	);
}

export default NavbarLayout;
