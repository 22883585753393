var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Hike = function (props) {
    return (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", stroke: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M19 23H17.5V14H19V23ZM7.53 14H6L4 23H5.53L7.53 14ZM13.5 5.5C14.6 5.5 15.5 4.6 15.5 3.5C15.5 2.4 14.6 1.5 13.5 1.5C12.4 1.5 11.5 2.4 11.5 3.5C11.5 4.6 12.4 5.5 13.5 5.5ZM9.8 8.9L7 23H9.1L10.9 15L13 17V23H15V15.5L12.9 13.5L13.5 10.5C14.8 12 16.8 13 19 13V11C17.1 11 15.5 10 14.7 8.6L13.7 7C13.4299 6.56816 13.015 6.24651 12.5295 6.09261C12.044 5.93871 11.5195 5.96259 11.05 6.16L6 8.3V13H8V9.6L9.8 8.9Z" }, void 0) }), void 0));
};
export default Hike;
