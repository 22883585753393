export var NotificationTargetTypes;
(function (NotificationTargetTypes) {
    NotificationTargetTypes["allUsers"] = "allUsers";
    NotificationTargetTypes["distinctUsers"] = "distinctUsers";
    NotificationTargetTypes["sportEventUsers"] = "sportEventUsers";
    NotificationTargetTypes["tournamentUsers"] = "tournamentUsers";
    NotificationTargetTypes["sportClubUsers"] = "sportClubUsers";
    NotificationTargetTypes["communityUsers"] = "communityUsers";
    NotificationTargetTypes["teamUsers"] = "teamUsers";
})(NotificationTargetTypes || (NotificationTargetTypes = {}));
