var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DocsHelper from 'components/DocsHelper/DocsHelper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VirtualPointsBuyTeamCoinsButton from './VirtualPointsBuyTeamCoinsButton';
var CoinsInfo = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var team = _a.team, teamCoins = _a.teamCoins, virtualPointsFeature = _a.virtualPointsFeature, isTournamentEnded = _a.isTournamentEnded;
    var _l = useState(false), openTooltip = _l[0], setOpenTooltip = _l[1];
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "flex flex-col space-y-10 w-full" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-10 w-full items-center justify-center xs:text-14 md:text-16" }, { children: [_jsx("p", { children: t('Монеты') }, void 0), _jsx(DocsHelper, __assign({ id: "db2c81d3-b279-48fb-b42f-a1e3695b46d6" }, { children: _jsx(InfoOutlinedIcon, { onClick: function () { return setOpenTooltip(!openTooltip); }, fontSize: "small" }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-row space-x-5 justify-center items-center" }, { children: [_jsxs("p", __assign({ className: "text-grey" }, { children: [t('Монет в кошельке команды'), ":"] }), void 0), _jsx("p", { children: teamCoins }, void 0), !isTournamentEnded &&
                        ((_d = (_c = (_b = virtualPointsFeature === null || virtualPointsFeature === void 0 ? void 0 : virtualPointsFeature.config) === null || _b === void 0 ? void 0 : _b.boostersConfig) === null || _c === void 0 ? void 0 : _c.coinsConfig) === null || _d === void 0 ? void 0 : _d.coinsGetMethod) === 'realMoney' &&
                        ((_g = (_f = (_e = virtualPointsFeature === null || virtualPointsFeature === void 0 ? void 0 : virtualPointsFeature.config) === null || _e === void 0 ? void 0 : _e.boostersConfig) === null || _f === void 0 ? void 0 : _f.coinsConfig) === null || _g === void 0 ? void 0 : _g.coinPrice) && (_jsx("div", __assign({ className: "pl-10 max-w-200" }, { children: _jsx(VirtualPointsBuyTeamCoinsButton, { team: team, coinPrice: (_k = (_j = (_h = virtualPointsFeature === null || virtualPointsFeature === void 0 ? void 0 : virtualPointsFeature.config) === null || _h === void 0 ? void 0 : _h.boostersConfig) === null || _j === void 0 ? void 0 : _j.coinsConfig) === null || _k === void 0 ? void 0 : _k.coinPrice }, void 0) }), void 0))] }), void 0)] }), void 0));
};
export default CoinsInfo;
