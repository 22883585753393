var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { NOTIFICATIONS_UNSUBSCRIBE } from 'common/graphql/mutations/user/notificationsUnsubscribe';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import LoginLayout from 'main/layouts/layout/components/LoginLayout';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
var NotificationsUnsubscribePage = function () {
    var dispatch = useDispatch();
    var search = useLocation().search;
    var searchParams = useMemo(function () { return new URLSearchParams(search); }, [search]);
    var _a = useState(true), isSubscribed = _a[0], setIsSubscribed = _a[1];
    // const [userId, setUserId] = useState(null)
    // const [unsubscribeKey, setUnsubscribeKey] = useState(null)
    var t = useTranslation().t;
    var unsubscribe = function (data) {
        return useMutation(NOTIFICATIONS_UNSUBSCRIBE, {
            variables: {
                userId: data === null || data === void 0 ? void 0 : data.userId,
                unsubscribeKey: data === null || data === void 0 ? void 0 : data.unsubscribeKey
            }
        });
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Вы успешно отписались от рассылок.'),
            severity: SnackbarSeverity.SUCCESS
        }));
        setIsSubscribed(false);
    };
    var handleExecuteMutation = useDynamicMutation(unsubscribe, onSuccess, onError).handleExecuteMutation;
    return (_jsx(LoginLayout, __assign({ className: "h-full flex justify-center sm:p-16 lg:p-24" }, { children: _jsx(Box, __assign({ className: "xs:w-full sm:w-11/12 md:w-9/12 text-white " }, { children: _jsxs("div", __assign({ className: "flex flex-col bg-opacityGrey xs:p-12 md:p-24 rounded-8 space-y-10 items-center" }, { children: [_jsx("div", __assign({ className: "text-center font-bold xs:text-16 sm:text-18" }, { children: t('Хотите отписаться от рассылки новостей Impulse?') }), void 0), isSubscribed ? (_jsx("div", __assign({ className: "flex items-center w-128" }, { children: _jsx(Button, __assign({ type: "primary", onClick: function () {
                                return handleExecuteMutation({
                                    userId: searchParams.get('userId'),
                                    unsubscribeKey: searchParams.get('unsubscribeKey')
                                });
                            } }, { children: t('Отписаться') }), void 0) }), void 0)) : (_jsx("div", __assign({ className: "flex items-center" }, { children: t('Вы отписались от оповещений, но вы всегда можете включить оповещения в настройках своего профиля!') }), void 0))] }), void 0) }), void 0) }), void 0));
};
export default NotificationsUnsubscribePage;
