var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tooltip } from '@mui/material';
import { appHost, appScheme } from 'common/appURLSetup';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { GENERATE_TEAM_JOIN_LINK_STRING } from 'common/graphql/mutations/team/generate-team-join-link-string';
import { TEAM } from 'common/graphql/queries/team/team';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
var TeamJoinLink = function (_a) {
    var teamId = _a.teamId, joinLinkString = _a.joinLinkString, isPrivate = _a.isPrivate;
    var _b = useState(false), openCopyTextButtonTooltip = _b[0], setOpenCopyTextButtonTooltip = _b[1];
    var _c = useState(false), openRefreshTextButtonTooltip = _c[0], setOpenRefreshTextButtonTooltip = _c[1];
    var dispatch = useDispatch();
    var onTeamJoinLinkCopy = function () {
        dispatch(openSnackbar({
            message: 'Ссылка-приглашение успешно скопирована.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onRefreshSuccess = function () {
        dispatch(openSnackbar({
            message: 'Ссылка-приглащение обновлена. Прежняя ссылка более невалидна.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(function (clubId) {
        return useMutation(GENERATE_TEAM_JOIN_LINK_STRING, {
            variables: {
                id: teamId
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TEAM,
                    variables: {
                        id: teamId,
                        showParticipationSettings: true
                    }
                },
            ]
        });
    }, onRefreshSuccess, onError).handleExecuteMutation;
    var handleRefresh = function () {
        handleExecuteMutation(teamId);
    };
    var invitationLink = isPrivate
        ? "".concat(appScheme, "://").concat(appHost, "/private-join-team-link/").concat(teamId, "/").concat(joinLinkString)
        : "".concat(appScheme, "://").concat(appHost, "/public-join-team-link/").concat(teamId);
    return (_jsx("div", __assign({ className: "flex flex-wrap items-center" }, { children: _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex pb-8" }, { children: [_jsx("div", __assign({ className: "dark:text-grey" }, { children: isPrivate ? 'Приватная ссылка-приглашение:' : 'Публичная ссылка-приглашение:' }), void 0), _jsxs("div", __assign({ className: "flex xs:ml-16" }, { children: [_jsx(Tooltip, __assign({ onMouseOver: function () { return setOpenCopyTextButtonTooltip(true); }, onMouseLeave: function () { return setOpenCopyTextButtonTooltip(false); }, onClose: function () { return setOpenCopyTextButtonTooltip(false); }, open: openCopyTextButtonTooltip, title: 'Копировать' }, { children: _jsx(ContentCopyIcon, { className: "w-24 h-24 xs:mr-8 cursor-pointer", color: "primary", onClick: function () {
                                            navigator.clipboard.writeText(invitationLink);
                                            onTeamJoinLinkCopy();
                                        } }, void 0) }), void 0), isPrivate && (_jsx(Tooltip, __assign({ onMouseOver: function () { return setOpenRefreshTextButtonTooltip(true); }, onMouseLeave: function () { return setOpenRefreshTextButtonTooltip(false); }, onClose: function () { return setOpenRefreshTextButtonTooltip(false); }, open: openRefreshTextButtonTooltip, title: 'Обновить' }, { children: _jsx(RefreshIcon, { className: "w-24 h-24 cursor-pointer", color: "primary", onClick: function () {
                                            dispatch(openDialog({
                                                dialogType: DialogTypes.RefreshInvitationLinkRequestDialog,
                                                dialogProps: { handleRefresh: handleRefresh }
                                            }));
                                        } }, void 0) }), void 0))] }), void 0)] }), void 0), _jsx("div", __assign({ className: "font-semibold break-all" }, { children: invitationLink }), void 0)] }), void 0) }), void 0));
};
export default TeamJoinLink;
