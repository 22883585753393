import React from 'react';
import { Drawer, Hidden } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import NavbarLayout from './NavbarLayout';
import { useDispatch, useSelector } from 'react-redux';
import { navbarCloseFolded, navbarCloseMobile, navbarOpenFolded } from 'store/actions';

const navbarWidthFolded = 64;
const navbarWidth = 280;

const useStyles = makeStyles((theme) => ({
	wrapper: {
		zIndex: 4,
		[theme.breakpoints.up('md')]: {
			width: navbarWidth,
			maxWidth: navbarWidth,
		},
	},
	wrapperFolded: {
		[theme.breakpoints.up('md')]: {
			width: navbarWidthFolded,
			minWidth: navbarWidthFolded,
		},
	},
	navbar: {
		display: 'flex',
		//overflow: 'hidden',
		flexDirection: 'column',
		flex: '1 1 auto',
		width: navbarWidth,
		minWidth: navbarWidth,
		height: '100%',
		zIndex: 4,
		backgroundImage: 'none' + '!important',
		backgroundColor: theme.palette.background.navbar,
		transition: theme.transitions.create(['width', 'min-width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.standart,
		}),
		boxShadow: theme.shadows[3],
	},
	left: {
		left: 0,
	},
	folded: {
		position: 'absolute',
		width: navbarWidthFolded,
		minWidth: navbarWidthFolded,
		top: 0,
		bottom: 0,
	},
	foldedAndOpened: {
		width: navbarWidth,
		minWidth: navbarWidth,
	},
}));

const NavbarWrapperLayout = (props) => {
	const dispatch = useDispatch();
	const config = useSelector(({ base }) => base.settings.current.layout.config);
	const navbarTheme = useSelector(({ base }) => base.settings.navbarTheme);
	const navbar = useSelector(({ base }) => base.navbar);

	const classes = useStyles();

	const folded = config.navbar.folded;
	const foldedAndClosed = folded && !navbar.foldedOpen;
	const foldedAndOpened = folded && navbar.foldedOpen;

	return (
		<ThemeProvider theme={navbarTheme}>
			<div id="impulse-navbar" className={clsx('flex flex-col', classes.wrapper, folded && classes.wrapperFolded)}>
				<Hidden mdDown>
					<div
						className={clsx(
							classes.navbar,
							classes[config.navbar.position],
							folded && classes.folded,
							foldedAndOpened && classes.foldedAndOpened,
						)}
						//свернуть/развернруть меню в момент наведения
						// onMouseEnter={() => foldedAndClosed && dispatch(navbarOpenFolded())}
						// onMouseLeave={() => foldedAndOpened && dispatch(navbarCloseFolded())}
					>
						<NavbarLayout className='NavbarLayout h-full flex flex-col' 
						navbarOpen={!folded || foldedAndOpened} />
					</div>
				</Hidden>


				{/* mobile version */}
				<Hidden mdUp>
					<Drawer
						anchor={config.navbar.position}
						variant="temporary"
						open={navbar.mobileOpen}
						classes={{
							paper: classes.navbar,
						}}
						onClose={() => dispatch(navbarCloseMobile())}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						<NavbarLayout className='NavbarLayout h-full flex flex-col' navbarOpen={true} />
					</Drawer>
				</Hidden>
			</div>
		</ThemeProvider>
	);
};

export default NavbarWrapperLayout;
