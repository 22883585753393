var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation, useQuery } from '@apollo/client';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { DELETE_TEAM_USER } from 'common/graphql/mutations/team/delete-team-user';
import { UPDATE_TEAM_USER } from 'common/graphql/mutations/team/update-team-user';
import { TEAM } from 'common/graphql/queries/team/team';
import { TEAM_USERS } from 'common/graphql/queries/team/team-users';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
import TeamParticipantsSearchResultListItem from './TeamParticipantsSearchResultListItem';
var TeamParticipants = function (_a) {
    var team = _a.team;
    var t = useTranslation().t;
    var _b = useQuery(TEAM_USERS, {
        variables: {
            teamId: team.id
        }
    }), teamUserData = _b.data, teamUserDataLoading = _b.loading;
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var errorDetails = _a.errorDetails, code = _a.code;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(function (userId) {
        return useMutation(DELETE_TEAM_USER, {
            variables: {
                deleteTeamUserData: {
                    teamId: team.id,
                    userId: userId
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TEAM,
                    variables: {
                        id: team.id,
                        showBasicFields: true,
                        showUsers: true
                    }
                },
                {
                    query: TEAM_USERS,
                    variables: {
                        teamId: team.id
                    }
                },
            ]
        });
    }, onSuccess, onError).handleExecuteMutation;
    var handleUpdateTeamUserMutation = useDynamicMutation(function (data) {
        return useMutation(UPDATE_TEAM_USER, {
            variables: {
                updateTeamUserData: {
                    teamId: team.id,
                    userId: data === null || data === void 0 ? void 0 : data.userId,
                    isCaptain: data === null || data === void 0 ? void 0 : data.isCaptain
                }
            },
            refetchQueries: [
                {
                    query: TEAM_USERS,
                    variables: {
                        teamId: team.id
                    }
                },
            ]
        });
    }, onSuccess, onError).handleExecuteMutation;
    var dispatch = useDispatch();
    var handleExpelUser = function (userId) {
        dispatch(openDialog({
            dialogType: DialogTypes.ExpelUserRequestDialog,
            dialogProps: { from: 'team', handleExpel: function () { return handleExecuteMutation(userId); } }
        }));
    };
    var teamUsers = team.users || [];
    var teamUsersInfo = (teamUserData === null || teamUserData === void 0 ? void 0 : teamUserData.teamUsers) || [];
    if (teamUserDataLoading)
        return _jsx(CircularProgressMui, {}, void 0);
    return teamUsers.length > 0 && teamUsersInfo.length > 0 ? (_jsx("div", __assign({ className: "flex flex-col space-y-5" }, { children: teamUsers.map(function (user) {
            var teamUserInfo = _.find(teamUsersInfo, { userId: user.id });
            return (_jsx(TeamParticipantsSearchResultListItem, { userProfile: user, teamUserCreatedAt: teamUserInfo === null || teamUserInfo === void 0 ? void 0 : teamUserInfo.createdAt, teamUserIsCaptain: (teamUserInfo === null || teamUserInfo === void 0 ? void 0 : teamUserInfo.isCaptain) || false, handleExpelUser: handleExpelUser, handleUpdateTeamUser: handleUpdateTeamUserMutation }, user.id));
        }) }), void 0)) : (_jsx("div", { children: "\u0412 \u043A\u043E\u043C\u0430\u043D\u0434\u0435 \u043D\u0435\u0442 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u043E\u0432" }, void 0));
};
export default TeamParticipants;
