var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Autocomplete, Button, Checkbox, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { NotificationTypes } from 'common/enums/notification/notification-types.enum';
import { CREATE_NOTIFICATIONN_INPUT } from 'common/graphql/mutations/notification/create-notification-input';
import { SEND_NOTIFICATIONS } from 'common/graphql/mutations/notification/send-notification';
import { NOTIFICATION_INPUTS } from 'common/graphql/queries/notification/notification-inputs';
import { NOTIFICATION_SUBTYPE } from 'common/graphql/queries/notification/notification-subtype';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import supportRequestSchema from 'common/validationSchemas/supportRequestSchema';
import DraftEditor from 'components/DraftEditor/DraftEditor';
import ReadOnlyEditor from 'components/DraftEditor/ReadOnlyEditor/ReadOnlyEditor';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import { useFormik } from 'formik';
import linkifyHtml from 'linkify-html';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var UserMessageDialog = function (_a) {
    var userId = _a.userId, handleClose = _a.handleClose;
    var _b = useQuery(NOTIFICATION_SUBTYPE, {
        variables: {
            name: 'userMessage'
        }
    }), notificationSubTypeData = _b.data, notificationSubTypeDataLoading = _b.loading, notificationSubTypeError = _b.error;
    var defaultFormState = {
        userId: userId,
        text: '',
        inputId: '',
        subject: '',
        createNewInput: true
    };
    var _c = useState(false), isPickAlreadyCreateInput = _c[0], setIsPickAlreadyCreateInput = _c[1];
    var handleIsPickAlreadyCreateInput = function (event) {
        setIsPickAlreadyCreateInput(event.target.checked);
    };
    var _d = useState(''), value = _d[0], setValue = _d[1];
    var _e = useState(undefined), valueObj = _e[0], setValueObj = _e[1];
    var _f = useState([]), options = _f[0], setOptions = _f[1];
    var _g = useState([]), optionObjects = _g[0], setOptionObjects = _g[1];
    var _h = useState(false), isReadyToSendMessage = _h[0], setIsReadyToSendMessage = _h[1];
    var _j = useState(''), inputId = _j[0], setInputId = _j[1];
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: "\u0414\u0436\u043E\u0431\u0430 \u0437\u0430\u043F\u0443\u0449\u0435\u043D\u0430",
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var _k = useLazyQuery(NOTIFICATION_INPUTS), getNotificationInputs = _k[0], _l = _k[1], data = _l.data, loading = _l.loading, error = _l.error;
    var createNotificationInput = function (data) {
        return useMutation(CREATE_NOTIFICATIONN_INPUT, {
            variables: {
                createNotificationInputData: __assign({}, data)
            }
        });
    };
    var sendNotification = function (data) {
        return useMutation(SEND_NOTIFICATIONS, {
            variables: {
                sendNotificationsData: __assign({}, data)
            }
        });
    };
    var _m = useDynamicMutation(createNotificationInput, undefined, onError), createNewInputMutation = _m.handleExecuteMutation, createdNotificationInput = _m.data;
    var sendNotificationsMutation = useDynamicMutation(sendNotification, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        var _a;
        if (data.createNewInput) {
            var options_1 = { defaultProtocol: 'https' };
            createNewInputMutation({
                notificationSubTypeId: (_a = notificationSubTypeData === null || notificationSubTypeData === void 0 ? void 0 : notificationSubTypeData.notificationSubType) === null || _a === void 0 ? void 0 : _a.id,
                inputData: {
                    subject: data.subject,
                    html: linkifyHtml(data.text, options_1)
                }
            });
        }
        else {
            setInputId(data.inputId);
            setIsReadyToSendMessage(true);
        }
    };
    useEffect(function () {
        var _a, _b;
        if ((_a = createdNotificationInput === null || createdNotificationInput === void 0 ? void 0 : createdNotificationInput.createNotificationInput) === null || _a === void 0 ? void 0 : _a.id) {
            setInputId((_b = createdNotificationInput === null || createdNotificationInput === void 0 ? void 0 : createdNotificationInput.createNotificationInput) === null || _b === void 0 ? void 0 : _b.id);
            setIsReadyToSendMessage(true);
        }
    }, [createdNotificationInput]);
    useEffect(function () {
        if (isReadyToSendMessage)
            sendNotificationsMutation({
                userIds: [userId],
                isIgnoreUserDisabledNotifications: true,
                notificationInputId: inputId,
                notificationType: NotificationTypes.byEmail
            });
        formik.resetForm();
    }, [isReadyToSendMessage]);
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: supportRequestSchema.pick(Object.keys(defaultFormState)),
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    useEffect(function () {
        var _a;
        if (isPickAlreadyCreateInput) {
            getNotificationInputs({
                variables: {
                    notificationSubTypeId: (_a = notificationSubTypeData === null || notificationSubTypeData === void 0 ? void 0 : notificationSubTypeData.notificationSubType) === null || _a === void 0 ? void 0 : _a.id
                }
            });
            formik.setFieldValue('createNewInput', false);
        }
    }, [isPickAlreadyCreateInput]);
    useEffect(function () {
        var _a, _b;
        if ((_a = data === null || data === void 0 ? void 0 : data.notificationInputs) === null || _a === void 0 ? void 0 : _a.length) {
            setOptions((_b = data === null || data === void 0 ? void 0 : data.notificationInputs) === null || _b === void 0 ? void 0 : _b.map(function (o) { return o.name; }));
            setOptionObjects(data === null || data === void 0 ? void 0 : data.notificationInputs);
        }
    }, [data]);
    useEffect(function () {
        if (value) {
            var valueObj_1 = optionObjects.filter(function (o) { return o.name === value; })[0];
            setValueObj(valueObj_1);
            formik.setFieldValue('text', valueObj_1.inputData.html);
            formik.setFieldValue('inputId', valueObj_1.id);
        }
    }, [value]);
    var dispatch = useDispatch();
    useEffect(function () {
        if (error)
            dispatch(openSnackbar({
                message: 'Ошибка получения данных',
                severity: SnackbarSeverity.ERROR
            }));
    }, [error]);
    useEffect(function () {
        if (notificationSubTypeError) {
            dispatch(openSnackbar({
                message: 'Подтип оповещения userMessage не найден!',
                severity: SnackbarSeverity.ERROR
            }));
            handleClose();
        }
    }, [notificationSubTypeError]);
    if (notificationSubTypeDataLoading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx("div", { children: _jsxs("form", __assign({ className: "flex flex-col space-y-5 p-0 pb-20", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044E \u043F\u043E Email" }), void 0), _jsxs("div", __assign({ className: "flex flex-row space-x-5 items-center" }, { children: [_jsx(Checkbox, { name: "isPickAlreadyCreateInput", checked: isPickAlreadyCreateInput, onChange: function (e) { return handleIsPickAlreadyCreateInput(e); } }, void 0), _jsx("p", __assign({ className: "dark:text-white" }, { children: "\u0418\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C \u0437\u0430\u0433\u043E\u0442\u043E\u0432\u043B\u0435\u043D\u043D\u044B\u0439 \u0442\u0435\u043A\u0441\u0442" }), void 0)] }), void 0), isPickAlreadyCreateInput ? (_jsxs("div", { children: [_jsx(Autocomplete, { value: value, filterOptions: function (x) { return x; }, options: options, disableClearable: false, renderInput: function (params) { return (_jsx(TextField, __assign({ name: "address", label: 'Выберите именованные данные' }, params), void 0)); }, freeSolo: true, onChange: function (event, newValue) {
                                setValue(newValue);
                            }, loading: loading }, void 0), valueObj && (_jsxs("div", __assign({ className: "flex flex-col space-y-5 md:max-w-840 items-center dark:text-white" }, { children: [_jsx("p", __assign({ className: "text-gray font-semibold pt-10" }, { children: "\u0422\u0435\u043C\u0430 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F:" }), void 0), _jsx(ReadOnlyEditor, { value: valueObj.inputData.subject }, void 0), _jsx("p", __assign({ className: "text-gray font-semibold pt-10" }, { children: "\u0422\u0435\u043A\u0441\u0442 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F:" }), void 0), _jsx(ReadOnlyEditor, { value: valueObj.inputData.html }, void 0)] }), void 0))] }, void 0)) : (_jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsx(TextField, { className: "w-full", label: "\u0422\u0435\u043C\u0430 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F", autoFocus: true, name: "subject", value: formik.values['subject'], onChange: function (e) {
                                formik.setFieldError('subject', undefined);
                                formik.setFieldValue('subject', e.target.value);
                            }, error: Boolean(formik.errors['subject']), helperText: formik.errors['subject'] }, void 0), _jsx(DraftEditor, { value: formik.values.text, onChange: function (data) {
                                formik.setFieldError('text', undefined);
                                formik.setFieldValue('text', data);
                            }, required: true, name: "html", placeholder: "\u0422\u0435\u043A\u0441\u0442 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F", error: Boolean(formik.errors.text), helperText: formik.errors.text, toolbarHidden: true }, void 0)] }), void 0)), _jsx(Button, __assign({ type: "submit" }, { children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C" }), void 0)] }), void 0) }, void 0));
};
export default UserMessageDialog;
