var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeProvider } from '@mui/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
function PageSimpleHeader(props) {
    var mainThemeDark = useSelector(function (_a) {
        var base = _a.base;
        return base.settings.mainTheme;
    });
    return (_jsx("div", __assign({ className: clsx(props.classes.header) }, { children: _jsx("div", __assign({ className: clsx(props.classes.container, 'xs:px-8 md:px-12 xl:px-24 xs:mt-8 md:mt-12') }, { children: props.header && _jsx(ThemeProvider, __assign({ theme: mainThemeDark }, { children: props.header }), void 0) }), void 0) }), void 0));
}
export default PageSimpleHeader;
