var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import CircularProgress from '@mui/material/CircularProgress';
import useTimeout from 'common/hooks/useTimeout';
import { useState } from 'react';
var CircularProgressMui = function (_a) {
    var delay = _a.delay;
    var _b = useState(!delay), showLoading = _b[0], setShowLoading = _b[1];
    useTimeout(function () {
        setShowLoading(true);
    }, delay);
    if (!showLoading) {
        return null;
    }
    return (_jsx("div", __assign({ className: "flex flex-1 flex-col items-center justify-center" }, { children: _jsx(CircularProgress, { className: "text-mainYellow" }, void 0) }), void 0));
};
export default CircularProgressMui;
