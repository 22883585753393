var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button, Divider, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cleanResetPasswordError, forgotPassword } from 'store/actions';
var ForgotPasswordForm = function (props) {
    var _a;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var resetPassword = useSelector(function (_a) {
        var auth = _a.auth;
        return auth.resetPassword;
    });
    useEffect(function () {
        return function () {
            //componentWillUnmount удалить ошибку
            dispatch(cleanResetPasswordError());
        };
    }, []);
    var handleChangeWrapper = function (e) {
        var _a;
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
        if ((_a = resetPassword.error) === null || _a === void 0 ? void 0 : _a.errorCode) {
            dispatch(cleanResetPasswordError());
        }
    };
    var defaultFormState = {
        email: ''
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: userValidationSchema.pick(Object.keys(defaultFormState)),
        validateOnChange: false,
        onSubmit: function (value) {
            dispatch(forgotPassword(value));
        },
        onReset: function () { },
        validateOnBlur: false
    });
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '32rem' } }, { children: [_jsxs(Box, __assign({ sx: { mb: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' } }, { children: [_jsxs(Typography, __assign({ sx: { fontSize: { xs: '2rem', md: '2rem' }, fontWeight: 500, lineHeight: 1 } }, { children: [t('Забыли пароль'), "?"] }), void 0), _jsx(Typography, __assign({ sx: { fontSize: '1.4rem', fontWeight: 400 }, className: "w-full mb-28 text-center leading-tight" }, { children: t('введите регистрационную почту') }), void 0)] }), void 0), _jsxs(Box, __assign({ sx: { width: '32rem' } }, { children: [_jsx("form", __assign({ onSubmit: formik.handleSubmit }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: _jsx(TextField, { label: "E-mail", name: "email", value: formik.values['email'], onChange: handleChangeWrapper, error: Boolean(formik.errors['email']), helperText: formik.errors['email'], autoFocus: true }, void 0) }), void 0), ((_a = resetPassword.error) === null || _a === void 0 ? void 0 : _a.errorCode) && (_jsx(Box, __assign({ sx: {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '32rem',
                                        color: 'red'
                                    } }, { children: _jsx(Typography, { children: t(resetPassword.error.errorCode) }, void 0) }), void 0)), _jsx(Box, __assign({ sx: { width: '32rem', mt: '1rem' } }, { children: _jsx(Button, __assign({ sx: { width: '32rem' }, type: "submit" }, { children: t('Отправить ссылку') }), void 0) }), void 0)] }), void 0) }), void 0), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', mt: '2.5rem' } }, { children: [_jsx(Box, __assign({ sx: { width: '32rem' } }, { children: _jsx(Divider, {}, void 0) }), void 0), _jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'space-between', mt: '1rem' } }, { children: [_jsxs("span", { children: [t('Я все помню'), "!"] }, void 0), _jsx(Link, __assign({ to: "/login" }, { children: t('Войти') }), void 0)] }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default ForgotPasswordForm;
