var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var YandexIconV2 = function (props) {
    return (_jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 60 60", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 0H48C54.6274 0 60 5.37258 60 12V48C60 54.6274 54.6274 60 48 60H12C5.37258 60 0 54.6274 0 48V12C0 5.37258 5.37258 0 12 0ZM31.7728 15.41H34.9286V50H42V10H31.7143C21.3701 10 15.935 15.2376 15.935 22.9496C15.935 29.108 18.9156 32.7336 24.2338 36.4748L15 50H22.656L32.9414 34.8634L29.3767 32.5037C25.052 29.6258 22.9481 27.3813 22.9481 22.5467C22.9481 18.2879 25.987 15.41 31.7728 15.41Z", fill: "#DBB400" }, void 0) }), void 0));
};
export default YandexIconV2;
