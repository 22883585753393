var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
var JoinAccountRequestDialog = function (_a) {
    var handleJoin = _a.handleJoin;
    return (_jsxs("div", { children: [_jsxs(DialogContent, __assign({ className: "dialog_content flex flex-col dark:text-white " }, { children: [_jsx(Typography, __assign({ className: "text-16 text-center" }, { children: "\u0410\u043A\u043A\u0430\u0443\u043D\u0442 \u0441 \u0442\u0430\u043A\u0438\u043C e-mail \u0443\u0436\u0435 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D \u0432 \u0441\u0438\u0441\u0442\u0435\u043C\u0435." }), void 0), _jsx(Typography, __assign({ className: "text-16 text-center" }, { children: "\u0425\u043E\u0442\u0438\u0442\u0435 \u0441\u0432\u044F\u0437\u0430\u0442\u044C \u0430\u043A\u043A\u0430\u0443\u043D\u0442 \u0441\u043E\u0446\u0441\u0435\u0442\u0438 \u0441 \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u043E\u043C Impulse?" }), void 0)] }), void 0), _jsx(DialogActions, __assign({ className: "flex justify-content" }, { children: _jsx(Button, __assign({ onClick: function () { return handleJoin(); } }, { children: "\u0421\u0432\u044F\u0437\u0430\u0442\u044C \u0438 \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C" }), void 0) }), void 0)] }, void 0));
};
export default JoinAccountRequestDialog;
