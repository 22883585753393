import { makeVar } from '@apollo/client';
import { DefaultTournamentStandingsColumns } from 'common/enums/standings/tournament-standings-columns.enum';
export var tournamentPageVar = makeVar({
    defaultTournamentStandingsColumnsValues: [
        DefaultTournamentStandingsColumns.FIO,
        DefaultTournamentStandingsColumns.Gender,
        DefaultTournamentStandingsColumns.AgeGroup,
        DefaultTournamentStandingsColumns.Kilometers,
    ]
});
