var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Stats = function (props) {
    return (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 19 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M13.9998 2.05908C13.9998 0.954512 13.1043 0.059082 11.9998 0.059082C10.8952 0.059082 9.99976 0.954512 9.99976 2.05908V19.5584C9.99976 20.663 10.8952 21.5584 11.9998 21.5584C13.1043 21.5584 13.9998 20.663 13.9998 19.5584V2.05908Z", fill: "currentColor" }, void 0), _jsx("path", { d: "M-0.000244141 10.8523C-0.000244141 9.74773 0.895191 8.8523 1.99976 8.8523C3.10434 8.8523 3.99977 9.74773 3.99977 10.8523V19.5582C3.99977 20.6627 3.10434 21.5582 1.99976 21.5582C0.895191 21.5582 -0.000244141 20.6627 -0.000244141 19.5582V10.8523Z", fill: "currentColor" }, void 0), _jsx("path", { d: "M8.99976 5.55842C8.99976 4.45385 8.10432 3.55842 6.99975 3.55842C5.89517 3.55842 4.99974 4.45385 4.99974 5.55842V19.5582C4.99974 20.6628 5.89517 21.5582 6.99975 21.5582C8.10432 21.5582 8.99976 20.6628 8.99976 19.5582V5.55842Z", fill: "currentColor" }, void 0), _jsx("path", { d: "M14.9997 8.5581C14.9997 7.45353 15.8952 6.55811 16.9997 6.55811C18.1043 6.55811 18.9998 7.45353 18.9998 8.5581V19.5574C18.9998 20.6619 18.1043 21.5574 16.9997 21.5574C15.8952 21.5574 14.9997 20.6619 14.9997 19.5574V8.5581Z", fill: "currentColor" }, void 0), _jsx("path", { d: "M16.9997 1.05811C15.8952 1.05811 14.9997 1.95354 14.9997 3.05811C14.9997 4.16261 15.8952 5.05811 16.9997 5.05811C18.1043 5.05811 18.9998 4.16261 18.9998 3.05811C18.9998 1.95354 18.1043 1.05811 16.9997 1.05811Z", fill: "currentColor" }, void 0), _jsx("path", { d: "M6.99976 0.059082L6.49976 0.559082L5.49976 1.05908L5.99976 1.55908V2.55908L6.99976 2.05908L7.99976 2.55908V1.55908L8.49976 1.05908L7.49976 0.559082L6.99976 0.059082Z", fill: "currentColor" }, void 0)] }), void 0));
};
export default Stats;
