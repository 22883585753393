import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { TEAM_PUBLIC_FIELDS } from 'common/graphql/queries/team/team-public-fields';
import { TEAM_USERS } from 'common/graphql/queries/team/team-users';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { openDialog, openSnackbar } from 'store/actions';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
var JoinTeamByLink = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    var joinLinkString = _a.joinLinkString, teamId = _a.teamId;
    var t = useTranslation().t;
    var currentUserId = (_b = useReactiveVar(userProfileDataVar).userProfile) === null || _b === void 0 ? void 0 : _b.id;
    var _w = useLazyQuery(TEAM_PUBLIC_FIELDS), getTeamData = _w[0], _x = _w[1], teamData = _x.data, teamDataLoading = _x.loading, teamDataError = _x.error;
    var _y = useLazyQuery(TEAM_USERS), getTeamUserData = _y[0], _z = _y[1], teamUserData = _z.data, teamUserDataLoading = _z.loading, teamUserDataError = _z.error;
    var canJoinViaLink = joinLinkString &&
        ((_d = (_c = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _c === void 0 ? void 0 : _c.participationSettings) === null || _d === void 0 ? void 0 : _d.isPrivate) &&
        ((_f = (_e = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _e === void 0 ? void 0 : _e.participationSettings) === null || _f === void 0 ? void 0 : _f.joinViaLink);
    var canJoinViaCode = ((_h = (_g = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _g === void 0 ? void 0 : _g.participationSettings) === null || _h === void 0 ? void 0 : _h.isPrivate) && ((_k = (_j = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _j === void 0 ? void 0 : _j.participationSettings) === null || _k === void 0 ? void 0 : _k.joinViaCode);
    var canJoinPublic = !((_p = (_o = (_m = (_l = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _l === void 0 ? void 0 : _l.tournaments) === null || _m === void 0 ? void 0 : _m[0].sportEvent) === null || _o === void 0 ? void 0 : _o.privacy) === null || _p === void 0 ? void 0 : _p.isOpenEvent) ||
        ((_t = (_s = (_r = (_q = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _q === void 0 ? void 0 : _q.tournaments) === null || _r === void 0 ? void 0 : _r[0].sportEvent) === null || _s === void 0 ? void 0 : _s.privacy) === null || _t === void 0 ? void 0 : _t.isPrivate);
    var canJoinTeam = canJoinViaLink || canJoinViaCode || canJoinPublic;
    var dispatch = useDispatch();
    var teamNotFound = function () {
        history.push('/events');
        dispatch(openSnackbar({
            message: t('Ошибка. Вероятно команда не найдена. Обратитесь к администратору.'),
            severity: SnackbarSeverity.ERROR
        }));
    };
    var teamJoinDialog = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        history.push("/team/".concat((_a = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _a === void 0 ? void 0 : _a.name));
        dispatch(openDialog({
            dialogType: DialogTypes.JoinTeamRequestDialog,
            dialogProps: {
                joinVia: canJoinViaLink ? 'privateLink' : canJoinViaCode ? 'privateCode' : 'public',
                teamId: teamId,
                joinLinkString: joinLinkString,
                teamName: (_b = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _b === void 0 ? void 0 : _b.name,
                teamDisplayName: (_c = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _c === void 0 ? void 0 : _c.displayName,
                tournamentId: (_f = (_e = (_d = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _d === void 0 ? void 0 : _d.tournaments) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.id,
                sportEventDisplayName: (_l = (_k = (_j = (_h = (_g = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _g === void 0 ? void 0 : _g.tournaments) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.sportEvent) === null || _k === void 0 ? void 0 : _k.mainSettings) === null || _l === void 0 ? void 0 : _l.displayName,
                teamParticipationSettings: (_m = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _m === void 0 ? void 0 : _m.participationSettings
            }
        }));
    };
    useEffect(function () {
        if (teamId && currentUserId) {
            getTeamData({
                variables: {
                    id: teamId
                }
            });
        }
    }, [currentUserId]);
    useEffect(function () {
        var _a;
        if (teamData && ((_a = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _a === void 0 ? void 0 : _a.name)) {
            getTeamUserData({
                variables: {
                    teamId: teamId,
                    userId: currentUserId
                }
            });
        }
    }, [teamData]);
    useEffect(function () {
        var _a, _b, _c;
        if ((teamUserData === null || teamUserData === void 0 ? void 0 : teamUserData.teamUsers) && ((_a = teamUserData === null || teamUserData === void 0 ? void 0 : teamUserData.teamUsers) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            history.push("/team/".concat((_b = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _b === void 0 ? void 0 : _b.name));
            dispatch(openSnackbar({
                message: t('Вы уже являетесь членом этой команды.'),
                severity: SnackbarSeverity.INFO
            }));
        }
        if ((teamUserData === null || teamUserData === void 0 ? void 0 : teamUserData.teamUsers) && ((_c = teamUserData === null || teamUserData === void 0 ? void 0 : teamUserData.teamUsers) === null || _c === void 0 ? void 0 : _c.length) === 0 && canJoinTeam) {
            teamJoinDialog();
        }
    }, [teamUserData]);
    useEffect(function () {
        if (canJoinTeam === false) {
            dispatch(openSnackbar({
                message: t('Вы не можете присоединиться к этой команде'),
                severity: SnackbarSeverity.ERROR
            }));
        }
    }, [canJoinTeam]);
    useEffect(function () {
        if (teamUserDataError || teamDataError) {
            teamNotFound();
        }
    }, [teamUserDataError, teamDataError]);
    if (teamDataLoading || teamUserDataLoading)
        return _jsx(CircularProgressMui, {}, void 0);
    return ((_u = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _u === void 0 ? void 0 : _u.name) && canJoinTeam === false ? _jsx(Redirect, { to: "/team/".concat((_v = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _v === void 0 ? void 0 : _v.name) }, void 0) : _jsx(_Fragment, {}, void 0);
};
export default JoinTeamByLink;
