var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function AppleHealthIcon(props) {
    return (_jsxs("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 100 100", fill: "none" }, props, { children: [_jsx("path", { d: "M63.6 5C72.6 5 77.1 5 82 6.5C87.3 8.4 91.5 12.6 93.4 17.9C95 22.8 95 27.4 95 36.4V63.6C95 72.6 95 77.1 93.5 82C91.6 87.3 87.4 91.5 82.1 93.4C77.1 95 72.6 95 63.6 95H36.4C27.4 95 22.9 95 18 93.5C12.6 91.5 8.5 87.4 6.5 82C5 77.2 5 72.7 5 63.6V36.4C5 27.4 5 22.9 6.5 18C8.5 12.7 12.6 8.5 18 6.6C22.8 5 27.3 5 36.4 5H63.6Z", fill: "white" }, void 0), _jsx("path", { d: "M80.7 32C80.7 25.2 75.5 20 69.5 20C65.3 20 61.9 21.4 59.8 24.5C57.7 21.4 54.3 20 50.8 20C44 20 38.8 25.2 38.8 32C38.8 31.8 38.8 31.7 38.8 32C38.8 42.1 48.5 52.3 59.8 56.7C68.6 53.8 80.7 42.1 80.7 32C80.7 31.8 80.7 31.7 80.7 32Z", fill: "url(#paint0_linear_9091_13866)" }, void 0), _jsx("defs", { children: _jsxs("linearGradient", __assign({ id: "paint0_linear_9091_13866", x1: "59.73", y1: "20.0254", x2: "59.73", y2: "56.7631", gradientUnits: "userSpaceOnUse" }, { children: [_jsx("stop", { "stop-color": "#FF61AD" }, void 0), _jsx("stop", { offset: "1", "stop-color": "#FF2719" }, void 0)] }), void 0) }, void 0)] }), void 0));
}
export default AppleHealthIcon;
