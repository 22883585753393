var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from '@apollo/client';
import { DialogActions, FormControl, InputLabel, MenuItem, OutlinedInput, Select, } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { FeatureTypes } from 'common/enums/feature/feature-types.enum';
import { CREATE_TOURNAMENT_FEATURE } from 'common/graphql/mutations/tournament/create-tournament-feature';
import { FEATURES } from 'common/graphql/queries/feature/features';
import { TOURNAMENT } from 'common/graphql/queries/tournament/tournament';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var CreateTournamentFeatureDialog = function (_a) {
    var tournamentId = _a.tournamentId, tournamentName = _a.tournamentName, handleClose = _a.handleClose;
    var _b = useQuery(FEATURES), data = _b.data, loading = _b.loading;
    var _c = useState(''), featureDisplayName = _c[0], setFeatureDisplayName = _c[1];
    var features = data === null || data === void 0 ? void 0 : data.features;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var createTournamentFeature = function (data) {
        return useMutation(CREATE_TOURNAMENT_FEATURE, {
            variables: {
                createTournamentFeatureData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TOURNAMENT,
                    variables: {
                        id: tournamentId,
                        showFeatures: true
                    }
                },
            ]
        });
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Фича добавлена к турниру.',
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(createTournamentFeature, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        var _a;
        var createObj = {
            tournamentId: tournamentId,
            featureId: ((_a = _.find(features, { displayName: featureDisplayName })) === null || _a === void 0 ? void 0 : _a.id) || ''
        };
        handleExecuteMutation(__assign({}, createObj));
    };
    var handleChange = function (event) {
        var value = event.target.value;
        setFeatureDisplayName(value);
    };
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20" }, { children: [_jsxs("div", __assign({ className: "tw-dialog-header" }, { children: ["\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u0443\u044E \u0444\u0438\u0447\u0443 \u043A \u0442\u0443\u0440\u043D\u0438\u0440\u0443 ", tournamentName] }), void 0), features && (features === null || features === void 0 ? void 0 : features.length) > 0 ? (_jsx("div", __assign({ className: "flex flex-col space-y-20" }, { children: _jsxs(FormControl, __assign({ className: "w-full" }, { children: [_jsx(InputLabel, { children: "\u0422\u0438\u043F" }, void 0), _jsx(Select, __assign({ label: "\u0422\u0438\u043F", value: featureDisplayName, onChange: handleChange, input: _jsx(OutlinedInput, { className: "\n\t\t\t\t\t\t\tbg-bg-container-inner-lt \n\t\t\t\t\t\t\tdark:bg-bg-container-inner-dk  \n\t\t\t\t\t\t\tdark:text-white", label: "\u0422\u0438\u043F" }, void 0), error: false }, { children: Object.values(FeatureTypes).map(function (featureType) {
                                        var _a;
                                        var featureDisplayName = ((_a = _.find(features, { name: featureType })) === null || _a === void 0 ? void 0 : _a.displayName) || '';
                                        return (_jsx(MenuItem, __assign({ value: featureDisplayName }, { children: featureDisplayName }), featureType));
                                    }) }), void 0)] }), void 0) }), void 0)) : (_jsx("p", { children: "\u041E\u0448\u0438\u0431\u043A\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0438 \u0434\u0430\u043D\u043D\u044B\u0445" }, void 0))] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm", onClick: function () {
                            handleSubmit({});
                        } }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0)] }), void 0)] }, void 0));
};
export default CreateTournamentFeatureDialog;
