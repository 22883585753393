var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Scrollbars from 'components/Scrollbars/Scrollbars';
function PageCardedSidebarContent(props) {
    var classes = props.classes;
    return (_jsxs("div", __assign({ className: clsx(classes.sidebarPaper, 'w-full') }, { children: [props.header && _jsx("div", __assign({ className: clsx(classes.sidebarHeader, props.variant) }, { children: props.header }), void 0), props.content && (_jsx(Scrollbars, __assign({ className: clsx('page-carded-sidebar-сontent bg-transparent md:bg-bg-container-inner-lt md:dark:bg-bg-container-inner-dk', classes.sidebarContent), enable: props.innerScroll }, { children: props.content }), void 0))] }), void 0));
}
export default PageCardedSidebarContent;
