var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from '@apollo/client';
import { Card, CardContent } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { NotificationStatus } from 'common/enums/notification/notification-status.enum';
import { MARK_NOTIFICATION_AS_READ } from 'common/graphql/mutations/notification/mark-notification-as-read';
import { NOTIFICATION } from 'common/graphql/queries/notification/notification';
import { USER_POP_UP_UI_NOTIFICATIONS_COUNT } from 'common/graphql/queries/notification/user-pop-up-ui-notifications-count';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import HelperFunctions from 'utils/HelperFunctions';
var UserNotificationPageContent = function (_a) {
    var _b;
    var notificationId = _a.notificationId;
    var i18n = useTranslation().i18n;
    var dispatch = useDispatch();
    var _c = useQuery(NOTIFICATION, {
        variables: {
            id: notificationId
        }
    }), data = _c.data, loading = _c.loading;
    var notification = data === null || data === void 0 ? void 0 : data.notification;
    var getFancyDate = HelperFunctions.getFancyDate;
    var markAsRead = function (data) {
        return useMutation(MARK_NOTIFICATION_AS_READ, {
            variables: {
                id: data
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER_POP_UP_UI_NOTIFICATIONS_COUNT,
                    variables: {
                        status: NotificationStatus.isNotified
                    }
                },
            ]
        });
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(markAsRead, undefined, onError).handleExecuteMutation;
    useEffect(function () {
        if (notification && notification.status !== NotificationStatus.isRead) {
            handleExecuteMutation(notification.id);
        }
    }, [notification]);
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return notification ? (_jsx("div", __assign({ className: "h-full max-h-full tw-page-container-shadow flex-nowrap" }, { children: _jsxs("div", __assign({ className: "flex flex-col space-y-10 w-full items-center" }, { children: [_jsx("p", __assign({ className: "text-12 text-grey" }, { children: getFancyDate(notification.createdAt, i18n.language) }), void 0), _jsx(Card, { children: _jsx(CardContent, { children: _jsx("div", { children: parse((_b = notification.content) === null || _b === void 0 ? void 0 : _b.message) }, void 0) }, void 0) }, void 0)] }), void 0) }), void 0)) : null;
};
export default UserNotificationPageContent;
