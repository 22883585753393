var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import TeamParticipantMoreMenu from './TeamParticipantMoreMenu';
var TeamParticipantsSearchResultListItem = function (_a) {
    var _b, _c, _d, _e;
    var userProfile = _a.userProfile, teamUserCreatedAt = _a.teamUserCreatedAt, teamUserIsCaptain = _a.teamUserIsCaptain, handleExpelUser = _a.handleExpelUser, handleUpdateTeamUser = _a.handleUpdateTeamUser;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    // const isTeamMate = userProfile?.teamId === currentUserProfile?.team?.id;
    var isMy = (userProfile === null || userProfile === void 0 ? void 0 : userProfile.id) === (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id);
    var getUserInfo = function () {
        return (_jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 pb-2 w-148 sm:w-170" }, { children: "\u0414\u0430\u0442\u0430 \u0432\u0441\u0442\u0443\u043F\u043B\u0435\u043D\u0438\u044F:" }), void 0), _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14" }, { children: teamUserCreatedAt ? new Date(teamUserCreatedAt).toLocaleString() : "-" }), void 0)] }), void 0));
    };
    return (_jsxs("div", __assign({ className: "flex justify-between xs:py-8 md:py-12 decoration-0" }, { children: [_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex items-center w-full space-x-10" }, { children: [_jsx(UserAvatar, { style: "xs:w-48 xs:h-48 xs:text-16", profilePhoto: (_b = userProfile.mainSettings) === null || _b === void 0 ? void 0 : _b.profilePhoto, firstName: (_c = userProfile.mainSettings) === null || _c === void 0 ? void 0 : _c.firstName }, void 0), _jsx("div", __assign({ className: "w-full flex xs:flex-col sm:flex-row justify-between h-full" }, { children: _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex flex-col sm:flex-row" }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                        history.push({
                                                            pathname: '/profile/' + userProfile.userName,
                                                            state: { fromAthletesSearchPage: true }
                                                        });
                                                    }, className: "flex xs:text-14 md:text-16 font-medium py-2 cursor-pointer items-center sm:pr-8" }, { children: [(_d = userProfile.mainSettings) === null || _d === void 0 ? void 0 : _d.firstName, " ", (_e = userProfile.mainSettings) === null || _e === void 0 ? void 0 : _e.lastName] }), void 0), _jsxs("div", __assign({ className: "flex items-center" }, { children: [isMy && _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 py-2 pr-4" }, { children: "(\u042D\u0442\u043E \u0432\u044B)" }), void 0), teamUserIsCaptain && (_jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-2 py-2" }, { children: "\u041A\u0430\u043F\u0438\u0442\u0430\u043D" }), void 0))] }), void 0)] }), void 0), _jsx("div", __assign({ className: "hidden sm:flex flex-col" }, { children: getUserInfo() }), void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex sm:hidden pt-8 flex-col" }, { children: getUserInfo() }), void 0)] }), void 0), !isMy && (_jsx(TeamParticipantMoreMenu, { handleExpelUser: handleExpelUser, handleUpdateTeamUser: handleUpdateTeamUser, userId: userProfile.id, isCaptain: teamUserIsCaptain }, void 0))] }), void 0));
};
export default TeamParticipantsSearchResultListItem;
