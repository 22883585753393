import React from 'react';
export var athletesSearchPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/profiles/search',
            component: React.lazy(function () { return import('./AthletesSearchPage'); })
        },
    ]
};
