var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@apollo/client';
import { CREATE_SPORT_EVENT_PARTICIPANT } from 'common/graphql/mutations/sportEvent/createSportEventParticipant';
import { SPORT_EVENT } from 'common/graphql/queries/sportEvent/sportEvent';
import { SPORT_EVENTS } from 'common/graphql/queries/sportEvent/sportEvents';
export function useJoinSportEventMutation(variables) {
    return useMutation(CREATE_SPORT_EVENT_PARTICIPANT, {
        variables: {
            createSportEventParticipantData: __assign({}, variables)
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: SPORT_EVENT,
                variables: {
                    id: variables === null || variables === void 0 ? void 0 : variables.sportEventId,
                    showBasicFields: true
                }
            },
            {
                query: SPORT_EVENTS
            },
        ]
    });
}
