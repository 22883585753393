var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Event = function (props) {
    return (_jsx("svg", __assign({ width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fill: "currentColor", d: "M21.333 2.667h-2.666V0H5.333v2.667H2.667A2.675 2.675 0 0 0 0 5.333v1.334c0 3.4 2.56 6.173 5.853 6.586a6.68 6.68 0 0 0 4.814 3.947v4.133H5.333V24h13.334v-2.667h-5.334V17.2a6.68 6.68 0 0 0 4.814-3.947C21.44 12.84 24 10.067 24 6.667V5.333c0-1.466-1.2-2.666-2.667-2.666Zm-16 7.76c-1.546-.56-2.666-2.027-2.666-3.76V5.333h2.666v5.094Zm16-3.76c0 1.733-1.12 3.2-2.666 3.76V5.333h2.666v1.334Z" }, void 0) }), void 0));
};
export default Event;
