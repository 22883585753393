var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
import { NOTIFICATION_TEMPLATES } from 'common/graphql/queries/notification/notification-templates';
var ITEM_HEIGHT = 48;
var ITEM_PADDING_TOP = 8;
var MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
function getStyles(name, personName, theme) {
    return {
        fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    };
}
export var NotificationTemplateSelect = function (_a) {
    var multiple = _a.multiple, selectedOptionIds = _a.selectedOptionIds, setSelectedOptions = _a.setSelectedOptions;
    var theme = useTheme();
    var data = useQuery(NOTIFICATION_TEMPLATES).data;
    var notificationTemplates = (data === null || data === void 0 ? void 0 : data.notificationTemplates) || [];
    var selectedValues = notificationTemplates.filter(function (o) { return selectedOptionIds.includes(o.id); });
    return (_jsxs(FormControl, __assign({ className: "w-full" }, { children: [_jsx(InputLabel, { children: "\u0428\u0430\u0431\u043B\u043E\u043D\u044B \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u0439" }, void 0), _jsx(Select, __assign({ style: { width: 400 }, label: "\u0428\u0430\u0431\u043B\u043E\u043D\u044B \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u0439", multiple: multiple, value: selectedValues.map(function (o) { return o.name; }), onChange: function (e) {
                    var value = e.target.value;
                    setSelectedOptions(notificationTemplates.filter(function (o) {
                        if (typeof value === 'string')
                            return o.name === value;
                        return value.includes(o.name);
                    }));
                }, input: _jsx(OutlinedInput, { className: "\n        bg-bg-container-inner-lt \n        dark:bg-bg-container-inner-dk  \n        dark:text-white", label: "\u0428\u0430\u0431\u043B\u043E\u043D\u044B \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u0439" }, void 0), renderValue: multiple
                    ? function (selected) { return (_jsx(Box, __assign({ sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }, { children: selected.map(function (value) { return (_jsx(Chip, { label: value }, value)); }) }), void 0)); }
                    : undefined, MenuProps: MenuProps }, { children: notificationTemplates.map(function (notificationTemplate) { return (_jsx(MenuItem, __assign({ value: notificationTemplate.name, style: getStyles(notificationTemplate.name, notificationTemplates.map(function (o) { return o.name; }), theme) }, { children: notificationTemplate.name }), notificationTemplate.id)); }) }), void 0)] }), void 0));
};
