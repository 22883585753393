import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import React from 'react';
import JoinEventByLink from './JoinEventByLink';
export var sportEventPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/event/:sportEventUrl',
            component: React.lazy(function () { return import('./SportEventPage'); })
        },
        {
            path: '/event-join/:sportEventId',
            component: function (props) {
                var sportEventId = props.match.params.sportEventId;
                return _jsx(JoinEventByLink, { sportEventId: sportEventId }, void 0);
            }
        },
    ]
};
