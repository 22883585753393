var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { STANDINGS } from 'common/graphql/queries/standings/standings';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
var StandingsSportSettings = function (_a) {
    var _b;
    var standingsId = _a.standingsId;
    var _c = useQuery(STANDINGS, {
        variables: {
            id: standingsId,
            showBasicFields: true,
            showTournament: true,
            showTeam: true,
            showSports: true
        }
    }), data = _c.data, loading = _c.loading;
    var standingsSports = (_b = data === null || data === void 0 ? void 0 : data.standings) === null || _b === void 0 ? void 0 : _b.sports;
    var dispatch = useDispatch();
    var openCreateStandingsSportDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.CreateStandingsSport,
            dialogProps: {
                standingsId: standingsId,
                standingsSports: standingsSports
            }
        }));
    };
    var openDeleteStandingsSportRequestDialog = function (sportId) {
        dispatch(openDialog({
            dialogType: DialogTypes.DeleteStandingsSportRequest,
            dialogProps: {
                standingsId: standingsId,
                sportId: sportId
            }
        }));
    };
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u0432\u0438\u0434\u043E\u0432 \u0441\u043F\u043E\u0440\u0442\u0430" }), void 0) }), void 0), content: _jsxs("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: [_jsx("div", { children: _jsx(Button, __assign({ onClick: openCreateStandingsSportDialog }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0) }, void 0), standingsSports && (_jsx("div", __assign({ className: "flex flex-col space-y-20 max-w-160" }, { children: standingsSports.map(function (sport) {
                        return (_jsxs("div", __assign({ className: "flex flex-row space-x-10 items-center w-full justify-between" }, { children: [_jsx("p", { children: sport.displayName }, void 0), _jsx(Button, __assign({ type: "button", color: "error", onClick: function () { return openDeleteStandingsSportRequestDialog(sport.id); } }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }), void 0)] }), sport.id));
                    }) }), void 0))] }), void 0), innerScroll: false }, void 0));
};
export default StandingsSportSettings;
