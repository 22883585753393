var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Standings = function (props) {
    return (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M2.41291 23H21.5871C22.9178 23 24 21.9037 24 20.5556V3.44444C24 2.09633 22.9178 1 21.5871 1H2.41291C1.08219 1 0 2.09633 0 3.44444V20.5556C0 21.9037 1.08219 23 2.41291 23ZM2.41291 20.5556V14.4444H7.23873V20.5556H2.41291ZM14.4775 5.88889V12H9.65164V5.88889H14.4775ZM7.23873 5.88889V12H2.41291V5.88889H7.23873ZM9.65164 20.5556V14.4444H14.4775V20.5556H9.65164ZM16.8904 20.5556V14.4444H21.5883V20.5556H16.8904ZM21.5871 12H16.8904V5.88889H21.5871V12Z", fill: "currentColor" }, void 0) }), void 0));
};
export default Standings;
