var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_USER_NOTIFICATION_SETTINGS } from 'common/graphql/mutations/user/updateUser/update-user-notification-settings';
import { USER_NOTIFICATION_SETTINGS } from 'common/graphql/queries/user/user-notification-settings';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var UserNotificationSettingsListItem = function (_a) {
    var userNotificationSettingsGrouped = _a.userNotificationSettingsGrouped;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var updateUserNotificationSettings = function (data) {
        return useMutation(UPDATE_USER_NOTIFICATION_SETTINGS, {
            variables: {
                updateUserNotificationSettingsData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER_NOTIFICATION_SETTINGS
                },
            ]
        });
    };
    var handleExecuteMutation = useDynamicMutation(updateUserNotificationSettings, onSuccess, onError).handleExecuteMutation;
    return (_jsxs("div", __assign({ className: "flex flex-row items-center justify-between w-full" }, { children: [_jsx(Typography, __assign({ className: "[overflow-wrap:anywhere] max-w-240", display: "inline", sx: { fontSize: '1.4rem' } }, { children: t(userNotificationSettingsGrouped.subTypeDescription) }), void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [userNotificationSettingsGrouped.statusPerType.byEmail && (_jsx(FormControlLabel, { control: _jsx(Switch, { checked: userNotificationSettingsGrouped.statusPerType.byEmail.enabled, onChange: function () {
                                var _a, _b;
                                handleExecuteMutation({
                                    notificationSettingsId: (_a = userNotificationSettingsGrouped.statusPerType.byEmail) === null || _a === void 0 ? void 0 : _a.id,
                                    enabled: !((_b = userNotificationSettingsGrouped.statusPerType.byEmail) === null || _b === void 0 ? void 0 : _b.enabled)
                                });
                            } }, void 0), label: t('По email') }, void 0)), userNotificationSettingsGrouped.statusPerType.popUpUi && (_jsx(FormControlLabel, { control: _jsx(Switch, { checked: userNotificationSettingsGrouped.statusPerType.popUpUi.enabled, onChange: function () {
                                var _a, _b;
                                var enabled = !((_a = userNotificationSettingsGrouped.statusPerType.popUpUi) === null || _a === void 0 ? void 0 : _a.enabled);
                                handleExecuteMutation({
                                    notificationSettingsId: (_b = userNotificationSettingsGrouped.statusPerType.popUpUi) === null || _b === void 0 ? void 0 : _b.id,
                                    enabled: enabled
                                });
                            } }, void 0), label: t('Пуш-уведомление') }, void 0))] }), void 0)] }), void 0));
};
export default UserNotificationSettingsListItem;
