import { TournamentStandingsType } from 'common/enums/tournament/tournament-standings-type.enum';
import * as Yup from 'yup';
var sportEventValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(20, 'Слишком длинное название. Пожалуйста, введите до 20 символов')
        .required('Не указано уникальное имя')
        .matches(/^([a-zA-Z0-9_]+)$/, 'Уникальное имя может состоять из латинских букв, цифр и подчеркивания')
        .matches(/^([a-zA-Z0-9_]{5,})$/, 'Минимальная длина уникального имени 5 символов')
        .matches(/^(?:[0-9_]*[a-zA-Z]){3}/, 'Уникальное имя должно содержать не менее 3 латинских букв'),
    displayName: Yup.string()
        .trim()
        .min(3)
        .max(128, 'Слишком длинное название. Пожалуйста, введите от 3 до 128 символов')
        .required('Не указано отображаемое имя'),
    startDate: Yup.date()
        .min(new Date(), "\u0412\u044B \u043D\u0435 \u043C\u043E\u0436\u0435\u0442\u0435 \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u0434\u0430\u0442\u0443 \u043D\u0430\u0447\u0430\u043B\u0430 \u043F\u0440\u043E\u0432\u0435\u0434\u0435\u043D\u0438\u044F \u0442\u0443\u0440\u043D\u0438\u0440\u0430 \u0440\u0430\u043D\u0435\u0435 ".concat(new Date(), "."))
        .required('Не указана дата начала турнира.')
        .nullable()
        .typeError('Неверный формат даты.'),
    endDate: Yup.date()
        .min(new Date(), "\u0412\u044B \u043D\u0435 \u043C\u043E\u0436\u0435\u0442\u0435 \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u0434\u0430\u0442\u0443 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F \u043F\u0440\u043E\u0432\u0435\u0434\u0435\u043D\u0438\u044F \u0442\u0443\u0440\u043D\u0438\u0440\u0430 \u0440\u0430\u043D\u0435\u0435 ".concat(new Date(), "."))
        .required('Не указана дата окончания турнира.')
        .nullable()
        .typeError('Неверный формат даты.'),
    standingsType: Yup.mixed()
        .oneOf(Object.values(TournamentStandingsType))
        .required('Не задан тип турнира')
        .typeError('Неверный формат типа турнира.')
});
export default sportEventValidationSchema;
