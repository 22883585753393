var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Table, TableContainer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { StandingsParticipantType } from 'common/enums/standings/standings-participant-type.enum';
import BasicTableHelper from 'components/BasicTable/BasicTableHelper';
import { useEffect, useRef, useState } from 'react';
import BasicTableBody from './BasicTableBody';
import BasicTableComponents from './BasicTableComponents';
var BasicTable = function (_a) {
    var _b, _c, _d, _e;
    var onEmptyMessage = _a.onEmptyMessage, columns = _a.columns, rows = _a.rows, highlightedRowConditionFunc = _a.highlightedRowConditionFunc, isShowHighlightedRow = _a.isShowHighlightedRow, setShowedHighlightedRow = _a.setShowedHighlightedRow, isShowStandingsPages = _a.isShowStandingsPages, setShowedStandingsPage = _a.setShowedStandingsPage, _f = _a.displayColumnsIfEmpty, displayColumnsIfEmpty = _f === void 0 ? false : _f, _g = _a.displayTablePaginationComponent, displayTablePaginationComponent = _g === void 0 ? true : _g, _h = _a.displayTableHead, displayTableHead = _h === void 0 ? true : _h, _j = _a.collapseColumns, collapseColumns = _j === void 0 ? true : _j, innerTableName = _a.innerTableName, onRowClick = _a.onRowClick, handleChangePageAndRowsCount = _a.handleChangePageAndRowsCount, pageAndRowsPerPageCount = _a.pageAndRowsPerPageCount, rowsCount = _a.rowsCount, orderByAndOrderParam = _a.orderByAndOrderParam, setOrderByAndOrderParam = _a.setOrderByAndOrderParam, _k = _a.isAlignRowHeight, isAlignRowHeight = _k === void 0 ? false : _k, unwrapRowsInMobile = _a.unwrapRowsInMobile, _l = _a.participantType, participantType = _l === void 0 ? StandingsParticipantType.User : _l;
    var _m = useState(orderByAndOrderParam === null || orderByAndOrderParam === void 0 ? void 0 : orderByAndOrderParam.orderBy), orderByColumn = _m[0], setOrderByColumn = _m[1];
    var _o = useState((orderByAndOrderParam === null || orderByAndOrderParam === void 0 ? void 0 : orderByAndOrderParam.orderParam) || 'asc'), order = _o[0], setOrder = _o[1];
    var _p = useState(), highlightedRowIndex = _p[0], setHighlightedRowIndex = _p[1];
    var _q = useState(0), page = _q[0], setPage = _q[1];
    var _r = useState(localStorage.getItem('tableRowsPerPage') ? Number(localStorage.getItem('tableRowsPerPage')) : 10), rowsPerPage = _r[0], setRowsPerPage = _r[1];
    var ref = useRef(null);
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('md'));
    var tableHeadComponentId = 'TableHeadComponent' + (Math.floor(Math.random() * 6) + 1).toString();
    var getComparator = BasicTableHelper.getComparator;
    var collapseIconColumn = {
        id: 'collapseIcon',
        data: 'collapseIcon',
        value: 'collapseIcon',
        label: '',
        style: 'collapse-icon w-40 text-center p-0'
    };
    useEffect(function () {
        //метод показывает страницу на которой находится выделенная строка
        if (highlightedRowConditionFunc && isShowStandingsPages && setShowedStandingsPage) {
            var page_1 = highlightedRowIndex && Math.ceil((highlightedRowIndex + 1) / rowsPerPage) - 1;
            typeof page_1 === 'number' && setPage(page_1);
            setShowedStandingsPage(!isShowStandingsPages);
        }
    }, [isShowStandingsPages]);
    useEffect(function () {
        if (rows.length && !!!setOrderByAndOrderParam) {
            var arrayForSort = __spreadArray([], rows, true);
            var sortedRows = arrayForSort.sort(getComparator(order, orderByColumn));
            sortedRows.map(function (row, index) {
                if (highlightedRowConditionFunc && highlightedRowConditionFunc(row)) {
                    setHighlightedRowIndex(index);
                }
            });
        }
    }, [rows]);
    var collapsedColumns = collapseColumns
        ? columns.filter(function (column) { return column.collapsible && isMobile; })
        : [];
    var visibleColumns = collapsedColumns.length > 0 && isMobile
        ? __spreadArray([collapseIconColumn], columns.filter(function (column) { return !isMobile || !column.collapsible; }), true) : __spreadArray([], columns.filter(function (column) { return !isMobile || !column.collapsible || !collapseColumns; }), true);
    return (_jsx(_Fragment, { children: (rows && rows.length !== 0) || displayColumnsIfEmpty ? (_jsxs("div", __assign({ className: "basic-table flex h-full flex-col justify-between overflow-hidden" }, { children: [_jsxs(Box, { children: [innerTableName && (_jsx("div", __assign({ className: "w-full flex items-center justify-center text-16 pb-8 pt-5" }, { children: innerTableName }), void 0)), _jsx(TableContainer, { children: _jsxs(Table, __assign({ stickyHeader: true, "aria-label": "sticky table" }, { children: [(rows.length !== 0 || displayColumnsIfEmpty) && displayTableHead && (_jsx(BasicTableComponents.TableHeadComponent, { id: tableHeadComponentId, visibleColumns: visibleColumns, order: order, setOrderAndOrderParam: function (params) {
                                            if (setOrderByAndOrderParam) {
                                                setOrderByAndOrderParam({
                                                    orderBy: params.orderBy,
                                                    orderParam: params.orderParam
                                                });
                                            }
                                            else {
                                                setOrderByColumn(params.orderBy);
                                                setOrder(params.orderParam);
                                            }
                                        }, orderByColumn: orderByColumn }, void 0)), rows.length !== 0 && (_jsx(BasicTableBody, { isAlignRowHeight: isAlignRowHeight, visibleColumns: visibleColumns, collapsedColumns: collapsedColumns, rows: rows, highlightedRowConditionFunc: highlightedRowConditionFunc, order: order, orderByColumn: orderByColumn, 
                                        //чтобы не было slice
                                        backendPagination: pageAndRowsPerPageCount && pageAndRowsPerPageCount.pageNum >= 0, page: (_b = pageAndRowsPerPageCount === null || pageAndRowsPerPageCount === void 0 ? void 0 : pageAndRowsPerPageCount.pageNum) !== null && _b !== void 0 ? _b : page, rowsPerPage: (_c = pageAndRowsPerPageCount === null || pageAndRowsPerPageCount === void 0 ? void 0 : pageAndRowsPerPageCount.rowsCount) !== null && _c !== void 0 ? _c : rowsPerPage, onRowClick: onRowClick, unwrapRowsInMobile: unwrapRowsInMobile !== undefined ? isMobile && unwrapRowsInMobile : false, isShowHighlightedRow: isShowHighlightedRow, isShowStandingsPages: isShowStandingsPages, setShowedHighlightedRow: setShowedHighlightedRow, participantType: participantType }, void 0))] }), void 0) }, void 0), rows.length == 0 && displayColumnsIfEmpty && (_jsx(BasicTableComponents.EmptyDataMessageComponent, { onEmptyMessage: onEmptyMessage }, void 0))] }, void 0), rows.length !== 0 && displayTablePaginationComponent && (_jsx(BasicTableComponents.TablePaginationComponent, { rowsLength: rowsCount !== null && rowsCount !== void 0 ? rowsCount : rows.length, page: (_d = pageAndRowsPerPageCount === null || pageAndRowsPerPageCount === void 0 ? void 0 : pageAndRowsPerPageCount.pageNum) !== null && _d !== void 0 ? _d : page, setPage: function (page) {
                        var doc = document.getElementById(tableHeadComponentId);
                        if (doc) {
                            doc.scrollIntoView();
                        }
                        if (handleChangePageAndRowsCount) {
                            handleChangePageAndRowsCount({
                                rowsCount: (pageAndRowsPerPageCount === null || pageAndRowsPerPageCount === void 0 ? void 0 : pageAndRowsPerPageCount.rowsCount) || 10,
                                pageNum: page
                            });
                        }
                        else {
                            setPage(page);
                        }
                    }, rowsPerPage: (_e = pageAndRowsPerPageCount === null || pageAndRowsPerPageCount === void 0 ? void 0 : pageAndRowsPerPageCount.rowsCount) !== null && _e !== void 0 ? _e : rowsPerPage, setRowsPerPage: function (rowsPerPage) {
                        localStorage.setItem('tableRowsPerPage', rowsPerPage.toString());
                        var doc = document.getElementById(tableHeadComponentId);
                        if (doc) {
                            doc.scrollIntoView();
                        }
                        if (handleChangePageAndRowsCount) {
                            handleChangePageAndRowsCount({
                                rowsCount: rowsPerPage,
                                pageNum: 0
                            });
                        }
                        else {
                            setRowsPerPage(rowsPerPage);
                            setPage(0);
                        }
                    } }, void 0))] }), void 0)) : (_jsx(BasicTableComponents.EmptyDataMessageComponent, { onEmptyMessage: onEmptyMessage }, void 0)) }, void 0));
};
export default BasicTable;
