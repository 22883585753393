import * as Actions from '../../actions/auth';

const initialState = {
    inputVerificationKey: false,
    currentEmail: '',
    success: false,
    error: {
        email: null,
        password: null,
        verification_key: null
    }
};

const register = function (state = initialState, action) {
    switch (action.type) {
        case Actions.REGISTER_SUCCESS: {
            return {
                ...state,
                success: true
            };
        }
        case Actions.REGISTER_ERROR: {
            return {
                ...state,
                success: false,
                error: action.payload
            };
        }
        case Actions.INVALID_KEY_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.INPUT_VERIFICATION_KEY: {
            return {
                ...state,
                inputVerificationKey: true,
                ...action.payload
            };
        }
        default: {
            return state
        }
    }
};

export default register;