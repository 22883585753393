import { logoutUser } from 'store/actions/auth';
import store from 'store/store';
import authRoles from 'common/authRoles';
export var logoutPageConfig = {
    auth: authRoles.user,
    routes: [
        {
            path: '/logout',
            component: function () {
                store.dispatch(logoutUser());
            }
        },
    ]
};
