export var ResponseErrors;
(function (ResponseErrors) {
    ResponseErrors["UserAlreadyExists"] = "USER_ALREADY_EXISTS";
    ResponseErrors["UserNotFound"] = "USER_NOT_FOUND";
    ResponseErrors["UserAlreadyLoggedIn"] = "USER_ALREADY_LOGGED_IN";
    ResponseErrors["UserAlreadyRegistered"] = "USER_ALREADY_REGISTERED";
    ResponseErrors["AccountJoinRequired"] = "ACCOUNT_JOIN_REQUIRED";
    ResponseErrors["AccountsAlreadyJoined"] = "ACCOUNTS_ALREADY_JOINED";
    ResponseErrors["InvalidBirthDate"] = "INVALID_BIRTH_DATE";
    // Support Request
    ResponseErrors["TooMuchSupportRequestsFromUser"] = "TOO_MUCH_SUPPORT_REQUESTS_FROM_USER";
    // Activity
    ResponseErrors["ActivitiesQueueTooMuchRequests"] = "ACTIVITIES_QUEUE_TOO_MUCH_REQUESTS";
    // Social exceptions
    ResponseErrors["ErrorLoadingSocialData"] = "ERROR_LOADING_SOCIAL_DATA";
    ResponseErrors["NoDataFromSocial"] = "NO_DATA_FROM_SOCIAL";
    ResponseErrors["NoStravaRefreshToken"] = "NO_STRAVA_TOKEN";
    ResponseErrors["AccountBindingLimitReached"] = "ACCOUNT_BINDING_LIMIT_REACHED";
    ResponseErrors["AccountBindingFailed"] = "ACCOUNT_BINDING_FAILED";
    ResponseErrors["AccountBindingNotFound"] = "ACCOUNT_BINDING_NOT_FOUND";
    ResponseErrors["AccountAlreadyBoundAnotherUser"] = "ACCOUNT_ALREADY_BOUND_ANOTHER_USER";
    ResponseErrors["AnotherStravaAccountActivitiesExist"] = "ANOTHER_STRAVA_ACCOUNT_ACTIVITIES_EXIST";
    // Auth exceptions
    ResponseErrors["InvalidEmailOrPassword"] = "INVALID_EMAIL_OR_PASSWORD";
    ResponseErrors["InvalidUsernameOrPassword"] = "INVALID_USERNAME_OR_PASSWORD";
    ResponseErrors["InvalidPhoneNumberOrPassword"] = "INVALID_PHONE_OR_PASSWORD";
    // Role exceptions
    ResponseErrors["RoleAlreadyExists"] = "ROLE_ALREADY_EXISTS";
    ResponseErrors["RoleNotFound"] = "ROLE_NOT_FOUND";
    ResponseErrors["RoleAlreadyAssigned"] = "ROLE_ALREADY_ASSIGNED";
    //Tournament
    ResponseErrors["TournamentAlreadyExists"] = "TOURNAMENT_ALREADY_EXISTS";
    ResponseErrors["TournamentParticipantAlreadyExists"] = "TOURNAMENT_PARTICIPANT_ALREADY_EXISTS";
    ResponseErrors["TournamentParticipantDoesNotExists"] = "TOURNAMENT_PARTICIPANT_DOES_NOT_EXIST";
    ResponseErrors["TournamentCantBeArchivedWhenActive"] = "TOURNAMENT_CANT_BE_ARCHIVED_WHEN_ACTIVE";
    ResponseErrors["TournamentCantBeActivatedIfArchived"] = "TOURNAMENT_CANT_BE_ACTIVATE_IF_ARCHIVED";
    ResponseErrors["TournamentRegistrationNotOpen"] = "TOURNAMENT_REGISTRATION_NOT_OPEN";
    ResponseErrors["TournamentRegistrationStartCantBeAfterTournamentStart"] = "TOURNAMENT_REGISTRATION_START_DATE_INVALID";
    ResponseErrors["TournamentRegistrationEndCantBeAfterTournamentEnd"] = "TOURNAMENT_REGISTRATION_END_DATE_INVALID";
    ResponseErrors["TournamentCantChangeStartDateIfStarted"] = "TOURNAMENT_CANT_CHANGE_START_DATE_IF_STARTED";
    ResponseErrors["TournamentCantSetEndDateBeforePreviousEndDate"] = "TOURNAMENT_CANT_SET_END_DATE_BEFORE_PREVIOUS_END_DATE";
    ResponseErrors["TournamentIsInactive"] = "TOURNAMENT_IS_INACTIVE";
    ResponseErrors["TournamentIsActiveCantMakeInvisible"] = "TOURNAMENT_IS_ACTIVE_CANT_MAKE_INVISIBLE";
    ResponseErrors["TournamentArchivedCantDearchive"] = "TOURNAMENT_CANT_DEARCHIVE";
    ResponseErrors["TournamentNotArchivedCantDelete"] = "TOURNAMENT_NOT_ARCHIVED_CANT_DELETE";
    // Verification exceptions
    ResponseErrors["VerificationAlreadyComplete"] = "VERIFICATION_ALREADY_COMPLETE";
    ResponseErrors["VerificationNotFound"] = "VERIFICATION_NOT_FOUND";
    ResponseErrors["VerificationRequired"] = "VERIFICATION_REQUIRED";
    ResponseErrors["VerificationNotComplete"] = "VERIFICATION_NOT_COMPLETE";
    ResponseErrors["InvalidPayloadOrKey"] = "INVALID_PAYLOAD_OR_KEY";
    ResponseErrors["InvalidatedVerification"] = "VERIFICATION_INVALIDATED";
    ResponseErrors["MaxAttemptsReached"] = "MAX_NUM_OF_ATTEMPTS";
    ResponseErrors["VerificationTimedOut"] = "VERIFICATION_TIMED_OUT";
    ResponseErrors["WaitBeforeRestartVerification"] = "WAIT_BEFORE_RETRY_VERIFICATION";
    ResponseErrors["InternalError"] = "INTERNAL_ERROR";
    // Sport Club
    ResponseErrors["SportClubUserJoinLimitReached"] = "SPORT_CLUB_USER_JOIN_LIMIT_REACHED";
    ResponseErrors["StandingsRefreshTooMuchRequests"] = "STANDINGS_REFRESH_TOO_MUCH_REQUESTS";
})(ResponseErrors || (ResponseErrors = {}));
