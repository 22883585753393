import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery, useQuery } from '@apollo/client';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { SPORT_CLUB } from 'common/graphql/queries/sportClub/sportClub';
import { USER } from 'common/graphql/queries/user/user';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
import history from 'store/history';
var JoinClubByLink = function (_a) {
    var joinLinkString = _a.joinLinkString, clubId = _a.clubId;
    var _b = useLazyQuery(SPORT_CLUB), getSportClubData = _b[0], _c = _b[1], sportClub = _c.data, loading = _c.loading, sportClubNotFound = _c.error;
    var data = useQuery(USER, {
        variables: {
            showSportClub: true,
            showSportClubPermissions: true
        }
    }).data;
    var dispatch = useDispatch();
    var clubNotFound = function () {
        history.push('/clubs/search');
        dispatch(openSnackbar({
            message: 'Клуб не найден.',
            severity: SnackbarSeverity.WARNING
        }));
    };
    var clubJoinDialog = function () {
        var _a, _b, _c;
        history.push("/club/".concat((_a = sportClub === null || sportClub === void 0 ? void 0 : sportClub.sportClub) === null || _a === void 0 ? void 0 : _a.url));
        var userSportClubId = (_c = (_b = data === null || data === void 0 ? void 0 : data.user) === null || _b === void 0 ? void 0 : _b.sportClub) === null || _c === void 0 ? void 0 : _c.id;
        if (!userSportClubId) {
            dispatch(openDialog({
                dialogType: DialogTypes.JoinClubByInvitationLinkRequestDialog,
                dialogProps: {
                    sportClubId: clubId,
                    joinLinkString: joinLinkString
                }
            }));
        }
        else if (userSportClubId === clubId) {
            dispatch(openSnackbar({
                message: 'Вы уже являетесь участником данного клуба.',
                severity: SnackbarSeverity.WARNING
            }));
        }
        else {
            dispatch(openSnackbar({
                message: 'Вы не можете присоедениться к клубу, потому что уже являетесь участником другого клуба.',
                severity: SnackbarSeverity.ERROR
            }));
        }
    };
    useEffect(function () {
        if (joinLinkString && clubId) {
            getSportClubData({
                variables: {
                    id: clubId
                }
            });
        }
    }, [joinLinkString, clubId]);
    useEffect(function () {
        if ((sportClub === null || sportClub === void 0 ? void 0 : sportClub.sportClub.id) && (data === null || data === void 0 ? void 0 : data.user)) {
            clubJoinDialog();
        }
    }, [sportClub, data]);
    useEffect(function () {
        if (sportClubNotFound) {
            clubNotFound();
        }
    }, [sportClubNotFound]);
    return _jsx(_Fragment, {}, void 0);
};
export default JoinClubByLink;
