import { jsx as _jsx } from "react/jsx-runtime";
import TelegramIcon from 'assets/logos/TelegramIcon';
var contactsGroupsConfig = [
    {
        id: 'phone_number',
        value: 'phone_number',
        title: 'Мобильный телефон',
        settingsGroupParams: [
            {
                id: 'phoneNumberGroupParams',
                values: [
                    {
                        id: 'phoneNumber',
                        value: 'phoneNumber',
                        title: 'Номер телефона',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'telegram_id',
        value: 'telegram_id',
        logo: _jsx(TelegramIcon, {}, void 0),
        title: 'Telegram',
        settingsGroupParams: [
            {
                id: 'telegramIdGroupParams',
                values: [
                    {
                        id: 'telegram',
                        value: 'telegram',
                        title: 'Telegram',
                        type: 'objectsArray'
                    },
                ],
                type: 'connection',
                editable: true
            },
        ]
    },
];
export default contactsGroupsConfig;
