var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { NOTIFICATION_SUBTYPES } from 'common/graphql/queries/notification/notification-subtypes';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import NotificationSubTypeListItemItem from './NotificationSubTypeListItem';
var NotificationSubTypesList = function () {
    var _a = useQuery(NOTIFICATION_SUBTYPES), data = _a.data, loading = _a.loading;
    var notificationSubTypes = data === null || data === void 0 ? void 0 : data.notificationSubTypes;
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx("div", __assign({ className: "flex flex-col space-y-10 pt-20" }, { children: notificationSubTypes && (notificationSubTypes === null || notificationSubTypes === void 0 ? void 0 : notificationSubTypes.length) > 0 ? (_jsx("div", { children: notificationSubTypes.map(function (notificationSubType) {
                return (_jsx(NotificationSubTypeListItemItem, { notificationSubType: notificationSubType }, notificationSubType.id));
            }) }, void 0)) : (_jsx("div", { children: "\u041F\u043E\u0434\u0442\u0438\u043F\u044B \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u0439 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B" }, void 0)) }), void 0));
};
export default NotificationSubTypesList;
