var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { FeatureTypes } from 'common/enums/feature/feature-types.enum';
import { DELETE_TOURNAMENT_FEATURE } from 'common/graphql/mutations/tournament/delete-tournament-feature';
import { TOURNAMENT } from 'common/graphql/queries/tournament/tournament';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
var TournamentFeautreListItem = function (_a) {
    var tournamentId = _a.tournamentId, feature = _a.feature;
    var deleteTournamentFeature = function (data) {
        return useMutation(DELETE_TOURNAMENT_FEATURE, {
            variables: {
                deleteTournamentFeatureData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TOURNAMENT,
                    variables: {
                        id: tournamentId,
                        showFeatures: true
                    }
                },
            ]
        });
    };
    var dispatch = useDispatch();
    var onChangeButtonClick = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.TournamentFeatureVirtualPoints,
            dialogProps: {
                tournamentId: tournamentId,
                featureId: feature.id
            }
        }));
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Фича удалена из турнира.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(deleteTournamentFeature, onSuccess, onError).handleExecuteMutation;
    var handleDelete = function (data) {
        var createObj = {
            tournamentId: tournamentId,
            featureId: feature.id
        };
        handleExecuteMutation(__assign({}, createObj));
    };
    return (_jsx("div", __assign({ className: "flex border-1 solid rounded-8 xs:p-8 md:p-10 \n                xs:mb-8 md:mb-10 decoration-0 border-border-lt dark:border-border-dk" }, { children: _jsxs("div", __assign({ className: "flex flex-col space-y-20 w-full" }, { children: [_jsxs("div", __assign({ className: "flex  flex-row items-center justify-between pr-10" }, { children: [_jsx("div", __assign({ className: "xs:text-14 md:text-16 font-semibold px-8 py-2" }, { children: feature.displayName }), void 0), _jsxs("div", __assign({ className: "flex flex-row items-center space-x-10" }, { children: [_jsx("div", __assign({ className: "max-w-288" }, { children: feature.name === FeatureTypes.virtualPoints && (_jsx(Button, __assign({ type: "button", onClick: onChangeButtonClick }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" }), void 0)) }), void 0), _jsx("div", __assign({ className: "max-w-288" }, { children: _jsx(Button, __assign({ type: "button", color: "error", onClick: handleDelete }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }), void 0) }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex xs:flex-col sm:flex-row xs:space-x-0 xs:space-y-20 sm:space-y-0 sm:space-x-20" }, { children: _jsxs("div", __assign({ className: "flex flex-col px-8" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['ID:', "\u00A0"] }), void 0), _jsx("div", { children: feature.id }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Уникальное название:', "\u00A0"] }), void 0), _jsx("div", { children: feature.name }, void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default TournamentFeautreListItem;
