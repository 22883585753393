var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var VKIconV2 = function (props) {
    return (_jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M24.5834 0H6.25002C4.70292 0 3.21919 0.614581 2.12523 1.70854C1.03127 2.80251 0.416687 4.28624 0.416687 5.83333L0.416687 24.1667C0.416687 25.7138 1.03127 27.1975 2.12523 28.2915C3.21919 29.3854 4.70292 30 6.25002 30H24.5834C26.1304 30 27.6142 29.3854 28.7081 28.2915C29.8021 27.1975 30.4167 25.7138 30.4167 24.1667V5.83333C30.4167 4.28624 29.8021 2.80251 28.7081 1.70854C27.6142 0.614581 26.1304 0 24.5834 0ZM22.3417 21.6667C22.3417 21.6667 20.0417 16.6667 17.15 16.975V21.6667C17.15 21.6667 4.90835 22.6 5.03335 8.33333H8.97502C8.97502 8.33333 7.91669 14.7917 13.3167 17.325L13.4417 8.33333H17.15V13.525C17.15 13.525 20.4 12.8583 21.6 8.33333H25.0584C25.0584 8.33333 25.675 11.0167 21.1 15C21.1 15 25.1833 17.25 25.8 21.6667H22.3417Z", fill: "#DBB400" }, void 0) }), void 0));
};
export default VKIconV2;
