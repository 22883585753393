export default function Dialog(theme) {
    return {
        MuiDialogContent: {
        /* styleOverrides: {
            root: {
                padding: {sx: '1.6rem', md: '2.4rem'}
            },
        } */
        }
    };
}
