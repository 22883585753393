import { jsx as _jsx } from "react/jsx-runtime";
import 'assets/styles/tailwind.base.css';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/styles/index.css';
import reportWebVitals from './reportWebVitals';
if ('serviceWorker' in navigator) {
    // Весь код регистрации у нас асинхронный.
    navigator.serviceWorker
        .register('/service-worker.js')
        .then(function (registration) {
        console.log('Service Worker registered with scope:', registration.scope);
    })["catch"](function (error) {
        console.error('Service Worker registration failed:', error);
    });
}
ReactDOM.render(_jsx(App, {}, void 0), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
