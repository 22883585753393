import * as Yup from 'yup';
var tournamentTeamFeatureVirtualPointsValidationSchema = Yup.object().shape({
    teamInitialCoins: Yup.number()
        .min(0, 'Значение от 0 до 100000')
        .max(100000, 'Значение от 0 до 100000')
        .required('Необходимо задать значение'),
    teamParticipantInitialCoins: Yup.number()
        .min(0, 'Значение от 0 до 100000')
        .max(100000, 'Значение от 0 до 100000')
        .required('Необходимо задать значение'),
    inheritCoinsNumberFromParticipationPrice: Yup.boolean().required('Необходимо задать значение')
});
export default tournamentTeamFeatureVirtualPointsValidationSchema;
