var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { usePullActivitiesMutation } from 'common/hooks/usePullActivitiesMutation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var SynchronizeButton = function (_a) {
    var userId = _a.userId, fromDate = _a.fromDate, beforeDate = _a.beforeDate, isTriggeredByAdmin = _a.isTriggeredByAdmin;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var onSyncError = function (_a) {
        var errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || t("\u041E\u0448\u0438\u0431\u043A\u0430 \u0441\u0438\u043D\u0445\u0440\u043E\u043D\u0438\u0437\u0430\u0446\u0438\u0438"),
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSyncSuccess = function () {
        dispatch(openSnackbar({
            message: t('syncStartMessage'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onVerifyDatesError = function (message) {
        dispatch(openSnackbar({
            message: message,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var _b = useDynamicMutation(usePullActivitiesMutation, onSyncSuccess, onSyncError), handleExecuteMutation = _b.handleExecuteMutation, loading = _b.loading;
    var verifyDates = function () {
        if (new Date(beforeDate) < new Date(fromDate)) {
            onVerifyDatesError('onVerifyDatesError1');
            return false;
        }
        if (!isTriggeredByAdmin &&
            Math.round(new Date(beforeDate).getTime() - new Date(fromDate).getTime()) / (1000 * 3600 * 24) > 183) {
            onVerifyDatesError('onVerifyDatesError2');
            return false;
        }
        return true;
    };
    var handleClick = function () {
        if (verifyDates())
            handleExecuteMutation({ userId: userId, fromDate: fromDate, beforeDate: beforeDate });
    };
    return loading ? (_jsx(LoadingButton, __assign({ size: "small", loading: loading, variant: "outlined", disabled: true }, { children: _jsx("span", { children: t('Синхронизировать') }, void 0) }), void 0)) : (_jsx(Button, __assign({ variant: "polarButton", onClick: function () { return handleClick(); } }, { children: t('Синхронизировать') }), void 0));
};
export default SynchronizeButton;
