export default function Button(theme) {
    return {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'polarButton' },
                    style: {
                        backgroundColor: theme.palette.polarBlack,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: theme.palette.polarBlackDarken
                        }
                    }
                },
                {
                    props: { variant: 'stravaButton' },
                    style: {
                        backgroundColor: theme.palette.stravaOrange,
                        color: 'white' + '!important',
                        '&:hover': {
                            backgroundColor: theme.palette.stravaOrangeDarken
                        }
                    }
                },
                {
                    props: { variant: 'activityFilterButton' },
                    style: {
                        backgroundColor: 'transparent',
                        color: '#8E8E8E',
                        '&:hover': {
                            backgroundColor: 'green'
                        }
                    }
                },
            ],
            defaultProps: {
                variant: 'contained',
                color: 'primary'
            },
            styleOverrides: {
                root: {
                    borderRadius: '0.8rem',
                    textTransform: 'none',
                    fontSize: '1.4rem',
                    paddingTop: '0.8rem',
                    paddingBottom: '0.8rem',
                    paddingLeft: '1.2rem',
                    paddingRight: '1.2rem'
                },
                containedPrimary: {
                    color: theme.palette.button.containePrimaryButtonText + '!important'
                },
                containedSecondary: {
                    color: 'FFFFFF' + '!important',
                    backgroundColor: theme.palette.secondary.dark + '!important'
                },
                textSecondary: {
                    color: theme.palette.text.primary,
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                },
                outlinedSecondary: {
                    color: theme.palette.button.containePrimaryButtonText + '!important',
                    borderColor: theme.palette.button.outlinedSecondaryBorderColor
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorSecondary: {
                    color: theme.palette.icon.secondary
                },
                colorAction: {
                    color: theme.palette.icon.action
                }
            }
        }
    };
}
