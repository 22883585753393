var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { GoogleSettingsFragment } from 'common/graphql/queries/user/fragments/google-settings.fragment';
import { MainSettingsFragment } from 'common/graphql/queries/user/fragments/main-settings.fragment';
import { RolesFragment } from 'common/graphql/queries/user/fragments/roles.fragment';
import { StravaSettingsFragment } from 'common/graphql/queries/user/fragments/strava-settings.fragment';
import { UserBasicFieldsFragment } from 'common/graphql/queries/user/fragments/user-basic-fields.fragment';
import { VkSettingsFragment } from 'common/graphql/queries/user/fragments/vk-settings.fragment';
export var UPDATE_USER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation updateUser(\n\t\t$updateUserData: UpdateUserInput!\n\t\t$profilePhoto: Upload\n\t\t$showMainSettings: Boolean! = false\n\t\t$showRoles: Boolean! = false\n\t\t$showSocialSettings: Boolean! = false\n\t) {\n\t\tupdateUser(updateUserData: $updateUserData, profilePhoto: $profilePhoto) {\n\t\t\t...UserBasicFieldsFragment\n\t\t\t...MainSettingsFragment @include(if: $showMainSettings)\n\t\t\t...RolesFragment @include(if: $showRoles)\n\n\t\t\t...GoogleSettingsFragment @include(if: $showSocialSettings)\n\t\t\t...VkSettingsFragment @include(if: $showSocialSettings)\n\t\t\t...StravaSettingsFragment @include(if: $showSocialSettings)\n\t\t}\n\t}\n\t", "\n\t", "\n\t", "\n\n\t", "\n\t", "\n\t", "\n"], ["\n\tmutation updateUser(\n\t\t$updateUserData: UpdateUserInput!\n\t\t$profilePhoto: Upload\n\t\t$showMainSettings: Boolean! = false\n\t\t$showRoles: Boolean! = false\n\t\t$showSocialSettings: Boolean! = false\n\t) {\n\t\tupdateUser(updateUserData: $updateUserData, profilePhoto: $profilePhoto) {\n\t\t\t...UserBasicFieldsFragment\n\t\t\t...MainSettingsFragment @include(if: $showMainSettings)\n\t\t\t...RolesFragment @include(if: $showRoles)\n\n\t\t\t...GoogleSettingsFragment @include(if: $showSocialSettings)\n\t\t\t...VkSettingsFragment @include(if: $showSocialSettings)\n\t\t\t...StravaSettingsFragment @include(if: $showSocialSettings)\n\t\t}\n\t}\n\t", "\n\t", "\n\t", "\n\n\t", "\n\t", "\n\t", "\n"])), UserBasicFieldsFragment, MainSettingsFragment, RolesFragment, GoogleSettingsFragment, VkSettingsFragment, StravaSettingsFragment);
var templateObject_1;
