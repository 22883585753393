import React from 'react';

function LeftSideLayout() {
    return (
        <React.Fragment>
        </React.Fragment>
    );
}

export default LeftSideLayout;
