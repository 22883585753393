export default function Autocomplete(theme) {
    return {
        MuiAutocomplete: {
            defaultProps: {
                openText: 'Показать список',
                closeText: 'Закрыть',
                clearText: 'Очистить',
                noOptionsText: 'Не найдено'
            },
            styleOverrides: {
                root: {
                    input: {
                        padding: '0.5rem' + '!important'
                    }
                }
            }
        }
    };
}
