var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Box, Button, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { COMPLETE_UPDATE_USER_EMAIL } from 'common/graphql/mutations/user/updateUser/complete-update-user-email';
import { UPDATE_USER_EMAIL } from 'common/graphql/mutations/user/updateUser/update-user-email';
import { USER } from 'common/graphql/queries/user/user';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import ResendCodeLink from 'components/Link/ResendCodeLink';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var VerifyUserEmailDialog = function (_a) {
    var email = _a.email, isCodeSentInitially = _a.isCodeSentInitially, handleClose = _a.handleClose;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _b = useState(null), key = _b[0], setKey = _b[1];
    var _c = useState(isCodeSentInitially || false), isCodeSent = _c[0], setIsCodeSent = _c[1];
    var onSuccessComplete = function () {
        dispatch(openSnackbar({
            message: t('Email адрес успешно подтверждён'),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onSuccessSendCode = function () {
        dispatch(openSnackbar({
            message: t('Мы отправили 6-значный код на') + ' ' + email,
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var completeUpdateUserEmail = function (data) {
        return useMutation(COMPLETE_UPDATE_USER_EMAIL, {
            variables: {
                completeUpdateUserEmailData: {
                    key: key
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER,
                    variables: {
                        showVerified: true
                    }
                },
            ]
        });
    };
    var updateUserEmail = function (data) {
        return useMutation(UPDATE_USER_EMAIL, {
            variables: {
                updateUserEmailData: {
                    email: data
                }
            }
        });
    };
    var handleExecuteCompleteUpdateUserEmail = useDynamicMutation(completeUpdateUserEmail, onSuccessComplete, onError).handleExecuteMutation;
    var handleExecuteUpdateUserEmail = useDynamicMutation(updateUserEmail, onSuccessSendCode, onError).handleExecuteMutation;
    var onResendCodeLinkClick = function () {
        handleExecuteUpdateUserEmail(email);
        setIsCodeSent(true);
    };
    var handleClickVerifyEmail = function () {
        handleExecuteCompleteUpdateUserEmail({});
    };
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320 space-y-20" }, { children: [_jsx("p", __assign({ className: "dark:text-white" }, { children: t('Введите код подтверждения, который был отправлен на указанный email') }), void 0), _jsx(TextField, { label: t('Проверочный код'), name: "key", value: key, onChange: function (e) {
                    setKey(e.target.value);
                } }, void 0), _jsx(Button, __assign({ disabled: !key, onClick: handleClickVerifyEmail }, { children: t('Подтвердить email') }), void 0), _jsx(Box, __assign({ sx: { width: '32rem' } }, { children: isCodeSent ? (_jsx(ResendCodeLink, { initialMinute: 0, initialSeconds: 60, callBackFunction: onResendCodeLinkClick }, void 0)) : (_jsxs("div", __assign({ className: "flex flex-row space-x-5 items-center" }, { children: [_jsx("div", __assign({ className: "dark:text-white" }, { children: t('Нету кода?') }), void 0), _jsx("div", __assign({ className: "tw-link-btn", onClick: onResendCodeLinkClick }, { children: t('Отправить код повторно') }), void 0)] }), void 0)) }), void 0)] }), void 0));
};
export default VerifyUserEmailDialog;
