var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import StravaIconV2 from 'assets/logos/StravaIconV2';
import { appHost, appScheme } from 'common/appURLSetup';
import { stravaClientId } from 'common/socialClientIdsSetup';
import * as queryString from 'querystring';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { stravaSignIn } from 'store/actions/auth';
var StravaSignIn = function (props) {
    var dispatch = useDispatch();
    useEffect(function () {
        var handleLogin = function (code) {
            dispatch(stravaSignIn(code));
        };
        var queryObj = queryString.parse(props.location.search);
        if (queryObj && queryObj['code']) {
            handleLogin(queryObj['code']);
        }
    }, [props.location.search, props.history]);
    var cbLink = "".concat(appScheme, "://").concat(appHost, "/login/strava/");
    var handleRedirect = function () {
        window.location.href = "https://www.strava.com/oauth/authorize?client_id=".concat(stravaClientId, "\n    &display=popup&redirect_uri=").concat(cbLink, "&scope=profile:read_all,activity:read_all&response_type=code");
    };
    return (_jsx(IconButton, __assign({ sx: { width: '6rem' }, onClick: handleRedirect }, { children: _jsx(StravaIconV2, {}, void 0) }), void 0));
};
export default withRouter(StravaSignIn);
