import { StandingsParticipantType } from 'common/enums/standings/standings-participant-type.enum';
import * as Yup from 'yup';
var standingsValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(20, 'Слишком длинное название. Пожалуйста, введите до 20 символов')
        .required('Не указано уникальное имя')
        .matches(/^([a-zA-Z0-9_]+)$/, 'Уникальное имя может состоять из латинских букв, цифр и подчеркивания')
        .matches(/^([a-zA-Z0-9_]{5,})$/, 'Минимальная длина уникального имени 5 символов')
        .matches(/^(?:[0-9_]*[a-zA-Z]){3}/, 'Уникальное имя должно содержать не менее 3 латинских букв'),
    displayName: Yup.string()
        .trim()
        .min(1, 'Слишком короткое наименование. Пожалуйста, введите от 1 до 128 символов.')
        .max(128, 'Слишком длинное наименование. Пожалуйста, введите от 1 до 128 символов.')
        .required('Не указано наименование турнирной таблицы.'),
    overrideDisplayNameInFilter: Yup.string()
        .trim()
        .min(1, 'Слишком короткое наименование. Пожалуйста, введите от 1 до 128 символов.')
        .max(128, 'Слишком длинное наименование. Пожалуйста, введите от 1 до 128 символов.')
        .required('Не указано наименование турнирной таблицы.'),
    description: Yup.string()
        .trim()
        .max(1024, 'Слишком длинное описание. Пожалуйста, введите до 1024 символов.')
        .required('Введите описание турнирной таблицы.'),
    participantType: Yup.mixed()
        .oneOf(Object.values(StandingsParticipantType))
        .required('Выберите тип участника турнирной таблицы.'),
    activityStartDate: Yup.date()
        .required('Не указана дата начала приёма тренировок турнирной таблицы.')
        .nullable()
        .typeError('Неверный формат даты.'),
    activityEndDate: Yup.date()
        .min(Yup.ref('activityStartDate'), 'Вы не можете выбрать дату окончания приёма тренировок ранее даты начала.')
        .required('Не указана дата окончания приёма тренировок.')
        .nullable()
        .typeError('Неверный формат даты.')
});
export default standingsValidationSchema;
