import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading/Loading';

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */
function Suspense(props){
    return (
        <React.Suspense fallback={<Loading {...props.loadingProps}/>}>

           

            {props.children}
        </React.Suspense>
    );
}

Suspense.propTypes = {
    loadingProps: PropTypes.object,
};

Suspense.defaultProps = {
    loadingProps: {
        delay: 300
    }
};

export default Suspense;
