var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql, useMutation } from '@apollo/client';
import { USER } from 'common/graphql/queries/user/user';
export var UPDATE_SOCIAL_UNBIND = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation socialUnBind($socialUnBindData: UserProfileSocialUnBindInput!) {\n\t\tsocialUnBind(socialUnBindData: $socialUnBindData)\n\t}\n"], ["\n\tmutation socialUnBind($socialUnBindData: UserProfileSocialUnBindInput!) {\n\t\tsocialUnBind(socialUnBindData: $socialUnBindData)\n\t}\n"])));
export function useSocialUnBindMutation(socialUnBindVariables) {
    return useMutation(UPDATE_SOCIAL_UNBIND, {
        variables: {
            socialUnBindData: __assign({}, socialUnBindVariables)
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: USER,
                variables: {
                    showSocialSettings: true
                }
            },
        ]
    });
}
var templateObject_1;
