var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
var AllowedPropertySelect = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'allowedProperty' : _b, value = _a.value, errorMessage = _a.errorMessage, disableClearable = _a.disableClearable, handleChange = _a.handleChange, allowedProperties = _a.allowedProperties, label = _a.label;
    var _c = useState(allowedProperties), allowedPropertiesList = _c[0], setAllowedPropertiesList = _c[1];
    var _d = useState(undefined), property = _d[0], setProperty = _d[1];
    useEffect(function () {
        if (allowedPropertiesList.length > 0) {
            setProperty(allowedPropertiesList.find(function (allowedProperties) {
                return allowedProperties.value === value;
            }));
        }
    }, [allowedPropertiesList, value]);
    return (_jsx(Autocomplete, { options: allowedPropertiesList || [], value: property || null, disableClearable: disableClearable, renderInput: function (params) { return (_jsx(TextField, __assign({ name: name, error: !!errorMessage, helperText: errorMessage, label: label }, params), void 0)); }, onChange: function (_event, column) {
            if ((column === null || column === void 0 ? void 0 : column.value) || !disableClearable) {
                handleChange(name, column === null || column === void 0 ? void 0 : column.value);
            }
        }, getOptionLabel: function (option) { return option.displayName || ''; }, renderOption: function (props, option) { return (_jsx(Box, __assign({ component: "li", sx: { '& > img': { mr: 2, flexShrink: 0 } } }, props, { children: option.displayName }), void 0)); } }, void 0));
};
export default AllowedPropertySelect;
