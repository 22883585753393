var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import { VelocityComponent } from 'velocity-react';
import 'velocity-animate/velocity.ui';
var Animate = React.forwardRef(function Animate(props, ref) {
    var children = React.cloneElement(props.children, {
        style: __assign(__assign({}, props.children.style), { visibility: 'hidden' })
    });
    return _jsx(VelocityComponent, __assign({ ref: ref }, props, { children: children }), void 0);
});
Animate.propTypes = {
    children: PropTypes.element.isRequired
};
Animate.defaultProps = {
    animation: 'transition.fadeIn',
    runOnMount: true,
    targetQuerySelector: null,
    interruptBehavior: 'stop',
    visibility: 'visible',
    duration: 300,
    delay: 50,
    easing: [0.4, 0.0, 0.2, 1],
    display: null,
    setRef: undefined
};
export default React.memo(Animate);
