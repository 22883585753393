var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { USER } from 'common/graphql/queries/user/user';
import { useUpdateUserMutation } from 'common/hooks/useUpdateUserMutation';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import { useTranslation } from 'react-i18next';
import SettingsForm from '../components/SettingsForm/SettingsForm';
import SettingsGroup from '../components/SettingsGroup/SettingsGroup';
import contactsGroupsConfig from './contactsGroupsConfig';
var UserContacts = function () {
    var t = useTranslation().t;
    var _a = useQuery(USER, {
        variables: {
            showContact: true
        }
    }), data = _a.data, loading = _a.loading;
    var user = data === null || data === void 0 ? void 0 : data.user;
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: t('Мои контакты') }, void 0), content: _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "p-10" }, { children: t('userContactsText') }), void 0), _jsx(Divider, { light: true }, void 0), contactsGroupsConfig.length > 0 &&
                    contactsGroupsConfig.map(function (settingsGroup) { return (_jsxs("div", { children: [_jsx(SettingsGroup, { settingsGroup: settingsGroup, data: __assign({ id: user === null || user === void 0 ? void 0 : user.id }, user === null || user === void 0 ? void 0 : user.contact), handleChangeData: useUpdateUserMutation }, void 0), _jsx(Divider, { light: true }, void 0)] }, settingsGroup.id)); })] }, void 0) }, void 0));
};
export default UserContacts;
