import * as Actions from '../../actions/auth';

const initialState = {
    email: null,
    isVisible: false,
    isVerified: false,
    isIdValid: false,
};

const login = function (state = initialState, action) {
    switch (action.type) {
        case Actions.RESET_PASSWORD_SUCCESS: {
            return {
                ...initialState,
                success: true
            }
        }
        case Actions.RESET_PASSWORD_ERROR: {
            return {
                ...state,
                isVerified: true,
                error: action.payload,
            }
        }
        case Actions.RESET_PASSWORD: {
            return {
                ...state,
                ...action.payload
            }
        }
        default: {
            return state
        }
    }
};

export default login;