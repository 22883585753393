var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { SUPPORT_REQUESTS_ADMIN } from 'common/graphql/queries/support-request/support-requests-admin';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import _ from 'lodash';
import SupportRequestListItem from './components/SupportRequestAdminListItem/SupportRequestAdminListItem';
var SupportRequestsAdminPage = function () {
    var _a;
    var _b = useQuery(SUPPORT_REQUESTS_ADMIN), data = _b.data, loading = _b.loading;
    var orderByLastMessage = function (supportRequests) {
        return _.orderBy(supportRequests, ['lastMessageAt'], ['desc']);
    };
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u041E\u0431\u0440\u0430\u0449\u0435\u043D\u0438\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439" }), void 0) }), void 0), content: _jsx("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: _jsx("div", __assign({ className: "flex flex-col space-y-10" }, { children: (data === null || data === void 0 ? void 0 : data.supportRequests) && ((_a = data === null || data === void 0 ? void 0 : data.supportRequests) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (_jsx("div", { children: orderByLastMessage(data.supportRequests).map(function (supportRequest) {
                        return (_jsx(SupportRequestListItem, { supportRequest: supportRequest }, supportRequest.id));
                    }) }, void 0)) : (_jsx("div", { children: "\u041E\u0431\u0440\u0430\u0449\u0435\u043D\u0438\u0439 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E" }, void 0)) }), void 0) }), void 0), innerScroll: false }, void 0));
};
export default SupportRequestsAdminPage;
