var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
var BuyBoosterButton = function (_a) {
    var teamId = _a.teamId, tournamentId = _a.tournamentId, boosterName = _a.boosterName, price = _a.price, teamCoins = _a.teamCoins;
    var dispatch = useDispatch();
    var handleClick = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.VirtualPointsBuyBoosterRequest,
            dialogProps: {
                teamId: teamId,
                tournamentId: tournamentId,
                boosterName: boosterName,
                price: price,
                teamCoins: teamCoins
            }
        }));
    };
    return (_jsx(Button, __assign({ onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            handleClick();
        }, type: "button" }, { children: "\u041A\u0443\u043F\u0438\u0442\u044C" }), void 0));
};
export default BuyBoosterButton;
