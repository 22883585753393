var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';
import { appHost, appScheme } from 'common/appURLSetup';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { usePolarBindMutation } from 'common/hooks/usePolarBindMutation';
import { polarClientId } from 'common/socialClientIdsSetup';
import * as queryString from 'querystring';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { openDialog, openSnackbar } from 'store/actions';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
var PolarBind = function (_a) {
    var text = _a.text, isButton = _a.isButton;
    var t = useTranslation().t;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    var userId = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id;
    var dispatch = useDispatch();
    var location = useHistory().location;
    var onPolarBindError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onPolarBindSuccess = function () {
        dispatch(openSnackbar({
            message: t('Подключение Polar аккаунта запущено.'),
            severity: SnackbarSeverity.SUCCESS
        }));
        dispatch(openDialog({
            dialogType: DialogTypes.AddTrainingSourceSuccessInfo,
            dialogProps: {
                userId: userId
            }
        }));
    };
    var _b = useDynamicMutation(usePolarBindMutation, onPolarBindSuccess, onPolarBindError), handleExecuteMutation = _b.handleExecuteMutation, loading = _b.loading;
    useEffect(function () {
        var handleLogin = function (code) {
            handleExecuteMutation({ code: code });
        };
        var queryObj = queryString.parse(location.search);
        var code = queryObj && queryObj['?code'];
        if (code) {
            //Todo в настройках полара
            history.push(location.pathname);
            handleLogin(code);
        }
    }, [location.search, history]);
    var cbLink = "".concat(appScheme, "://").concat(appHost, "/settings/connections");
    var handleRedirect = function () {
        localStorage.setItem('isShownAddTraningSourceDialog', 'true');
        window.location.href = "https://flow.polar.com/oauth2/authorization?response_type=code&client_id=".concat(polarClientId, "&redirect_uri=").concat(cbLink);
    };
    return (_jsx(_Fragment, { children: !loading && (_jsx(_Fragment, { children: isButton ? (_jsx(Button, __assign({ variant: "polarButton", onClick: handleRedirect }, { children: text || t('Подключить') }), void 0)) : (_jsx(Link, __assign({ className: "tw-link-btn p-4", to: "#", onClick: handleRedirect }, { children: text || t('Подключить') }), void 0)) }, void 0)) }, void 0));
};
export default PolarBind;
