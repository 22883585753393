var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
var DraftEditor = function (_a) {
    var value = _a.value, onChange = _a.onChange, error = _a.error, helperText = _a.helperText, options = _a.options, toolbarHidden = _a.toolbarHidden, placeholder = _a.placeholder, readOnly = _a.readOnly;
    var state = convertFromHTML(value || '');
    var _b = useState(EditorState.createWithContent(state)), editorState = _b[0], setEditorState = _b[1];
    useEffect(function () {
        if (editorState) {
            var html = convertToHTML({
                styleToHTML: function (style) {
                    if (style === 'BOLD') {
                        return _jsx("span", { style: { color: 'blue' } }, void 0);
                    }
                },
                blockToHTML: function (block) {
                    if (block.type === 'PARAGRAPH') {
                        return _jsx("p", {}, void 0);
                    }
                },
                entityToHTML: function (entity, originalText) {
                    if (entity.type === 'LINK') {
                        return (_jsx("a", __assign({ href: entity.data.url, referrerPolicy: "no-referrer", target: "_blank" }, { children: originalText }), void 0));
                    }
                    return originalText;
                }
            })(editorState.getCurrentContent());
            onChange(html);
        }
    }, [editorState]);
    useEffect(function () {
        if (value === '' || value === '<p></p>') {
            setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()));
        }
    }, [value]);
    //options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign',
    //'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
    return (_jsxs(_Fragment, { children: [_jsx(Editor, { readOnly: readOnly, editorState: editorState, onEditorStateChange: setEditorState, editorClassName: "border-1 border-border-lt dark:border-border-dk px-8 dark:text-white", wrapperClassName: "", toolbarHidden: toolbarHidden, toolbarClassName: "border-none", placeholder: placeholder, toolbar: {
                    options: options || [
                        'inline',
                        'blockType',
                        //'fontSize',
                        'list',
                        // 'textAlign',
                        'history',
                        'link',
                    ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true }
                } }, void 0), error && _jsx("div", __assign({ className: "text-xs text-red px-14 pt-3" }, { children: helperText }), void 0)] }, void 0));
};
export default DraftEditor;
