var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip } from '@mui/material';
import { green, yellow } from '@mui/material/colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
var ActivityDownloadStatus = function (_a) {
    var fullyDownloaded = _a.fullyDownloaded;
    var t = useTranslation().t;
    var _b = useState(false), openActivityDownloadStatusTooltip = _b[0], setOpenActivityDownloadStatusTooltip = _b[1];
    var status = fullyDownloaded
        ? {
            color: green[500],
            text: t('Тренировка полностью загружена')
        }
        : {
            color: yellow[800],
            text: t('Тренировка загружена частично')
        };
    return (_jsx(Tooltip, __assign({ onMouseOver: function () { return setOpenActivityDownloadStatusTooltip(true); }, onMouseLeave: function () { return setOpenActivityDownloadStatusTooltip(false); }, onClose: function () { return setOpenActivityDownloadStatusTooltip(false); }, open: openActivityDownloadStatusTooltip, title: status.text }, { children: _jsx(CircleIcon, { style: { fontSize: '14' }, sx: { color: status.color } }, void 0) }), void 0));
};
export default ActivityDownloadStatus;
