var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { ActivityValidationLevels } from 'common/enums/activity/activity-validation-levels.enum';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import history from 'store/history';
var ActivityValidationResultListItem = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var activityValidation = _a.activityValidation;
    var t = useTranslation().t;
    var formatActivityValidationLevel = function (activityValidationLevel) {
        switch (activityValidationLevel) {
            case ActivityValidationLevels.SportClub:
                return t('Клуб');
            case ActivityValidationLevels.SportEvent:
                return t('Событие');
            case ActivityValidationLevels.Standings:
                return t('Турнирная таблица');
            case ActivityValidationLevels.Tournament:
                return t('Турнир');
            case ActivityValidationLevels.Base:
                return t('Базовый');
            default:
                return activityValidationLevel;
        }
    };
    var activityOwner = (_b = activityValidation.activity) === null || _b === void 0 ? void 0 : _b.user;
    var validationDate = new Date(activityValidation === null || activityValidation === void 0 ? void 0 : activityValidation.createdAt).toLocaleString();
    return (_jsx("div", __assign({ className: "flex border-1 solid rounded-8 xs:p-8 md:p-10 \n                xs:mb-8 md:mb-10 decoration-0 border-border-lt dark:border-border-dk" }, { children: _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ onClick: function () {
                        history.push({
                            pathname: '/activity-validation/' + activityValidation.id,
                            state: { fromAthletesSearchPage: true }
                        });
                    }, className: "xs:text-14 md:text-16 font-semibold px-8 py-2 cursor-pointer" }, { children: [t('Валидация тренировки'), " ", (_c = activityValidation.activity) === null || _c === void 0 ? void 0 : _c.name] }), void 0), _jsxs("div", __assign({ className: "flex flex-col px-8" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey max-w-148 flex-1" }, { children: [t('Пользователь'), ":\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex items-center flex-1" }, { children: _jsxs("div", __assign({ className: "flex cursor-pointer items-center pb-4", onClick: function () {
                                            history.push("/profile/".concat(activityOwner.userName));
                                        } }, { children: [_jsx(UserAvatar, { style: "cursor-pointer xs:w-28 xs:h-28 text-16", firstName: (_d = activityOwner.mainSettings) === null || _d === void 0 ? void 0 : _d.firstName, profilePhoto: (_e = activityOwner.mainSettings) === null || _e === void 0 ? void 0 : _e.profilePhoto }, void 0), ((_f = activityOwner.mainSettings) === null || _f === void 0 ? void 0 : _f.firstName) && (_jsxs("div", { children: ["\u00A0", activityOwner.mainSettings.firstName] }, void 0)), ((_g = activityOwner.mainSettings) === null || _g === void 0 ? void 0 : _g.lastName) && (_jsxs("div", { children: ["\u00A0", activityOwner.mainSettings.lastName] }, void 0))] }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: [t('Дата создания'), ":\u00A0"] }), void 0), _jsxs("div", { children: [" ", validationDate] }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: [t('Уровень валидации'), ":\u00A0"] }), void 0), _jsxs("div", { children: [" ", formatActivityValidationLevel(activityValidation.validationLevel)] }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: [t('Приоритет (кол-во запросов на валидацию)'), ":\u00A0"] }), void 0), _jsxs("div", { children: [" ", activityValidation.validationRequestPriority] }, void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default ActivityValidationResultListItem;
