var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { StandingsParticipantType } from 'common/enums/standings/standings-participant-type.enum';
import { useTranslation } from 'react-i18next';
import { standingsPageVar } from 'utils/apollo/reactivities/standingsPageVar';
var ShowUserDataInStandingsTableButton = function (_a) {
    var className = _a.className, standingsParticipantType = _a.standingsParticipantType;
    var t = useTranslation().t;
    var setShowButtonPressed = function () {
        standingsPageVar(__assign(__assign({}, standingsPageVar()), { participantType: standingsParticipantType, showUserDataRowInStatsTableButtonPressed: true, showStandingsPageButtonPressed: true }));
    };
    return (_jsx("div", __assign({ className: clsx(className, 'tw-link-btn'), onClick: setShowButtonPressed }, { children: standingsParticipantType === StandingsParticipantType.SportClub
            ? t('Показать мой клуб')
            : standingsParticipantType === StandingsParticipantType.Team
                ? t('Показать мою команду')
                : t('Показать меня') }), void 0));
};
export default ShowUserDataInStandingsTableButton;
