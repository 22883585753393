import { NotificationTargetTypes } from 'common/enums/notification/notification-target-types.enum';
import { NotificationTypes } from 'common/enums/notification/notification-types.enum';
import * as Yup from 'yup';
export var notificationDispatchInfoCreateValidationSchema = Yup.object().shape({
    userIds: Yup.array().of(Yup.string()).optional(),
    targetId: Yup.string().optional().nullable(),
    notificationInputId: Yup.string().required('Нужно указать инпут'),
    target: Yup.mixed().oneOf(Object.values(NotificationTargetTypes)).optional().nullable(),
    notificationType: Yup.mixed()
        .oneOf(Object.values(NotificationTypes))
        .required('Нужно указать тип оповещения'),
    isIgnoreUserDisabledNotifications: Yup.boolean().optional().nullable(),
    emailDelayPerMessage: Yup.number().min(100).max(300000).optional().nullable(),
    isDispatch: Yup.boolean().optional().nullable()
});
