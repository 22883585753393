var settingsConfig = {
    layout: {
        style: 'layout',
        config: {}
    },
    customScrollbars: true,
    theme: {
        //main: 'mainThemeDark1', // здесь тема defaultDark
        main: 'default',
        navbar: 'defaultDark',
        toolbar: 'default',
        loginPage: 'defaultDark'
    }
};
export default settingsConfig;
