import jwtService from '../services/auth/jwtService';
var inMemoryJWTManager = function () {
    var inMemoryJWT = null;
    var getToken = function () { return inMemoryJWT; };
    var setToken = function (token) {
        inMemoryJWT = token;
        return true;
    };
    var eraseToken = function () {
        inMemoryJWT = null;
        window.localStorage.setItem('logout', String(Date.now()));
        return true;
    };
    window.addEventListener('storage', function (event) {
        if (event.key === 'logout') {
            inMemoryJWT = null;
            jwtService.handleOnLogout();
        }
    });
    return {
        eraseToken: eraseToken,
        getToken: getToken,
        setToken: setToken
    };
};
export default inMemoryJWTManager();
