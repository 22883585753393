import { jsx as _jsx } from "react/jsx-runtime";
import PageCarded from 'components/PageLayouts/carded/PageCarded';
import SidebarContent from '../../layouts/shared-components/SidebarContent/SidebarContent';
import administrationNavigationConfig from './administrationNavigationConfig';
var AdministrationPage = function (props) {
    return (_jsx(PageCarded, { classes: {
            root: 'w-full',
            header: 'hidden',
            topBg: 'hidden',
            content: 'flex flex-col',
            sidebar: 'xs:px-8 md:px-12 xl:px-24',
            contentCard: 'bg-transparent shadow-none'
        }, content: props.children, rightSidebarContent: _jsx(SidebarContent, { navigationConfigItems: administrationNavigationConfig, mainPathname: "administration" }, void 0), mobileSidebarContent: true }, void 0));
};
export default AdministrationPage;
