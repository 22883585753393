var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar } from 'store/actions/base/dialog.actions';
var Alert = React.forwardRef(function Alert(props, ref) {
    return _jsx(MuiAlert, __assign({ elevation: 6, variant: "filled" }, props, { ref: ref }), void 0);
});
var CustomizedSnackbars = function () {
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var message = useSelector(function (_a) {
        var base = _a.base;
        return base.dialog.snackbar.message;
    });
    var severity = useSelector(function (_a) {
        var base = _a.base;
        return base.dialog.snackbar.severity;
    });
    var anchorOrigin = useSelector(function (_a) {
        var base = _a.base;
        return base.dialog.snackbar.anchorOrigin;
    });
    var handleClose = function () {
        dispatch(closeSnackbar());
    };
    return (_jsxs("div", { children: [message && severity && (_jsx(Snackbar, __assign({ anchorOrigin: anchorOrigin || {
                    vertical: 'top',
                    horizontal: 'center'
                }, open: true, onClose: handleClose, autoHideDuration: 6000 }, { children: _jsx(Alert, __assign({ onClose: handleClose, severity: severity, sx: { width: '100%' } }, { children: t(message) }), void 0) }), void 0)), message && !severity && (_jsx(Snackbar, { anchorOrigin: anchorOrigin || {
                    vertical: 'top',
                    horizontal: 'center'
                }, open: true, onClose: handleClose, autoHideDuration: 6000, message: t(message) }, void 0))] }, void 0));
};
export default CustomizedSnackbars;
