var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Typography } from '@mui/material';
import clsx from 'clsx';
import { Box } from '@mui/system';
function SettingsHeading(props) {
    return (_jsxs(Box, { children: [props.header && (_jsx(Typography, __assign({ className: clsx(props.classes.header), sx: { fontSize: '2.4rem' } }, { children: props.header }), void 0)), _jsx(Divider, {}, void 0)] }, void 0));
}
export default SettingsHeading;
