var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@mui/styles';
import DOMPurify from 'dompurify';
import { convertFromHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
var useStyles = makeStyles(function (theme) { return ({
    editorClassName: {
        '& [class*="public-DraftStyleDefault-block"]': {
            margin: 0,
            overflowWrap: 'anywhere'
        }
    }
}); });
var CustomLink = function (props) {
    var data = props.contentState.getEntity(props.entityKey).getData();
    var url = data.url;
    return (_jsx("a", __assign({ href: url, referrerPolicy: "no-referrer", target: "_blank" }, { children: props.children }), void 0));
};
function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(function (character) {
        var entityKey = character.getEntity();
        if (entityKey !== null) {
            var type = contentState.getEntity(entityKey).getType();
            return type === 'LINK';
        }
        return false;
    }, callback);
}
var decorator = [
    {
        strategy: findLinkEntities,
        component: CustomLink
    },
];
var ReadOnlyEditor = function (_a) {
    var value = _a.value;
    var classes = useStyles();
    var html = DOMPurify.sanitize(value, { ALLOWED_ATTR: ['referrerpolicy', 'href', 'target'] });
    var state = convertFromHTML({
        htmlToEntity: function (nodeName, node, createEntity) {
            if (nodeName === 'a') {
                return createEntity('LINK', 'MUTABLE', {
                    url: node.href
                });
            }
        },
        htmlToBlock: function (nodeName, node) {
            if (nodeName === 'blockquote') {
                return {
                    type: 'blockquote',
                    data: {}
                };
            }
        }
    })(html);
    var editorState = EditorState.createWithContent(state);
    return (_jsx(Editor, { editorState: editorState, readOnly: true, editorClassName: classes.editorClassName, toolbarClassName: "hidden", toolbar: {
            link: {
                showOpenOptionOnHover: false
            }
        }, customDecorators: decorator }, void 0));
};
export default ReadOnlyEditor;
