import { jsx as _jsx } from "react/jsx-runtime";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useTranslation } from 'react-i18next';
import UserNotificationPageContent from './components/UserNotificationPageContent';
import UserNotificationPageHeader from './components/UserNotificationPageHeader';
var UserNotificationPage = function (_a) {
    var notificationId = _a.notificationId;
    var t = useTranslation().t;
    return (_jsx(PageSimple, { header: _jsx(UserNotificationPageHeader, {}, void 0), breadcrumbs: _jsx(Breadcrumbs, { link: "/notifications", goBack: false, name: t('Вернуться к списку уведомлений') }, void 0), content: _jsx(UserNotificationPageContent, { notificationId: notificationId }, void 0), innerScroll: false }, void 0));
};
export default UserNotificationPage;
