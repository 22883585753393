import { jsx as _jsx } from "react/jsx-runtime";
import { FeatureTypeIds } from 'common/const/feature-ids.const';
import { FeatureTypes } from 'common/enums/feature/feature-types.enum';
import TeamPageTournamentFeatureVirtualPointsContent from './TournamentFeatureVirtualPointsContent/TeamPageTournamentFeatureVirtualPointsContent';
var TeamPageTournamentFeatureContent = function (_a) {
    var _b;
    var tournamentId = _a.tournamentId, team = _a.team, tournamentFeatures = _a.tournamentFeatures, isTournamentEnded = _a.isTournamentEnded, currentUserId = _a.currentUserId;
    var virtualPointsFeature = (_b = tournamentFeatures.filter(function (tf) { return tf.featureId === FeatureTypeIds[FeatureTypes.virtualPoints]; })) === null || _b === void 0 ? void 0 : _b[0];
    return tournamentFeatures.length > 0 && team && virtualPointsFeature ? (_jsx(TeamPageTournamentFeatureVirtualPointsContent, { tournamentId: tournamentId, team: team, virtualPointsFeature: virtualPointsFeature, isTournamentEnded: isTournamentEnded, currentUserId: currentUserId }, void 0)) : null;
};
export default TeamPageTournamentFeatureContent;
