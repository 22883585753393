var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { USER } from 'common/graphql/queries/user/user';
import { useUpdateUserMutation } from 'common/hooks/useUpdateUserMutation';
import { useTranslation } from 'react-i18next';
import inMemoryJwt from 'utils/inMemoryJwt';
import SettingsForm from '../components/SettingsForm/SettingsForm';
import SettingsGroup from '../components/SettingsGroup/SettingsGroup';
import connectionsGroupsConfig from './connectionsGroupsConfig';
var Connections = function () {
    var _a;
    var t = useTranslation().t;
    var user = (_a = useQuery(USER, {
        skip: !inMemoryJwt.getToken(),
        fetchPolicy: 'cache-only',
        variables: {
            showSocialSettings: true
        }
    }).data) === null || _a === void 0 ? void 0 : _a.user;
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: t('Соцсети и источники тренировок') }, void 0), content: _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "p-10" }, { children: [_jsxs("p", { children: [t('SocialConnectText'), ". "] }, void 0), " ", _jsxs("p", { children: [t('ActivitySourceConnectText'), "."] }, void 0)] }), void 0), _jsx(Divider, { light: true }, void 0), connectionsGroupsConfig.length > 0 &&
                    connectionsGroupsConfig.map(function (settingsGroup) { return (_jsxs("div", { children: [_jsx(SettingsGroup, { settingsGroup: settingsGroup, data: user, handleChangeData: useUpdateUserMutation }, void 0), _jsx(Divider, { light: true }, void 0)] }, settingsGroup.id)); })] }, void 0) }, void 0));
};
export default Connections;
