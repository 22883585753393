import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from 'store/history';
function useDynamicMutation(executableMutation, onSuccess, onError) {
    var _a = useState(undefined), variablesData = _a[0], setVariablesData = _a[1];
    var _b = executableMutation(variablesData), updateData = _b[0], _c = _b[1], data = _c.data, loading = _c.loading, error = _c.error;
    var t = useTranslation().t;
    var handleExecuteMutation = function (data) {
        setVariablesData(data);
    };
    useEffect(function () {
        if (variablesData) {
            executeMutation();
        }
    }, [variablesData]);
    var executeMutation = useCallback(function () {
        if (variablesData) {
            updateData()
                .then(function (response) {
                if (response && onSuccess) {
                    onSuccess(response.data);
                }
            })["catch"](function (_a) {
                var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation, forward = _a.forward;
                if ((graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.length) > 0 && onError) {
                    graphQLErrors.map(function (_a) {
                        var extensions = _a.extensions;
                        onError({
                            code: t(extensions.code),
                            errorField: extensions.errorField,
                            errorDetails: extensions.errorDetails,
                            severity: SnackbarSeverity.ERROR
                        });
                    });
                }
                else if (networkError) {
                    history.push('/error');
                }
            })["finally"](function () {
                setVariablesData(undefined);
            });
        }
    }, [variablesData]);
    return {
        data: data,
        error: error,
        loading: loading,
        handleExecuteMutation: handleExecuteMutation
    };
}
export default useDynamicMutation;
