var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import SportEventOwnerListItem from './SportEventOwnerListItem';
var SportEventOwnersDetailsDialog = function (_a) {
    var eventSportClubOwners = _a.eventSportClubOwners, eventOrganizationOwners = _a.eventOrganizationOwners, handleClose = _a.handleClose;
    var owners = __spreadArray(__spreadArray([], ((eventSportClubOwners === null || eventSportClubOwners === void 0 ? void 0 : eventSportClubOwners.map(function (o) {
        return {
            ownerType: 'SportClub',
            owner: o
        };
    })) || []), true), ((eventOrganizationOwners === null || eventOrganizationOwners === void 0 ? void 0 : eventOrganizationOwners.map(function (o) {
        return {
            ownerType: 'Organization',
            owner: o
        };
    })) || []), true);
    return (owners === null || owners === void 0 ? void 0 : owners.length) > 0 ? (_jsx("div", __assign({ className: "flex flex-col space-y-20" }, { children: owners.map(function (o) {
            return (_jsx(SportEventOwnerListItem, { ownerType: o.ownerType, sportClub: o.ownerType === 'SportClub' ? o.owner : undefined, organization: o.ownerType === 'Organization' ? o.owner : undefined, handleClose: handleClose }, o.owner.id));
        }) }), void 0)) : null;
};
export default SportEventOwnersDetailsDialog;
