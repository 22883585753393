var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Checkbox, DialogActions, FormControlLabel, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { TeamContactTypes } from 'common/enums/team/team-contact-types.enum';
import { CREATE_TEAM_CONTACT } from 'common/graphql/mutations/team/create-team-contact';
import { TEAM_CONTACTS } from 'common/graphql/queries/team/team-contacts';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import contactValidationSchema from 'common/validationSchemas/contactValidationSchema copy';
import Button from 'components/Buttons/Button';
import TeamContactTypeField from 'components/Fields/TeamContactTypeField';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var CreateTeamContactDialog = function (_a) {
    var teamId = _a.teamId, handleClose = _a.handleClose;
    var defaultFormState = {
        teamId: teamId,
        type: TeamContactTypes.Telegram,
        text: '',
        url: '',
        isPrivate: false
    };
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var createTeamContact = function (data) {
        return useMutation(CREATE_TEAM_CONTACT, {
            variables: {
                createTeamContactData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TEAM_CONTACTS,
                    variables: {
                        teamId: teamId
                    }
                },
            ]
        });
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(createTeamContact, handleClose, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        //Todo
        handleExecuteMutation(__assign({}, data));
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: contactValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0441\u044B\u043B\u043A\u0443 \u043D\u0430 \u0441\u043E\u0446. \u0441\u0435\u0442\u044C" }), void 0), _jsx(TextField, { className: "", label: "\u0422\u0435\u043A\u0441\u0442", name: "text", value: formik.values['text'], onChange: handleChangeWrapper, error: Boolean(formik.errors['text']), helperText: formik.errors['text'] }, void 0), _jsx(TextField, { className: "", label: "URL", name: "url", value: formik.values['url'], onChange: handleChangeWrapper, error: Boolean(formik.errors['url']), helperText: formik.errors['url'] }, void 0), _jsx(TeamContactTypeField, { name: "type", label: "\u041C\u0435\u0441\u0441\u0435\u043D\u0434\u0436\u0435\u0440", value: formik.values['type'], isError: Boolean(formik.errors['type']), helperText: formik.errors['type'], onChange: formik.setFieldValue }, void 0), _jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { checked: formik.values['isPrivate'] || false, onChange: function (e) {
                                formik.setFieldError('isPrivate', undefined);
                                formik.setFieldValue('isPrivate', e.target.checked);
                            } }, void 0), label: "\u0421\u0441\u044B\u043B\u043A\u0430 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u0430\u044F, \u0432\u0438\u0434\u044F\u0442 \u0442\u043E\u043B\u044C\u043A\u043E \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0438 \u043A\u043E\u043C\u0430\u043D\u0434\u044B" }, void 0)] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm" }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0)] }), void 0)] }), void 0));
};
export default CreateTeamContactDialog;
