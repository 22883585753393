var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultSettings } from 'store/actions';
import { displayPreferencesVar, ThemeMode } from 'utils/apollo/reactivities/displayPreferencesVar';
function Theme(_a) {
    var children = _a.children;
    var themeMode = useReactiveVar(displayPreferencesVar).themeMode;
    var dispatch = useDispatch();
    var settings = useSelector(function (_a) {
        var base = _a.base;
        return base.settings.current;
    });
    var i18n = useTranslation().i18n;
    window.addEventListener('storage', function (event) {
        if (event.key === 'displayMode') {
            setMode();
        }
    });
    var setMode = function () {
        if (localStorage.getItem('displayMode') === ThemeMode.dark) {
            dispatch(setDefaultSettings(_.assign({}, __assign(__assign({}, settings), { theme: {
                    main: 'defaultDark',
                    navbar: 'defaultDark',
                    toolbar: 'defaultDark',
                    loginPage: 'defaultDark'
                } }))));
        }
        else {
            dispatch(setDefaultSettings(_.assign({}, __assign(__assign({}, settings), { theme: {
                    main: 'default',
                    navbar: 'defaultDark',
                    toolbar: 'default',
                    loginPage: 'defaultDark'
                } }))));
        }
    };
    useEffect(function () {
        themeMode === ThemeMode.dark
            ? document.documentElement.classList.add('dark')
            : document.documentElement.classList.remove('dark');
        if (themeMode === ThemeMode.unknown) {
            var displayMode = localStorage.getItem('displayMode');
            if (displayMode) {
                displayPreferencesVar(__assign(__assign({}, displayPreferencesVar()), { themeMode: displayMode === ThemeMode.dark ? ThemeMode.dark : ThemeMode.light }));
            }
        }
        else {
            window.localStorage.setItem('displayMode', themeMode);
            setMode();
        }
    }, [themeMode]);
    var _b = React.useState('ruRU'), locale = _b[0], setLocale = _b[1];
    useEffect(function () {
        setLocale(i18n.language === 'en' ? 'enUS' : 'ruRU');
    }, [i18n.language]);
    var mainTheme = useSelector(function (_a) {
        var base = _a.base;
        return base.settings.mainTheme;
    });
    var themeWithLocale = React.useMemo(function () { return createTheme(mainTheme, locales[locale]); }, [locale, mainTheme]);
    return _jsx(ThemeProvider, __assign({ theme: themeWithLocale }, { children: children }), void 0);
}
export default React.memo(Theme);
