var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import AccordionSettings from './AccordionSettings/AccordionSettings';
import ConnectionSettings from './ConnectionSettings/ConnectionSettings';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        width: '100%'
    }
}); });
var GroupContent = function (props) {
    var classes = useStyles(props);
    var settingsGroup = props.settingsGroup, data = props.data, handleChangeData = props.handleChangeData;
    var convertData = function (data, values) {
        var convertedData = {
            id: data.id
        };
        values.forEach(function (param) {
            var _a;
            var _b;
            var value = param.value;
            var dependentFields = {};
            (_b = param.dependentFields) === null || _b === void 0 ? void 0 : _b.forEach(function (fieldName) {
                var _a;
                dependentFields = __assign(__assign({}, dependentFields), (_a = {}, _a[fieldName] = data[fieldName], _a));
            });
            convertedData = __assign(__assign(__assign({}, convertedData), dependentFields), (_a = {}, _a[param.id] = _.get(data, value) || undefined, _a));
        });
        return convertedData;
    };
    var renderParams = function (settingsGroupParams) {
        switch (settingsGroupParams.type) {
            case 'accordion': {
                return (_jsx(AccordionSettings, { settingsGroupParams: settingsGroupParams, data: convertData(data, settingsGroupParams.values), handleChangeData: handleChangeData }, settingsGroupParams.id));
            }
            case 'connection': {
                return (_jsx(ConnectionSettings, { settingsGroupParams: settingsGroupParams, data: convertData(data, settingsGroupParams.values) }, settingsGroupParams.id));
            }
        }
    };
    return (_jsx("div", __assign({ className: clsx(classes.root, 'group-content') }, { children: data && (_jsxs(Box, __assign({ sx: {
                padding: {
                    xs: '1.5rem 0.8rem',
                    sm: '2rem 1rem'
                },
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 100%',
                zIndex: 2,
                maxWidth: '100%'
            } }, { children: [settingsGroup.info && _jsx("div", { children: settingsGroup.info }, void 0), settingsGroup.settingsGroupParams.length > 0 &&
                    settingsGroup.settingsGroupParams.map(function (settingsGroupParams) {
                        return renderParams(settingsGroupParams);
                    })] }), void 0)) }), void 0));
};
export default GroupContent;
