import { TournamentStandingsType } from 'common/enums/tournament/tournament-standings-type.enum';
var tournamentProfileGroupsConfig = [
    // {
    // 	id: 'changeProfilePhoto-group',
    // 	value: 'changeProfilePhoto-group',
    // 	settingsGroupParams: [
    // 		{
    // 			id: 'changeTournamentProfilePhotoGroupParams',
    // 			values: [
    // 				{
    // 					id: 'profilePhoto',
    // 					value: 'profilePhoto',
    // 					title: 'Фото события',
    // 					type: 'photo-upload',
    // 					dependentFields: ['name'],
    // 				},
    // 			],
    // 			type: 'accordion',
    // 		},
    // 	],
    // },
    {
        id: 'id-group',
        value: 'id-group',
        title: 'ID',
        settingsGroupParams: [
            {
                id: 'id-group',
                values: [
                    {
                        id: 'id',
                        value: 'id',
                        title: 'ID',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: false
            },
        ]
    },
    {
        id: 'name-group',
        value: 'name-group',
        title: 'Название',
        settingsGroupParams: [
            {
                id: 'nameGroupParams',
                values: [
                    {
                        id: 'displayName',
                        value: 'displayName',
                        title: 'Название',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'description-Tournament',
        value: 'description-Tournament',
        title: 'О турнире',
        settingsGroupParams: [
            {
                id: 'descriptionTournamentGroupParams',
                values: [
                    {
                        id: 'description',
                        value: 'description',
                        title: 'Информация о турнире',
                        type: 'textEditor',
                        textFieldIsMultiline: true
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'name-Tournament',
        value: 'name-Tournament',
        title: 'Уникальное имя для URL',
        settingsGroupParams: [
            {
                id: 'nameTournamentGroupParams',
                values: [
                    {
                        id: 'name',
                        value: 'name',
                        title: 'Уникальное имя',
                        type: 'textField',
                        textFieldIsMultiline: false
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'standingsType-group',
        value: 'standingsType-group',
        title: 'Тип турнира',
        settingsGroupParams: [
            {
                id: 'standingsTypeGroupParams',
                values: [
                    {
                        id: 'standingsType',
                        value: 'standingsType',
                        title: 'Тип турнира',
                        type: 'selectField',
                        selectValues: [
                            {
                                value: TournamentStandingsType.User,
                                text: 'Пользовательский. Участники только пользователи.'
                            },
                            {
                                value: TournamentStandingsType.Team,
                                text: 'Командный. Участники - команды.'
                            },
                            {
                                value: TournamentStandingsType.SportClub,
                                text: 'Клубный. Участники - клубы.'
                            },
                        ]
                    },
                ],
                type: 'accordion',
                editable: false
            },
        ]
    },
];
export default tournamentProfileGroupsConfig;
