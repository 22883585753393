var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import SettingsHeading from '../SettingsHeading/SettingsHeading';
import { Hidden } from '@mui/material';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        root: {},
        contentWrapper: (_a = {
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 100%',
                zIndex: 2,
                maxWidth: '100%',
                minWidth: 0,
                minHeight: 0
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: '0 1.6rem'
            },
            _a[theme.breakpoints.up('sm')] = {
                padding: '0 2rem'
            },
            _a),
        header: (_b = {},
            _b[theme.breakpoints.down('sm')] = {
                padding: '1.6rem 1rem'
            },
            _b[theme.breakpoints.up('sm')] = {
                padding: '2rem 1rem'
            },
            _b),
        contentCard: {
            display: 'flex',
            flex: '1 1 100%',
            flexDirection: 'column',
            minHeight: 0,
            borderRadius: '8px 8px 0 0'
        },
        content: {
            flex: '1 1 auto',
            height: '100%',
            overflow: 'auto',
            '-webkit-overflow-scrolling': 'touch'
        }
    });
});
var SettingsForm = function (props) {
    var classes = useStyles(props);
    return (_jsx("div", __assign({ className: clsx(classes.root, 'settings-form') }, { children: _jsxs("div", __assign({ className: clsx(classes.contentWrapper) }, { children: [_jsx(Hidden, __assign({ mdDown: true }, { children: _jsx(SettingsHeading, { header: props.header, classes: classes }, void 0) }), void 0), _jsx("div", __assign({ className: clsx(classes.contentCard) }, { children: props.content }), void 0)] }), void 0) }), void 0));
};
export default SettingsForm;
