var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLazyQuery } from '@apollo/client';
import { Tooltip } from '@mui/material';
import { HELPER } from 'common/graphql/queries/helper/helper';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
var DocsHelper = function (_a) {
    var id = _a.id, children = _a.children, additionalText = _a.additionalText;
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var _c = useLazyQuery(HELPER), getHelper = _c[0], helper = _c[1].data;
    // const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    useEffect(function () {
        if (id) {
            getHelper({ variables: { id: id } });
        }
    }, [id]);
    var _d = useState(false), openTooltip = _d[0], setOpenTooltip = _d[1];
    return (_jsx(_Fragment, { children: (helper === null || helper === void 0 ? void 0 : helper.helper.text) ? (_jsx(Tooltip, __assign({ className: "hover:cursor-pointer", onOpen: function () { return setOpenTooltip(true); }, 
            // если раскоментить, то не будет работать кнопка Подробнее
            // onMouseLeave={() => isMobile && setOpenTooltip(false)}
            onClose: function () { return setOpenTooltip(false); }, open: openTooltip, placement: "top-start", enterTouchDelay: 0, leaveTouchDelay: 10000, leaveDelay: 700, title: _jsxs(Fragment, { children: [i18n.language === 'en' ? (_jsx("p", { children: (helper === null || helper === void 0 ? void 0 : helper.helper.textEn) || (helper === null || helper === void 0 ? void 0 : helper.helper.text) }, void 0)) : (_jsx("p", { children: helper === null || helper === void 0 ? void 0 : helper.helper.text }, void 0)), additionalText && _jsx("div", { children: additionalText }, void 0), (helper === null || helper === void 0 ? void 0 : helper.helper.link) && (_jsx("div", __assign({ onClick: function () {
                            if (helper === null || helper === void 0 ? void 0 : helper.helper.link) {
                                window.open(i18n.language === 'en'
                                    ? (helper === null || helper === void 0 ? void 0 : helper.helper.linkEn) || (helper === null || helper === void 0 ? void 0 : helper.helper.link)
                                    : helper === null || helper === void 0 ? void 0 : helper.helper.link, '_blank');
                            }
                        }, className: "flex underline justify-end cursor-pointer text-14" }, { children: t('Подробнее') }), void 0))] }, void 0) }, { children: children }), void 0)) : (_jsx("div", __assign({ className: "hover:cursor-pointer max-h-56", onClick: function () {
                if (helper === null || helper === void 0 ? void 0 : helper.helper.link) {
                    window.open(i18n.language === 'en'
                        ? (helper === null || helper === void 0 ? void 0 : helper.helper.linkEn) || (helper === null || helper === void 0 ? void 0 : helper.helper.link)
                        : helper === null || helper === void 0 ? void 0 : helper.helper.link, '_blank');
                }
            } }, { children: children }), void 0)) }, void 0));
};
export default DocsHelper;
