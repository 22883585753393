import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PageCarded from 'components/PageLayouts/carded/PageCarded';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import SidebarContent from '../../layouts/shared-components/SidebarContent/SidebarContent';
import teamSettingsNavigationConfig from './teamSettingsNavigationConfig';
var TeamSettingsPage = function (props) {
    var _a, _b;
    var dispatch = useDispatch();
    var teamId = useParams().teamId;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var isAdmin = !!((_b = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles) === null || _a === void 0 ? void 0 : _a.map(function (role) { return role.name; })) === null || _b === void 0 ? void 0 : _b.filter(function (roleName) { return ['Admin', 'SuperAdmin'].includes(roleName); })[0]);
    useEffect(function () {
        if (isAdmin === false) {
            dispatch(openSnackbar({
                message: 'У вас нет прав на управление этим турниром',
                severity: SnackbarSeverity.ERROR
            }));
        }
    }, [isAdmin]);
    return (_jsx(PageCarded, { classes: {
            root: 'w-full',
            header: 'hidden',
            topBg: 'hidden',
            content: 'flex flex-col',
            sidebar: 'xs:px-8 md:px-12 xl:px-24'
        }, breadcrumbs: _jsx(Breadcrumbs, {}, void 0), content: isAdmin && _jsx(_Fragment, { children: props.children }, void 0), rightSidebarContent: isAdmin && (_jsx(SidebarContent, { navigationConfigItems: teamSettingsNavigationConfig, mainPathname: 'team-settings/' + teamId }, void 0)), mobileSidebarContent: true }, void 0));
};
export default TeamSettingsPage;
