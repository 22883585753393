var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography } from '@mui/material';
import useOnScreen from 'common/hooks/useOnScreen';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ClubParticipantsSearchResultListItem from '../ClubParticipantsSearchResultListItem/ClubParticipantsSearchResultListItem';
var ClubParticipantsSearchResultList = function (_a) {
    var userProfiles = _a.userProfiles, isLoading = _a.isLoading, hasMore = _a.hasMore, currentUserRole = _a.currentUserRole, loadMore = _a.loadMore, handleChangeIsAdminRole = _a.handleChangeIsAdminRole, handleChangeIsOwnerRole = _a.handleChangeIsOwnerRole, handleExpelUser = _a.handleExpelUser;
    var _b = useOnScreen(), measureRef = _b.measureRef, isIntersecting = _b.isIntersecting, observer = _b.observer;
    var t = useTranslation().t;
    useEffect(function () {
        if (isIntersecting && hasMore) {
            loadMore();
            observer.disconnect();
        }
    }, [isIntersecting, hasMore, loadMore]);
    return (_jsx(Box, __assign({ sx: {
            overflowY: 'scroll'
        }, className: "w-full " }, { children: userProfiles.length ? (_jsxs("div", __assign({ className: "flex flex-col mx-0 w-full" }, { children: [userProfiles.map(function (userProfile, index) {
                    if (index === userProfiles.length - 1) {
                        return (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx(ClubParticipantsSearchResultListItem, { handleChangeIsAdminRole: handleChangeIsAdminRole, handleChangeIsOwnerRole: handleChangeIsOwnerRole, handleExpelUser: handleExpelUser, mesureRef: measureRef, userProfile: userProfile, currentUserRole: currentUserRole }, void 0), _jsx(Divider, { light: true }, void 0)] }), index));
                    }
                    return (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx(ClubParticipantsSearchResultListItem, { handleChangeIsAdminRole: handleChangeIsAdminRole, handleChangeIsOwnerRole: handleChangeIsOwnerRole, handleExpelUser: handleExpelUser, userProfile: userProfile, currentUserRole: currentUserRole }, void 0), _jsx(Divider, { light: true }, void 0)] }), index));
                }), isLoading && _jsx("div", { children: "Loading..." }, void 0)] }), void 0)) : (_jsx("div", __assign({ className: "flex grow justify-center" }, { children: _jsx(Typography, __assign({ sx: { fontSize: '1.8rem', fontWeight: 400 } }, { children: t('Ни один спортсмен не найден') }), void 0) }), void 0)) }), void 0));
};
export default ClubParticipantsSearchResultList;
