export var OPEN_DIALOG = 'OPEN_DIALOG';
export var CLOSE_DIALOG = 'CLOSE_DIALOG';
export var OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export var CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export var openDialog = function (payload) { return ({
    type: OPEN_DIALOG,
    payload: payload
}); };
export var closeDialog = function () { return ({
    type: CLOSE_DIALOG
}); };
export var openSnackbar = function (payload) { return ({
    type: OPEN_SNACKBAR,
    payload: payload
}); };
export var closeSnackbar = function () { return ({
    type: CLOSE_SNACKBAR
}); };
