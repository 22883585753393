import * as Yup from 'yup';
var orderValidationSchema = Yup.object().shape({
    amount: Yup.number()
        .typeError('Сумма должна быть числом')
        .min(10, 'Минимальная сумма - 10 рублей. Это не мы, это все банк...')
        .max(10000000, 'Слишком большая сумма (максимум 10000000). Не то чтобы мы против, но это ограничение банка :(')
        .required('Не указана сумма'),
    description: Yup.string()
        .trim()
        .max(512, 'Слишком длинный текст отзыва. Пожалуйста, введите до 512 символов')
        .nullable()
});
export default orderValidationSchema;
