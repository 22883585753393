var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemText, MenuItem, Popover } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
var TeamAdditionalMenuButton = function (_a) {
    var teamId = _a.teamId;
    var _b = useState(null), userMenu = _b[0], setUserMenu = _b[1];
    var userMenuClick = function (event) {
        setUserMenu(event.currentTarget);
    };
    var userMenuClose = function () {
        setUserMenu(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(MoreVertIcon, { onClick: userMenuClick }, void 0), _jsx(Popover, __assign({ open: Boolean(userMenu), anchorEl: userMenu, onClose: userMenuClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                } }, { children: _jsx(_Fragment, { children: _jsx(MenuItem, __assign({ component: Link, to: '/team-settings/' + teamId, onClick: userMenuClose }, { children: _jsx(ListItemText, { primary: "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435" }, void 0) }), void 0) }, void 0) }), void 0)] }, void 0));
};
export default TeamAdditionalMenuButton;
