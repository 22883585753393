var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApolloClient, ApolloLink, fromPromise, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { appDomain, wsPort, wsScheme } from 'common/appURLSetup';
import { createClient } from 'graphql-ws';
import jwtService from 'services/auth';
import userService from 'services/userService';
import inMemoryJwt from 'utils/inMemoryJwt';
import { cache } from './cache';
/**
 * FOR DEBUG
 */
// const customFetch = (uri: string, options: any) => {
// 	return fetch(uri, options).then((response) => {
// 		if (response) {
// 		}
// 		return response;
// 	});
// };
var refreshToken = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, userService.refreshToken()];
            case 1:
                response = _a.sent();
                if (response.accessToken) {
                    jwtService.setSession(response.accessToken);
                    return [2 /*return*/, response.accessToken];
                }
                if (response) {
                    console.log("[Refresh token error]: ".concat(JSON.stringify(response)));
                }
                return [2 /*return*/];
        }
    });
}); };
var errorLink = onError(function (_a) {
    var _b, _c, _d;
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation, forward = _a.forward;
    if (graphQLErrors) {
        for (var _i = 0, graphQLErrors_1 = graphQLErrors; _i < graphQLErrors_1.length; _i++) {
            var err = graphQLErrors_1[_i];
            if (err.message === 'Unauthorized' && ((_d = (_c = (_b = err.extensions) === null || _b === void 0 ? void 0 : _b.exception) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.statusCode) === 401) {
                return fromPromise(refreshToken()["catch"](function (error) {
                    console.log("[Refresh token error]: ".concat(error));
                    return;
                }))
                    .filter(function (value) { return Boolean(value); })
                    .flatMap(function (accessToken) {
                    if (!accessToken) {
                        console.log("[Refresh token error]: access token is empty");
                    }
                    return forward(operation);
                });
            }
        }
    }
    if (networkError)
        console.log("[Network error]: ".concat(networkError));
});
var httpLink = createUploadLink({
    uri: '/graphql'
});
var authLink = new ApolloLink(function (operation, forward) {
    var token = inMemoryJwt.getToken();
    operation.setContext({
        headers: {
            Authorization: token ? "Bearer ".concat(token) : '',
            'Apollo-Require-Preflight': 'true'
        }
    });
    return forward(operation);
});
var wsLink = new GraphQLWsLink(createClient({
    url: "".concat(wsScheme, "://").concat(appDomain, ":").concat(wsPort, "/subscriptions"),
    connectionParams: {
        authToken: inMemoryJwt.getToken() || ''
    }
}));
var splitLink = split(function (_a) {
    var query = _a.query;
    var definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}, wsLink, httpLink);
var client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, splitLink]),
    cache: cache
});
client.onResetStore(function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        console.log('onResetStore');
        return [2 /*return*/];
    });
}); });
export default client;
