var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import NotificationManagementPageContent from './NotificationManagementPageContent';
var NotificationManagementPage = function () {
    return (_jsx(PageSimple, { header: _jsxs("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: [_jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u044F\u043C\u0438" }), void 0), _jsxs("p", { children: ["\u0418\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u044F", ' ', _jsx("a", __assign({ href: "https://wiki.yandex.ru/imp/impulse/podderzhka-sistem/instrukcija-po-rassylkam-i-shablonam-opoveshhenijj/", target: "_blank" }, { children: "\u043D\u0430 \u043D\u0430\u0448\u0435\u043C \u0441\u0430\u0439\u0442\u0435 wiki" }), void 0)] }, void 0)] }), void 0), content: _jsx("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: _jsx(NotificationManagementPageContent, {}, void 0) }), void 0), innerScroll: false }, void 0));
};
export default NotificationManagementPage;
