import { useQuery } from '@apollo/client';
import { USER } from 'common/graphql/queries/user/user';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import UserData from 'services/auth/UserData';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import inMemoryJwt from 'utils/inMemoryJwt';
import { AppContext } from '../Context';
import Utils from '../utils/Utils';

/* 
shouldComponentUpdate = React.memo((props) => {
   ваш компонент
}); */
const Authorization = React.memo((props) => {
	const appContext = useContext(AppContext);
	const { routes } = appContext;

	const { location, history } = props;
	const { pathname } = location;

	const mounted = useRef();

	const [prevPathname, setPrevPathname] = useState(null);

	const [userRole, setUserRole] = useState([]);
	const [state, setState] = useState({
		accessGranted: false,
		routes,
	});
	const token = inMemoryJwt.getToken();
	const { data, error } = useQuery(USER, {
		skip: !token,
		fetchPolicy: 'network-only',
		variables: {
			showRoles: true,
			showMainSettings: true,
			showSocialSettings: true,
			showSportClub: true,
			showOrganization: true,
		},
	});

	useEffect(() => {
		if (error) {
			history.push({
				pathname: '/error',
				state: { redirectUrl: pathname },
			});
		}
		if (!error && data?.user?.roles?.length) {
			userProfileDataVar({
				...userProfileDataVar(),
				userProfile: data.user,
			});
			// const roles = [];
			// data?.user.roles.forEach((role) => {
			// 	roles.push(role);
			// });
			setUserRole(data.user.roles);
		} else {
			setUserRole([]);
		}
	}, [data, error]);

	//getDerivedStateFromProps выполняется до -> componentDidMount()
	if (!token || (data?.user && userRole?.length > 0) || error) {
		if (pathname !== prevPathname) {
			const matched = matchRoutes(state.routes, pathname)[0];

			matched
				? setState({
						...state,
						accessGranted: Utils.hasPermission(matched.route.auth, userRole),
				  })
				: setState({
						...state,
						accessGranted: true,
				  });

			setPrevPathname(pathname);
		}
	}

	//выполняется если пользователь не залогинился или у него роль guest
	useEffect(() => {
		if (!token || (data?.user && userRole?.length > 0) || error) {
			if (!mounted.current) {
				//console.log('do componentDidMount logic')
				if (!state.accessGranted) {
					redirectRoute();
				}
				mounted.current = true;
			} else {
				//console.log('do componentDidUpdate logic')
				if (!state.accessGranted) {
					redirectRoute();
				}
			}
		}
	});

	function redirectRoute() {
		const { pathname, state } = location;
		const redirectUrl = error || pathname === '/error' ? '/error' : state?.redirectUrl ? state.redirectUrl : '/';

		if ((!userRole || userRole.length === 0) && !!!error) {
			history.push({
				pathname: '/login',
				state: { redirectUrl: pathname },
			});
		} else {
			history.push({
				pathname: redirectUrl,
			});
		}
	}

	return <>{state.accessGranted && <UserData userProfile={data?.user}>{props.children}</UserData>}</>;
});

export default withRouter(Authorization);
