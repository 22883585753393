var profilePrivacyGroupsConfig = [
    {
        id: 'changeProfilePrivacyMode',
        value: 'changeProfilePrivacyMode-group',
        title: 'Приватный аккаунт',
        settingsGroupParams: [
            {
                id: 'changeProfilePrivacyModeGroupParams',
                values: [
                    {
                        id: 'isPrivate',
                        value: 'isPrivate',
                        type: 'switch',
                        title: 'Приватный аккаунт'
                    },
                ],
                type: 'accordion'
            },
        ]
    },
];
export default profilePrivacyGroupsConfig;
