var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import { SocialTypes } from 'common/enums/user/social-types.enum';
import DocsHelper from 'components/DocsHelper/DocsHelper';
import GoogleFitSettings from 'main/pages/SettingsPage/connections/bindSocialNetwork/GoogleFitSettings';
import PolarBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/PolarBind';
import PolarUnBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/PolarUnBind';
import SocialBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/SocialBind';
import SocialUnBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/SocialUnBind';
import StravaBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/StravaBind';
import StravaUnBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/StravaUnBind';
import TelegramBind from 'main/pages/SettingsPage/contacts/TelegramBind';
import TelegramUnBind from 'main/pages/SettingsPage/contacts/TelegramUnBind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
var ConnectionSettings = function (props) {
    var t = useTranslation().t;
    var _a = useState(false), openStravaTooltip = _a[0], setOpenStravaTooltip = _a[1];
    var _b = useState(false), openPolarTooltip = _b[0], setOpenPolarTooltip = _b[1];
    var settingsGroupParams = props.settingsGroupParams, data = props.data;
    var renderParams = function (_a) {
        var _b, _c, _d, _e, _f, _g;
        var value = _a.value, id = _a.id, message = _a.message;
        switch (id) {
            case 'googleAccountId': {
                return (_jsx("div", { children: data[id] ? (_jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsxs(Typography, __assign({ display: "inline", className: "settings-subheading text-14" }, { children: [t('ID Google аккаунта'), ": ", _jsx("strong", { children: data.googleAccountId }, void 0)] }), void 0), data.email && (_jsxs(Typography, __assign({ display: "inline", className: "text-14 xs:max-w-180 md:max-w-480 break-words" }, { children: [t('почта Google'), ": ", _jsx("strong", { children: data.email }, void 0)] }), void 0))] }), id)) : (_jsx("div", __assign({ className: "flex justify-end" }, { children: _jsx("div", { className: "font-medium ml-4" }, void 0) }), void 0)) }, id));
            }
            case 'vkAccountId': {
                return (_jsx("div", { children: data[id] ? (_jsxs("div", __assign({ className: "flex xs:flex-col xs:space-y-10 xs:space-x-0 md:flex-row md:space-y-0 md:space-x-10" }, { children: [_jsxs(Typography, __assign({ display: "inline", className: "text-14" }, { children: [t('ID VK аккаунта'), ": ", _jsx("strong", { children: data.vkAccountId }, void 0), "\u00A0"] }), void 0), data.email && (_jsxs(Typography, __assign({ display: "inline", className: "text-14 xs:max-w-180 md:max-w-480 break-words" }, { children: [t('почта VK'), ": ", _jsx("strong", { children: data.email }, void 0)] }), void 0)), _jsx("div", __assign({ className: "w-full flex items-center justify-end" }, { children: _jsx(SocialUnBind, { socialType: SocialTypes.Vk }, void 0) }), void 0)] }), id)) : (_jsx("div", __assign({ className: "flex justify-end" }, { children: _jsx(SocialBind, { socialType: SocialTypes.Vk }, void 0) }), void 0)) }, id));
            }
            case 'yandexAccountId': {
                return (_jsx("div", { children: data[id] ? (_jsxs("div", __assign({ className: "flex xs:flex-col xs:space-y-10 xs:space-x-0 md:flex-row md:space-y-0 md:space-x-10" }, { children: [_jsxs(Typography, __assign({ display: "inline", className: "text-14" }, { children: [t('ID Яндекс аккаунта'), ": ", _jsx("strong", { children: data.yandexAccountId }, void 0), "\u00A0"] }), void 0), data.email && (_jsxs(Typography, __assign({ display: "inline", className: "text-14 xs:max-w-180 md:max-w-480 break-words" }, { children: [t('почта Яндекс'), ": ", _jsx("strong", { children: data.email }, void 0)] }), void 0)), _jsx("div", __assign({ className: "w-full flex items-center justify-end" }, { children: _jsx(SocialUnBind, { socialType: SocialTypes.Yandex }, void 0) }), void 0)] }), id)) : (_jsx("div", __assign({ className: "flex justify-end" }, { children: _jsx(SocialBind, { socialType: SocialTypes.Yandex }, void 0) }), void 0)) }, id));
            }
            case 'stravaAccountId':
                return (_jsxs("div", __assign({ className: "flex flex-row items-center justify-between xs:space-x-10 sm:space-x-20" }, { children: [_jsx(DocsHelper, __assign({ id: "434df5ef-4510-483f-bd6f-d739347e9300" }, { children: _jsx(InfoOutlinedIcon, { onClick: function () { return setOpenStravaTooltip(!openStravaTooltip); }, fontSize: "small" }, void 0) }), void 0), ((_b = data[id]) === null || _b === void 0 ? void 0 : _b.length) > 0 ? (data[id].map(function (element) {
                            return (_jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row items-center justify-between sm:space-x-5 sm:space-y-0 xs:space-x-0 xs:space-y-5" }, { children: [element.accountId && (_jsxs("div", __assign({ className: "flex flex-row items-center text-14" }, { children: [_jsxs("div", { children: [t('ID Strava аккаунта'), ":"] }, void 0), "\u00A0", _jsx("div", { children: element.accountId }, void 0)] }), void 0)), element.isInError && (_jsxs("div", __assign({ className: "flex items-center text-14 text-red" }, { children: [_jsxs("strong", { children: [t('Ошибка подключения, попробуйте подключить повторно'), "."] }, void 0), "\u00A0", element.errorMessage && _jsx("strong", { children: element.errorMessage }, void 0)] }), void 0)), _jsx("div", __assign({ className: "w-full flex items-center justify-end" }, { children: _jsx(StravaUnBind, { serviceBindingId: element.serviceBindingId }, element.serviceBindingId) }), void 0)] }), element.serviceBindingId));
                        })) : (_jsx("div", __assign({ className: "flex justify-end" }, { children: _jsx(StravaBind, {}, void 0) }), void 0))] }), id));
            case 'polarAccountId':
                return (_jsxs("div", __assign({ className: "flex flex-row items-center justify-between xs:space-x-10 sm:space-x-20" }, { children: [_jsx(DocsHelper, __assign({ id: "622ecfbf-bef2-4182-9255-964b43c5f3fc" }, { children: _jsx(InfoOutlinedIcon, { onClick: function () { return setOpenPolarTooltip(!openPolarTooltip); }, fontSize: "small" }, void 0) }), void 0), ((_c = data[id]) === null || _c === void 0 ? void 0 : _c.length) > 0 ? (data[id].map(function (element) {
                            return (_jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row items-center justify-between sm:space-x-5 sm:space-y-0 xs:space-x-0 xs:space-y-5" }, { children: [element.accountId && (_jsxs("div", __assign({ className: "flex flex-row items-center text-14" }, { children: [_jsxs("div", { children: [t('ID Polar аккаунта'), ":"] }, void 0), "\u00A0", _jsx("div", { children: element.accountId }, void 0)] }), void 0)), element.isInError && (_jsxs("div", __assign({ className: "flex items-center text-14 text-red" }, { children: [t('Ошибка подключения, попробуйте подключить повторно'), ". \u00A0", element.errorMessage && element.errorMessage] }), void 0)), _jsx("div", __assign({ className: "w-full flex items-center justify-end" }, { children: _jsx(PolarUnBind, { serviceBindingId: element.serviceBindingId }, element.serviceBindingId) }), void 0)] }), element.serviceBindingId));
                        })) : (_jsx("div", __assign({ className: "flex justify-end" }, { children: _jsx(PolarBind, {}, void 0) }), void 0))] }), id));
            case 'googleFit': {
                return (_jsx("div", __assign({ className: "flex w-full justify-end" }, { children: _jsx(GoogleFitSettings, { userProfileGoogleSocialSettings: data[id] }, void 0) }), id));
            }
            case 'telegram': {
                return ((_d = data[id]) === null || _d === void 0 ? void 0 : _d.id) ? (_jsxs("div", __assign({ className: "flex w-full justify-between" }, { children: [_jsxs("div", __assign({ className: "flex md:flex-row xs:flex-col md:space-x-10 md:space-y-0 xs:space-y-10 xs:space-x-0 items-center text-14" }, { children: [_jsx("div", { children: (_e = data[id]) === null || _e === void 0 ? void 0 : _e.id }, void 0), ((_f = data[id]) === null || _f === void 0 ? void 0 : _f.username) && _jsx("div", { children: (_g = data[id]) === null || _g === void 0 ? void 0 : _g.username }, void 0)] }), void 0), _jsx(TelegramUnBind, {}, void 0)] }), id)) : (_jsx("div", __assign({ className: "flex justify-end" }, { children: _jsx(TelegramBind, {}, void 0) }), id));
            }
        }
    };
    return (_jsx("div", __assign({ className: "accordion-settings w-full" }, { children: settingsGroupParams.values.length > 0 &&
            settingsGroupParams.values.map(function (settingsGroupParamsValue) {
                return renderParams(settingsGroupParamsValue);
            }) }), void 0));
};
export default ConnectionSettings;
