var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Scrollbars from 'components/Scrollbars/Scrollbars';
import { ThemeProvider } from '@mui/styles';
function PageSimpleSidebarContent(props) {
    var mainThemeDark = useSelector(function (_a) {
        var base = _a.base;
        return base.settings.mainTheme;
    });
    var classes = props.classes;
    return (_jsxs(Scrollbars, __assign({ enable: props.innerScroll }, { children: [props.header && (_jsx(ThemeProvider, __assign({ theme: mainThemeDark }, { children: _jsx("div", __assign({ className: clsx(classes.sidebarHeader, props.variant, props.sidebarInner && classes.sidebarHeaderInnerSidebar) }, { children: props.header }), void 0) }), void 0)), props.content && (_jsx("div", __assign({ className: classes.sidebarContent }, { children: props.content }), void 0))] }), void 0));
}
export default PageSimpleSidebarContent;
