var settingsNavigationConfig = [
    {
        id: 'account',
        title: 'Учетная запись',
        handle: 'account'
    },
    {
        id: 'connections',
        title: 'Соцсети и источники тренировок',
        handle: 'connections'
    },
    {
        id: 'profilePrivacy',
        title: 'Настройки приватности',
        handle: 'profilePrivacy'
    },
    {
        id: 'displayPreferences',
        title: 'Настройки отображения',
        handle: 'displayPreferences'
    },
    {
        id: 'address',
        title: 'Мои адреса',
        handle: 'address'
    },
    {
        id: 'contacts',
        title: 'Мои контакты',
        handle: 'contacts'
    },
    {
        id: 'notifications',
        title: 'Настройки уведомлений',
        handle: 'notifications'
    },
];
export default settingsNavigationConfig;
