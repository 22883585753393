var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Hidden, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavLinkAdapter from 'components/NavLinkAdapter/NavLinkAdapter';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
var useStyles = makeStyles(function (theme) { return ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 60,
        padding: '5px 10px 7px 10px',
        '&.active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText + '!important',
            pointerEvents: 'none',
            '& .svg': {
                color: 'inherit'
            }
        },
        '& .list-item-icon': {
            fontSize: 16,
            width: 16,
            height: 16,
            marginRight: 16
        }
    },
    mobileListItem: {
        borderRadius: '8px',
        borderWidth: '1px',
        borderColor: 'lightgray',
        '&.action-icon': {
            color: theme.palette.actionIcon,
            height: '50px'
        }
    },
    popoverPaper: {
        width: '100%'
    }
}); });
var SidebarContent = function (_a) {
    var navigationConfigItems = _a.navigationConfigItems, mainPathname = _a.mainPathname;
    var classes = useStyles();
    var t = useTranslation().t;
    var _b = useState(undefined), selectedItem = _b[0], setSelectedItem = _b[1];
    var _c = useState(null), anchorEl = _c[0], setAnchorEl = _c[1];
    var open = Boolean(anchorEl);
    var location = useHistory().location;
    var pathname = location.pathname;
    useEffect(function () {
        if (pathname) {
            var menuItem = navigationConfigItems.find(function (item) {
                return "/".concat(mainPathname, "/").concat(item.handle) === pathname;
            });
            setSelectedItem(menuItem);
        }
    }, [pathname]);
    var handleClickListItem = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleMenuItemClick = function (event, item) {
        setSelectedItem(item);
        setAnchorEl(null);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (_jsxs("div", __assign({ className: "sidebar-content flex-auto" }, { children: [_jsx(Hidden, __assign({ mdDown: true }, { children: _jsx(List, { children: navigationConfigItems.length > 0 &&
                        navigationConfigItems.map(function (item) { return (_jsx(ListItem, __assign({ component: NavLinkAdapter, to: "/".concat(mainPathname, "/") + item.handle, activeClassName: "active", className: classes.listItem }, { children: _jsx(ListItemText, { primary: t(item.title), secondary: _jsx(Typography, __assign({ component: "span", variant: "body2", color: "textPrimary" }, { children: t(item.description || '') }), void 0) }, void 0) }), item.id)); }) }, void 0) }), void 0), _jsx(Hidden, __assign({ mdUp: true }, { children: selectedItem ? (_jsxs("div", __assign({ id: "mobile" }, { children: [_jsx(List, { children: _jsxs(ListItem, __assign({ sx: {
                                    backgroundColor: function (theme) { return theme.palette.background.paper; }
                                }, "aria-expanded": open ? 'true' : undefined, onClick: handleClickListItem, className: classes.mobileListItem }, { children: [_jsx(ListItemText, { primary: t(selectedItem.title), secondary: t(selectedItem.description || '') }, void 0), _jsx(ListItemIcon, __assign({ sx: { color: function (theme) { return theme.palette.actionIcon; } } }, { children: open ? _jsx(KeyboardArrowUp, {}, void 0) : _jsx(KeyboardArrowDown, {}, void 0) }), void 0)] }), selectedItem.id) }, void 0), _jsx(Menu, __assign({ PopoverClasses: { paper: classes.popoverPaper }, id: "menu-items", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                                role: 'listbox'
                            } }, { children: navigationConfigItems.length > 0 &&
                                navigationConfigItems.map(function (item) { return (_jsx(MenuItem, __assign({ component: Link, to: "/".concat(mainPathname, "/") + item.handle, selected: item.id === selectedItem.id, onClick: function (event) { return handleMenuItemClick(event, item); } }, { children: _jsx(ListItemText, { primary: t(item.title) }, void 0) }), item.id)); }) }), void 0)] }), void 0)) : (_jsx("div", {}, void 0)) }), void 0)] }), void 0));
};
export default SidebarContent;
