import { jsx as _jsx } from "react/jsx-runtime";
import Switch from '@mui/material/Switch';
var ControlledSwitches = function (_a) {
    var id = _a.id, fieldName = _a.fieldName, isChecked = _a.isChecked, handleChangeData = _a.handleChangeData;
    return (_jsx(Switch, { checked: isChecked, onChange: function () {
            var _a;
            handleChangeData((_a = {
                    id: id
                },
                _a[fieldName] = !isChecked,
                _a));
        }, inputProps: { 'aria-label': 'controlled' } }, void 0));
};
export default ControlledSwitches;
