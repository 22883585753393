var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@apollo/client';
import { UPDATE_SPORT_CLUB } from 'common/graphql/mutations/sportClub/updateSportClub';
import { SPORT_CLUB } from 'common/graphql/queries/sportClub/sportClub';
export function useUpdateSportClubMutation(clubVariables) {
    var profilePhoto;
    var mainSettingsFields = ['about', 'description'];
    var rulesFields = ['acceptenceCondition', 'participationCondition', 'exclusionCondition'];
    var participationSettingsFields = ['allUsersCanJoin', 'joinViaLink', 'joinViaRequest'];
    var sportClubData = {
        mainSettings: {}
    };
    var sportClubId = clubVariables === null || clubVariables === void 0 ? void 0 : clubVariables.id;
    var _loop_1 = function (key) {
        var _a, _b, _c, _d, _e;
        if (key !== 'profilePhoto' && key !== 'removeProfilePhoto') {
            var isMainSettings = mainSettingsFields.filter(function (s) { return s === key; });
            var isRule = rulesFields.filter(function (s) { return s === key; });
            var isParticipationSettings = participationSettingsFields.filter(function (s) { return s === key; });
            // ключи
            if (isMainSettings.length > 0) {
                sportClubData = __assign(__assign({}, sportClubData), { mainSettings: __assign(__assign({}, sportClubData.mainSettings), (_a = {}, _a[key] = clubVariables[key], _a)) });
            }
            else if (isRule.length > 0) {
                sportClubData = __assign(__assign({}, sportClubData), { mainSettings: __assign(__assign({}, sportClubData.mainSettings), { rules: __assign(__assign({}, sportClubData.mainSettings.rules), (_b = {}, _b[key] = clubVariables[key], _b)) }) });
            }
            else if (isParticipationSettings.length > 0) {
                sportClubData = __assign(__assign({}, sportClubData), { participationSettings: __assign(__assign({}, sportClubData.participationSettings), (_c = {}, _c[key] = clubVariables[key], _c)) });
            }
            else if (key === 'isPrivate') {
                sportClubData = __assign(__assign({}, sportClubData), { privacy: (_d = {},
                        // @ts-ignore: Unreachable code error
                        _d[key] = clubVariables[key],
                        _d) });
            }
            else {
                sportClubData = __assign(__assign({}, sportClubData), (_e = {}, _e[key] = clubVariables[key], _e));
            }
        }
    };
    for (var key in clubVariables) {
        _loop_1(key);
    }
    if (clubVariables === null || clubVariables === void 0 ? void 0 : clubVariables.profilePhoto) {
        profilePhoto = clubVariables.profilePhoto;
    }
    if (clubVariables === null || clubVariables === void 0 ? void 0 : clubVariables.removeProfilePhoto) {
        profilePhoto = null;
    }
    return useMutation(UPDATE_SPORT_CLUB, {
        variables: __assign({ updateSportClubData: __assign({}, sportClubData) }, (typeof profilePhoto !== undefined && { profilePhoto: profilePhoto })),
        refetchQueries: [
            {
                query: SPORT_CLUB,
                variables: {
                    id: sportClubId
                }
            },
        ]
    });
}
