import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: {
			'Новое сообщение от поддержки по обращению': 'A new message from support',
			'Непрочитанных уведомлений': 'Unread notifications',
			'Вернуться к списку уведомлений': 'Go back to the notification list',
			Уведомление: 'Notification',
			Прочитано: 'Viewed',
			'Уведомление не прочитано': 'The notification has not been read',
			'Пометить как прочитанное': 'Mark as read',
			'Пометить все как прочитанные': 'Mark all as read',
			'Список уведомлений': 'Notification List',
			'Ваш браузер не поддерживает оповещения. Вы все равно будете их получать, если включите, но без всплывающих уведомлений.':
				'Your browser does not support alerts. You will still receive them if you enable them, but without pop-up notifications.',
			'Настройки уведомлений': 'Notification settings',
			'Смотреть все уведомления': 'View all notifications',
			'Недавние уведомления': 'Recent notifications',
			'Здесь пока ничего нет': `There's nothing here yet`,
			'Уведомления в браузере разрешены.': 'Notifications are allowed in the browser.',
			'Уведомления в браузере запрещены.': 'Notifications are not allowed in the browser.',
			'чтобы мы спросили у вас разрешение на включение оповещение, затем нажмите "Разрешить".':
				'for us to ask you for permission to enable the alert, then click "Allow".',
			Нажмите: 'Click',
			'Для того, чтобы получать уведомления в браузере, их нужно разрешить.':
				'In order to receive notifications in the browser, you need to allow them.',
			'Пуш-уведомление': 'Push',
			'По email': 'By Email',
			'Рассылка с новостями Импульса': 'Newsletter with Impulse news',
			'Оповещение о создании и зачете новой тренировки':
				'Notification about the creation and validation of a new workout',
			'Настройки уведомлений': 'Notification settings',
			'Некорректный email': 'Invalid email address',
			'Нету кода?': 'No code?',
			'Email адрес успешно подтверждён': 'The email address has been successfully confirmed',
			'Введите код подтверждения, который был отправлен на указанный email':
				'Enter the confirmation code that was sent to the specified email',
			'Ожидает подтверждения': 'Awaiting verification',
			'Подтвердить email': 'Verify the email address',
			Подтверждён: 'Verified',
			'Отправить код повторно': 'Send the code again',
			'Отправить код повторно можно через': 'You can send the code again in',
			'Проверочный код': 'Verification code',
			'Подтвердите, что e-mail принадлежит вам, чтобы обезопасить свою учетную запись.':
				'Confirm that the e-mail belongs to you in order to secure your account.',
			'Мы отправили 6-значный код на': 'We have sent a 6-digit code to',
			'Подтверждение регистрации': 'Confirmation of registration',
			'Подтвердите пароль': 'Confirm the password',
			'Введите новый пароль': 'Enter a new password',
			'Вы действительно хотите покинуть клуб?': 'Do you really want to leave the club?',
			'Покинуть клуб': 'Leave the club',
			'Вы подали заявку': 'You applied for membership',
			Покинуть: 'Leave',
			'Вы больше не являетесь участником клуба.': 'You are no longer a member of the club.',
			'Тренировки за данный промежуток времени не найдены':
				'No training sessions were found for this period of time',
			'Поставить лайк': 'Like',
			'Убрать лайк': 'Remove like',
			'Поставить дислайк': 'Dislike',
			'Убрать дислайк': 'Remove dislike',
			createActivityManualFormDatePickerAlert:
				'The workout should not overlap in time with your other workouts, otherwise it will be marked as a duplicate and will not be counted in the standings.',
			'Удаление тренировки повлечёт за собой перерасчёт вашего зачёта всех активных турнирных таблиц, в которых она зачтена.':
				'Deleting a workout will result in a recalculation of all active standings in which it is counted.',
			'Вы уверены, что хотите удалить тренировку': 'Are you sure you want to delete a workout',
			'Удалить тренировку': 'Delete activity',
			'Тренировка удалена': 'Activity has been deleted',
			'Обнаружена новая версия приложения, загружаем...':
				'A new version of the application has been detected, downloading...',
			createAcitivityManualFormFileAttachmentsHelper:
				'Attachments must be a PNG/JPG image. You can add a screenshot of your workout from the app that recorded it. This way the administrator will be able to understand that this is a valid training when he checks it.',
			'Количество файлов не должно быть больше 5': 'The number of files should be no more than 5',
			'Максимум 5 файлов': 'Up to 5 files',
			'Выберите событие': 'Choose event',
			'Выберите клуб': 'Choose club',
			'Выберите турнирную таблицу': 'Choose standings',
			'Выберите турнир события': 'Choose event tournament',
			'Изменить уровень валидации': 'Change the validation level',
			createActivityValidationSchema_name_min: 'The name is too short. At least one character',
			createActivityValidationSchema_name_max: 'The name is too long. Please enter between 1 and 256 characters',
			createActivityValidationSchema_name_required: 'The name is not specified',
			createActivityValidationSchema_description_max:
				'The description is too long. Please enter from 0 to 512 characters',
			createActivityValidationSchema_distance_type: 'The distance must be a number',
			createActivityValidationSchema_distance_min: 'The distance is too small',
			createActivityValidationSchema_distance_max: 'The distance is too large',
			createActivityValidationSchema_distance_required:
				'The distance is not specified. If not required, specify 0',
			createActivityValidationSchema_startDate_type: 'Specify the start date of the training session',
			createActivityValidationSchema_startDate_required: 'The training start date is not specified',
			createActivityValidationSchema_elapsedTime_type: 'The duration must be a number',
			createActivityValidationSchema_elapsedTime_min: 'The training duration is too short',
			createActivityValidationSchema_elapsedTime_max: 'The training duration is too long',
			createActivityValidationSchema_elapsedTime_required: 'The duration of the training is not specified',
			createActivityValidationSchema_averageCadence_type: 'The cadence must be a number',
			createActivityValidationSchema_averageCadence_max: 'The cadence is too high',
			createActivityValidationSchema_maxSpeed_type: 'The maximum speed must be a number',
			createActivityValidationSchema_maxSpeed_max: 'The speed is too high. Maximum of 30 m/s',
			createActivityValidationSchema_avgHeartRate_type: 'The heart rate should be a number',
			createActivityValidationSchema_avgHeartRate_max:
				'The heart rate is too high. Specify up to 200 beats per minute',
			createActivityValidationSchema_elevation_type: 'The elevation gain must be a number',
			createActivityValidationSchema_elevation_max: 'The elevation gain is too high, maximum 3000 meters',
			createActivityValidationSchema_sport_required: 'Specify the type of sport',
			'Подробности на странице тренировки.': 'See details on activity page.',
			'Наведите на кружок, чтобы увидеть подробности': 'Hover over the circle to see the details',
			'на уровне вида спорта': 'on Sport Type level',
			'на уровне вида события': 'on Sport Event level',
			'Вы не можете просматривать данные приватного аккаунта': 'You cannot view private account data',
			'Код для входа в приватную команду': 'The code for joining the private team',
			'Команда приватная или находится в приватном или закрытом событии':
				'The team is private or belongs to a private event',
			'Введите приватный код для входа в команду': 'Enter the private code to join the team',
			'Турнир события не найден, обратитесь к администратору':
				'Sport event tournament not found, please ask administrator for help via support requests page',
			'Невозможно присоединиться к приватному событию по публичной ссылке':
				'You cannot join private sport event via public link',
			'Событие не найдено': 'Sport event not found',
			'Команда не найдена': 'Team not found',
			'Ошибка при загрузке данных': 'Error loading data',
			'У вас нет доступа к этой турнирной таблице': 'You do not have access to this standings',
			'Отобрать капитанство': 'Downgrade',
			'Сделать капитаном': 'Promote to captain',
			'Выдать права': 'Assign rigths',
			'Вы уверены, что хотите исключить пользователя из': 'Are you sure you want to dismiss user from',
			клуба: 'the club',
			команды: 'the team',
			'Исключить пользователя из': 'Dismis participant from',
			Исключить: 'Dismiss',
			Отклонить: 'Decline',
			Принять: 'Accept',
			Отозвать: 'Withdraw',
			'Заявка на вступление в клуб отозвана': 'The application to join the club has been withdrawn',
			'Подать заявку': 'Apply for membership',
			'Заявка отправлена': 'The application has been sent',
			'Нет активных заявок': 'There are no active applications',
			'Заявка принята! Пользователь добавлен в клуб.':
				'The application has been accepted! The user has been added to the club.',
			'Заявка пользователя отклонена.': `The user's request was rejected.`,
			'Контакты клуба': 'Club Contacts',
			'Правила клуба': 'Club Rules',
			'Профайл клуба': 'Club profile',
			'Сортировать по': 'Sort by',
			'Последняя тренировка': 'The last activity date',
			'Заявки на вступление': 'Applications for membership',
			'Управление участниками': 'Managing participants',
			'Ссылка-приглашение': 'The invitation Link',
			'Ссылка-приглашение обновлена. Прежняя ссылка более невалидна.':
				'The invitation link has been updated. The previous link is no longer valid.',
			'Ссылка-приглашение скопирована': 'The invitation link has been copied',
			'Вступление через ссылку-приглашение': 'Joining via an invitation link',
			'Приватное событие': 'The sport event is private',
			'Вы не можете присоединиться к этой команде, так как команда приватная или событие закрытое и/или приватное.':
				'You cannot join this team because the team is private or the event is private.',
			'Вы уже являетесь членом этой команды.': 'You are already a member of this team.',
			'Ошибка. Вероятно команда не найдена. Обратитесь к администратору.':
				'Error. The command you entered was not found. Please contact the administrator.',
			'Все понятно': 'Got it',
			importingWorkouts4:
				'and then, manually upload the files to Impulse. Go to your profile to view the imported workouts. The new workouts will be automatically added to your profile. If this does not happen for some reason, please contact our support team.',
			importingWorkouts3: 'export option',
			importingWorkouts2: 'To import past workouts from Polar, you can use',
			importingWorkouts1:
				'We have started connecting your account. Your workouts will be automatically imported and will appear in your profile shortly. You can import your older Strava workouts from one month up to one year ago. For Polar, only the workouts completed after connecting the Polar account with Impulse can be imported.',
			'Импорт тренировок': 'Importing workouts',
			Удалить: 'Delete',
			'Вложение удалено': 'The attachment has been deleted',
			Создано: 'Created at',
			'У вас нет доступа к этой тренировке': `You don't have access to this workout`,
			Вложения: 'Attachments',
			'Запрос на валидацию тренировки администратором отправлен':
				'The request for validation by an administrator of the training session has been submitted',
			'Без вида спорта': 'Sport type is unknown',
			'Условия исключения': 'Exclusion conditions',
			'Условия участия': 'Terms of participation',
			'Условия принятия': 'Acceptance conditions',
			'Сжимаем файл...': 'Compressing file...',
			'Сохраняем фото...': 'Saving...',
			activityValidationSchema_name_max: 'The name is too long. Please enter between 1 and 256 characters',
			activityValidationSchema_name_required: 'The name is not specified',
			activityValidationSchema_approved_required: 'No value specified',
			activityValidationSchema_distance_max: 'The distance is too large',
			activityValidationSchema_distance_required: 'The distance is not specified. If not required, specify 0',
			activityValidationSchema_movingTime_max: 'Moving time value is too high',
			activityValidationSchema_movingTime_required: 'The time is not specified. If not required, specify 0',
			activityValidationSchema_averageTempo_max: 'The tempo is too high',
			activityValidationSchema_averageTempo_required: 'The tempo is not specified. If not required, specify 0',
			activityValidationSchema_userMessage_max:
				'The description is too long. Please enter between 1 and 512 characters',
			activityValidationSchema_userMessage_required: 'The request description is not specified',
			activityValidationSchema_validationMessage_max:
				'The message is too long. Please enter up to 512 characters',
			activityValidationSchema_adminValidationMessage_max:
				'The message is too long. Please enter up to 256 characters',
			activityValidationSchema_sport_required: 'Specify the type of sport',
			activityValidationSchema_duplicatesActivityId_uuid: 'The ID must be in the UUID format',
			'Ваш отзыв': 'Your feedback',
			'Сумма в рублях': 'Amount in rubles',
			Продолжить0: 'Continue',
			Продолжить: 'Continue payment',
			Поддержать: 'Donate',
			donationText7:
				'We are delighted to have you as one of our clients and we wish you a great workout with effective recovery after each session!',
			donationText6:
				'We would greatly appreciate donations and support (and maybe we will stop using rainwater for coffee).',
			donationText5:
				'Do you have any comments, suggestions, or feedback for us? We would love to hear from you. We will take your input into consideration and try to improve based on your feedback.',
			donationText4: 'and a chat for communication',
			donationText3:
				'Our app is free to use and we encourage you to share it with your friends, enemies, and everyone who is not yet aware: our news channel',
			donationText2:
				'We work hard to provide you with a great Impulse Sports app that brings together all those who love to sweat and achieve their fitness goals.',
			donationText1: 'Hello there! We are a team of passionate night coders and cycling enthusiasts.',
			Актуальный: 'Actual',
			Дом: 'House',
			'Город не найден': 'City not found',
			Индекс: 'Index',
			Регион: 'Region',
			Квартира: 'Flat',
			Улица: 'Street',
			'Подключение аккаунта выполнено.': 'Account connection is completed.',
			invalid_grant: 'There is a problem with the access token, try reconnecting the source',
			'Произошла ошибка доступа к вашим Google Fit данным.':
				'An error has occurred while accessing your Google Fit data.',
			'км/чел': 'km/person',
			'Оригинальная дистанция, км': 'Original distance, km',
			Валидация: 'Validation',
			'Зачтено, км': 'Approved, km',
			Участник: 'Participant',
			'Клубная статистика': 'Club statistics',
			'Турнирная таблица для клубной статистики с клубным зачетом. В ней представлены все клубы Импульса.':
				'The standings for club statistics with a club score. All the Impulse clubs are represented in it.',
			'Ваше положение среди пользователей': 'Your position among users',
			'Всего километров': 'Total kilometers',
			'Перерассчитать очки за тренировки': 'Recalculate points for training',
			'Перерассчитать зачет': 'Recalculate standings',
			'Перерасчет запущен. Обновите страницу через несколько секунд, чтобы увидеть изменения.':
				'Recalculation has been started. Refresh the page after a few seconds to see the changes.',
			'Дата расчета': 'Last recalculated at',
			'Дата исключения': 'Date of exclusion',
			'Дата вступления': 'Date of entry',
			'Зачет команды в турнирной таблице': 'Team standings workouts',
			'Зачет клуба в турнирной таблице': 'Club standings workouts',
			'Зачет пользователя в турнирной таблице': 'User standings workouts',
			'Скачать диплом': 'Download the diploma',
			'Вы также достигли свою цель, поздравляем!': 'You have also achieved your goal, congratulations!',
			участников: 'participants',
			клубов: 'clubs',
			команд: 'teams',
			из2: 'out of',
			'Цель достигли': 'The goal was achieved by',
			'Предварительные результаты': 'Preliminary results',
			'Победители и лидеры не определены': 'The winners and leaders have not been determined',
			Отклонена: 'Rejected',
			Перейти: 'Click to open',
			'Вы запросили валидацию этой тренировки': 'You have requested validation of this workout',
			Событие: 'Event',
			'Дата проверки': 'Verification date',
			'Не выполнено': 'Not complete',
			среди: 'among',
			место: 'place',
			'Вы заняли': 'You took',
			'Участник занял': 'The participant took',
			мужчин: 'men',
			женщин: 'женщин',
			первое: 'first',
			второе: 'second',
			третье: 'третье',
			runInsteadOfRiding:
				'While the booster is active, all kilometers traveled by bike will be counted as kilometers of running.',
			doubleRidePoints: `While the booster is active, the team's cycling kilometers will be doubled.`,
			swimmingImprovement:
				'While the booster is active, every third kilometer of swimming brings an additional 10 points.',
			gentlemenDay: 'While the booster is active, all points earned by women are doubled.',
			morningExercises:
				'While the booster is active, all points earned from 5:00 to 7:59 local time will be doubled.',
			ironman:
				'While the booster is active, participants who practice in all sports will receive three times more points.',
			'Основная турнирная таблица для клуба': 'The main standings for the club',
			'Выбрать язык': 'Change language',
			'Значение должно быть меньше или равно': 'The value must be less than or equal',
			'Значение должно быть числом': 'The value must be a number',
			часы: 'hours',
			минуты: 'minutes',
			секунды: 'seconds',
			настройках: 'on the profile settings page',
			'Ошибка загрузки. Повторите попытку или обратитесь в поддержку. Проверьте статус подключения аккаунта в':
				'Download error. Try again or contact support. Check the account connection status',
			'Загрузка завершена успешно': 'Download completed successfully',
			'Загрузка в процессе': 'Download is in progress',
			'Запрос отправлен': 'Запрос отправлен',
			'Запрос синхронизации от': 'Download started at',
			'Вы отписались от оповещений, но вы всегда можете включить оповещения в настройках своего профиля!':
				'You have unsubscribed from notifications. However, you can always enable notifications in your account settings!',
			Отписаться: 'Unsubscribe',
			'Хотите отписаться от рассылки новостей Impulse?':
				'Would you like to stop receiving the Impulse newsletter?',
			'Вы успешно отписались от рассылок': 'You have successfully unsubscribed from the mailing lists',
			'Удалить фото': 'Delete profile photo',
			'Фото удалено': 'Profile photo has been deleted',
			'Обновить фото': 'Update profile photo',
			'Номинации турнира': 'Tournament nominations',
			tournamentEnded: 'The tournament is over! 🎉 You can see winners in the nominations now!',
			'Клуб не найден.': 'Club not found',
			'Наша команда': 'Participants',
			Name1: 'Name',
			Управление: 'Manage',
			'Данные цели не найдены': 'These goals were not found',
			'Статус цели': 'Goal status',
			'Статус вашей цели': 'Your goal status',
			'Поздравляем! Вы стали участником события.': 'Congratulations! You have become a participant in the event.',
			'Вы стали участником турнира !': 'You have become a participant in the tournament!',
			Участвовать: 'Participate',
			'Дата окончания': 'Ends at',
			'Дата начала': 'Starts at',
			'Окончание регистрации': 'Registration ends at',
			'Σ,км': 'Σ,km',
			ФИО: 'Name',
			'Имя участника': 'Participant',
			'Ваш клуб': 'Your club',
			'Количество участников': 'Number of participants',
			'Кол-во участников': 'Number of participants',
			Соревнование: 'Competition',
			'Лидеры недели': 'Leaders of the Week',
			'Победители определены!': 'The winners are determined!',
			'Победители пока не определены. Лидируют:':
				'The winners have not yet been determined. They are in the lead:',
			'Сумма, км (Σ)': 'Distance sum, km (Σ)',
			'Среднее, км (км/чел)': 'Average, km (km/user)',
			'Активность (%)': 'Activeness (%)',
			'Всего тренировок': 'Total number of activities',
			неделя: 'week',
			'Статистика за неделю': 'Statistics for the week',
			'Последние тренировки не найдены': 'Recent workouts not found',
			'Недавние тренировки участников': 'Recent workouts of the participants',
			'Вступление в клуб через подачу заявки на вступление':
				'Joining the club by applying request for membership',
			'Любой пользователь может вступить в клуб': 'Any user can join the club',
			notTeamMemberT2:
				'Use the "Select a team" button on the tournament page, and then the "Join" button next to the team, or join the team using a private link, if you have one.',
			notTeamMemberT1:
				'You joined the tournament, but did not join the team. This is a team competition, in order to participate in it, you need to choose and join one of the teams.',
			'Вы не в команде!': 'You are not team member!',
			'Поздравляем! Вы стали участником команды': 'Congratulations! You have become a member of the team',
			'Переадресация на страницу оплаты...': 'Redirection to the payment page...',
			'Введите сумму от': 'Enter amount from',
			'Введите численное значение': 'Enter a numeric value',
			до: 'to',
			'Cумма от': 'The amount from',
			'Перейти к оплате': 'Proceed to payment',
			'Фиксированная стоимость': 'Fixed price',
			'в событии': 'in event',
			'Вы уверены, что хотите стать участником команды': 'Are you sure you want to become a member of the team',
			'Вступить в команду': 'Join the team',
			'Поздравляем! Вы стали участником клуба.': 'Congratulations! You have become a member of the club.',
			joinClubConfirmation:
				'Do you really want to become a member of the club? A user can be a member of only one club.',
			joinClubRLConfirmation: 'Are you sure you want to become a member of the club using the invitation link?',
			Вступить: 'Join',
			'Найти клуб': 'Join a club',
			'Вступить в клуб': 'Join the club',
			'Вы не являетесь членом клуба': 'You are not a member of any club',
			'Все клубы': 'All clubs',
			'Мой клуб': 'My club',
			'Статистика клубов': 'Club statistics',
			'Данные турнирной таблицы не найдены': 'Standings not found',
			'Показать мою команду': 'Show my team',
			'Показать мой клуб': 'Show my club',
			'Показать меня': 'Show me',
			'ID дублируемой тренировки': 'Duplicate activity ID',
			duplicateWarning2:
				'If, on the contrary, you cancel the duplication, you will need to repeat the auto-validation and/or perform manual validation.',
			duplicateWarning1:
				'If you make a workout a duplicate of another workout, it will be removed from ALL standings',
			'Внимание!': 'Attention!',
			'Описание запроса': 'Request description',
			requestActivityValidationConfirm:
				'Do you really want this workout to be reviewed by an administrator? Administrators primarily check the workouts that have received the most validation requests from users.',
			'Выбрать файл': 'Select file',
			'Выбрать файлы': 'Select files',
			'Лыжи/Сноуборд': 'Skiing/Snowboarding',
			'Изменить дублирование': 'Alter duplication',
			'Изменить данные дублирования': 'Alter duplication data',
			'Повторить автовалидацию': 'Restart autovalidation',
			'Запросить валидацию администратором': 'Request validation by the administrator',
			'Запросить валидацию тренировки администратором': 'Request activity validation by the administrator',
			'Добавить вложение': 'Add attachment',
			Изменить: 'Edit',
			'Валидация перезапущена, перезагрузите страницу через несколько секунд':
				'Validation process is restarted, reload the page in a few seconds',
			Подтвердить: 'Confirm',
			changeSportTypeInfo:
				'Attention! If you change the type of training sport, it will be removed from the standings that do not contain the new sport and then added to the standings with suitable sports',
			'Изменить данные тренировки': 'Edit activity data',
			'Валидация отклонена': 'Validation is rejected',
			Активна: 'Active',
			Неактивна: 'Not active',
			Зачтена: 'Approved',
			'У этой тренировки нет карты': `This workout doesn't have a map`,
			'Карта тренировки': 'Activity map',
			Пользователь: 'The user',
			'Приоритет (кол-во запросов на валидацию)': 'Priority (number of validation requests)',
			'Валидация тренировки': 'Activity validation',
			Базовый: 'Base',
			зачтено: 'approved',
			'Тренировка зачтена в следующих турнирных таблицах': 'The training is counted in the following standings',
			'Тренировка зачтена?': 'Is activity approved?',
			'Сообщение ответа': 'Response message',
			Выполнил: 'Performed by',
			Запросил: 'Requested by',
			'Сообщение запроса': 'Request message',
			'Начало действия': 'Start date',
			'Окончание действия': 'End date',
			Сплиты: 'Splits',
			'Нет тренировок в зачете': 'There is no training in the standings',
			'Тренировок за все время': 'Training sessions for all time',
			'Суммарная зачтенная дистанция': 'Total distance approved',
			'Тренировок зачтенных полностью': 'Number of activities fully approved',
			'Скорректированных и зачтенных тренировок': 'Number of activities corrected & approved',
			'Незачтенных тренировок': 'Number of rejected activities',
			'Количество тренировок': 'Number of activities',
			'Кол-во тренировок, Бег': 'Activities num, Running',
			'Кол-во тренировок, Ходьба': 'Activities num, Walking',
			'Кол-во тренировок, Велосипед': 'Activities num, Biking',
			'Кол-во тренировок, Плавание': 'Activities num, Swimming',
			'Кол-во тренировок, Лыжи': 'Activities num, Skiing',
			Выполнено: 'Completed',
			Осталось: 'Left',
			'Сообщение пользователю': 'Message to the user',
			'км/ч': 'km/h',
			Отклонен: 'Rejected',
			Зачтен: 'Approved',
			'Зачтен?': 'Is approved?',
			'Зачтенные данные': 'Approved data',
			'Темп, мин/км': 'Tempo, min/km',
			'Оригинальные данные тренировки': 'Original training data',
			'Дата последней валидации': 'Date of the last validation',
			'Один или более сплитов не прошли валидацию': 'One or more splits failed validation',
			'Все сплиты успешно прошли валидацию': 'All of the splits have been successfully validated',
			'Статус сплитов': 'Splits status',
			'Количество сплитов': 'Number of splits',
			'Средняя скорость': 'Average speed',
			'Полное время': 'Elapsed time',
			'Зачтенное время': 'Validated duration',
			'Набор высоты, м': 'Elevation gain, m',
			'Максимальная скорость, м/с': 'Maximum speed, m/s',
			'Средний каденс': 'Average cadence',
			'Длительность, cек': 'Duration, sec',
			'Дата и время начала': 'Start date and time',
			'Тренировка создана и отправлена на проверку администратору':
				'The activity has been created and will be reviewed by administrator',
			'Список валидаций пуст': 'No validations found',
			Темп: 'Tempo',
			'Дата окончания действия валидации': 'Validation end date',
			'Дата начала действия валидации': 'Validation start date',
			'Уровень валидации': 'Validation level',
			'ID валидации': 'Validation ID',
			от: 'from',
			'Валидация администратором': 'Validation by the administrator',
			Автовалидация: 'Auto-validation',
			'Прочие валидации (прошлые или в ожидании)': 'Other validation (that are terminated or pending)',
			'Нет данных о валидации сплитов': 'There is no data on the validation of splits',
			метров: 'meters',
			'Средний темп': 'Average tempo',
			'Разница высот': 'Elevation difference',
			'Участок зачтен': 'Is split approved',
			Участок: 'Split',
			'Сообщение администратора': 'Message from the administrator',
			'Сообщение автовалидации': 'Auto-validation message',
			Время: 'Time',
			'Неразмеченная часть дистанции': 'The unmarked part of the distance',
			'Валидация для тренировки не найдена': 'Activity validation not found',
			'Дата валидации': 'Validation date',
			'Сообщение валидации': 'Validation message',
			'Ручная валидация администратором': 'Manual validation by the administrator',
			Автоматическая: 'Automatic',
			'Тип валидации': 'Type of validation',
			Нет: 'No',
			Да: 'Yes',
			'Тренировка зачтена': 'Is activity approved',
			'Основная валидация': 'Base validation',
			originalActivityText:
				'A duplicate workout that overlaps with this workout in time, but which was created in the system earlier and is therefore original one',
			'Оригинальная тренирвка': 'Original activity',
			'Дистанция добавленная администратором': 'The distance added by the administrator',
			Подробнее: 'Read more',
			'Тренировка загружена частично': 'The activity is partially downloaded',
			'Тренировка полностью загружена': 'The activity is fully downloaded',
			'Выполнение этой валидации администратором не требуется, актуальная валидация подтверждена':
				'The administrator does not need to perform this validation, the current validation has been confirmed',
			'Тренировка скорректирована и зачтена администратором':
				'The activity was corrected & approved by administrator',
			'Тренировка скорректирована и зачтена автоматически':
				'The activity was corrected and approved automatically by the system',
			'Тренировка зачтена администратором': 'The activity fully approved by admministrator',
			'Тренировка прошла автоматическую валидацию': 'The activity was fully approved automatically by system',
			'Тренировка отклонена администратором': 'The training was rejected by the administrator',
			'Тренировка не прошла автоматическую валидацию': 'The activity was rejected automatically by the system',
			'Валидация рассматривается. Изменения ожидают подтверждения администратором.':
				'The activity is being validated, changes are being made by administrator',
			'Тренировка ожидает валидации': 'The activity is pending validation',
			'Тренировка ожидает валидации, проверьте что задан вид спорта':
				'The activity is pending validation, check that sport type is set',
			'Показывать дубликаты': 'Show duplicates',
			'Не задан': 'Not set',
			'Не задана': 'Not set',
			'Создана вручную': 'Created manually',
			из: 'of',
			'Добавить вложения к тренировке': 'Upload attachments',
			'Загрузка файлов': 'File upload',
			Загрузка: 'Loading',
			Назад: 'Go back',
			Контакты: 'Contacts',
			'Об организации': 'About this organization',
			'Организация не найдена': 'Organization not found',
			Организация: 'Organization',
			'Показывать по': 'Rows per page',
			Источник: 'Source',
			'Статус загрузки': 'Fully downloaded status',
			'Статус валидации': 'Validation status',
			Длительность: 'Duration',
			Дата: 'Date',
			День: 'Day',
			Неделя: 'Week',
			'Время в движении': 'Moving time',
			Дистанция: 'Distance',
			'Дистанция, км': 'Distance, km',
			'Дистанция, м': 'Distance, m',
			'Вид спорта': 'Sport type',
			Название: 'Name',
			Награды: 'Rewards',
			standingsDataFixed:
				'The results of the standings are fixed: The structure and data of the standings cannot be changed.',
			клуб: 'club',
			команду: 'team',
			'родительскую сущность': 'parent entity',
			Информация: 'Information',
			Незачет: 'Rejected',
			'Не принята': 'Rejected',
			'Валидация не требовалась': 'Validation was not required',
			'Ожидает валидации': 'Awaits validation',
			'Частичный зачет': 'Partially passed',
			'Полный зачет': 'Fully passed',
			Турнир: 'Tournament',
			'Турнирная таблица': 'Standings',
			'Спортивное событие': 'Event',
			'Спортивный клуб': 'Club',
			'Основной - вид спорта': 'Basic - sport type',
			activityHasDuplicates: 'This activity has duplicates - workouts that overlap with this one in time',
			'Тренировка имеет дубликаты': 'This activity has duplicates',
			'Тренировка является дубликатом другой тренировки': 'The training is a duplicate of another activity',
			'Дубликаты тренировки': 'Activity duplicates',
			'Общий набор высоты': 'Elevation gain',
			'мин/км': 'min/km',
			км: 'km',
			'Зачтенная дистанция': 'Validated distance',
			'Оригинальная дистанция': 'Original distance',
			'Оригинальное полное время': 'Original elapsed time',
			'Оригинальное время (в движении)': 'Original moving time',
			'Средняя ЧСС': 'Avg heart rate',
			ЧСС: 'Avg heart rate',
			'Основная информация': 'Basic information',
			'Подробная информация о тренировке': 'Activity in details',
			'Тип зачета': 'Standings type',
			Клубный: 'Club',
			Командный: 'Team',
			Пользовательский: 'User',
			'Прием тренировок с': 'Accept activities from',
			'Начало приёма тренировок': 'Accept activities from',
			'Начало приёма': 'Accept activities from',
			'Прием тренировок по': 'Accept activities until',
			'Окончание приёма тренировок': 'Accept activities until',
			'Тренировки принимаются только с момента вступления пользователя в':
				'Activities are accepted only from the moment a user enters a',
			'Тренировки принимаются только с момента вступления пользователя в турнир':
				'Activities are accepted only from the moment a user enters a tournament',
			'Тренировки принимаются только с момента вступления пользователя в команду':
				'Training sessions are accepted only from the moment a user joins a team',
			'Окончание прёма': 'Accept activities until',
			'Событие завершено': 'The event is completed',
			'Дата окончания': 'End date',
			'О событии': 'About event',
			Организаторы: 'Organizers',
			Организатор: 'Organizer',
			'Вы участник': 'You are a participant',
			'Регистрация закрыта': 'Registration is closed',
			'Регистрация открыта': 'Registration is open',
			'Начало регистрации': 'Registration starts at',
			'Событие сейчас активно!': 'The event is currently active!',
			'Вы владелец': 'You are the owner',
			Владелец: 'The owner',
			Администратор: 'The administrator',
			Владелецы: 'The owners',
			'Турнир c виртуальными очками и бустерами': 'A tournament with virtual points and boosters',
			'Турнир c виртуальными очками': 'A tournament with virtual points',
			'Очки, заработанные командой': 'Points earned by the team',
			'Монет в кошельке команды': `Coins in the team's wallet`,
			Бустеры: 'Boosters',
			'Вы действительно хотите купить бустер': 'Do you really want to buy a booster',
			'командных монет': 'team coins',
			потратив: 'spending',
			монет: 'coins',
			'У вас недостаточно монет для покупки бустера': `You don't have enough coins to buy a booster`,
			'Не хватает ёще': 'Need more',
			'Бустер куплен!': 'The booster is purchased!',
			'Купить бустер': 'Buy a booster',
			Купить: 'Purchase',
			Стоимость: 'Price',
			Активирован: 'Activated at',
			'Будет деактивирован': 'Will be deactivated at',
			'Активен до': 'Is active until',
			'Будет активирован': 'Will be activated at',
			'Ни один спортсмен не найден': 'No athlete has been found',
			'Бег вместо велосипеда': 'Running instead of cycling',
			'Супер-ускорение на велопробег': 'Super-acceleration for a bike ride',
			'Примененный бустер': 'Applied booster',
			'Ускорение на плавание': 'Acceleration for swimming',
			'Джентльменский день': `Gentleman's Day`,
			'Ранние пташки': 'Early risers',
			'Железный человек': 'Iron man',
			'Нет бустера на завтра': 'There is no booster for tomorrow',
			'Бустер на завтра': 'Booster for tomorrow',
			'Выбрать бустер': 'Choose a booster',
			'Купить бустер может капитан команды.': 'The team captain can buy a booster.',
			'Продажа бустеров открыта!': 'Booster sales are open!',
			'Продажа бустеров будет доступна': 'Booster sales will be available at',
			'Количество очков за 1 км: ': 'Number of points per 1 km: ',
			'Очки, заработанные вами': 'Points earned by you',
			'О команде': 'About the team',
			'Вы капитан команды': 'You are the captain of the team',
			'Все команды': 'All teams',
			'Турнир с командным зачётом': 'This tournament is a team-based event',
			'Команды ещё не созданы': 'The teams have not yet been formed',
			'Вы ещё не состоите ни в одной команде.': 'You are not yet a member of any team.',
			'Выбрать команду.': 'Select a team.',
			'Командный зачёт': 'Team standings',
			'Личный зачёт': 'Individual standings',
			'Вы уже подали заявку': 'You have already submitted a request',
			'Ваша команда': 'Your team',
			'Приватная команда': 'Private team',
			'Публичная команда': 'Public team',
			'Приватный клуб': 'Private club',
			'О клубе': 'About club',
			Локации: 'Locations',
			Участников: 'Participants',
			'Виды спорта': 'Types of sports',
			'О проекте': 'About us',
			Возраст: 'Age',
			'Возрастная группа': 'Age group',
			'По убыванию': 'Descending',
			'По возрастанию': 'Ascending',
			Пол: 'Gender',
			Женщины: 'Women',
			Женский: 'Female',
			Мужчины: 'Men',
			Мужской: 'Male',
			Город: 'City',
			Тренировок: 'Activities',
			'За все время': 'All time',
			Бег: 'Running',
			'Бег, м': 'Running, m',
			'Бег, км': 'Running, km',
			Велосипед: 'Biking',
			'Велосипед, м': 'Biking, m',
			'Велосипед, км': 'Biking, km',
			Плавание: 'Swimming',
			'Плавание, м': 'Swimming, m',
			'Плавание, км': 'Swimming, km',
			Очки: 'Points',
			Монеты: 'Coins',
			Участники: 'Participants',
			'Активный бустер': 'Active booster',
			'Нет активного бустера': 'There is no active booster',
			'Имя команды': 'Team name',
			Лыжи: 'Skiing',
			'Лыжи, м': 'Skiing, m',
			'Лыжи, км': 'Skiing, km',
			Ходьба: 'Walking',
			'Ходьба, м': 'Walking, m',
			'Ходьба, км': 'Walking, km',
			'Суммарная дистанция, км': 'Summary distance, km',
			'Сумма, км': 'Sum, km',
			'Не задано': 'Unknown',
			'О себе': 'About me',
			Клуб: 'Club',
			Клубы: 'Clubs',
			Команды: 'Teams',
			Команда: 'Team',
			'Капитан команды': 'Team Captain',
			'Подробная информация': 'More information',
			'Приватный аккаунт': 'Private account',
			'Ваш одноклубник': 'Your clubmate',
			'Это вы': 'This is you',
			'Нет загруженных тренировок': 'No activities found',
			'Добавить тренировки': 'Add activities',
			Добавить: 'Add',
			Отмена: 'Cancel',
			Сохранить: 'Save',
			Игнорировать: 'Ignore',
			Ошибки: 'Errors',
			Ошибка: 'Error',
			Описание: 'Description',
			Создать: 'Create',
			Заявитель: 'Requestor',
			Закрыт: 'Closed',
			'Назначен на': 'Assigned to',
			'Создать клуб': 'Create club',
			'Создать свой клуб': 'Create your own club',
			'Создать событие': 'Create event',
			'Создать обращение в поддержку': 'Create support request',
			'Обращение в поддержку': 'Support request',
			'Создать обращение': 'Create request',
			'Ошибка загрузки': 'Upload error',
			'Размер файла не должен превышать': 'The file size should not exceed',
			'Неподдерживаемый формат, используйте JPEG или PNG': 'Unsupported format, use JPEG or PNG',
			'Последнее сообщение': 'Last message at',
			'Обращение не найдено': 'Support request not found',
			'Краткое описание': 'Short description',
			'Подробное описание': 'Detailed description',
			'Обращение в поддержку создано успешно!': 'The support request was created successfully!',
			'Превышена допустимая длина сообщения': 'The message is too long',
			'Сообщение слишком короткое': 'The message is too short',
			'Не заполнено сообщение': 'Please fill message field',
			longDescription1to256: 'The description text is too long, must be maximum 256 characters long',
			'Не указано описание': 'Please fill description field',
			Статус: 'Status',
			'Дата создания': 'Creation date',
			'Ваши обращения в поддержку': 'Your support requests',
			'Вы еще не создавали обращений в поддержку': `You haven't created any support requests yet`,
			'Перейти в настройки': 'Go to settings',
			Редактировать: 'Edit',
			'Поиск спортсменов': 'Athletes search',
			Поиск: 'Search',
			'Все время': 'All time',
			Год: 'Year',
			Месяц: 'Month',
			Период: 'Period',
			'Турнирные таблицы': 'Standings',
			'Клубные таблицы': 'Club standings',
			'Прочие турнирные таблицы': 'Other standings',
			'Статистика по видам спорта': 'Sport statistics',
			'Турнирная таблица по виду спорта “Лыжи”': 'The standings for the sport of “Skiing”',
			'Турнирная таблица по виду спорта “Ходьба”': 'The standings for the sport of “Walking”',
			'Турнирная таблица по виду спорта “Плавание”': 'The standings for the sport of “Swaimming”',
			'Турнирная таблица по виду спорта “Велосипед”': 'The standings for the sport of “Biking”',
			'Турнирная таблица по виду спорта “Бег”': 'The standings for the sport of “Running”',
			'Только мои': 'Only mine',
			'Только активные': 'Only active',
			'Скрыть таблицы статистики': 'Hide statistics standings',
			'Турнирные таблицы не найденцы.': 'Standings not found.',
			'События и турниры': 'Events & Tournaments',
			'События и турниры организации': 'Organized Events & Tournaments',
			'Моя страница': 'My profile',
			'Мой профиль': 'My profile',
			'Служба поддержки': 'Support Requests',
			'Поддержать проект': 'Donate',
			Пароль: 'Password',
			или: 'or',
			и: 'and',
			Войти: 'Sign in',
			'Готов к импульсу': 'Ready for impulse',
			'но забыл пароль': 'forgot password',
			'У тебя еще нет Импульса': 'Not registered',
			'Я все помню': 'I remember the password',
			'Отправить ссылку': 'Send link',
			'Забыли пароль': 'Forgot password',
			'введите регистрационную почту': 'enter your registration email',
			'Создайте пароль': 'Create password',
			'Уже есть аккаунт': 'Already have an account',
			'Нажимая на кнопку, ты принимаешь': 'Clicking on this button you accept',
			'политику конфиденциальности': 'privacy policy',
			'условия использования': 'terms of service',
			Регистрация: 'Register',
			Язык: 'Language',
			Справка: 'About us',
			Выйти: 'Logout',
			Настройки: 'Settings',
			'Заполните профиль': 'Fill in your profile',
			'Эти данные помогут нам корректно отображать вашу статистику и ваше положение в турнирных таблицах':
				'This data will help us to correctly display your statistics and your standings position',
			Имя: 'First name',
			Фамилия: 'Last name',
			'День рождения': 'Date of birth',
			'Дата рождения': 'Date of birth',
			Далее: 'Continue',
			'Загрузка GPX файлов': 'Upload GPX files',
			'Из источника': 'From activities source',
			'Синхронизировать все тренировки из источника': 'Download activities from source',
			Вручную: 'Manually',
			Синхронизировать: 'Start download',
			'Полная загрузка тренировок с': 'Full activity download from date',
			'Синхронизировать с даты': 'Download from date',
			'Синхронизировать до': 'Download until date',
			'Аккаунт Polar не подключен': 'Polar account is not set up',
			'Аккаунт Strava не подключен': 'Strava account is not set up',
			'Аккаунт Strava не подключен': 'Strava account is not set up',
			'Загрузить GPX файлы тренировок': 'Upload activities GPX files',
			'Подключение Polar аккаунта запущено.': 'Started Polar account setup.',
			'Подключение Strava аккаунта запущено.': 'Started Strava account setup.',
			Подключить: 'Connect',
			'Подключить в настройках профиля': 'Go to settings to connect',
			'Подключите источник данных ваших тренировок': 'Connect  your activities data source',
			'Узнать подробнее': 'Learn more',
			'Подключить Polar': 'Connect Polar',
			'Подключить Google Fit': 'Connect Google Fit',
			'Продолжить без подключения': 'Continue without activities data source',
			'Ваш ID': 'Your ID',
			Никнейм: 'Username',
			'Эл. почта': 'Email',
			'Учетная запись': 'Account settings',
			'Основные сведения': 'Main settings',
			'Соцсети и источники тренировок': 'Auth and activity sources',
			'Настройки приватности': 'Privacy settings',
			'Настройки отображения': 'Display settings',
			'Мои адреса': 'My addresses',
			'Мои контакты': 'My contacts',
			'Данные отсутствуют': 'No data',
			SocialConnectText: `Setting up Google, Yandex or VK is optional, it just makes sign in process easier`,
			ActivitySourceConnectText: `Setting up Strava, Polar and/or Google Fit is required for importing activities`,
			'ID Google аккаунта': 'Google account ID',
			'почта Google': 'Google email',
			'ID VK аккаунта': 'VK account ID',
			'почта VK': 'VK email',
			'ID Яндекс аккаунта': 'Yandex account ID',
			'почта Яндекс': 'Yandex email',
			'Ошибка подключения, попробуйте подключить повторно': 'Connection error, please try again',
			'Ошибка при подключении источника тренировок': 'Error connection to activities source',
			'обратитесь в поддержку': 'create support request',
			'ID Strava аккаунта': 'Strava account ID',
			'ID Polar аккаунта': 'Polar account ID',
			'Отвязать аккаунт': 'Disconnect account',
			'Telegram аккаунт успешно откреплен.': 'Telegram has been successfully disconnected.',
			'Strava аккаунт успешно откреплен.': 'Strava has been successfully disconnected.',
			'Аккаунт успешно откреплен.': 'Account has been disconnected.',
			'Аккаунт подключен, включен импорт тренировок': 'Account connected, activities import is turned on',
			'Аккаунт подключен, импорт тренировок выключен': 'Account connected, but activity import is disabled.',
			privacyAccountText:
				'Private account let you hide your activities data from other users, except your clubmates and administrators',
			ВКонтакте: 'VK',
			Яндекс: 'Yandex',
			'Данные сохранены': 'Data saved',
			emailVerification: 'Email verification',
			'Темная тема': 'Dark theme',
			'Ошибка синхронизации': 'Download error',
			syncStartMessage:
				'Started downloading your activities. When complete, your activities will be visible on your profile page. Download process can take up to several seconds',
			onVerifyDatesError1: 'Download until date must be later than download from date',
			onVerifyDatesError2: 'You can download activities only for half a year at one time',
			useAddressesText:
				'Your addresses are visible only for Impulse administrators. We may use it for sending prized, e.g. medals.',
			'Адрес успешно сохранен': 'Address saved successfully',
			'Не задан город': 'City not set',
			'Не задан дом': 'House number not set',
			'Введите адрес': 'Enter address',
			userContactsText:
				'This data is visible to administrators only. We can use it to contact regardning your support requests or prizes delivery.',
			'Мобильный телефон': 'Mobile number',
			'Номер телефона': 'Mobile number',
			// User exceptions
			USER_ALREADY_EXISTS: 'Such user already exists.',
			USER_NOT_FOUND: 'User not found.',
			USER_ALREADY_LOGGED_IN: 'User already logged in.',
			USER_ALREADY_REGISTERED: 'User already registered.',
			ACCOUNT_JOIN_REQUIRED: 'User with provided email exists. Join account to proceed.',
			ACCOUNTS_ALREADY_JOINED: 'Accounts already joined.',
			INVALID_BIRTH_DATE: 'Minimum age is 13 years old.',
			USER_OLD_PASSWORD_INCORRECT: 'Incorrect password',
			// Support Request
			TOO_MUCH_SUPPORT_REQUESTS_FROM_USER: 'You have already created support request today. Try again later.',
			// Activity
			ACTIVITIES_QUEUE_TOO_MUCH_REQUESTS: 'Cannot run manual sync yet. Try again later.',
			// Social exceptions
			ERROR_LOADING_SOCIAL_DATA:
				'Unable to load authentication data from social network. Please try another login option.',
			NO_DATA_FROM_SOCIAL: 'Unable to load user data from social network.',
			NO_STRAVA_TOKEN: 'User does not have Strava refresh token.',
			ACCOUNT_ALREADY_BOUND: 'Social account ID already bound to user who is trying to bind it',
			ACCOUNT_ALREADY_BOUND_ANOTHER_USER: 'Social account ID is already bound to another user', //
			ANOTHER_STRAVA_ACCOUNT_ACTIVITIES_EXIST:
				'Case whe user is trying to bind another strava account, but still has activities from another one',
			// Auth exceptions
			INVALID_EMAIL_OR_PASSWORD: 'Invalid email or password.',
			INVALID_USERNAME_OR_PASSWORD: 'Invalid username or password.',
			INVALID_PHONE_OR_PASSWORD: 'Invalid phone number or password.',
			// Role exceptions
			ROLE_ALREADY_EXISTS: 'Such role already exists.',
			ROLE_NOT_FOUND: 'Role not found.',
			ROLE_ALREADY_ASSIGNED: 'Role already assigned to user.',
			// Verification exceptions
			VERIFICATION_ALREADY_COMPLETE: 'Verification already complete.',
			VERIFICATION_NOT_FOUND: 'Verification not found.',
			VERIFICATION_REQUIRED: 'Please verify you account to proceed.',
			VERIFICATION_NOT_COMPLETE: 'Verification process is not complete.',
			INVALID_PAYLOAD_OR_KEY: 'Invalid key provided.',
			VERIFICATION_INVALIDATED: 'Key expired or maximum number of attempts reached.',
			MAX_NUM_OF_ATTEMPTS: 'Maximum number of attempts reached. Please restart verification.',
			VERIFICATION_TIMED_OUT: 'Verification timed out. Please restart verification.',
			WAIT_BEFORE_RETRY_VERIFICATION: 'You need to wait before restarting verification.',
			SPORT_CLUB_USER_JOIN_LIMIT_REACHED: 'You can join club only once per month and four times per year.',
			// Standings
			STANDINGS_REFRESH_TOO_MUCH_REQUESTS: 'Too much requests for standings refresh.',
			// Sport Club
			SPORT_CLUB_JOIN_LINK_INCORRECT:
				'The entrance to the club via the link is disabled or the link is incorrect',
			GOOGLE_FIT_API_ACCESS_ERROR:
				'Error receiving data from Google Fit. Check the account status in the your profile settings.',
			TEAM_NOT_FOUND: 'Team not found',
			TEAM_ALREADY_EXISTS: 'Team already exists with such data',
			USER_IS_ALREADY_TOURNAMENT_TEAM_MEMBER: 'You are already member of the team',
			TEAM_USER_JOIN_VIA_LINK_DISABLED: 'Join via link is disabled',
			TEAM_USER_JOIN_VIA_CODE_DISABLED: 'Join via code is disabled',
			TEAM_IS_PRIVATE: 'You cannot join this team via public link',
			TEAM_JOIN_LINK_INCORRECT: 'Incorrect link url',
			TEAM_JOIN_CODE_INCORRECT: 'Incorrect code',
			TEAM_JOIN_CODE_UNACCEPTABLE: 'It is not possible to use this value for code. Try something else.',
			TEAM_PARTICIPATION_IS_PAID: 'Participation is paid',
			TEAM_PARTICIPATION_IS_NOT_PAID: 'Participation is not paid',
			TEAM_PAID_PARTICIPATION_INVALID_AMOUNT: 'Invalid amount',
			TEAM_MAX_PARTICIPANTS_LIMIT_REACHED: `The team already has the maximum possible number of users. You can't join.`,
			ACTIVITY_ATTACHMENTS_LIMIT_REACHED: `The maximum number of attachments reached`,
			TELEGRAM_JOIN_ERROR: `Error while joining telegram`,
			NOTIFICATION_TEMPLATE_UNKNOWN_VARIABLE:
				'One of the variables mentioned in template does not exist. It must be created first.',
		},
	},
	ru: {
		translation: {
			'Новое сообщение от поддержки по обращению': 'Новое сообщение от поддержки по обращению',
			'Непрочитанных уведомлений': 'Непрочитанных уведомлений',
			'Вернуться к списку уведомлений': 'Вернуться к списку уведомлений',
			Уведомление: 'Уведомление',
			Прочитано: 'Прочитано',
			'Уведомление не прочитано': 'Уведомление не прочитано',
			'Пометить как прочитанное': 'Пометить как прочитанное',
			'Пометить все как прочитанные': 'Пометить все как прочитанные',
			'Список уведомлений': 'Список уведомлений',
			'Ваш браузер не поддерживает оповещения. Вы все равно будете их получать, если включите, но без всплывающих уведомлений.':
				'Ваш браузер не поддерживает оповещения. Вы все равно будете их получать, если включите, но без всплывающих уведомлений.',
			'Настройки уведомлений': 'Настройки уведомлений',
			'Смотреть все уведомления': 'Смотреть все уведомления',
			'Недавние уведомления': 'Недавние уведомления',
			'Здесь пока ничего нет': 'Здесь пока ничего нет',
			'Уведомления в браузере разрешены.': 'Уведомления в браузере разрешены.',
			'Уведомления в браузере запрещены.': 'Уведомления в браузере запрещены.',
			'чтобы мы спросили у вас разрешение на включение оповещение, затем нажмите "Разрешить".':
				'чтобы мы спросили у вас разрешение на включение оповещение, затем нажмите "Разрешить".',
			Нажмите: 'Нажмите',
			'Для того, чтобы получать уведомления в браузере, их нужно разрешить.':
				'Для того, чтобы получать уведомления в браузере, их нужно разрешить.',
			'Пуш-уведомление': 'Пуш-уведомление',
			'По email': 'По email',
			'Рассылка с новостями Импульса': 'Рассылка с новостями Импульса',
			'Оповещение о создании и зачете новой тренировки': 'Оповещение о создании и зачете новой тренировки',
			'Настройки уведомлений': 'Настройки уведомлений',
			'Некорректный email': 'Некорректный email',
			'Нету кода?': 'Нету кода?',
			'Email адрес успешно подтверждён': 'Email адрес успешно подтверждён',
			'Введите код подтверждения, который был отправлен на указанный email':
				'Введите код подтверждения, который был отправлен на указанный email',
			'Ожидает подтверждения': 'Ожидает подтверждения',
			'Подтвердить email': 'Подтвердить email',
			Подтверждён: 'Подтверждён',
			'Отправить код повторно': 'Отправить код повторно',
			'Отправить код повторно можно через': 'Отправить код повторно можно через',
			'Проверочный код': 'Проверочный код',
			'Подтвердите, что e-mail принадлежит вам, чтобы обезопасить свою учетную запись.':
				'Подтвердите, что e-mail принадлежит вам, чтобы обезопасить свою учетную запись.',
			'Мы отправили 6-значный код на': 'Мы отправили 6-значный код на',
			'Подтверждение регистрации': 'Подтверждение регистрации',
			'Подтвердите пароль': 'Подтвердите пароль',
			'Введите новый пароль': 'Введите новый пароль',
			'Вы действительно хотите покинуть клуб?': 'Вы действительно хотите покинуть клуб?',
			'Покинуть клуб': 'Покинуть клуб',
			'Вы подали заявку': 'Вы подали заявку',
			Покинуть: 'Покинуть',
			'Вы больше не являетесь участником клуба.': 'Вы больше не являетесь участником клуба.',
			'Тренировки за данный промежуток времени не найдены': 'Тренировки за данный промежуток времени не найдены',
			'Поставить лайк': 'Поставить лайк',
			'Убрать лайк': 'Убрать лайк',
			'Поставить дислайк': 'Поставить дислайк',
			'Убрать дислайк': 'Убрать дислайк',
			createActivityManualFormDatePickerAlert:
				'Тренировка не должна пересекаться по времени с другими вашими тренировками, иначе она будет помечена как дубликат и не будет зачтена в турнирные таблицы.',
			'Удаление тренировки повлечёт за собой перерасчёт вашего зачёта всех активных турнирных таблиц, в которых она зачтена.':
				'Удаление тренировки повлечёт за собой перерасчёт вашего зачёта всех активных турнирных таблиц, в которых она зачтена.',
			'Вы уверены, что хотите удалить тренировку': 'Вы уверены, что хотите удалить тренировку',
			'Удалить тренировку': 'Удалить тренировку',
			'Тренировка удалена': 'Тренировка удалена',
			'Обнаружена новая версия приложения, загружаем...': 'Обнаружена новая версия приложения, загружаем...',
			createAcitivityManualFormFileAttachmentsHelper:
				'Вложения должны быть картинкой формата PNG/JPG. Вы можете добавить скриншот вашей тренировки из приложения, которым её записывали. Так администратор сможет понять, что это валидная тренировка, когда будет её проверять.',
			'Количество файлов не должно быть больше 5': 'Количество файлов не должно быть больше 5',
			'Максимум 5 файлов': 'Максимум 5 файлов',
			'Выберите событие': 'Выберите событие',
			'Выберите клуб': 'Выберите клуб',
			'Выберите турнирную таблицу': 'Выберите турнирную таблицу',
			'Выберите турнир события': 'Выберите турнир события',
			'Изменить уровень валидации': 'Изменить уровень валидации',
			'Изменить уровень валидации': 'Изменить уровень валидации',
			createActivityValidationSchema_name_min: 'Слишком короткое название. Как минимум один символ',
			createActivityValidationSchema_name_max:
				'Слишком длинное название. Пожалуйста, введите от 1 до 256 символов',
			createActivityValidationSchema_name_required: 'Не указано название',
			createActivityValidationSchema_description_max:
				'Слишком длинное описание. Пожалуйста, введите от 0 до 512 символов',
			createActivityValidationSchema_distance_type: 'Дистанция должна быть числом',
			createActivityValidationSchema_distance_min: 'Слишком маленькая дистанция',
			createActivityValidationSchema_distance_max: 'Слишком большая дистанция',
			createActivityValidationSchema_distance_required: 'Не указана дистанция. Если не требуется - укажите 0',
			createActivityValidationSchema_startDate_type: 'Укажите дату начала тренировки',
			createActivityValidationSchema_startDate_required: 'Не указана дата начала тренировки',
			createActivityValidationSchema_elapsedTime_type: 'Длительность должна быть числом',
			createActivityValidationSchema_elapsedTime_min: 'Слишком маленькая длительность тренировки',
			createActivityValidationSchema_elapsedTime_max: 'Слишком большая длительность тренировки',
			createActivityValidationSchema_elapsedTime_required: 'Не указана длительность тренировки',
			createActivityValidationSchema_averageCadence_type: 'Каденс должен быть числом',
			createActivityValidationSchema_averageCadence_max: 'Слишком большой каденс',
			createActivityValidationSchema_maxSpeed_type: 'Максимальная скорость должна быть числом',
			createActivityValidationSchema_maxSpeed_max: 'Слишком большая скорость. Максимум 30 м/с',
			createActivityValidationSchema_avgHeartRate_type: 'ЧСС должна быть числом',
			createActivityValidationSchema_avgHeartRate_max: 'Слишком большой ЧСС. Укажите до 200 ударов в минуту',
			createActivityValidationSchema_elevation_type: 'Набор высоты должен быть числом',
			createActivityValidationSchema_elevation_max: 'Слишком большой набор высоты, максимум 3000 метров',
			createActivityValidationSchema_sport_required: 'Укажите вид спорта',
			'Подробности на странице тренировки.': 'Подробности на странице тренировки.',
			'Наведите на кружок, чтобы увидеть подробности': 'Наведите на кружок, чтобы увидеть подробности',
			'на уровне вида спорта': 'на уровне вида спорта',
			'на уровне вида события': 'на уровне вида события',
			'Вы не можете просматривать данные приватного аккаунта':
				'Вы не можете просматривать данные приватного аккаунта',
			'Код для входа в приватную команду': 'Код для входа в приватную команду',
			'Команда приватная или находится в приватном или закрытом событии':
				'Команда приватная или находится в приватном или закрытом событии',
			'Введите приватный код для входа в команду': 'Введите приватный код для входа в команду',
			'Турнир события не найден, обратитесь к администратору':
				'Турнир события не найден, обратитесь к администратору',
			'Невозможно присоединиться к приватному событию по публичной ссылке':
				'Невозможно присоединиться к приватному событию по публичной ссылке',
			'Событие не найдено': 'Событие не найдено',
			'Команда не найдена': 'Команда не найдена',
			'Ошибка при загрузке данных': 'Ошибка при загрузке данных',
			'У вас нет доступа к этой турнирной таблице': 'У вас нет доступа к этой турнирной таблице',
			'Отобрать капитанство': 'Отобрать капитанство',
			'Сделать капитаном': 'Сделать капитаном',
			'Выдать права': 'Выдать права',
			'Вы уверены, что хотите исключить пользователя из': 'Вы уверены, что хотите исключить пользователя из',
			клуба: 'клуба',
			команды: 'команды',
			'Исключить пользователя из': 'Исключить пользователя из',
			Исключить: 'Исключить',
			Отклонить: 'Отклонить',
			Принять: 'Принять',
			Отозвать: 'Отозвать',
			'Заявка на вступление в клуб отозвана': 'Заявка на вступление в клуб отозвана',
			'Подать заявку': 'Подать заявку',
			'Заявка отправлена': 'Заявка отправлена',
			'Нет активных заявок': 'Нет активных заявок',
			'Заявка принята! Пользователь добавлен в клуб.': 'Заявка принята! Пользователь добавлен в клуб.',
			'Заявка пользователя отклонена.': 'Заявка пользователя отклонена.',
			'Контакты клуба': 'Контакты клуба',
			'Правила клуба': 'Правила клуба',
			'Профайл клуба': 'Профайл клуба',
			'Сортировать по': 'Сортировать по',
			'Последняя тренировка': 'Последняя тренировка',
			'Заявки на вступление': 'Заявки на вступление',
			'Управление участниками': 'Управление участниками',
			'Ссылка-приглашение': 'Ссылка-приглашение',
			'Ссылка-приглашение обновлена. Прежняя ссылка более невалидна.':
				'Ссылка-приглашение обновлена. Прежняя ссылка более невалидна.',
			'Ссылка-приглашение скопирована': 'Ссылка-приглашение скопирована',
			'Вступление через ссылку-приглашение': 'Вступление через ссылку-приглашение',
			'Приватное событие': 'Приватное событие',
			'Вы не можете присоединиться к этой команде, так как команда приватная или событие закрытое и/или приватное.':
				'Вы не можете присоединиться к этой команде, так как команда приватная или событие закрытое и/или приватное.',
			'Вы уже являетесь членом этой команды.': 'Вы уже являетесь членом этой команды.',
			'Ошибка. Вероятно команда не найдена. Обратитесь к администратору.':
				'Ошибка. Вероятно команда не найдена. Обратитесь к администратору.',
			'Все понятно': 'Все понятно',
			importingWorkouts4:
				'а затем вручную загрузить файлы в Impulse. Перейдите в свой профиль, чтобы увидеть импортированные тренировки. Новые тренировки будут появляться в вашем профиле автоматически. Если по какой-то причине это не происходит, просим обратиться в поддержку.',
			importingWorkouts3: 'опцию экспорта,',
			importingWorkouts2: 'Для импорта прошлых тренировок из Polar можно использовать',
			importingWorkouts1:
				'Подключение вашего аккаунта запущено успешно. Тренировки будут импортированы автоматически и появятся в вашем профиле через некоторое время. Для Strava тренировки импортируются за некоторое время (от месяца до года), для Polar будут импортироваться тренировки только совершенные после подключения аккаунта Polar к Impulse.',
			'Импорт тренировок': 'Импорт тренировок',
			Удалить: 'Удалить',
			'Вложение удалено': 'Вложение удалено',
			Создано: 'Создано',
			'У вас нет доступа к этой тренировке': 'У вас нет доступа к этой тренировке',
			Вложения: 'Вложения',
			'Запрос на валидацию тренировки администратором отправлен':
				'Запрос на валидацию тренировки администратором отправлен',
			'Без вида спорта': 'Без вида спорта',
			'Условия исключения': 'Условия исключения',
			'Условия участия': 'Условия участия',
			'Условия принятия': 'Условия принятия',
			'Сжимаем файл...': 'Сжимаем файл...',
			'Сохраняем фото...': 'Сохраняем фото...',
			activityValidationSchema_name_max: 'Слишком длинное название. Пожалуйста, введите от 1 до 256 символов',
			activityValidationSchema_name_required: 'Не указано название',
			activityValidationSchema_approved_required: 'Не указано значение',
			activityValidationSchema_distance_max: 'Слишком большая дистанция',
			activityValidationSchema_distance_required: 'Не указана дистанция. Если не требуется - укажите 0',
			activityValidationSchema_movingTime_max: 'Слишком большое время',
			activityValidationSchema_movingTime_required: 'Не указано время. Если не требуется - укажите 0',
			activityValidationSchema_averageTempo_max: 'Слишком большой темп',
			activityValidationSchema_averageTempo_required: 'Не указан темп. Если не требуется - укажите 0',
			activityValidationSchema_userMessage_max:
				'Слишком длинное описание. Пожалуйста, введите от 1 до 512 символов',
			activityValidationSchema_userMessage_required: 'Не указано описание запроса',
			activityValidationSchema_validationMessage_max:
				'Слишком длинное сообщение. Пожалуйста, введите до 512 символов',
			activityValidationSchema_adminValidationMessage_max:
				'Слишком длинное сообщение. Пожалуйста, введите до 256 символов',
			activityValidationSchema_sport_required: 'Укажите вид спорта',
			activityValidationSchema_duplicatesActivityId_uuid: 'ID должен быть формата UUID',
			'Ваш отзыв': 'Ваш отзыв',
			'Сумма в рублях': 'Сумма в рублях',
			Продолжить: 'Продолжить',
			Продолжить0: 'Продолжить',
			Поддержать: 'Поддержать',
			donationText7:
				'Рады видеть вас в рядах своих клиентов и желаем вам крутых тренировок с эффективным восстановлением после каждой из них!',
			donationText6:
				'Донаты и поддержка принимаются с радостью (и, возможно, мы перестанем варить кофе на дождевой воде).',
			donationText5:
				'Хотите похвалить нас, поругать или предложить улучшения? Пишите сюда, и мы примем все к сведению.',
			donationText4: 'и чат для общения',
			donationText3:
				'Приложение бесплатное, распространение приветствуется! Делитесь с друзьями, врагами и всеми, кто еще не в курсе: наш канал с новостями',
			donationText2:
				'Мы жертвуем сном и выходными, чтобы вы могли пользоваться нашим спортивным приложением «Импульс», созданным для объединения всех, кто любит потеть и добиваться результатов.',
			donationText1: 'Привет от нашей команды ночных кодеров и фанатов циклических видов спорта!',
			Актуальный: 'Актуальный',
			Дом: 'Дом',
			'Город не найден': 'Город не найден',
			Индекс: 'Индекс',
			Регион: 'Регион',
			Квартира: 'Квартира',
			Улица: 'Улица',
			'Подключение аккаунта выполнено.': 'Подключение аккаунта выполнено.',
			invalid_grant: 'Проблема с токеном доступа, попробуйте переподключить источник',
			'Произошла ошибка доступа к вашим Google Fit данным.':
				'Произошла ошибка доступа к вашим Google Fit данным.',
			'км/чел': 'км/чел',
			'Оригинальная дистанция, км': 'Оригинальная дистанция, км',
			Валидация: 'Валидация',
			'Зачтено, км': 'Зачтено, км',
			Участник: 'Участник',
			'Клубная статистика': 'Клубная статистика',
			'Турнирная таблица для клубной статистики с клубным зачетом. В ней представлены все клубы Импульса.':
				'Турнирная таблица для клубной статистики с клубным зачетом. В ней представлены все клубы Импульса.',
			'Ваше положение среди пользователей': 'Ваше положение среди пользователей',
			'Всего километров': 'Всего километров',
			'Перерассчитать очки за тренировки': 'Перерассчитать очки за тренировки',
			'Перерассчитать зачет': 'Перерассчитать зачет',
			'Перерасчет запущен. Обновите страницу через несколько секунд, чтобы увидеть изменения.':
				'Перерасчет запущен. Обновите страницу через несколько секунд, чтобы увидеть изменения.',
			'Дата расчета': 'Дата расчета',
			'Дата исключения': 'Дата исключения',
			'Дата вступления': 'Дата вступления',
			'Зачет клуба в турнирной таблице': 'Зачет клуба в турнирной таблице',
			'Зачет пользователя в турнирной таблице': 'Зачет пользователя в турнирной таблице',
			'Скачать диплом': 'Скачать диплом',
			'Вы также достигли свою цель, поздравляем!': 'Вы также достигли свою цель, поздравляем!',
			участников: 'участников',
			клубов: 'клубов',
			команд: 'команд',
			из2: 'из',
			'Цель достигли': 'Цель достигли',
			'Предварительные результаты': 'Предварительные результаты',
			'Победители и лидеры не определены': 'Победители и лидеры не определены',
			Отклонена: 'Отклонена',
			Перейти: 'Перейти',
			'Вы запросили валидацию этой тренировки': 'Вы запросили валидацию этой тренировки',
			Событие: 'Событие',
			'Дата проверки': 'Дата проверки',
			'Не выполнено': 'Не выполнено',
			среди: 'среди',
			место: 'место',
			'Вы заняли': 'Вы заняли',
			'Участник занял': 'Участник занял',
			мужчин: 'мужчин',
			женщин: 'женщин',
			первое: 'первое',
			второе: 'второе',
			третье: 'третье',
			runInsteadOfRiding:
				'Во время действия бустера, все километры, проеханные на велосипеде, будут засчитаны как километры бега.',
			doubleRidePoints: 'Во время действия бустера, километры команды, проеханные на велосипеде, будут удвоены.',
			swimmingImprovement:
				'Во время действия этого бустера, каждый третий километр плавания приносит дополнительные 10 очков.',
			gentlemenDay: 'Во время действия этого бустера, все очки, заработанные женщинами, удваиваются.',
			morningExercises:
				'Во время действия этого бустера, все очки, заработанные с 5:00 до 7:59 по местному времени, умножатся вдвое.',
			ironman:
				'Во время действия этого бустера, участники, которые потренируются во всех видах спорта, получат в три раза больше очков.',
			'Основная турнирная таблица для клуба': 'Основная турнирная таблица для клуба',
			'Выбрать язык': 'Выбрать язык',
			'Значение должно быть меньше или равно': 'Значение должно быть меньше или равно',
			'Значение должно быть числом': 'Значение должно быть числом',
			часы: 'часы',
			минуты: 'минуты',
			секунды: 'секунды',
			настройках: 'настройках',
			'Ошибка загрузки. Повторите попытку или обратитесь в поддержку. Проверьте статус подключения аккаунта в':
				'Ошибка загрузки. Повторите попытку или обратитесь в поддержку. Проверьте статус подключения аккаунта в',
			'Загрузка завершена успешно': 'Загрузка завершена успешно',
			'Загрузка в процессе': 'Загрузка в процессе',
			'Запрос отправлен': 'Запрос отправлен',
			'Запрос синхронизации от': 'Запрос синхронизации от',
			'Вы отписались от оповещений, но вы всегда можете включить оповещения в настройках своего профиля!':
				'Вы отписались от оповещений, но вы всегда можете включить оповещения в настройках своего профиля!',
			Отписаться: 'Отписаться',
			'Хотите отписаться от рассылки новостей Impulse?': 'Хотите отписаться от рассылки новостей Impulse?',
			'Вы успешно отписались от рассылок': 'Вы успешно отписались от рассылок',
			'Удалить фото': 'Удалить фото',
			'Фото удалено': 'Фото удалено',
			'Обновить фото': 'Обновить фото',
			'Номинации турнира': 'Номинации турнира',
			tournamentEnded: 'Турнир завершён! 🎉 Можете узнать победителей в номинациях!',
			'Клуб не найден.': 'Клуб не найден.',
			'Наша команда': 'Наша команда',
			Name1: 'Имя',
			Управление: 'Управление',
			'Данные цели не найдены': 'Данные цели не найдены',
			'Статус цели': 'Статус цели',
			'Статус вашей цели': 'Статус вашей цели',
			'Поздравляем! Вы стали участником события.': 'Поздравляем! Вы стали участником события.',
			'Вы стали участником турнира !': 'Вы стали участником турнира !',
			Участвовать: 'Участвовать',
			'Дата окончания': 'Дата окончания',
			'Дата начала': 'Дата начала',
			'Окончание регистрации': 'Окончание регистрации',
			'Σ,км': 'Σ,км',
			ФИО: 'ФИО',
			'Имя участника': 'Имя участника',
			'Ваш клуб': 'Ваш клуб',
			'Количество участников': 'Количество участников',
			'Кол-во участников': 'Кол-во участников',
			Соревнование: 'Соревнование',
			'Лидеры недели': 'Лидеры недели',
			'Победители определены!': 'Победители определены!',
			'Победители пока не определены. Лидируют:': 'Победители пока не определены. Лидируют:',
			'Сумма, км (Σ)': 'Сумма, км(Σ)',
			'Среднее, км (км/чел)': 'Среднее, км(км/чел)',
			'Активность (%)': 'Активность(%)',
			'Всего тренировок': 'Всего тренировок',
			неделя: 'неделя',
			'Статистика за неделю': 'Статистика за неделю',
			'Последние тренировки не найдены': 'Последние тренировки не найдены',
			'Недавние тренировки участников': 'Недавние тренировки участников',
			'Вступление в клуб через подачу заявки на вступление':
				'Вступление в клуб через подачу заявки на вступление',
			'Любой пользователь может вступить в клуб': 'Любой пользователь может вступить в клуб',
			notTeamMemberT2:
				'Воспользуйтесь кнопкой "Выбрать команду" на странице турнира, а затем кнопкой "Вступить" напротив команды, или вступите в команду по приватной ссылке, если у вас она есть.',
			notTeamMemberT1:
				'Вы присоединились к турниру, но не вступили в команду. Это турнир с командным зачётом, чтобы участвовать в нём, необходимо выбрать и вступить в одну из команд.',
			'Вы не в команде!': 'Вы не в команде!',
			'Поздравляем! Вы стали участником команды': 'Поздравляем! Вы стали участником команды',
			'Переадресация на страницу оплаты...': 'Переадресация на страницу оплаты...',
			'Введите сумму от': 'Введите сумму от',
			'Введите численное значение': 'Введите численное значение',
			до: 'до',
			'Cумма от': 'Cумма от',
			'Перейти к оплате': 'Перейти к оплате',
			'Фиксированная стоимость': 'Фиксированная стоимость',
			'в событии': 'в событии',
			'Вы уверены, что хотите стать участником команды': 'Вы уверены, что хотите стать участником команды',
			'Вступить в команду': 'Вступить в команду',
			'Поздравляем! Вы стали участником клуба.': 'Поздравляем! Вы стали участником клуба.',
			joinClubConfirmation:
				'Вы действительно хотите стать участником клуба? Пользователь может быть участником только одного клуба.',
			joinClubRLConfirmation: 'Вы уверены, что хотите стать участником клуба по ссылке-приглашению?',
			Вступить: 'Вступить',
			'Найти клуб': 'Найти клуб',
			'Вступить в клуб': 'Вступить в клуб',
			'Вы не являетесь членом клуба': 'Вы не являетесь членом клуба',
			'Все клубы': 'Все клубы',
			'Мой клуб': 'Мой клуб',
			'Статистика клубов': 'Статистика клубов',
			'Данные турнирной таблицы не найдены': 'Данные турнирной таблицы не найдены',
			'Показать мою команду': 'Показать мою команду',
			'Показать мой клуб': 'Показать мой клуб',
			'Показать меня': 'Показать меня',
			'ID дублируемой тренировки': 'ID дублируемой тренировки',
			duplicateWarning2:
				'Если, наоборот, отменить дублирование, то нужно будет повторить автовалидацию и/или выполнить ручную валидацию.',
			duplicateWarning1:
				'Если сделать тренировку дубликатом другой тренировки, то она будет удалена из зачёта ВСЕХ турнирных таблиц',
			'Внимание!': 'Внимание!',
			'Описание запроса': 'Описание запроса',
			requestActivityValidationConfirm:
				'Вы действительно хотите, чтобы эту тренировку рассмотрел администратор? Администраторы проверяют в первую очередь тренировки, которые получили больше всего запросов на валидацию от пользователей.',
			'Выбрать файл': 'Выбрать файл',
			'Выбрать файлы': 'Выбрать файлы',
			'Лыжи/Сноуборд': 'Лыжи/Сноуборд',
			'Изменить дублирование': 'Изменить дублирование',
			'Изменить данные дублирования': 'Изменить данные дублирования',
			'Повторить автовалидацию': 'Повторить автовалидацию',
			'Запросить валидацию администратором': 'Запросить валидацию администратором',
			'Запросить валидацию тренировки администратором': 'Запросить валидацию тренировки администратором',
			'Добавить вложение': 'Добавить вложение',
			Изменить: 'Изменить',
			'Валидация перезапущена, перезагрузите страницу через несколько секунд':
				'Валидация перезапущена, перезагрузите страницу через несколько секунд',
			Подтвердить: 'Подтвердить',
			changeSportTypeInfo:
				'Внимание! При изменении вида спорта тренировки она будет удалена из текущего зачета турнирных таблиц, которые не содержат новый вид спорта и добавлена в турнирные таблицы с подходящими видами спорта',
			'Изменить данные тренировки': 'Изменить данные тренировки',
			'Валидация отклонена': 'Валидация отклонена',
			Активна: 'Активна',
			Неактивна: 'Неактивна',
			Зачтена: 'Зачтена',
			'У этой тренировки нет карты': 'У этой тренировки нет карты',
			'Карта тренировки': 'Карта тренировки',
			Пользователь: 'Пользователь',
			'Приоритет (кол-во запросов на валидацию)': 'Приоритет (кол-во запросов на валидацию)',
			'Валидация тренировки': 'Валидация тренировки',
			Базовый: 'Базовый',
			'Тренировка зачтена в следующих турнирных таблицах': 'Тренировка зачтена в следующих турнирных таблицах',
			'Тренировка зачтена?': 'Тренировка зачтена?',
			'Сообщение ответа': 'Сообщение ответа',
			Выполнил: 'Выполнил',
			Запросил: 'Запросил',
			'Сообщение запроса': 'Сообщение запроса',
			'Начало действия': 'Начало действия',
			'Окончание действия': 'Окончание действия',
			Сплиты: 'Сплиты',
			'Нет тренировок в зачете': 'Нет тренировок в зачете',
			'Тренировок за все время': 'Тренировок за все время',
			'Суммарная зачтенная дистанция': 'Суммарная зачтенная дистанция',
			'Тренировок зачтенных полностью': 'Тренировок зачтенных полностью',
			'Скорректированных и зачтенных тренировок': 'Скорректированных и зачтенных тренировок',
			'Незачтенных тренировок': 'Незачтенных тренировок',
			'Количество тренировок': 'Количество тренировок',
			'Кол-во тренировок, Бег': 'Кол-во тренировок, Бег',
			'Кол-во тренировок, Ходьба': 'Кол-во тренировок, Ходьба',
			'Кол-во тренировок, Велосипед': 'Кол-во тренировок, Велосипед',
			'Кол-во тренировок, Плавание': 'Кол-во тренировок, Плавание',
			'Кол-во тренировок, Лыжи': 'Кол-во тренировок, Лыжи',
			Выполнено: 'Выполнено',
			Осталось: 'Осталось',
			'Сообщение пользователю': 'Сообщение пользователю',
			'км/ч': 'км/ч',
			Отклонен: 'Отклонен',
			Зачтен: 'Зачтен',
			'Зачтен?': 'Зачтен?',
			'Зачтенные данные': 'Зачтенные данные',
			'Темп, мин/км': 'Темп, мин/км',
			'Оригинальные данные тренировки': 'Оригинальные данные тренировки',
			'Дата последней валидации': 'Дата последней валидации',
			'Один или более сплитов не прошли валидацию': 'Один или более сплитов не прошли валидацию',
			'Все сплиты успешно прошли валидацию': 'Все сплиты успешно прошли валидацию',
			'Статус сплитов': 'Статус сплитов',
			'Количество сплитов': 'Количество сплитов',
			'Средняя скорость': 'Средняя скорость',
			'Полное время': 'Полное время',
			'Зачтенное время': 'Зачтенное время',
			'Набор высоты, м': 'Набор высоты, м',
			'Максимальная скорость, м/с': 'Максимальная скорость, м/с',
			'Средний каденс': 'Средний каденс',
			'Длительность, cек': 'Длительность, cек',
			'Дата и время начала': 'Дата и время начала',
			'Тренировка создана и отправлена на проверку администратору':
				'Тренировка создана и отправлена на проверку администратору',
			'Список валидаций пуст': 'Список валидаций пуст',
			Темп: 'Темп',
			'Дата окончания действия валидации': 'Дата окончания действия валидации',
			'Дата начала действия валидации': 'Дата начала действия валидации',
			'Уровень валидации': 'Уровень валидации',
			'ID валидации': 'ID валидации',
			от: 'от',
			'Валидация администратором': 'Валидация администратором',
			Автовалидация: 'Автовалидация',
			'Прочие валидации (прошлые или в ожидании)': 'Прочие валидации (прошлые или в ожидании)',
			'Нет данных о валидации сплитов': 'Нет данных о валидации сплитов',
			'Средний темп': 'Средний темп',
			'Разница высот': 'Разница высот',
			'Участок зачтен': 'Участок зачтен',
			Участок: 'Участок',
			'Сообщение администратора': 'Сообщение администратора',
			'Сообщение автовалидации': 'Сообщение автовалидации',
			Время: 'Время',
			'Неразмеченная часть дистанции': 'Неразмеченная часть дистанции',
			'Валидация для тренировки не найдена': 'Валидация для тренировки не найдена',
			'Дата валидации': 'Дата валидации',
			'Сообщение валидации': 'Сообщение валидации',
			'Ручная валидация администратором': 'Ручная валидация администратором',
			Автоматическая: 'Автоматическая',
			'Тип валидации': 'Тип валидации',
			Нет: 'Нет',
			Да: 'Да',
			'Тренировка зачтена': 'Тренировка зачтена',
			'Основная валидация': 'Основная валидация',
			originalActivityText:
				'Дубликат тренировки,  пересекающийся с данной тренировкой по времени, но который был создан в системе раньше и поэтому является истиным',
			'Оригинальная тренирвка': 'Оригинальная тренирвка',
			'Дистанция добавленная администратором': 'Дистанция добавленная администратором',
			Подробнее: 'Подробнее',
			'Тренировка загружена частично': 'Тренировка загружена частично',
			'Тренировка полностью загружена': 'Тренировка полностью загружена',
			'Выполнение этой валидации администратором не требуется, актуальная валидация подтверждена':
				'Выполнение этой валидации администратором не требуется, актуальная валидация подтверждена',
			'Тренировка скорректирована и зачтена администратором':
				'Тренировка скорректирована и зачтена администратором',
			'Тренировка скорректирована и зачтена автоматически': 'Тренировка скорректирована и зачтена автоматически',
			'Тренировка зачтена администратором': 'Тренировка зачтена администратором',
			'Тренировка прошла автоматическую валидацию': 'Тренировка прошла автоматическую валидацию',
			'Тренировка отклонена администратором': 'Тренировка отклонена администратором',
			'Тренировка не прошла автоматическую валидацию': 'Тренировка не прошла автоматическую валидацию',
			'Валидация рассматривается. Изменения ожидают подтверждения администратором.':
				'Валидация рассматривается. Изменения ожидают подтверждения администратором.',
			'Тренировка ожидает валидации': 'Тренировка ожидает валидации',
			'Тренировка ожидает валидации, проверьте что задан вид спорта':
				'Тренировка ожидает валидации, проверьте что задан вид спорта',
			'Показывать дубликаты': 'Показывать дубликаты',
			'Не задан': 'Не задан',
			'Не задана': 'Не задана',
			'Создана вручную': 'Создана вручную',
			из: 'из',
			'Добавить вложения к тренировке': 'Добавить вложения к тренировке',
			'Загрузка файлов': 'Загрузка файлов',
			Загрузка: 'Загрузка',
			Назад: 'Назад',
			Контакты: 'Контакты',
			'Об организации': 'Об организации',
			'Организация не найдена': 'Организация не найдена',
			Организация: 'Организация',
			'Время в движении': 'Время в движении',
			'Показывать по': 'Показывать по',
			Источник: 'Источник',
			'Статус загрузки': 'Статус загрузки',
			'Статус валидации': 'Статус валидации',
			Длительность: 'Длительность',
			Дата: 'Дата',
			День: 'День',
			Неделя: 'Неделя',
			Дистанция: 'Дистанция',
			'Дистанция, км': 'Дистанция, км',
			'Дистанция, м': 'Дистанция, м',
			'Вид спорта': 'Вид спорта',
			Название: 'Название',
			Награды: 'Награды',
			standingsDataFixed:
				'Результаты турнирной таблицы зафиксированы: структура и данные таблицы не могут быть изменены.',
			клуб: 'клуб',
			команду: 'команду',
			'родительскую сущность': 'родительскую сущность',
			Информация: 'Информация',
			Незачет: 'Незачет',
			'Валидация не требовалась': 'Валидация не требовалась',
			'Ожидает валидации': 'Ожидает валидации',
			'Частичный зачет': 'Частичный зачет',
			'Полный зачет': 'Полный зачет',
			Турнир: 'Турнир',
			'Турнирная таблица': 'Турнирная таблица',
			'Спортивное событие': 'Спортивное событие',
			'Спортивный клуб': 'Спортивный клуб',
			'Основной - вид спорта': 'Основной - вид спорта',
			activityHasDuplicates:
				'Тренировка имеет дубликаты - тренировки, которые пересекаются с данной тренировкой по времени',
			'Тренировка имеет дубликаты': 'Тренировка имеет дубликаты',
			'Тренировка является дубликатом другой тренировки': 'Тренировка является дубликатом другой тренировки',
			'Дубликаты тренировки': 'Дубликаты тренировки',
			'Общий набор высоты': 'Общий набор высоты',
			'мин/км': 'мин/км',
			км: 'км',
			'Зачтенная дистанция': 'Зачтенная дистанция',
			'Оригинальная дистанция': 'Оригинальная дистанция',
			'Оригинальное полное время': 'Оригинальное полное время',
			'Оригинальное время (в движении)': 'Оригинальное время (в движении)',
			'Средняя ЧСС': 'Средняя ЧСС',
			ЧСС: 'ЧСС',
			'Основная информация': 'Основная информация',
			'Подробная информация о тренировке': 'Подробная информация о тренировке',
			'Тип зачета': 'Тип зачета',
			Клубный: 'Клубный',
			Командный: 'Командный',
			Пользовательский: 'Пользовательский',
			'Начало приёма': 'Начало приёма',
			'Тренировки принимаются только с момента вступления пользователя в':
				'Тренировки принимаются только с момента вступления пользователя в',
			'Тренировки принимаются только с момента вступления пользователя в турнир':
				'Тренировки принимаются только с момента вступления пользователя в турнир',
			'Тренировки принимаются только с момента вступления пользователя в команду':
				'Тренировки принимаются только с момента вступления пользователя в команду',
			'Прием тренировок с': 'Прием тренировок с',
			'Прием тренировок по': 'Прием тренировок по',
			'Окончание прёма': 'Окончание прёма',
			'Событие завершено': 'Событие завершено',
			'Дата окончания': 'Дата окончания',
			'О событии': 'О событии',
			Организаторы: 'Организаторы',
			Организатор: 'Организатор',
			'Вы участник': 'Вы участник',
			'Регистрация закрыта': 'Регистрация закрыта',
			'Регистрация открыта': 'Регистрация открыта',
			'Начало регистрации': 'Начало регистрации',
			'Событие сейчас активно!': 'Событие сейчас активно!',
			'Вы владелец': 'Вы владелец',
			'Все команды': 'Все команды',
			'Турнир с командным зачётом': 'Турнир с командным зачётом',
			'Команды ещё не созданы': 'Команды ещё не созданы',
			'Вы ещё не состоите ни в одной команде.': 'Вы ещё не состоите ни в одной команде.',
			'Выбрать команду.': 'Выбрать команду.',
			Владелец: 'Владелец',
			Администратор: 'Администратор',
			Владелецы: 'Владелецы',
			'Турнир c виртуальными очками и бустерами': 'Турнир c виртуальными очками и бустерами',
			'Турнир c виртуальными очками': 'Турнир c виртуальными очками',
			'Количество очков за 1 км: ': 'Количество очков за 1 км: ',
			'Очки, заработанные командой': 'Очки, заработанные командой',
			'Монет в кошельке команды': 'Монет в кошельке команды',
			Бустеры: 'Бустеры',
			монет: 'монет',
			Купить: 'Купить',
			'Будет деактивирован': 'Будет деактивирован',
			Активирован: 'Активирован',
			'Активен до': 'Активен до',
			'Будет активирован': 'Будет активирован',
			'Ни один спортсмен не найден': 'Ни один спортсмен не найден',
			потратив: 'потратив',
			Стоимость: 'Стоимость',
			'Не хватает ёще': 'Не хватает ёще',
			'У вас недостаточно монет для покупки бустера': 'У вас недостаточно монет для покупки бустера',
			'командных монет': 'командных монет',
			'Вы действительно хотите купить бустер': 'Вы действительно хотите купить бустер',
			'Бустер куплен!': 'Бустер куплен!',
			'Купить бустер': 'Купить бустер',
			'Примененный бустер': 'Примененный бустер',
			'Бег вместо велосипеда': 'Бег вместо велосипеда',
			'Супер-ускорение на велопробег': 'Супер-ускорение на велопробег',
			'Ускорение на плавание': 'Ускорение на плавание',
			'Джентльменский день': 'Джентльменский день',
			'Ранние пташки': 'Ранние пташки',
			'Железный человек': 'Железный человек',
			'Нет бустера на завтра': 'Нет бустера на завтра',
			'Бустер на завтра': 'Бустер на завтра',
			'Нет активного бустера': 'Нет активного бустера',
			'Продажа бустеров будет доступна': 'Продажа бустеров будет доступна',
			'Выбрать бустер': 'Выбрать бустер',
			'Купить бустер может капитан команды.': 'Купить бустер может капитан команды.',
			'Продажа бустеров открыта!': 'Продажа бустеров открыта!',
			'Очки, заработанные вами': 'Очки, заработанные вами',
			'О команде': 'О команде',
			'Вы капитан команды': 'Вы капитан команды',
			'Командный зачёт': 'Командный зачёт',
			'Личный зачёт': 'Личный зачёт',
			'Вы уже подали заявку': 'Вы уже подали заявку',
			'Ваша команда': 'Ваша команда',
			'Приватная команда': 'Приватная команда',
			'Публичная команда': 'Публичная команда',
			'Приватный клуб': 'Приватный клуб',
			'О клубе': 'О клубе',
			Локации: 'Локации',
			Участников: 'Участников',
			'Виды спорта': 'Виды спорта',
			'О проекте': 'О проекте',
			Возраст: 'Возраст',
			'Возрастная группа': 'Возрастная группа',
			'По убыванию': 'По убыванию',
			'По возрастанию': 'По возрастанию',
			Пол: 'Пол',
			Женщины: 'Женщины',
			Женский: 'Женский',
			Мужчины: 'Мужчины',
			Мужской: 'Мужской',
			Город: 'Город',
			Тренировок: 'Тренировок',
			'За все время': 'За все время',
			Бег: 'Бег',
			'Бег, м': 'Бег, м',
			'Бег, км': 'Бег, км',
			Велосипед: 'Велосипед',
			'Велосипед, м': 'Велосипед, м',
			'Велосипед, км': 'Велосипед, км',
			Плавание: 'Плавание',
			'Плавание, м': 'Плавание, м',
			'Плавание, км': 'Плавание, km',
			Очки: 'Очки',
			Монеты: 'Монеты',
			Участники: 'Участники',
			'Активный бустер': 'Активный бустер',
			'Имя команды': 'Имя команды',
			Лыжи: 'Лыжи',
			'Лыжи, м': 'Лыжи, м',
			'Лыжи, км': 'Лыжи, км',
			Ходьба: 'Ходьба',
			'Ходьба, м': 'Ходьба, м',
			'Ходьба, км': 'Ходьба, км',
			'Суммарная дистанция, км': 'Суммарная дистанция, км',
			'Сумма, км': 'Сумма, км',
			'Не задано': 'Не задано',
			'О себе': 'О себе',
			Клуб: 'Клуб',
			Клубы: 'Клубы',
			Команды: 'Команды',
			Команда: 'Команда',
			'Капитан команды': 'Капитан команды',
			'Подробная информация': 'Подробная информация',
			'Приватный аккаунт': 'Приватный аккаунт',
			'Ваш одноклубник': 'Ваш одноклубник',
			'Нет загруженных тренировок': 'Нет загруженных тренировок',
			'Добавить тренировки': 'Добавить тренировки',
			Добавить: 'Добавить',
			Отмена: 'Отмена',
			Сохранить: 'Сохранить',
			Создать: 'Создать',
			Заявитель: 'Заявитель',
			'Назначен на': 'Назначен на',
			'Дата создания': 'Дата создания',
			Игнорировать: 'Игнорировать',
			Ошибки: 'Ошибки',
			Ошибка: 'Ошибка',
			Описание: 'Описание',
			Закрыт: 'Закрыт',
			'Создать клуб': 'Создать клуб',
			'Создать свой клуб': 'Создать свой клуб',
			'Создать событие': 'Создать событие',
			'Перейти в настройки': 'Перейти в настройки',
			'Создать обращение в поддержку': 'Создать обращение в поддержку',
			'Обращение в поддержку': 'Обращение в поддержку',
			'Создать обращение': 'Создать обращение',
			'Неподдерживаемый формат, используйте JPEG или PNG': 'Неподдерживаемый формат, используйте JPEG или PNG',
			'Размер файла не должен превышать': 'Размер файла не должен превышать',
			'Последнее сообщение': 'Последнее сообщение',
			'Обращение не найдено': 'Обращение не найдено',
			'Краткое описание': 'Краткое описание',
			'Подробное описание': 'Подробное описание',
			'Обращение в поддержку создано успешно!': 'Обращение в поддержку создано успешно!',
			'Сообщение слишком короткое': 'Сообщение слишком короткое',
			'Превышена допустимая длина сообщения': 'Превышена допустимая длина сообщения',
			'Не заполнено сообщение': 'Не заполнено сообщение',
			longDescription1to256: 'Слишком длинное описание. Пожалуйста, введите от 1 до 256 символов',
			'Не указано описание': 'Не указано описание',
			Статус: 'Статус',
			'Вы еще не создавали обращений в поддержку': 'Вы еще не создавали обращений в поддержку',
			'Ваши обращения в поддержку': 'Ваши обращения в поддержку',
			Редактировать: 'Редактировать',
			'Поиск спортсменов': 'Поиск спортсменов',
			Поиск: 'Поиск',
			'Все время': 'Все время',
			Год: 'Год',
			Месяц: 'Месяц',
			Период: 'Период',
			'Турнирные таблицы': 'Турнирные таблицы',
			'Только мои': 'Только мои',
			'Только активные': 'Только активные',
			'Скрыть таблицы статистики': 'Скрыть таблицы статистики',
			'Клубные таблицы': 'Клубные таблицы',
			'Прочие турнирные таблицы': 'Прочие турнирные таблицы',
			'Статистика по видам спорта': 'Статистика по видам спорта',
			'Турнирная таблица по виду спорта “Лыжи”': 'Турнирная таблица по виду спорта “Лыжи”',
			'Турнирная таблица по виду спорта “Ходьба”': 'Турнирная таблица по виду спорта “Ходьба”',
			'Турнирная таблица по виду спорта “Плавание”': 'Турнирная таблица по виду спорта “Плавание”',
			'Турнирная таблица по виду спорта “Велосипед”': 'Турнирная таблица по виду спорта “Велосипед”',
			'Турнирная таблица по виду спорта “Бег”': 'Турнирная таблица по виду спорта “Бег”',
			'Турнирные таблицы не найденцы.': 'Турнирные таблицы не найденцы.',
			'События и турниры': 'События и турниры',
			'События и турниры организации': 'События и турниры организации',
			'Моя страница': 'Моя страница',
			'Мой профиль': 'Мой профиль',
			'Служба поддержки': 'Служба поддержки',
			'Поддержать проект': 'Поддержать проект',
			Пароль: 'Пароль',
			или: 'или',
			и: 'и',
			Войти: 'Войти',
			'Готов к импульсу': 'Готов к импульсу',
			'но забыл пароль': 'но забыл пароль',
			'У тебя еще нет Импульса': 'У тебя еще нет Импульса',
			'Я все помню': 'Я все помню',
			'Отправить ссылку': 'Отправить ссылку',
			'Забыли пароль': 'Забыли пароль',
			'введите регистрационную почту': 'введите регистрационную почту',
			'Создайте пароль': 'Создайте пароль',
			'Уже есть аккаунт': 'Уже есть аккаунт',
			'Нажимая на кнопку, ты принимаешь': 'Нажимая на кнопку, ты принимаешь',
			'политику конфиденциальности': 'политику конфиденциальности',
			'условия использования': 'условия использования',
			Регистрация: 'Регистрация',
			Язык: 'Язык',
			Справка: 'Справка',
			Выйти: 'Выйти',
			Настройки: 'Настройки',
			'Заполните профиль': 'Заполните профиль',
			'Эти данные помогут нам корректно отображать вашу статистику и ваше положение в турнирных таблицах':
				'Эти данные помогут нам корректно отображать вашу статистику и ваше положение в турнирных таблицах',
			Имя: 'Имя',
			Фамилия: 'Фамилия',
			'День рождения': 'День рождения',
			'Дата рождения': 'Дата рождения',
			Далее: 'Далее',
			'Загрузка GPX файлов': 'Загрузка GPX файлов',
			'Из источника': 'Из источника',
			Вручную: 'Вручную',
			Синхронизировать: 'Синхронизировать',
			'Синхронизировать все тренировки из источника': 'Синхронизировать все тренировки из источника',
			'Полная загрузка тренировок с': 'Полная загрузка тренировок с',
			'Синхронизировать с даты': 'Синхронизировать с даты',
			'Синхронизировать до': 'Синхронизировать до',
			'Аккаунт Polar не подключен': 'Аккаунт Polar не подключен',
			'Аккаунт Strava не подключен': 'Аккаунт Strava не подключен',
			'Загрузить GPX файлы тренировок': 'Загрузить GPX файлы тренировок',
			'Подключение Polar аккаунта запущено.': 'Подключение Polar аккаунта запущено.',
			'Подключение Strava аккаунта запущено.': 'Подключение Strava аккаунта запущено.',
			Подключить: 'Подключить',
			'Подключить в настройках профиля': 'Подключить в настройках профиля',
			'Подключите источник данных ваших тренировок': 'Подключите источник данных ваших тренировок',
			'Узнать подробнее': 'Узнать подробнее',
			'Подключить Polar': 'Подключить Polar',
			'Подключить Google Fit': 'Подключить Google Fit',
			'Продолжить без подключения': 'Продолжить без подключения',
			'Ваш ID': 'Ваш ID',
			Никнейм: 'Никнейм',
			'Эл. почта': 'Эл. почта',
			'Учетная запись': 'Учетная запись',
			'Основные сведения': 'Основные сведения',
			'Соцсети и источники тренировок': 'Соцсети и источники тренировок',
			'Настройки приватности': 'Настройки приватности',
			'Настройки отображения': 'Настройки отображения',
			'Мои адреса': 'Мои адреса',
			'Мои контакты': 'Мои контакты',
			'Данные отсутствуют': 'Данные отсутствуют',
			'ID VK аккаунта': 'ID VK аккаунта',
			'почта VK': 'почта VK',
			SocialConnectText: `Подключение соц. сетей Google, Yandex или Вконтакте опционально, это лишь упрощает вход в «Импульс»`,
			ActivitySourceConnectText: `Подключение Strava, Polar и/или Google Fit необходимо для импорта ваших тренировок`,
			'ID Google аккаунта': 'ID Google аккаунта',
			'почта Google': 'почта Google',
			'ID Яндекс аккаунта': 'ID Яндекс аккаунта',
			'почта Яндекс': 'почта Яндекс',
			'Ошибка подключения, попробуйте подключить повторно': 'Ошибка подключения, попробуйте подключить повторно',
			'Ошибка при подключении источника тренировок': 'Ошибка при подключении источника тренировок',
			'ID Strava аккаунта': 'ID Strava аккаунта',
			'ID Polar аккаунта': 'ID Polar аккаунта',
			'Отвязать аккаунт': 'Отвязать аккаунт',
			'Polar аккаунт успешно откреплен.': 'Polar аккаунт успешно откреплен.',
			'Telegram аккаунт успешно откреплен.': 'Telegram аккаунт успешно откреплен.',
			'Strava аккаунт успешно откреплен.': 'Strava аккаунт успешно откреплен.',
			'Аккаунт успешно откреплен.': 'Аккаунт успешно откреплен.',
			'Аккаунт подключен, включен импорт тренировок': 'Аккаунт подключен, включен импорт тренировок',
			'Аккаунт подключен, импорт тренировок выключен': 'Аккаунт подключен, импорт тренировок выключен',
			ВКонтакте: 'ВКонтакте',
			Яндекс: 'Яндекс',
			privacyAccountText:
				'Приватный аккаунт позволяет скрыть данные ваших тренировок от других пользователей, кроме членов вашего клуба и администраторов Импульс',
			'Данные сохранены': 'Данные сохранены',
			emailVerification: 'Подтверждение адреса эл. почты',
			'Ошибка синхронизации': 'Ошибка синхронизации',
			'обратитесь в поддержку': 'обратитесь в поддержку',
			syncStartMessage:
				'Синхронизация запущена. Тренировки будут доступны в профиле послее ее завершения. Обычно это процесс занимает несколько секунд.',
			onVerifyDatesError1: 'Дата в "Синхроинизировать до" должна быть позднее даты "Синхронизировать с"',
			onVerifyDatesError2: 'За раз можно загружать тренировки только за полгода (183 дня)',
			useAddressesText:
				'Ваши адреса видны только администраторам Импульс. Мы будем использовать ваш актуальный адрес для отправки призов, например медалей.',
			'Адрес успешно сохранен': 'Адрес успешно сохранен',
			'Не задан город': 'Не задан город',
			'Не задан дом': 'Не задан дом',
			'Введите адрес': 'Введите адрес',
			'Ошибка загрузки': 'Ошибка загрузки',
			userContactsText:
				'Эти данные видны только администраторам Импульс. Мы можем использовать их для связи с вами по вашим обращениям или в связи с доставкой призов.',
			'Мобильный телефон': 'Мобильный телефон',
			'Номер телефона': 'Номер телефона',
			// Activity
			ACTIVITIES_QUEUE_TOO_MUCH_REQUESTS:
				'Вы уже запускали синхронизацию сегодня, запуск может быть произведен раз в 24 часа',
			// User exceptions
			USER_ALREADY_EXISTS: 'Учетная запись с такими данными уже существует.',
			USER_NOT_FOUND: 'Нам не удалось найти учетную запись с таким адресом электронной почты.',
			USER_ALREADY_LOGGED_IN: 'Пользователь уже вошёл в систему.',
			USER_ALREADY_REGISTERED: 'Пользователь уже зарегистрирован.',
			USER_ALREADY_CLUB_MEMBER: 'Вы уже являетесь членом клуба',
			ACCOUNT_JOIN_REQUIRED:
				'Вы уже зарегистрированы с такой почтой и паролем. Объедините аккаунты, чтобы продолжить с соц. сетью',
			ACCOUNTS_ALREADY_JOINED: 'Аккаунты уже объединены.',
			INVALID_BIRTH_DATE: 'Минимальный возраст - 13 лет.',
			USER_OLD_PASSWORD_INCORRECT: 'Введен неправильный пароль.',
			// Support Request
			TOO_MUCH_SUPPORT_REQUESTS_FROM_USER: 'Вы уже создавали обращение в поддержку сегодня, попробуйте позже.',
			// Social exceptions
			ERROR_LOADING_SOCIAL_DATA: 'Не удаётся подгрузить данные аутентификации. Попробуйте другой способ входа.',
			NO_DATA_FROM_SOCIAL: 'Не удаётся подгрузить дополнительные данные о пользовател из соц. сети.',
			NO_STRAVA_TOKEN: 'У пользователя нет токена обновления Strava.',
			ACCOUNT_ALREADY_BOUND: 'Социальный аккаунт ранее был привязан к вашему аккаунту.',
			ACCOUNT_ALREADY_BOUND_ANOTHER_USER: 'Социальный аккаунт уже привязан к аккаунту другого пользователя.', //
			ANOTHER_STRAVA_ACCOUNT_ACTIVITIES_EXIST:
				'Вы пытаетесь привязать новый социальный аакаунт, но данные старого еще не удалены.',
			// Auth exceptions
			INVALID_EMAIL_OR_PASSWORD: 'Введён некорректный email или пароль.',
			INVALID_USERNAME_OR_PASSWORD: 'Введён некорректный username или пароль.',
			INVALID_PHONE_OR_PASSWORD: 'Введён некорректный номер телефона или пароль.',
			// Role exceptions
			ROLE_ALREADY_EXISTS: 'Такая роль уже существует.',
			ROLE_NOT_FOUND: 'Роль не найдена.',
			ROLE_ALREADY_ASSIGNED: 'Роль уже назначена.',
			//Tournament
			TOURNAMENT_ALREADY_EXISTS: 'Турнир с таким наименованием уже существует.',
			TOURNAMENT_NOT_FOUND: 'Турнир не найден.',
			TOURNAMENT_STANDINGS_COLUMN_ALREADY_ADDED_FOR_THIS_TOURNAMENT:
				'Столбец турнирной таблицы уже добавлен к этому турниру.',
			TOURNAMENT_STANDINGS_COLUMN_NOT_YET_ADDED_FOR_THIS_TOURNAMENT:
				'Столбец турнирной таблицы не был добавлен к этому турниру.',
			TOURNAMENT_STANDINGS_COLUMN_NOT_FOUND: 'Указанного столбца турнирной таблицы не существует.',
			TOURNAMENT_PARTICIPANT_ALREADY_EXISTS: 'Пользователь уже является участником турнира.',
			TOURNAMENT_PARTICIPANT_DOES_NOT_EXIST: 'Пользователь не является участником турнира.',
			TOURNAMENT_CANT_BE_ARCHIVED_WHEN_ACTIVE: 'Турнир не может быть архивирован, если он активен.',
			TOURNAMENT_CANT_BE_ACTIVATE_IF_ARCHIVED: 'Архивированный турнир не может быть возобновлен.',
			TOURNAMENT_REGISTRATION_NOT_OPEN: 'Регистрация на турнир еще не началась.',
			TOURNAMENT_REGISTRATION_START_DATE_INVALID:
				'Дата регистрации на турнир не должна быть позднее даты начала турнира.',
			TOURNAMENT_REGISTRATION_END_DATE_INVALID:
				'Дата окончания регистрации на турнир не должна быть позднее даты окончания турнира.',
			TOURNAMENT_CANT_CHANGE_START_DATE_IF_STARTED:
				'Нельзя изменить дату начала турнира, если турнир уже начался.',
			TOURNAMENT_CANT_SET_END_DATE_BEFORE_PREVIOUS_END_DATE:
				'Нельзя выбрать дату окончания турнира ранее предыдущей даты окончания.',
			TOURNAMENT_IS_INACTIVE: 'Нельзя выполнить действие, так как турнир неактивен.',
			TOURNAMENT_IS_ACTIVE_CANT_MAKE_INVISIBLE: 'Турнир активен, его нельзя сделать скрытым от пользователей.',
			TOURNAMENT_CANT_DEARCHIVE: 'Нельзя деархивировать уже архивированный турнир.',
			TOURNAMENT_NOT_ARCHIVED_CANT_DELETE: 'Нельзя удалить неархивированный турнир.',
			// Verification exceptions
			VERIFICATION_ALREADY_COMPLETE: 'Подтверждение уже выполнено.',
			VERIFICATION_NOT_FOUND: 'Подтверждение не найдено.',
			VERIFICATION_REQUIRED: 'Требуется подтверждение аккаунта, чтобы продолжить.',
			VERIFICATION_NOT_COMPLETE: 'Подтверждение не выполнено.',
			INVALID_PAYLOAD_OR_KEY: 'Введен неверный код.',
			VERIFICATION_INVALIDATED: 'Невалидное подтверждение. Попробуйте отправить новый код.',
			MAX_NUM_OF_ATTEMPTS: 'Превышено количество попыток. Попробуйте отправить новый код.',
			VERIFICATION_TIMED_OUT: 'Код подтверждения истёк. Попробуйте отправить новый код.',
			WAIT_BEFORE_RETRY_VERIFICATION: 'Необходимо подождать, прежде чем отправлять новый код.',
			SPORT_CLUB_USER_JOIN_LIMIT_REACHED: 'Пользователь не может вступать в новый клуб чаще чем каждые 30 дней',
			STORE_API_REQUEST_ERROR: 'Произошла ошибка запроса, пожалуйста, попробуйте еще раз',
			// Standings
			STANDINGS_REFRESH_TOO_MUCH_REQUESTS: 'Слишком много запросов на перерассчёт. Попробуйте позже.',

			// Sport Club
			SPORT_CLUB_JOIN_LINK_INCORRECT: 'Вход в клуб по ссылке выключен или ссылка не корректная',
			//ActivityTypes
			AlpineSki: 'Катание на горных лыжах',
			BackcountrySki: 'Бэккантри',
			Canoeing: 'Каноэ',
			Crossfit: 'Кроссфит',
			EBikeRide: 'Электровелосипед',
			Elliptical: 'Эллиптический тренажер',
			Golf: 'Гольф',
			Handcycle: 'Хэндбайк',
			Hike: 'Поход',
			IceSkate: 'Катание на коньках',
			InlineSkate: 'Роликовые коньки',
			Kayaking: 'Каякинг',
			Kitesurf: 'Кайтсерфинг',
			NordicSki: 'Лыжные виды спорта',
			Skiing: 'Лыжные виды спорта',
			Ride: 'Велосипед',
			RockClimbing: 'Скалолазание',
			RollerSki: 'Роликовые лыжи',
			Rowing: 'Гребля',
			Run: 'Бег',
			Sail: 'Парусный спорт',
			Skateboard: 'Скейтборд',
			Snowboard: 'Катание на сноуборде',
			Snowshoe: 'Ходьба на снегоступах',
			Soccer: 'Футбол',
			StairStepper: 'Тренажер-степпер',
			StandUpPaddling: 'Сапсерфинг',
			Surfing: 'Серфинг',
			Swim: 'Плавание',
			Swimming: 'Плавание',
			Velomobile: 'Веломобиль',
			VirtualRide: 'Виртуальный заезд',
			VirtualRun: 'Виртуальный забег',
			Walk: 'Ходьба',
			Walking: 'Ходьба',
			WeightTraining: 'Силовая тренировка',
			Wheelchair: 'Кресло-коляска',
			Windsurf: 'Виндсерфинг',
			Workout: 'Тренировка',
			Yoga: 'Йога',

			//Gender
			FEMALE: 'Ж',
			MALE: 'М',

			//OrderParams
			desc: 'По убыванию',
			asc: 'По возрастанию',

			//LogicalConnectives

			Conjunction: 'Логическое И',
			Disjunction: 'Логическое ИЛИ',
			'&&': 'и',
			'||': 'или',

			INVALID_GPX_FILE: 'Выбранный GPX файл уже был загружен', //Todo
			GPX_ALREADY_UPLOADED: 'Выбранный GPX файл уже был загружен',
			GOOGLE_FIT_API_ACCESS_ERROR:
				'Ошибка получения данных от Google Fit. Проверьте статус аккаунта в настройках профиля.',
			TEAM_ALREADY_EXISTS: 'Команда с такими данными уже существует',
			TEAM_NOT_FOUND: 'Команда не найдена',
			USER_IS_ALREADY_TOURNAMENT_TEAM_MEMBER: 'Вы уже являетесь членом этой команды',
			TEAM_USER_JOIN_VIA_LINK_DISABLED: 'Вход по ссылке закрыт',
			TEAM_USER_JOIN_VIA_CODE_DISABLED: 'Вход по коду закрыт',
			TEAM_IS_PRIVATE: 'Вы не можете вступить в эту команду по публичной ссылке',
			TEAM_JOIN_LINK_INCORRECT: 'Некорректная ссылка для вступления в команду',
			TEAM_JOIN_CODE_INCORRECT: 'Указан неверный код',
			TEAM_JOIN_CODE_UNACCEPTABLE: 'Невозможно использовать это значение для кода. Попробуйте другое.',
			TEAM_PARTICIPATION_IS_PAID: 'Участие в команде платное',
			TEAM_PARTICIPATION_IS_NOT_PAID: 'Участие в команде бесплатное',
			TEAM_PAID_PARTICIPATION_INVALID_AMOUNT: 'Указана неверная сумма',
			TEAM_MAX_PARTICIPANTS_LIMIT_REACHED:
				'В команде уже максимально возможное число пользователей. Вы не можете вступить.',
			ACTIVITY_ATTACHMENTS_LIMIT_REACHED: `Превышено количество допустимых вложений`,
			TELEGRAM_JOIN_ERROR: `Ошибка подключения telegram`,
			NOTIFICATION_TEMPLATE_UNKNOWN_VARIABLE:
				'Одной из переменных, указанных в шаблоне не существует. Создайете её или переиспользуйте существующую',
		},
	},
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'ru',

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
