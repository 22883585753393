export default function List(theme) {
    return {
        MuiListItem: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        }
    };
}
