/**
 * Authorization Roles
 */
var authRoles = {
    superAdmin: ['SuperAdmin', 'superAdmin'],
    admin: ['SuperAdmin', 'superAdmin', 'Admin', 'admin'],
    staff: ['SuperAdmin', 'superAdmin', 'Admin', 'admin', 'staff', 'Operator', 'Operator'],
    user: ['SuperAdmin', 'superAdmin', 'Admin', 'admin', 'staff', 'user', 'User', 'Operator', 'Operator'],
    onlyGuest: []
};
export default authRoles;
