var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { useEffect, useState } from 'react';
var DateRangeYear = function (_a) {
    var value = _a.value, activityStartYear = _a.activityStartYear, setRange = _a.setRange;
    var _b = useState([new Date().getFullYear()]), yearsList = _b[0], setYearsList = _b[1];
    useEffect(function () {
        if (activityStartYear) {
            var list = [];
            for (var i = activityStartYear; i <= new Date().getFullYear(); i++) {
                list.push(i);
            }
            setYearsList(list);
        }
    }, [activityStartYear]);
    return (_jsx(_Fragment, { children: yearsList.length &&
            yearsList.map(function (year, index) {
                return (_jsx("div", __assign({ onClick: function () {
                        setRange('year', year);
                    }, className: clsx("tw-date-range-filter-item", value && year === value && "selected") }, { children: year }), index));
            }) }, void 0));
};
export default DateRangeYear;
