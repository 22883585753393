import * as Yup from 'yup';
export var notificationTemplateCreateValidationSchema = Yup.object().shape({
    description: Yup.string().trim().min(1).max(400, 'Слишком длинное описание, максимум 400 символов').optional(),
    name: Yup.string().trim().min(1).max(64, 'Слишком длинное имя, максимум 64 символа').required('Не указано имя')
});
export var notificationTemplateUpdateValidationSchema = Yup.object().shape({
    description: Yup.string()
        .trim()
        .min(1)
        .max(400, 'Слишком длинное описание, максимум 400 символов')
        .optional()
        .nullable()
});
