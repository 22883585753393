var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { cleanResetPasswordError } from 'store/actions';
var ResetPasswordErrorForm = function () {
    var dispatch = useDispatch();
    useEffect(function () {
        return function () {
            //componentWillUnmount удалить ошибку 
            dispatch(cleanResetPasswordError());
        };
    }, []);
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '32rem' } }, { children: [_jsx(Box, __assign({ sx: { mb: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' } }, { children: _jsx(Typography, __assign({ sx: { fontSize: { xs: '2rem' }, fontWeight: 500, lineHeight: 1 } }, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C" }), void 0) }), void 0), _jsx(Box, __assign({ sx: { width: '32rem', mb: '2rem' } }, { children: _jsx(Typography, __assign({ sx: { fontSize: { xs: '1.4rem' }, fontWeight: 400, lineHeight: 1, textAlign: 'center' } }, { children: "\u041F\u043E\u0445\u043E\u0436\u0435, \u044D\u0442\u0443 \u0441\u0441\u044B\u043B\u043A\u0443 \u043D\u0430 \u0441\u0431\u0440\u043E\u0441 \u043F\u0430\u0440\u043E\u043B\u044F \u0431\u043E\u043B\u044C\u0448\u0435 \u043D\u0435\u043B\u044C\u0437\u044F \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C. \u0412\u0435\u0440\u043E\u044F\u0442\u043D\u043E, \u0432\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u0434\u0440\u0443\u0433\u043E\u0439 \u0437\u0430\u043F\u0440\u043E\u0441 \u043D\u0430 \u0441\u0431\u0440\u043E\u0441 \u043F\u0430\u0440\u043E\u043B\u044F; \u0432 \u044D\u0442\u043E\u043C \u0441\u043B\u0443\u0447\u0430\u0435 \u0432\u043E\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0439\u0442\u0435\u0441\u044C \u0431\u043E\u043B\u0435\u0435 \u043D\u043E\u0432\u043E\u0439 \u0441\u0441\u044B\u043B\u043A\u043E\u0439." }), void 0) }), void 0), _jsxs(Box, __assign({ sx: { width: '32rem', textAlign: 'center' } }, { children: [_jsx(Divider, { sx: { mb: '1rem' } }, void 0), _jsx(Link, __assign({ to: "/login" }, { children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043A \u0432\u0445\u043E\u0434\u0443 \u0432 \u0441\u0438\u0441\u0442\u0435\u043C\u0443" }), void 0)] }), void 0)] }), void 0));
};
export default ResetPasswordErrorForm;
