import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { STANDINGS } from 'common/graphql/queries/standings/standings';
import { useUpdateStandingsMutation } from 'common/hooks/standings/useUpdateStandingsMutation';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import SettingsForm from 'main/pages/SettingsPage/components/SettingsForm/SettingsForm';
import SettingsGroup from 'main/pages/SettingsPage/components/SettingsGroup/SettingsGroup';
import standingsGroupsConfig from './standingsGroupsConfig';
var StandingsMainSettings = function (_a) {
    var standingsId = _a.standingsId;
    var _b = useQuery(STANDINGS, {
        variables: {
            id: standingsId,
            showBasicFields: true
        }
    }), data = _b.data, loading = _b.loading;
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: "\u041E\u0441\u043D\u043E\u0432\u043D\u044B\u0435 \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u043E\u0439 \u0442\u0430\u0431\u043B\u0438\u0446\u044B" }, void 0), content: standingsGroupsConfig.length > 0 &&
            standingsGroupsConfig.map(function (settingsGroup) { return (_jsxs("div", { children: [_jsx(SettingsGroup, { settingsGroup: settingsGroup, data: data === null || data === void 0 ? void 0 : data.standings, handleChangeData: useUpdateStandingsMutation }, void 0), _jsx(Divider, { light: true }, void 0)] }, settingsGroup.id)); }) }, void 0));
};
export default StandingsMainSettings;
