var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DocsHelper from 'components/DocsHelper/DocsHelper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelperFunctions from 'utils/HelperFunctions';
import PointsPerSportToolTip from './PointsPerSportTooltip';
var getSportNameRu = HelperFunctions.getSportNameRu;
var PointsInfo = function (_a) {
    var _b;
    var team = _a.team, teamPointsPerSport = _a.teamPointsPerSport, userPointsPerSport = _a.userPointsPerSport, teamTotalPoints = _a.teamTotalPoints, userTotalPoints = _a.userTotalPoints, virtualPointsFeature = _a.virtualPointsFeature;
    var t = useTranslation().t;
    var _c = useState(false), openTooltip = _c[0], setOpenTooltip = _c[1];
    var pointsPerSportTypeMappings = ((_b = virtualPointsFeature === null || virtualPointsFeature === void 0 ? void 0 : virtualPointsFeature.config) === null || _b === void 0 ? void 0 : _b.pointsPerSportTypeMappings) || [];
    var mappingsText = t("\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043E\u0447\u043A\u043E\u0432 \u0437\u0430 1 \u043A\u043C: ");
    for (var i in pointsPerSportTypeMappings) {
        mappingsText =
            mappingsText +
                t(getSportNameRu(pointsPerSportTypeMappings[i].sport)) +
                " - ".concat(pointsPerSportTypeMappings[i].points).concat(parseInt(i) === pointsPerSportTypeMappings.length - 1 ? '.' : ', ');
    }
    return (_jsxs("div", __assign({ className: "flex flex-col space-y-10 w-full" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-10 w-full items-center justify-center xs:text-14 md:text-16" }, { children: [_jsx("p", { children: t('Очки') }, void 0), _jsx(DocsHelper, __assign({ id: "31781b00-c8a6-4430-b6b1-8735854e460c", additionalText: mappingsText }, { children: _jsx(InfoOutlinedIcon, { onClick: function () { return setOpenTooltip(!openTooltip); }, fontSize: "small" }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-row space-x-5 justify-center items-center" }, { children: [_jsxs("p", __assign({ className: "text-grey" }, { children: [t('Очки, заработанные командой'), ":"] }), void 0), teamPointsPerSport && (teamPointsPerSport === null || teamPointsPerSport === void 0 ? void 0 : teamPointsPerSport.length) > 0 ? (_jsx(PointsPerSportToolTip, { totalPoints: teamTotalPoints, pointsPerSport: teamPointsPerSport }, void 0)) : (_jsx("p", { children: teamTotalPoints }, void 0))] }), void 0), team.areYouParticipant && (_jsxs("div", __assign({ className: "flex flex-row space-x-5 justify-center items-center" }, { children: [_jsxs("p", __assign({ className: "text-grey" }, { children: [t('Очки, заработанные вами'), ":"] }), void 0), userPointsPerSport && (userPointsPerSport === null || userPointsPerSport === void 0 ? void 0 : userPointsPerSport.length) > 0 ? (_jsx(PointsPerSportToolTip, { totalPoints: userTotalPoints, pointsPerSport: userPointsPerSport }, void 0)) : (_jsx("p", { children: userTotalPoints }, void 0))] }), void 0))] }), void 0));
};
export default PointsInfo;
