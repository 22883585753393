var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { Card, CardContent } from '@mui/material';
import LoginLayout from 'main/layouts/layout/components/LoginLayout';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { navigateToVar } from 'utils/apollo/reactivities/navigateToVar';
import InputVerificationKeyForm from './InputVerificationKeyForm';
import RegisterForm from './RegisterForm';
var RegisterPage = function (props) {
    var search = useLocation().search;
    var useQueryParams = function () {
        return useMemo(function () { return new URLSearchParams(search); }, [search]);
    };
    var query = useQueryParams();
    var navigateTo = query.get('navigateTo') || undefined;
    useEffect(function () {
        if (navigateTo) {
            navigateToVar({
                navigateTo: navigateTo
            });
        }
    }, [navigateTo]);
    var navigateToFromVar = useReactiveVar(navigateToVar).navigateTo;
    var inputVerificationKey = useSelector(function (_a) {
        var auth = _a.auth;
        return auth.register.inputVerificationKey;
    });
    var height = inputVerificationKey ? '42rem' : '52rem';
    return (_jsx(LoginLayout, { children: _jsx(Card, __assign({ sx: {
                width: { xs: '100%', sm: '38rem', md: '40rem', lg: '42rem' },
                height: { xs: '100%', sm: height },
                marginRight: { lg: '10rem' },
                marginTop: { sm: '5rem' },
                borderRadius: { xs: 0, sm: '1rem' },
                backgroundColor: function (theme) { return theme.palette.background.opacityContent; }
            } }, { children: _jsx(CardContent, __assign({ sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    m: '2rem'
                } }, { children: !inputVerificationKey ? (_jsx(RegisterForm, {}, void 0)) : (_jsx(InputVerificationKeyForm, { navigateTo: navigateToFromVar }, void 0)) }), void 0) }), void 0) }, void 0));
};
export default RegisterPage;
