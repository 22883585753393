var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Run = function (props) {
    //fill="#8E8E8E"
    return (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 17 21", stroke: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M14.1147 2C14.1147 3.10457 13.2193 4 12.1147 4C11.0102 4 10.1147 3.10457 10.1147 2C10.1147 0.89543 11.0102 0 12.1147 0C13.2193 0 14.1147 0.89543 14.1147 2Z" }, void 0), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.51374 7.81974C6.54706 7.92174 6.58506 8.05544 6.62747 8.20465C6.64621 8.2706 6.66582 8.33957 6.68626 8.41018C6.79414 8.78279 6.70401 9.19134 6.43019 9.4661L4.98371 10.9176C4.63053 11.272 4.06978 11.3108 3.67112 11.0085L1.49289 9.35655C1.06587 9.0327 0.454264 9.13557 0.156676 9.58128C-0.102257 9.9691 -0.0341629 10.4887 0.315958 10.7967L3.66646 13.7441C4.06301 14.0929 4.66246 14.0732 5.03528 13.6991L6.90111 11.8269C7.23205 11.4948 7.74928 11.4372 8.14515 11.6884L9.41781 12.4958C9.82237 12.7525 9.98804 13.26 9.8128 13.7059L8.42335 17.2416C8.23557 17.7194 8.44517 18.2615 8.90558 18.4886C9.42977 18.7473 10.0627 18.4966 10.2676 17.9492L12.3039 12.5109C12.4781 12.0457 12.2843 11.5231 11.849 11.2839L10.5981 10.5965C10.1291 10.3388 9.94611 9.75762 10.1828 9.27773L10.7089 8.21104C11.0565 7.50629 12.0438 7.45939 12.4566 8.12801L12.778 8.64849C13.0157 9.02915 13.5909 9.0708 13.9687 8.70057C14.4022 8.27644 16.7322 6.5978 16.6147 5.93725C16.5493 5.56904 15.944 5.27437 15.6147 5.43725C15.1658 5.65931 14.7523 6.31115 14.4059 6.66857C13.7555 7.30591 13.2183 6.72893 12.8373 6.12886C12.6541 5.83552 11.9827 4.8951 11.5113 4.64849C11.4645 4.62398 11.4165 4.60262 11.3708 4.57607C10.9274 4.31854 10.3759 3.98543 9.8553 4.03694L6.30508 4.3702C5.85849 4.41438 5.56604 4.9114 5.73027 5.41422L6.51374 7.81974ZM8.62174 5.66069C8.65076 5.60185 8.60227 5.53448 8.53699 5.54094V5.54094L7.93565 5.605C7.66855 5.63143 7.43511 5.80802 7.34206 6.07957C7.27953 6.26204 7.29151 6.50531 7.35752 6.70739V6.70739C7.45038 7.04809 7.91535 7.09289 8.07155 6.77619L8.62174 5.66069Z" }, void 0)] }), void 0));
};
export default Run;
