var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import PageSimpleSidebarContent from './PageSimpleSidebarContent';
import { Drawer, Hidden } from '@mui/material';
function FusePageSimpleSidebar(props, ref) {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var classes = props.classes;
    useImperativeHandle(ref, function () { return ({
        toggleSidebar: handleToggleDrawer
    }); });
    var handleToggleDrawer = function () {
        setIsOpen(!isOpen);
    };
    return (_jsx(React.Fragment, { children: props.variant === 'permanent' && (_jsx(Hidden, __assign({ mdDown: true }, { children: _jsx(Drawer, __assign({ variant: "permanent", className: clsx(classes.sidebarWrapper, props.variant), open: isOpen, classes: {
                    paper: clsx(classes.sidebar, props.variant, props.position === 'left' ? classes.leftSidebar : classes.rightSidebar)
                } }, { children: _jsx(PageSimpleSidebarContent, __assign({}, props), void 0) }), void 0) }), void 0)) }, void 0));
}
export default React.forwardRef(FusePageSimpleSidebar);
