var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { TOURNAMENT_TEAMS } from 'common/graphql/queries/tournament/tournament-teams';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import _ from 'lodash';
import TournamentTeamListItem from './TournamentTeamListItem';
var TournamentTeamsDialog = function (_a) {
    var tournamentId = _a.tournamentId, currentUserId = _a.currentUserId, isUserTeamParticipant = _a.isUserTeamParticipant, handleClose = _a.handleClose;
    var _b = useQuery(TOURNAMENT_TEAMS, {
        fetchPolicy: 'network-only',
        variables: {
            tournamentId: tournamentId,
            showTeam: true
        }
    }), data = _b.data, loading = _b.loading;
    var tournamentTeams = (data === null || data === void 0 ? void 0 : data.tournamentTeams)
        ? _.orderBy(data === null || data === void 0 ? void 0 : data.tournamentTeams, function (tournamentTeam) {
            var _a, _b, _c, _d;
            return [
                (_a = tournamentTeam === null || tournamentTeam === void 0 ? void 0 : tournamentTeam.team) === null || _a === void 0 ? void 0 : _a.areYouParticipant,
                (_c = (_b = tournamentTeam === null || tournamentTeam === void 0 ? void 0 : tournamentTeam.team) === null || _b === void 0 ? void 0 : _b.participationSettings) === null || _c === void 0 ? void 0 : _c.isPrivate,
                (_d = tournamentTeam === null || tournamentTeam === void 0 ? void 0 : tournamentTeam.team) === null || _d === void 0 ? void 0 : _d.usersCount,
            ];
        }, ['desc', 'asc', 'desc'])
        : [];
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (tournamentTeams === null || tournamentTeams === void 0 ? void 0 : tournamentTeams.length) > 0 ? (_jsx("div", __assign({ className: "flex flex-col space-y-20" }, { children: tournamentTeams.map(function (t) {
            return t.team ? (_jsx(TournamentTeamListItem, { team: t.team, currentUserId: currentUserId, isUserTeamParticipant: isUserTeamParticipant, handleClose: handleClose }, t.teamId)) : null;
        }) }), void 0)) : null;
};
export default TournamentTeamsDialog;
