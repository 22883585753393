import { jsx as _jsx } from "react/jsx-runtime";
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Club from 'assets/icons/menu/Club';
import Event from 'assets/icons/menu/Event';
import Standings from 'assets/icons/menu/Standings';
import Stats from 'assets/icons/menu/Stats';
var navigationConfig = [
    {
        id: 'userProfile',
        title: 'Моя страница',
        icon: _jsx(PersonIcon, {}, void 0),
        type: 'profileItem',
        url: "/profile",
        children: []
    },
    {
        id: 'profilesSearch',
        title: 'Поиск спортсменов',
        icon: _jsx(PeopleOutlinedIcon, {}, void 0),
        type: 'item',
        url: "/profiles/search",
        children: []
    },
    {
        id: 'standings',
        title: 'Турнирные таблицы',
        icon: _jsx(Standings, {}, void 0),
        type: 'item',
        url: "/standings",
        children: []
    },
    {
        id: 'stats',
        title: 'Статистика по видам спорта',
        icon: _jsx(Stats, {}, void 0),
        type: 'item',
        url: "/stats/",
        children: []
    },
    {
        id: 'clubs',
        title: 'Клубы',
        icon: _jsx(Club, {}, void 0),
        type: 'item',
        url: "/clubs",
        children: []
    },
    {
        id: 'events',
        title: 'События и турниры',
        icon: _jsx(Event, {}, void 0),
        type: 'item',
        url: "/events",
        children: []
    },
];
export default navigationConfig;
