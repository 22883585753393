var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StandingsParticipantType } from 'common/enums/standings/standings-participant-type.enum';
import _ from 'lodash';
import TournamentNominationWinnersGroupListItem from './TournamentNominationWinnersGroupListItem';
var TournamentNominationWinnersGroup = function (_a) {
    var nomination = _a.nomination, winners = _a.winners, standingsParticipantType = _a.standingsParticipantType, gender = _a.gender, handleClose = _a.handleClose;
    var preparedWinners = _.orderBy(winners, ['place'], ['asc']);
    if (standingsParticipantType === StandingsParticipantType.Team) {
        preparedWinners = _.uniqBy(preparedWinners, function (o) { var _a; return (_a = o.team) === null || _a === void 0 ? void 0 : _a.id; });
    }
    return (_jsx("div", __assign({ className: "flex pt-6 flex-col space-y-8" }, { children: preparedWinners.map(function (winner) {
            var _a, _b;
            return (_jsx(TournamentNominationWinnersGroupListItem, { nomination: nomination, standingsParticipantType: standingsParticipantType, winner: winner, gender: gender, handleClose: handleClose }, winner.id || ((_a = winner.user) === null || _a === void 0 ? void 0 : _a.id) || ((_b = winner.team) === null || _b === void 0 ? void 0 : _b.id)));
        }) }), void 0));
};
export default TournamentNominationWinnersGroup;
