var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Collapse, IconButton, ListItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useReactiveVar } from '@apollo/client';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import NavVerticalGroup from './NavVerticalGroup';
import NavVerticalItem from './NavVerticalItem';
import NavVerticalLink from './NavVerticalLink';
import Utils from '../../../utils/Utils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
var BG_COLOR_HOVER_ACTIVE = '#404040';
var COLOR_LIST_ITEM = '#a6a6a6';
var COLOR_ACTIVE_LIST_ITEM = 'white';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        padding: 0,
        '&.open': {
            backgroundColor: 'rgba(0,0,0,.08)'
        }
    },
    item: {
        height: 40,
        width: 'calc(100% - 20px)',
        '& .list-item-icon': {
            borderRadius: '6px',
            padding: '10px 10px',
            left: 12,
            marginRight: '4px',
            color: COLOR_LIST_ITEM,
            transition: 'background-color 0.3s linear'
        },
        '& .pill-wrap': {
            width: '8px',
            height: 40,
            position: 'absolute',
            '& .pill-wrap-item': {
                borderRadius: '0 10px 10px 0',
                width: '4px',
                transition: 'height 0.1s linear'
            }
        },
        '&.active': {
            //	pointerEvents: 'none',
            transition: 'border-radius .17s cubic-bezier(0.4,0.0,0.2,1)',
            '& .pill-wrap-item': {
                height: 20,
                background: BG_COLOR_HOVER_ACTIVE
            }
        },
        '&.active , &:hover': {
            '& .list-item-icon': {
                color: COLOR_ACTIVE_LIST_ITEM,
                backgroundColor: BG_COLOR_HOVER_ACTIVE
            }
        }
    },
    navbarFolded: {
        opacity: 0,
        display: 'none' + '!important'
    }
}); });
function needsToBeOpened(location, item) {
    return location && isUrlInChildren(item, location.pathname);
}
function isUrlInChildren(parent, url) {
    if (!parent.children) {
        return false;
    }
    for (var i = 0; i < parent.children.length; i++) {
        if (parent.children[i].children) {
            if (isUrlInChildren(parent.children[i], url)) {
                return true;
            }
        }
        if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
            return true;
        }
    }
    return false;
}
var NavVerticalCollapse = function (props) {
    var _a, _b;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var location = useHistory().location;
    var _c = useState(function () { return needsToBeOpened(location, props.item); }), open = _c[0], setOpen = _c[1];
    var _d = useState(function () { return needsToBeOpened(location, props.item); }), activeChildren = _d[0], setActiveChildren = _d[1];
    var item = props.item, nestedLevel = props.nestedLevel, active = props.active, navbarOpen = props.navbarOpen;
    var classes = useStyles({
        itemPadding: nestedLevel > 0 ? 28 + nestedLevel * 16 : 6
    });
    useEffect(function () {
        if (needsToBeOpened(location, props.item)) {
            setOpen(true);
            setActiveChildren(true);
        }
        else {
            setActiveChildren(false);
        }
    }, [location, props.item]);
    function handleClick() {
        setOpen(!open);
    }
    if (!Utils.hasPermission(item.auth, userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles)) {
        return null;
    }
    return (_jsxs("ul", __assign({ className: clsx(classes.root, navbarOpen && open && 'open') }, { children: [_jsxs(ListItem, __assign({ button: true, className: clsx(classes.item, active, activeChildren && !navbarOpen && 'active', activeChildren && !open && 'active'), sx: { pl: 0 }, onClick: handleClick }, { children: [_jsx(Box, __assign({ className: clsx('pill-wrap'), sx: { display: 'flex', alignItems: 'center' } }, { children: _jsx(Box, { className: clsx('pill-wrap-item'), sx: { display: 'flex' } }, void 0) }), void 0), _jsx(Box, __assign({ sx: { display: 'flex', flexGrow: 1 } }, { children: _jsxs(IconButton, __assign({ className: clsx('list-item-icon', (_b = (_a = item.icon) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.className), sx: { display: 'flex', justifyContent: 'space-between', flexGrow: 1 } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'flex-start', flexGrow: 1 } }, { children: [item.icon, _jsx(Typography, __assign({ className: clsx('list-item-title', !navbarOpen && classes.navbarFolded), sx: {
                                                paddingLeft: '1.2rem',
                                                fontWeight: 500,
                                                transition: 'opacity 500ms, max-height 300ms'
                                            } }, { children: item.title }), void 0)] }), void 0), _jsx("div", __assign({ className: clsx(!navbarOpen && classes.navbarFolded, 'flex') }, { children: open ? _jsx(ExpandLessIcon, {}, void 0) : _jsx(ExpandMoreIcon, {}, void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), item.children && navbarOpen && (_jsx(Collapse, __assign({ "in": open, className: "collapse-children" }, { children: item.children.map(function (item) { return (_jsxs(Box, __assign({ sx: { margin: '6px 0 4px 0' } }, { children: [item.type === 'group' && (_jsx(NavVerticalGroup, { item: item, nestedLevel: nestedLevel + 1, active: active, navbarOpen: navbarOpen }, void 0)), item.type === 'collapse' && (_jsx(NavVerticalCollapse, { item: item, nestedLevel: nestedLevel + 1, active: active, navbarOpen: navbarOpen }, void 0)), item.type === 'item' && (_jsx(NavVerticalItem, { item: item, nestedLevel: nestedLevel + 1, active: active, navbarOpen: navbarOpen }, void 0)), item.type === 'link' && (_jsx(NavVerticalLink, { item: item, nestedLevel: nestedLevel + 1, active: active }, void 0))] }), item.id)); }) }), void 0))] }), void 0));
};
// NavVerticalCollapse.propTypes = {
// 	item: PropTypes.shape({
// 		id: PropTypes.string.isRequired,
// 		title: PropTypes.string,
// 		icon: PropTypes.string,
// 		children: PropTypes.array,
// 		nestedLevel: PropTypes.number,
// 	}),
// };
// NavVerticalCollapse.defaultProps = {};
export default React.memo(NavVerticalCollapse);
