var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { FeatureTypes } from 'common/enums/feature/feature-types.enum';
import { UPDATE_TOURNAMENT_TEAM } from 'common/graphql/mutations/tournament/update-tournament-team';
import { TOURNAMENT_TEAMS } from 'common/graphql/queries/tournament/tournament-teams';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import TeamTournamentFeatureVirtualPointsConfiguration from './TeamTournamentFeatureVirtualPointsConfiguration';
var TeamTournamentFeatureConfiguration = function (_a) {
    var _b, _c;
    var tournamentTeam = _a.tournamentTeam, featureType = _a.featureType, handleClose = _a.handleClose;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var errorDetails = _a.errorDetails, code = _a.code;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var updateTournamentFeature = function (data) {
        var _a;
        return useMutation(UPDATE_TOURNAMENT_TEAM, {
            variables: {
                updateTournamentTeamData: {
                    tournamentId: tournamentTeam.tournamentId,
                    teamId: tournamentTeam.teamId,
                    featureConfigs: {
                        configs: __spreadArray(__spreadArray([], __spreadArray([], (((_a = tournamentTeam === null || tournamentTeam === void 0 ? void 0 : tournamentTeam.featureConfigs) === null || _a === void 0 ? void 0 : _a.configs)
                            ? tournamentTeam.featureConfigs.configs
                            : []), true).filter(function (o) { return o.featureType !== featureType; }), true), [
                            __assign({}, data),
                        ], false)
                    }
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TOURNAMENT_TEAMS,
                    variables: {
                        tournamentId: tournamentTeam.tournamentId,
                        teamId: tournamentTeam.teamId
                    }
                },
            ]
        });
    };
    var handleExecuteMutation = useDynamicMutation(updateTournamentFeature, onSuccess, onError).handleExecuteMutation;
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "flex flex-col p-0 pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u043E\u0439 \u0444\u0438\u0447\u0438 \u043A\u043E\u043C\u0430\u043D\u0434\u044B" }), void 0), featureType === FeatureTypes.virtualPoints && (_jsx(TeamTournamentFeatureVirtualPointsConfiguration, { teamFeatureConfig: ((_b = tournamentTeam === null || tournamentTeam === void 0 ? void 0 : tournamentTeam.featureConfigs) === null || _b === void 0 ? void 0 : _b.configs)
                        ? _.find((_c = tournamentTeam === null || tournamentTeam === void 0 ? void 0 : tournamentTeam.featureConfigs) === null || _c === void 0 ? void 0 : _c.configs, { featureType: featureType })
                        : undefined, handleClose: handleClose, handleExecuteMutation: handleExecuteMutation }, void 0))] }), void 0) }, void 0));
};
export default TeamTournamentFeatureConfiguration;
