import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
var Timer = function (props) {
    var initialHours = props.initialHours, initialMinute = props.initialMinute, initialSeconds = props.initialSeconds, callBackFunction = props.callBackFunction, description = props.description;
    var _a = useState(initialHours || 0), hours = _a[0], setHours = _a[1];
    var _b = useState(initialMinute), minutes = _b[0], setMinutes = _b[1];
    var _c = useState(initialSeconds), seconds = _c[0], setSeconds = _c[1];
    useEffect(function () {
        var myInterval = setInterval(function () {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    if (hours === 0) {
                        clearInterval(myInterval);
                    }
                    else {
                        setHours(hours - 1);
                        setMinutes(59);
                    }
                }
                else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
            if (seconds === 1 && minutes === 0 && hours === 0) {
                callBackFunction();
            }
        }, 1000);
        return function () {
            clearInterval(myInterval);
        };
    });
    return (_jsx(_Fragment, { children: minutes === 0 && seconds === 0 && hours === 0 ? null : hours > 0 ? (_jsxs(_Fragment, { children: [hours < 10 ? "".concat(description, " 0").concat(hours) : "".concat(description, " ").concat(hours), ":", minutes < 10 ? "0".concat(minutes) : "".concat(minutes), ":", seconds < 10 ? "0".concat(seconds) : seconds] }, void 0)) : (_jsxs(_Fragment, { children: [minutes < 10 ? "".concat(description, " 0").concat(minutes) : "".concat(description, " ").concat(minutes), ":", seconds < 10 ? "0".concat(seconds) : seconds] }, void 0)) }, void 0));
};
export default Timer;
