import { URL } from 'common/enums/common/url.enum';
import apiService from './apiService';
var registerService = {
    createUser: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/email-register"), payload, null); },
    resendCode: function (payload) { return apiService.POST("".concat(URL.VERIFICATION_URL, "/email/send-key"), payload, null); },
    verifyEmailRegistration: function (payload) {
        return apiService.POST("".concat(URL.AUTH_URL, "/email-register-verify"), payload, null);
    }
};
export default registerService;
