import React, { useContext } from 'react';
import { renderRoutes } from 'react-router-config'
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import ToolbarLayout from './components/ToolbarLayout';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout from './components/LeftSideLayout';
import RightSideLayout1 from './components/RightSideLayout1';
import NavbarWrapperLayout from './components/NavbarWrapperLayout';
import clsx from 'clsx';
import Scrollbars from "../../../components/Scrollbars/Scrollbars";
import Suspense from "../../../components/Suspense/Suspense";
import RootDialog from '../../../components/Dialog/RootDialog';
import CustomizedSnackbars from 'components/Snackbar/CustomizedSnackbars';
import { AppContext } from '../../../Context';


const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        '&.boxed': {
            clipPath: 'inset(0)',
            maxWidth: (props) => `${props.config.containerWidth}px`,
            margin: '0 auto',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          },


        // '&.scroll-body': {
        //     '& $wrapper': {
        //         height: 'auto',
        //         flex: '0 0 auto',
        //         overflow: 'auto'
        //     },
        //     '& $contentWrapper': {},
        //     '& $content': {}
        // },
        // '&.scroll-content': {
        //     '& $wrapper': {},
        //     '& $contentWrapper': {},
        //     '& $content': {}
        // },
        // '& .navigation': {
        //     '& .list-subheader-text, & .list-item-text, & .item-badge, & .arrow-icon': {
        //         transition: theme.transitions.create('opacity', {
        //             duration: theme.transitions.duration.shortest,
        //             easing: theme.transitions.easing.easeInOut
        //         })
        //     },
        // }
    },
    wrapper: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: '1 1 auto',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: 3,
        overflow: 'hidden',
        flex: '1 1 auto'
    },
    content: {
        position: 'relative',
        display: 'flex',
        overflow: 'auto',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '100%',
        '-webkit-overflow-scrolling': 'touch',
        zIndex: 2
    }
}));


function Layout(props) {
    const config = useSelector(({ base }) => base.settings.current.layout.config);
    const classes = useStyles({ ...props, config });

    const appContext = useContext(AppContext);
    const { routes } = appContext;


    switch (config.scroll) {
        case 'body': {
            return (
                <div id="body-layout" className={clsx(classes.root, config.mode, 'scroll-' + config.scroll, 'w-full flex')}>

                    {config.leftSidePanel.display && (
                        <LeftSideLayout />
                    )}

                    <div className="flex flex-1 flex-col overflow-hidden relative">

                        {config.toolbar.display && config.toolbar.style === 'fixed' && config.toolbar.position === 'above' && (
                            <ToolbarLayout />
                        )}

                        {config.footer.display && config.footer.style === 'fixed' && config.footer.position === 'above' && (
                            <FooterLayout1 />
                        )}

                    </div>

                    {config.rightSidePanel.display && (
                        <RightSideLayout1 />
                    )}

                </div>
            );
        }



        case 'content':
        default: {
            return (
                <div id="content-layout" style={config.contentStyle} className={clsx(classes.root, config.mode, 'scroll-' + config.scroll, 'w-full flex')}>

                    <CustomizedSnackbars />

                    <RootDialog />

                    {config.leftSidePanel.display && (
                        <LeftSideLayout />
                    )}
                    <div className="flex flex-1 flex-col overflow-hidden relative">

                        {config.toolbar.display && config.toolbar.position === 'above' && (
                            <ToolbarLayout />
                        )}

                        <div className={classes.wrapper}>

                            {config.navbar.display && config.navbar.position === 'left' && (
                                <NavbarWrapperLayout />
                            )}

                            {/* //position: "below" //style: "fixed" */}
                            <div className={classes.contentWrapper}>
                                {config.toolbar.display && config.toolbar.position === 'below' && config.toolbar.style === 'fixed' && (
                                    <ToolbarLayout />
                                )}
                                {/* скролл в начало страницы */}
                                <Scrollbars className={classes.content} scrollToTopOnChildChange={false}>
                                    {config.toolbar.display && config.toolbar.position === 'below' && config.toolbar.style !== 'fixed' && (
                                        <ToolbarLayout />
                                    )}

                                    <Suspense>
                                        {renderRoutes(routes)}
                                    </Suspense>
                                    {props.children}

                                    {config.footer.display && config.footer.position === 'below' && config.footer.style !== 'fixed' && (
                                        <FooterLayout1 />
                                    )}
                                </Scrollbars>

                                {config.footer.display && config.footer.position === 'below' && config.footer.style === 'fixed' && (
                                    <FooterLayout1 />
                                )}

                            </div>

                            {config.navbar.display && config.navbar.position === 'right' && (
                                <NavbarWrapperLayout />
                            )}
                        </div>

                        {config.footer.display && config.footer.position === 'above' && (
                            <FooterLayout1 />
                        )}
                    </div>

                    {config.rightSidePanel.display && (
                        <RightSideLayout1 />
                    )}

                </div>
            )
        }
    }
}

export default Layout;