var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Box, TextField } from '@mui/material';
import { SocialLinkTypes } from 'common/enums/sport-club/social-link-types.enum';
var ClubContactTypeField = function (_a) {
    var value = _a.value, onChange = _a.onChange, label = _a.label, isError = _a.isError, helperText = _a.helperText, name = _a.name, disabled = _a.disabled;
    return (_jsx("div", { children: _jsx(Autocomplete, { options: Object.keys(SocialLinkTypes), getOptionLabel: function (option) { return option; }, value: value, renderInput: function (params) {
                var _a;
                return (_jsx(TextField, __assign({ name: name }, params, { error: isError, helperText: helperText, label: label, inputProps: __assign(__assign({}, params.inputProps), { value: (_a = params === null || params === void 0 ? void 0 : params.inputProps) === null || _a === void 0 ? void 0 : _a.value }) }), void 0));
            }, onChange: function (e, value) { return value && onChange(name, value); }, renderOption: function (props, option) { return (_jsx(Box, __assign({ component: "li", sx: { '& > img': { mr: 2, flexShrink: 0 } } }, props, { children: option }), void 0)); }, disabled: disabled, disableClearable: true }, void 0) }, void 0));
};
export default ClubContactTypeField;
