var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EditIcon from '@mui/icons-material/Edit';
import { ListItemText, MenuItem, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
var useStyles = makeStyles(function () { return ({
    input: {
        display: 'none'
    },
    avatarWrapper: {
        width: '125px',
        height: '125px',
        position: 'relative',
        '&:hover': {
            '& $uploadButton': {
                opacity: 1
            }
        }
    },
    avatarEditIconWrapper: {
        position: 'absolute',
        top: 0,
        right: '-20px'
    },
    uploadButton: {
        opacity: 0,
        position: 'absolute',
        top: 50,
        right: 50
    }
}); });
var ProfilePhotoUpload = function (props) {
    var fieldName = props.fieldName, id = props.id, profilePhoto = props.profilePhoto, firstName = props.firstName, handleChangeData = props.handleChangeData;
    var dispatch = useDispatch();
    var classes = useStyles(props);
    var t = useTranslation().t;
    var onUploadMessage = function (_a) {
        var code = _a.code, severity = _a.severity;
        dispatch(openSnackbar({
            message: code,
            severity: severity || SnackbarSeverity.ERROR
        }));
    };
    var onSuccessRemoveMessage = function () {
        dispatch(openSnackbar({
            message: t('Фото удалено'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    // const { handleExecuteMutation } = useDynamicMutation(handleChangeData, undefined, onUploadMessage);
    var handleOpenPhotoCrop = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.ProfilePhotoCropDialog,
            dialogProps: {
                id: id,
                handleChangeData: handleChangeData,
                editPhotoMenuClose: editPhotoMenuClose
            }
        }));
    };
    var handleRemovePhotoMutation = useDynamicMutation(handleChangeData, onSuccessRemoveMessage, onUploadMessage).handleExecuteMutation;
    var _a = useState(null), editPhotoMenu = _a[0], setEditPhotoMenu = _a[1];
    var userEditButtonClick = function (event) {
        setEditPhotoMenu(event.currentTarget);
    };
    var editPhotoMenuClose = function () {
        setEditPhotoMenu(null);
    };
    // const handleCapture = async (event: any) => {
    // 	var file: File = event.target.files[0];
    // 	event.target.value = null; // if upload same file twice event will fire
    // 	if (file.size > 30000000) {
    // 		onUploadMessage({ code: 'Размер файла не должен превышать 30Мб' });
    // 		return;
    // 	}
    // 	if (file.size >= 1000000) {
    // 		const options = {
    // 			maxSizeMB: 1,
    // 			maxWidthOrHeight: 1920,
    // 			useWebWorker: true,
    // 		};
    // 		try {
    // 			file = await imageCompression(file, options);
    // 			// console.log('compressedFile instanceof Blob', file instanceof Blob); // true
    // 			// console.log(`compressedFile size ${file.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    // 		} catch (error) {
    // 			console.log(error);
    // 		}
    // 	}
    // 	const fileReader = new FileReader();
    // 	fileReader.readAsDataURL(file);
    // 	fileReader.onload = () => {
    // 		const result = fileReader.result?.toString();
    // 		if (result) {
    // 			const fileMetadata = result.split(',')[0]; // "data:image/jpeg;base64,<base64 encoded string>"
    // 			const fileType = fileMetadata.split(':')[1].split(';')[0];
    // 			if (fileType !== 'image/jpeg' && fileType !== 'image/jpg' && fileType !== 'image/png') {
    // 				onUploadMessage({ code: 'Неподдерживаемый формат, используйте JPEG или PNG' });
    // 				return;
    // 			}
    // 			handleExecuteMutation({
    // 				id: id,
    // 				profilePhoto: file,
    // 			});
    // 		}
    // 	};
    // 	fileReader.onerror = () => {
    // 		console.log('fileReader error');
    // 		onUploadMessage({ code: 'Ошибка загрузки' });
    // 	};
    // };
    var removePhoto = function () {
        if (profilePhoto) {
            handleRemovePhotoMutation({
                id: id,
                removeProfilePhoto: true
            });
        }
        editPhotoMenuClose();
    };
    return (_jsx("div", __assign({ className: clsx(classes.avatarWrapper) }, { children: _jsxs(Fragment, { children: [_jsx(UserAvatar, { style: "w-128 h-128 text-40", 
                    // {{ width: 125, height: 125, fontSize: 45 }}
                    firstName: firstName, profilePhoto: profilePhoto }, void 0), _jsxs("div", __assign({ className: classes.avatarEditIconWrapper }, { children: [_jsx(EditIcon, { onClick: userEditButtonClick }, void 0), _jsxs(Popover, __assign({ open: Boolean(editPhotoMenu), anchorEl: editPhotoMenu, onClose: editPhotoMenuClose, anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center'
                            }, transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center'
                            }, classes: {} }, { children: [_jsx(MenuItem, __assign({ onClick: handleOpenPhotoCrop }, { children: _jsx("label", __assign({ htmlFor: "button-file" }, { children: _jsx(ListItemText, { primary: t('Обновить фото') }, void 0) }), void 0) }), void 0), profilePhoto && (_jsx(MenuItem, __assign({ onClick: removePhoto }, { children: _jsx(ListItemText, { primary: t('Удалить фото') }, void 0) }), void 0))] }), void 0)] }), void 0)] }, void 0) }), void 0));
};
export default ProfilePhotoUpload;
