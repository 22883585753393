var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Actions from '../../actions/base/message.actions';
var initialState = {
    state: null,
    options: {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
        },
        autoHideDuration: 6000,
        message: 'Hi',
        variant: null
    }
};
var message = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case Actions.SHOW_MESSAGE: {
            return {
                state: true,
                options: __assign(__assign({}, state.options), action.options)
            };
        }
        case Actions.HIDE_MESSAGE: {
            return __assign(__assign({}, state), { state: null });
        }
        default: {
            return state;
        }
    }
};
export default message;
