var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import { Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { sportEventUserSearchPageVar } from 'utils/apollo/reactivities/sporteventUserSearchPageVar';
var SportEventUsersSearchField = function () {
    var t = useTranslation().t;
    var sportEventUserSearchFieldInput = useReactiveVar(sportEventUserSearchPageVar).sportEventUserSearchFieldInput;
    var setSportEventUsersSearchFieldInput = function (event) {
        sportEventUserSearchPageVar(__assign(__assign({}, sportEventUserSearchPageVar()), { sportEventUserSearchFieldInput: event.target.value }));
    };
    return (_jsx("div", __assign({ className: "flex flex-wrap p-2 " }, { children: _jsxs("div", __assign({ className: "flex px-8  py-3 rounded-8 border-1\n\t\t\t\tborder-border-lt  \n\t\t\t\tdark:border-border-dk \n\t\t\t\titems-center xs:w-full sm:w-360 lg:w-400" }, { children: [_jsx(SearchIcon, { className: "h-20 w-20 dark:text-white" }, void 0), _jsx(Input, { className: "pl-8", placeholder: t('Поиск'), disableUnderline: true, fullWidth: true, value: sportEventUserSearchFieldInput, inputProps: {
                        'aria-label': 'Search'
                    }, onChange: function (ev) { return setSportEventUsersSearchFieldInput(ev); }, autoFocus: true }, void 0)] }), void 0) }), void 0));
};
export default SportEventUsersSearchField;
