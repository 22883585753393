var administrationNavigationConfig = [
    {
        id: 'activity-validation',
        title: 'Валидации тренировок',
        description: 'Ожидающие рассмотрения',
        handle: 'activity-validation'
    },
    {
        id: 'support-requests',
        title: 'Обращения пользователей',
        handle: 'support-requests'
    },
    {
        id: 'notification-management',
        title: 'Управление оповещениями',
        handle: 'notification-management'
    },
];
export default administrationNavigationConfig;
