var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var VKContact = function (props) {
    return (_jsx("svg", __assign({ version: "1.0", xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 90.000000 90.000000", preserveAspectRatio: "xMidYMid meet" }, props, { children: _jsx("g", __assign({ transform: "translate(0.000000,90.000000) scale(0.100000,-0.100000)" }, { children: _jsx("path", { d: "M140 760 c-19 -19 -20 -33 -20 -310 0 -277 1 -291 20 -310 19 -19 33\n-20 310 -20 277 0 291 1 310 20 19 19 20 33 20 310 0 277 -1 291 -20 310 -19\n19 -33 20 -310 20 -277 0 -291 -1 -310 -20z m344 -206 c3 -9 6 -36 6 -61 0\n-44 1 -45 19 -29 10 9 26 34 36 56 17 39 20 40 67 40 29 0 48 -4 48 -11 0 -7\n-16 -34 -35 -60 -19 -27 -35 -52 -35 -56 0 -4 16 -24 36 -44 20 -21 33 -42 30\n-48 -4 -6 -26 -11 -50 -11 -34 0 -48 6 -71 30 -30 31 -45 31 -45 0 0 -27 -15\n-33 -62 -25 -55 10 -107 59 -152 144 -20 38 -36 72 -36 75 0 3 18 6 39 6 36 0\n41 -4 64 -43 37 -63 53 -74 61 -42 7 29 -2 75 -16 75 -5 0 -6 5 -3 10 11 17\n92 12 99 -6z" }, void 0) }), void 0) }), void 0));
};
export default VKContact;
