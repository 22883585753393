import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import { Redirect } from 'react-router-dom';
import SportEventDatesSettingsPage from './sportEventDatesSettings/SportEventDatesSettingsPage';
import SportEventParticipantionSettingsPage from './sportEventParticipationSettings/SportEventParticipationSettingsPage';
import SportEventPrivacyAndVisibilitySettingsPage from './sportEventPrivacyAndVisibilitySettings/SportEventPrivacyAndVisibilitySettingsPage';
import SportEventProfile from './sportEventProfile/SportEventProfile';
import SportEventSettingsPage from './SportEventSettingsPage';
import SportEventSportsSettingsPage from './sportEventsSportsSettings/SportEventSportsSettingsPage';
import SportEventTournamentsSettingsPage from './sportEventTournamentSettings/SportEventTournamentsSettingsPage';
export var sportEventSettingsPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: ['/sport-event-settings/:sportEventId/sport-event-tournaments-settings'],
            component: function (props) {
                var sportEventId = props.match.params.sportEventId;
                return (_jsx(SportEventSettingsPage, { children: _jsx(SportEventTournamentsSettingsPage, { sportEventId: sportEventId }, void 0) }, void 0));
            }
        },
        {
            path: ['/sport-event-settings/:sportEventId/sport-event-participation-settings'],
            component: function (props) {
                var sportEventId = props.match.params.sportEventId;
                return (_jsx(SportEventSettingsPage, { children: _jsx(SportEventParticipantionSettingsPage, { sportEventId: sportEventId }, void 0) }, void 0));
            }
        },
        {
            path: ['/sport-event-settings/:sportEventId/sport-event-sports-settings'],
            component: function (props) {
                var sportEventId = props.match.params.sportEventId;
                return (_jsx(SportEventSettingsPage, { children: _jsx(SportEventSportsSettingsPage, { sportEventId: sportEventId }, void 0) }, void 0));
            }
        },
        {
            path: ['/sport-event-settings/:sportEventId/sport-event-privacy-and-visibility-settings'],
            component: function (props) {
                var sportEventId = props.match.params.sportEventId;
                return (_jsx(SportEventSettingsPage, { children: _jsx(SportEventPrivacyAndVisibilitySettingsPage, { sportEventId: sportEventId }, void 0) }, void 0));
            }
        },
        {
            path: ['/sport-event-settings/:sportEventId/sport-event-dates-settings'],
            component: function (props) {
                var sportEventId = props.match.params.sportEventId;
                return (_jsx(SportEventSettingsPage, { children: _jsx(SportEventDatesSettingsPage, { sportEventId: sportEventId }, void 0) }, void 0));
            }
        },
        {
            path: ['/sport-event-settings/:sportEventId/sport-event-profile'],
            component: function (props) {
                var sportEventId = props.match.params.sportEventId;
                return (_jsx(SportEventSettingsPage, { children: _jsx(SportEventProfile, { sportEventId: sportEventId }, void 0) }, void 0));
            }
        },
        {
            path: '/sport-event-settings/:sportEventId',
            component: function (props) {
                var sportEventId = props.match.params.sportEventId;
                return _jsx(Redirect, { to: "/sport-event-settings/".concat(sportEventId, "/sport-event-profile") }, void 0);
            }
        },
        {
            path: '/sport-event-settings',
            component: function (props) {
                var sportEventId = props.match.params.sportEventId;
                return _jsx(Redirect, { to: "/sport-event-settings/".concat(sportEventId, "/sport-event-profile") }, void 0);
            }
        },
    ]
};
