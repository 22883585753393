var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { Button, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { UserLanguage } from 'common/enums/user/user-language.enum';
import { UPDATE_USER } from 'common/graphql/mutations/user/updateUser/updateUser';
import { USER } from 'common/graphql/queries/user/user';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import GenderSelect from 'components/Select/GenderSelect';
import LanguageSelect from 'components/Select/LanguageSelect';
import { enUS, ru } from 'date-fns/locale';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'store/actions';
var FillUserProfileDialog = function (_a) {
    var id = _a.id, firstName = _a.firstName, lastName = _a.lastName, birthDate = _a.birthDate, gender = _a.gender, handleClose = _a.handleClose;
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var dispatch = useDispatch();
    var defaultFormState = {
        id: id,
        lastName: lastName || '',
        firstName: firstName || '',
        gender: gender || '',
        birthDate: birthDate && new Date(birthDate)
    };
    var updateUser = function (variables) {
        return useMutation(UPDATE_USER, {
            variables: {
                updateUserData: __assign({}, variables === null || variables === void 0 ? void 0 : variables.updateUserData),
                profilePhoto: variables === null || variables === void 0 ? void 0 : variables.profilePhoto,
                showMainSettings: true
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER,
                    fetchPolicy: 'network-only',
                    variables: {
                        showRoles: true,
                        showMainSettings: true,
                        showSocialSettings: true,
                        showSportClub: true
                    }
                },
            ]
        });
    };
    var handleExecuteMutation = useDynamicMutation(updateUser, handleClose).handleExecuteMutation;
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var handleSubmit = function (_a) {
        var id = _a.id, rest = __rest(_a, ["id"]);
        handleExecuteMutation({
            updateUserData: {
                id: id,
                mainSettings: __assign({}, rest)
            },
            showMainSettings: true
        });
        // window.location.reload();
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: userValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    var onLogout = function () {
        dispatch(logoutUser());
        handleClose();
    };
    return (_jsxs("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsxs(DialogContent, __assign({ sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 0 2rem 0'
                } }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Заполните профиль') }), void 0), _jsx(Typography, __assign({ className: "dark:text-white", sx: { fontSize: '1.2rem', fontWeight: 300, textAlign: 'center', paddingBottom: '1rem' } }, { children: t('Эти данные помогут нам корректно отображать вашу статистику и ваше положение в турнирных таблицах') }), void 0), _jsx("div", __assign({ className: "w-full pb-10" }, { children: _jsx(LanguageSelect, { userId: id, currentLang: i18n.language === 'en' ? UserLanguage.En : UserLanguage.Ru }, void 0) }), void 0), _jsx(TextField, { label: t('Имя'), name: "firstName", value: formik.values['firstName'], onChange: handleChangeWrapper, error: Boolean(formik.errors['firstName']), helperText: formik.errors['firstName'] }, void 0), _jsx(TextField, { label: t('Фамилия'), autoFocus: true, name: "lastName", value: formik.values['lastName'], onChange: handleChangeWrapper, error: Boolean(formik.errors['lastName']), helperText: formik.errors['lastName'] }, void 0), _jsx(GenderSelect, { value: formik.values['gender'], handleChange: function (name, value) {
                            formik.setFieldValue(name, value);
                            formik.setFieldError(name, undefined);
                        }, disableClearable: true, errorMessage: formik.errors['gender'], name: "gender" }, void 0), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns, locale: i18n.language === 'en' ? enUS : ru }, { children: _jsx(DatePicker, { mask: "__/__/____", inputFormat: "dd/MM/yyyy", label: t('День рождения'), value: formik.values['birthDate']
                                ? new Date(formik.values['birthDate'])
                                : formik.values['birthDate'], minDate: new Date('1921-01-01'), onChange: function (date) {
                                formik.setFieldError('birthDate', undefined);
                                formik.setFieldValue('birthDate', date);
                            }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { error: Boolean(formik.errors['birthDate']), helperText: formik.errors['birthDate'] }), void 0)); } }, void 0) }), void 0)] }), void 0), _jsxs(DialogActions, __assign({ sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 0
                } }, { children: [_jsx(Button, __assign({ variant: "text", color: "secondary", onClick: onLogout }, { children: t('Выйти') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Далее') }), void 0)] }), void 0)] }), void 0));
};
export default FillUserProfileDialog;
