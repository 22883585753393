var sportEventSettingsNavigationConfig = [
    {
        id: 'sport-event-profile',
        title: 'Профайл события',
        handle: 'sport-event-profile'
    },
    {
        id: 'sport-event-dates-settings',
        title: 'Даты проведения',
        handle: 'sport-event-dates-settings'
    },
    {
        id: 'sport-event-sports-settings',
        title: 'Виды спорта',
        handle: 'sport-event-sports-settings'
    },
    {
        id: 'sport-event-privacy-and-visibility-settings',
        title: 'Настройка приватности и видимости',
        handle: 'sport-event-privacy-and-visibility-settings'
    },
    {
        id: 'sport-event-participation-settings',
        title: 'Управление участниками',
        handle: 'sport-event-participation-settings'
    },
    {
        id: 'sport-event-tournaments-settings',
        title: 'Управление турнирами',
        handle: 'sport-event-tournaments-settings'
    },
];
export default sportEventSettingsNavigationConfig;
