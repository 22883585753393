var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql, useMutation } from '@apollo/client';
import { USER } from 'common/graphql/queries/user/user';
export var UPDATE_USER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation updateUser($updateUserData: UpdateUserInput!, $profilePhoto: Upload) {\n\t\tupdateUser(updateUserData: $updateUserData, profilePhoto: $profilePhoto) {\n\t\t\tid\n\t\t}\n\t}\n"], ["\n\tmutation updateUser($updateUserData: UpdateUserInput!, $profilePhoto: Upload) {\n\t\tupdateUser(updateUserData: $updateUserData, profilePhoto: $profilePhoto) {\n\t\t\tid\n\t\t}\n\t}\n"])));
export function useUpdateUserMutation(userVariables) {
    var profilePhoto;
    var mainSettingsFields = ['firstName', 'about', 'lastName', 'birthDate', 'gender', 'location'];
    var userData = {
        mainSettings: {}
    };
    var _loop_1 = function (key) {
        var _a, _b, _c, _d;
        if (key !== 'confirmPassword' && key !== 'profilePhoto' && key !== 'removeProfilePhoto') {
            var isMainSettings = mainSettingsFields.filter(function (s) { return s === key; });
            // ключи
            if (isMainSettings.length > 0) {
                userData = __assign(__assign({}, userData), { mainSettings: __assign(__assign({}, userData.mainSettings), (_a = {}, _a[key] = userVariables[key], _a)) });
            }
            else if (key === 'isPrivate') {
                userData = __assign(__assign({}, userData), { privacy: (_b = {},
                        // @ts-ignore: Unreachable code error
                        _b[key] = userVariables[key],
                        _b) });
            }
            else if (key === 'phoneNumber') {
                userData = __assign(__assign({}, userData), { contact: (_c = {},
                        // @ts-ignore: Unreachable code error
                        _c[key] = userVariables[key],
                        _c) });
            }
            else {
                userData = __assign(__assign({}, userData), (_d = {}, _d[key] = userVariables[key], _d));
            }
        }
    };
    for (var key in userVariables) {
        _loop_1(key);
    }
    if (userVariables === null || userVariables === void 0 ? void 0 : userVariables.profilePhoto) {
        profilePhoto = userVariables.profilePhoto;
    }
    if (userVariables === null || userVariables === void 0 ? void 0 : userVariables.removeProfilePhoto) {
        profilePhoto = null;
    }
    return useMutation(UPDATE_USER, {
        variables: __assign({ updateUserData: __assign({}, userData) }, (typeof profilePhoto !== undefined && { profilePhoto: profilePhoto })),
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: USER,
                variables: {
                    showMainSettings: true,
                    showContact: true
                }
            },
        ]
    });
}
var templateObject_1;
