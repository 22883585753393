import { jsx as _jsx } from "react/jsx-runtime";
import { TableBody } from '@mui/material';
import BasicTableHelper from 'components/BasicTable/BasicTableHelper';
import { useState } from 'react';
import BasicTableRow from './BasicTableRow';
var BasicTableBody = function (_a) {
    var visibleColumns = _a.visibleColumns, collapsedColumns = _a.collapsedColumns, rows = _a.rows, highlightedRowConditionFunc = _a.highlightedRowConditionFunc, order = _a.order, orderByColumn = _a.orderByColumn, page = _a.page, rowsPerPage = _a.rowsPerPage, onRowClick = _a.onRowClick, _b = _a.backendPagination, backendPagination = _b === void 0 ? false : _b, _c = _a.isAlignRowHeight, isAlignRowHeight = _c === void 0 ? false : _c, unwrapRowsInMobile = _a.unwrapRowsInMobile, isShowHighlightedRow = _a.isShowHighlightedRow, isShowStandingsPages = _a.isShowStandingsPages, setShowedHighlightedRow = _a.setShowedHighlightedRow, participantType = _a.participantType;
    var getComparator = BasicTableHelper.getComparator;
    var _d = useState(0), rowHeight = _d[0], setRowHeight = _d[1];
    return (_jsx(TableBody, { children: rows
            .slice()
            .sort(getComparator(order, orderByColumn))
            .slice(backendPagination ? 0 : page * rowsPerPage, backendPagination ? rowsPerPage : page * rowsPerPage + rowsPerPage)
            .map(function (row, index) {
            return (_jsx(BasicTableRow, { isAlignRowHeight: isAlignRowHeight, rowHeight: rowHeight, setRowHeight: setRowHeight, visibleColumns: visibleColumns, collapsedColumns: collapsedColumns, row: row, index: index, highlightedRowConditionFunc: highlightedRowConditionFunc, onRowClick: onRowClick, page: page, rowsPerPage: rowsPerPage, unwrapRowsInMobile: unwrapRowsInMobile, isShowHighlightedRow: isShowHighlightedRow, isShowStandingsPages: isShowStandingsPages, setShowedHighlightedRow: setShowedHighlightedRow, participantType: participantType }, index));
        }) }, void 0));
};
export default BasicTableBody;
