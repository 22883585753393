var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import VKIconV2 from 'assets/logos/VKIconV2';
import { appHost, appScheme } from 'common/appURLSetup';
import { vkClientId } from 'common/socialClientIdsSetup';
import * as queryString from 'querystring';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { vkSignIn } from 'store/actions';
var VKSignIn = function (props) {
    var dispatch = useDispatch();
    useEffect(function () {
        if (props.history.location.pathname === '/login/vkontakte/') {
            var handleLogin = function (code) {
                dispatch(vkSignIn(code, sessionStorage.getItem('join')));
            };
            var queryObj = queryString.parse(props.location.search);
            if (queryObj && queryObj['?code']) {
                handleLogin(queryObj['?code']);
            }
        }
    }, [props.location.search, props.history]);
    var handleRedirect = function () {
        var cbLink = "".concat(appScheme, "://").concat(appHost, "/login/vkontakte/");
        window.location.href = "https://oauth.vk.com/authorize?client_id=".concat(vkClientId, "\n    &display=popup&redirect_uri=").concat(cbLink, "&scope=email&response_type=code&v=5.130");
    };
    return (_jsx(IconButton, __assign({ sx: { width: '6rem' }, onClick: handleRedirect }, { children: _jsx(VKIconV2, {}, void 0) }), void 0));
};
export default withRouter(VKSignIn);
