import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Avatar, AvatarGroup } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { TEAM_USERS_SEARCH } from 'common/graphql/queries/team/team-users-search';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
var TeamUsersAvatarGroup = function (_a) {
    var teamId = _a.teamId, teamUsersTotalCount = _a.teamUsersTotalCount;
    var children = [];
    var data = useQuery(TEAM_USERS_SEARCH, {
        fetchPolicy: 'network-only',
        variables: {
            teamId: teamId,
            limit: 3
        }
    }).data;
    var teamUsers = (data === null || data === void 0 ? void 0 : data.teamUsersSearch) || [];
    for (var _i = 0, teamUsers_1 = teamUsers; _i < teamUsers_1.length; _i++) {
        var teamUser = teamUsers_1[_i];
        children.push(_jsx(Avatar, { alt: teamUser.firstName, src: teamUser.profilePhotoUrl }, teamUser.userId));
    }
    var dispatch = useDispatch();
    var openTeamUsersDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.TournamentTeamUsersDialog,
            dialogProps: {
                teamId: teamId,
                teamUsersTotalCount: teamUsersTotalCount
            }
        }));
    };
    return (_jsx(AvatarGroup, { className: "cursor-pointer", onClick: function () { return openTeamUsersDialog(); }, total: teamUsersTotalCount, sx: {
            '& .MuiAvatar-root': { width: 28, height: 28, fontSize: 15 }
        }, children: children }, void 0));
};
export default TeamUsersAvatarGroup;
