var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Checkbox, DialogActions, FormControlLabel, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { TeamParentType } from 'common/enums/tournament/team-parent-type.enum';
import { TeamParticipantType } from 'common/enums/tournament/team-participant-type.enum';
import { CREATE_TEAM } from 'common/graphql/mutations/team/create-team';
import { TOURNAMENT } from 'common/graphql/queries/tournament/tournament';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import teamValidationSchema from 'common/validationSchemas/teamValidationSchema';
import Button from 'components/Buttons/Button';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var CreateTournamentTeamDialogDialog = function (_a) {
    var tournamentId = _a.tournamentId, tournamentName = _a.tournamentName, handleClose = _a.handleClose;
    var defaultFormState = {
        name: '',
        displayName: '',
        isPrivate: false,
        parentType: TeamParentType.Tournament,
        parentId: tournamentId,
        participantType: TeamParticipantType.User
    };
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var createTeam = function (data) {
        return useMutation(CREATE_TEAM, {
            variables: {
                createTeamData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TOURNAMENT,
                    variables: {
                        id: tournamentId,
                        showTeams: true
                    }
                },
            ]
        });
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Команда создана.',
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(createTeam, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        var createObj = __assign(__assign({}, data), { name: "".concat(tournamentName, "_").concat(data.name) });
        handleExecuteMutation(__assign({}, createObj));
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: teamValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u043A\u043E\u043C\u0430\u043D\u0434\u0443" }), void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-20" }, { children: [_jsx(TextField, { className: "", label: "\u0423\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u043E\u0435 \u0438\u043C\u044F", name: "name", value: formik.values['name'], onChange: handleChangeWrapper, error: Boolean(formik.errors['name']), helperText: formik.errors['name'] }, void 0), _jsx(TextField, { className: "", label: "\u041E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u043C\u043E\u0435 \u0438\u043C\u044F", name: "displayName", value: formik.values['displayName'], onChange: handleChangeWrapper, error: Boolean(formik.errors['displayName']), helperText: formik.errors['displayName'] }, void 0), _jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { checked: formik.values['isPrivate'] || false, onChange: function (e) {
                                        formik.setFieldError('isPrivate', undefined);
                                        formik.setFieldValue('isPrivate', e.target.checked);
                                    } }, void 0), label: "\u041A\u043E\u043C\u0430\u043D\u0434\u0430 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u0430\u044F. \u0412\u0445\u043E\u0434 \u0442\u043E\u043B\u044C\u043A\u043E \u043F\u043E \u0437\u0430\u044F\u0432\u043A\u0430\u043C \u0438\u043B\u0438 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u043E\u0439 \u0441\u0441\u044B\u043B\u043A\u0435." }, void 0)] }), void 0)] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm" }, { children: t('Создать') }), void 0)] }), void 0)] }), void 0));
};
export default CreateTournamentTeamDialogDialog;
