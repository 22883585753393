var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import { SPORT_EVENTS_SHORT } from 'common/graphql/queries/sportEvent/sportEventsShort';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export var SportEventAutocomplete = function (_a) {
    var selectedValueId = _a.selectedValueId, setSelectedOptions = _a.setSelectedOptions, isError = _a.isError, errorText = _a.errorText;
    var t = useTranslation().t;
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    var _c = useQuery(SPORT_EVENTS_SHORT), sportEventsData = _c.data, loading = _c.loading;
    useEffect(function () {
        if (sportEventsData) {
            setOptions(sportEventsData.sportEvents);
        }
    }, [sportEventsData]);
    return (_jsx(Autocomplete, { options: options, value: options.filter(function (o) { return o.id === selectedValueId; })[0], getOptionLabel: function (option) { var _a; return typeof option === 'object' ? ((_a = option.mainSettings) === null || _a === void 0 ? void 0 : _a.displayName) || option.name : option; }, renderInput: function (params) { return (_jsx(TextField, __assign({ name: "sportEvent", label: t('Выберите событие'), error: isError, helperText: errorText }, params), void 0)); }, onInputChange: function (event, newInputValue, reason) {
            if (reason === 'clear') {
                setSelectedOptions(undefined);
            }
        }, onChange: function (event, value) {
            if (typeof value === 'object')
                setSelectedOptions(value);
        }, loading: loading, isOptionEqualToValue: function (option, value) { return option.id === value.id; } }, void 0));
};
