var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Box, Button, TextField } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_USER_EMAIL } from 'common/graphql/mutations/user/updateUser/update-user-email';
import { USER } from 'common/graphql/queries/user/user';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
var UpdateUserEmailSetting = function (_a) {
    var exitEditMode = _a.exitEditMode;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var openVerifyUserEmailDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.VerifyUserEmailDialog,
            dialogProps: { isCodeSentInitially: true, email: formik.values['email'] }
        }));
    };
    var onSuccessSendCode = function () {
        dispatch(openSnackbar({
            message: t('Мы отправили 6-значный код на' + ' ' + formik.values['email']),
            severity: SnackbarSeverity.SUCCESS
        }));
        exitEditMode(undefined);
        openVerifyUserEmailDialog();
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var updateUserEmail = function (data) {
        return useMutation(UPDATE_USER_EMAIL, {
            variables: {
                updateUserEmailData: {
                    email: data
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER,
                    variables: {
                        showMainSettings: true,
                        showVerified: true
                    }
                },
            ]
        });
    };
    var handleExecuteUpdateUserEmail = useDynamicMutation(updateUserEmail, onSuccessSendCode, onError).handleExecuteMutation;
    var formik = useFormik({
        initialValues: { email: '' },
        validationSchema: userValidationSchema.pick(['email']),
        onReset: exitEditMode,
        onSubmit: function (values) {
            handleExecuteUpdateUserEmail(values.email);
        },
        validateOnChange: false,
        validateOnBlur: false
    });
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    return (_jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs("div", { children: [_jsx(TextField, { required: true, label: t('Эл. почта'), name: "email", value: formik.values['email'], onChange: handleChangeWrapper, error: Boolean(formik.errors['email']), helperText: Boolean(formik.errors['email']) && t(formik.errors['email'] || '') }, void 0), _jsxs(Box, __assign({ sx: { mt: '0.8rem' } }, { children: [_jsx(Button, __assign({ sx: { mr: '1.2rem' }, type: "reset", color: "secondary" }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)] }, void 0) }), void 0));
};
export default UpdateUserEmailSetting;
