import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import { Redirect } from 'react-router-dom';
import ClubContacts from './clubContacts/ClubContacts';
import ClubParticipants from './clubParticipants/ClubParticipants';
import ClubProfile from './clubProfile/ClubProfile';
import ClubRules from './clubRules/ClubRules';
import ClubSettingsPage from './ClubSettingsPage';
export var clubSettingsPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: ['/club-settings/:clubId/club-profile'],
            component: function (props) {
                var clubId = props.match.params.clubId;
                return (_jsx(ClubSettingsPage, { children: _jsx(ClubProfile, { clubId: clubId }, void 0) }, void 0));
            }
        },
        {
            path: ['/club-settings/:clubId/club-rules'],
            component: function (props) {
                var clubId = props.match.params.clubId;
                return (_jsx(ClubSettingsPage, { children: _jsx(ClubRules, { clubId: clubId }, void 0) }, void 0));
            }
        },
        {
            path: ['/club-settings/:clubId/club-contacts'],
            component: function (props) {
                var clubId = props.match.params.clubId;
                return (_jsx(ClubSettingsPage, { children: _jsx(ClubContacts, { clubId: clubId }, void 0) }, void 0));
            }
        },
        {
            path: ['/club-settings/:clubId/club-participants'],
            component: function (props) {
                var clubId = props.match.params.clubId;
                return (_jsx(ClubSettingsPage, { children: _jsx(ClubParticipants, { clubId: clubId }, void 0) }, void 0));
            }
        },
        {
            path: '/club-settings/:clubId',
            component: function (props) {
                var clubId = props.match.params.clubId;
                return _jsx(Redirect, { to: "/club-settings/".concat(clubId, "/club-profile") }, void 0);
            }
        },
        {
            path: '/club-settings',
            component: function (props) {
                var clubId = props.match.params.clubId;
                return _jsx(Redirect, { to: "/club-settings/".concat(clubId, "/club-profile") }, void 0);
            }
        },
    ]
};
