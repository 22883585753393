import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import { Redirect } from 'react-router-dom';
import TeamContactsSettingsPage from './teamContacts/TeamContactsSettingsPage';
import TeamFeatureSettings from './teamFeatureSettings/TeamFeatureSettings';
import TeamParticipationSettingsPage from './teamParticipationSettings/TeamParticipationSettingsPage';
import TeamProfile from './teamProfile/TeamProfile';
import TeamSettingsPage from './TeamSettingsPage';
export var teamSettingsPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: ['/team-settings/:teamId/team-contacts-settings'],
            component: function (props) {
                var teamId = props.match.params.teamId;
                return (_jsx(TeamSettingsPage, { children: _jsx(TeamContactsSettingsPage, { teamId: teamId }, void 0) }, void 0));
            }
        },
        {
            path: ['/team-settings/:teamId/team-tournament-feature-settings'],
            component: function (props) {
                var teamId = props.match.params.teamId;
                return (_jsx(TeamSettingsPage, { children: _jsx(TeamFeatureSettings, { teamId: teamId }, void 0) }, void 0));
            }
        },
        {
            path: ['/team-settings/:teamId/team-participation-settings'],
            component: function (props) {
                var teamId = props.match.params.teamId;
                return (_jsx(TeamSettingsPage, { children: _jsx(TeamParticipationSettingsPage, { teamId: teamId }, void 0) }, void 0));
            }
        },
        {
            path: ['/team-settings/:teamId/team-profile'],
            component: function (props) {
                var teamId = props.match.params.teamId;
                return (_jsx(TeamSettingsPage, { children: _jsx(TeamProfile, { teamId: teamId }, void 0) }, void 0));
            }
        },
        {
            path: '/team-settings/:teamId',
            component: function (props) {
                var teamId = props.match.params.teamId;
                return _jsx(Redirect, { to: "/team-settings/".concat(teamId, "/team-profile") }, void 0);
            }
        },
        {
            path: '/team-settings',
            component: function (props) {
                var teamId = props.match.params.teamId;
                return _jsx(Redirect, { to: "/team-settings/".concat(teamId, "/team-profile") }, void 0);
            }
        },
    ]
};
