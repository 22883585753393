var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Divider, Hidden, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as authActions from 'store/actions';
var UserMenu = function (_a) {
    var userName = _a.userName, firstName = _a.firstName, profilePhoto = _a.profilePhoto;
    var dispatch = useDispatch();
    var _b = useState(null), userMenu = _b[0], setUserMenu = _b[1];
    var t = useTranslation().t;
    var userMenuClick = function (event) {
        setUserMenu(event.currentTarget);
    };
    var userMenuClose = function () {
        setUserMenu(null);
    };
    var onLogout = function () {
        dispatch(authActions.logoutUser());
        userMenuClose();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Button, __assign({ variant: "text", color: "secondary", className: "user-menu h-56 mt-0 mb-0 px-4 py-12", onClick: userMenuClick }, { children: [_jsx(Hidden, __assign({ smDown: true }, { children: _jsx(Typography, __assign({ sx: { fontWeight: 600, mr: '1.2rem' } }, { children: firstName || 'Impulse User' }), void 0) }), void 0), _jsx(UserAvatar, { firstName: firstName, profilePhoto: profilePhoto }, void 0), _jsx(Hidden, __assign({ smDown: true }, { children: _jsx(KeyboardArrowDownIcon, {}, void 0) }), void 0)] }), void 0), _jsx(Popover, __assign({ open: Boolean(userMenu), anchorEl: userMenu, onClose: userMenuClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                } }, { children: _jsxs(_Fragment, { children: [_jsx(MenuItem, __assign({ component: Link, to: '/profile/' + userName, onClick: userMenuClose }, { children: _jsx(ListItemText, { primary: t('Мой профиль') }, void 0) }), void 0), _jsx(MenuItem, __assign({ component: Link, to: "/settings/account", onClick: userMenuClose }, { children: _jsx(ListItemText, { primary: t('Настройки') }, void 0) }), void 0), _jsx(Divider, {}, void 0), _jsx(MenuItem, __assign({ onClick: onLogout }, { children: _jsx(ListItemText, { primary: t('Выйти') }, void 0) }), void 0)] }, void 0) }), void 0)] }, void 0));
};
export default UserMenu;
