var organizationProfileGroupsConfig = [
    {
        id: 'changeProfilePhoto-group',
        value: 'changeProfilePhoto-group',
        settingsGroupParams: [
            {
                id: 'changeOrganizationProfilePhotoGroupParams',
                values: [
                    {
                        id: 'profilePhoto',
                        value: 'profilePhoto',
                        title: 'Фото организации',
                        type: 'photo-upload',
                        dependentFields: ['name']
                    },
                ],
                type: 'accordion'
            },
        ]
    },
    {
        id: 'id-group',
        value: 'id-group',
        title: 'ID',
        settingsGroupParams: [
            {
                id: 'id-group',
                values: [
                    {
                        id: 'id',
                        value: 'id',
                        title: 'ID',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: false
            },
        ]
    },
    {
        id: 'name-group',
        value: 'name-group',
        title: 'Название',
        settingsGroupParams: [
            {
                id: 'nameGroupParams',
                values: [
                    {
                        id: 'name',
                        value: 'name',
                        title: 'Название',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'description-organization',
        value: 'description-organization',
        title: 'Об организации',
        settingsGroupParams: [
            {
                id: 'descriptionOrganizationGroupParams',
                values: [
                    {
                        id: 'description',
                        value: 'description',
                        title: 'Об организации',
                        type: 'textEditor',
                        textFieldIsMultiline: true
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'contacts-organization',
        value: 'contacts-organization',
        title: 'Контакты',
        settingsGroupParams: [
            {
                id: 'contactsOrganizationGroupParams',
                values: [
                    {
                        id: 'contacts',
                        value: 'contacts',
                        title: 'Контакты',
                        type: 'textEditor',
                        textFieldIsMultiline: true
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'Url-organization',
        value: 'Url-organization',
        title: 'Уникальное имя для URL',
        settingsGroupParams: [
            {
                id: 'UrlorganizationGroupParams',
                values: [
                    {
                        id: 'url',
                        value: 'url',
                        title: 'Уникальное имя для URL',
                        type: 'textField',
                        textFieldIsMultiline: false
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'hide-owners-organization',
        value: 'hide-owners-organization',
        title: 'Скрыть владельцев',
        settingsGroupParams: [
            {
                id: 'HideOrganizationOwnersGroupParams',
                values: [
                    {
                        id: 'hideOwners',
                        value: 'hideOwners',
                        title: 'Скрыть владельцев',
                        type: 'switch'
                    },
                ],
                type: 'accordion'
            },
        ]
    },
];
export default organizationProfileGroupsConfig;
