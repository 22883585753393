var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var GoogleIconV2 = function (props) {
    return (_jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M24.5834 0H6.25002C4.70292 0 3.21919 0.614581 2.12523 1.70854C1.03127 2.80251 0.416687 4.28624 0.416687 5.83333L0.416687 24.1667C0.416687 25.7138 1.03127 27.1975 2.12523 28.2915C3.21919 29.3854 4.70292 30 6.25002 30H24.5834C26.1304 30 27.6142 29.3854 28.7081 28.2915C29.8021 27.1975 30.4167 25.7138 30.4167 24.1667V5.83333C30.4167 4.28624 29.8021 2.80251 28.7081 1.70854C27.6142 0.614581 26.1304 0 24.5834 0ZM23.5084 20.5583C22.3812 22.1599 20.7935 23.3806 18.9561 24.0583C17.1188 24.736 15.1187 24.8386 13.2216 24.3525C11.3245 23.8664 9.62015 22.8146 8.33505 21.3369C7.04995 19.8591 6.24488 18.0253 6.02679 16.0791C5.8087 14.1329 6.18792 12.1664 7.11407 10.4409C8.04022 8.7153 9.46945 7.31238 11.2119 6.41845C12.9544 5.52452 14.9276 5.18191 16.8694 5.43612C18.8112 5.69033 20.6297 6.52933 22.0834 7.84167L19.35 10.5417C18.3202 9.65665 17.0079 9.16897 15.65 9.16667C14.1029 9.16667 12.6192 9.78125 11.5252 10.8752C10.4313 11.9692 9.81669 13.4529 9.81669 15C9.81669 16.5471 10.4313 18.0308 11.5252 19.1248C12.6192 20.2188 14.1029 20.8333 15.65 20.8333C18.15 20.8333 20.65 19.3917 20.875 16.8917H15.625V13.175H24.7084C25.4167 18.0833 23.5084 20.5583 23.5084 20.5583Z", fill: "#DBB400" }, void 0) }), void 0));
};
export default GoogleIconV2;
