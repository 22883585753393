export var ActivitySources;
(function (ActivitySources) {
    ActivitySources["STRAVA"] = "Strava";
    ActivitySources["POLAR"] = "Polar";
    ActivitySources["GOOGLE_FIT"] = "GoogleFit";
    ActivitySources["GOOGLE_HEALTH_CONNECT"] = "GoogleHealthConnect";
    ActivitySources["APPLE_HEALTH"] = "AppleHealth";
    ActivitySources["GPX"] = "Gpx";
    ActivitySources["MANUAL"] = "Manual";
})(ActivitySources || (ActivitySources = {}));
