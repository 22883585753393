var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, DialogActions, FormControlLabel, TextField } from '@mui/material';
import { FeatureTypes } from 'common/enums/feature/feature-types.enum';
import tournamentTeamFeatureVirtualPointsValidationSchema from 'common/validationSchemas/tournament-feature/tournament-team-feature-virtual-points-validation-schema';
import Button from 'components/Buttons/Button';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
var TeamTournamentFeatureVirtualPointsConfiguration = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var teamFeatureConfig = _a.teamFeatureConfig, handleClose = _a.handleClose, handleExecuteMutation = _a.handleExecuteMutation;
    var t = useTranslation().t;
    var defaultFormState = __assign({}, (teamFeatureConfig || {
        featureType: FeatureTypes.virtualPoints,
        inheritCoinsNumberFromParticipationPrice: false,
        teamInitialCoins: 0,
        teamParticipantInitialCoins: 0
    }));
    var handleSubmit = function (data) {
        var convertedData = __assign({}, data);
        if (typeof convertedData.teamInitialCoins === 'string')
            convertedData.teamInitialCoins = parseInt(convertedData.teamInitialCoins);
        if (typeof convertedData.teamParticipantInitialCoins === 'string')
            convertedData.teamParticipantInitialCoins = parseInt(convertedData.teamParticipantInitialCoins);
        // console.log(convertedData);
        handleExecuteMutation(__assign({}, convertedData));
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined);
        formik.handleChange(e);
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: tournamentTeamFeatureVirtualPointsValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsx(TextField, { label: "\u041D\u0430\u0447\u0430\u043B\u044C\u043D\u043E\u0435 \u043A\u043E\u043B-\u0432\u043E \u043C\u043E\u043D\u0435\u0442 \u043A\u043E\u043C\u0430\u043D\u0434\u044B", name: "teamInitialCoins", value: (_c = (_b = formik.values) === null || _b === void 0 ? void 0 : _b.teamInitialCoins) !== null && _c !== void 0 ? _c : '', onChange: handleChangeWrapper, error: Boolean((_d = formik.errors) === null || _d === void 0 ? void 0 : _d.teamInitialCoins), helperText: (_e = formik.errors) === null || _e === void 0 ? void 0 : _e.teamInitialCoins }, void 0), _jsx(TextField, { label: "\u041D\u0430\u0447\u0430\u043B\u044C\u043D\u043E\u0435 \u043A\u043E\u043B-\u0432\u043E \u043C\u043E\u043D\u0435\u0442 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0430 \u043A\u043E\u043C\u0430\u043D\u0434\u044B", name: "teamParticipantInitialCoins", value: (_g = (_f = formik.values) === null || _f === void 0 ? void 0 : _f.teamParticipantInitialCoins) !== null && _g !== void 0 ? _g : '', onChange: handleChangeWrapper, error: Boolean((_h = formik.errors) === null || _h === void 0 ? void 0 : _h.teamParticipantInitialCoins), helperText: (_j = formik.errors) === null || _j === void 0 ? void 0 : _j.teamParticipantInitialCoins }, void 0), _jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { name: "inheritCoinsNumberFromParticipationPrice", checked: ((_k = formik.values) === null || _k === void 0 ? void 0 : _k.inheritCoinsNumberFromParticipationPrice) || false, onChange: function (e) {
                                formik.setFieldError('inheritCoinsNumberFromParticipationPrice', undefined);
                                formik.setFieldValue('inheritCoinsNumberFromParticipationPrice', e.target.checked);
                            } }, void 0), label: "\u041E\u043F\u043B\u0430\u0447\u0435\u043D\u043D\u0430\u044F \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u0443\u0447\u0430\u0441\u0442\u0438\u044F \u043F\u0440\u0435\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u0432 \u043C\u043E\u043D\u0435\u0442\u044B (1 \u0440\u0443\u0431\u043B\u044C = 1 \u043C\u043E\u043D\u0435\u0442\u0430)" }, void 0)] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm" }, { children: t('Сохранить') }), void 0)] }), void 0)] }), void 0));
};
export default TeamTournamentFeatureVirtualPointsConfiguration;
