var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var GoogleFitIcon = function (props) {
    return (_jsxs("svg", __assign({ width: "28", height: "24", viewBox: "0 0 28 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsxs("g", __assign({ clipPath: "url(#clip0_7589_17318)" }, { children: [_jsx("path", { d: "M2.67909 12.696L4.08975 14.124L7.13632 11.052L5.73751 9.624L5.09737 8.976C4.58764 8.46 4.31499 7.788 4.31499 7.056C4.31499 6.42 4.52837 5.844 4.89585 5.388C5.39373 4.752 6.15241 4.344 7.00593 4.344C7.72904 4.344 8.39289 4.632 8.91448 5.148L9.54276 5.76L10.9534 7.2L14.0119 4.128L12.5893 2.7L11.9492 2.076C10.6808 0.792 8.93819 0 7.00593 0C3.12955 0 0 3.168 0 7.068C0 8.04 0.18967 8.964 0.545301 9.816C0.900931 10.668 1.41067 11.424 2.0508 12.06L2.67909 12.696Z", fill: "#EA4335" }, void 0), _jsx("path", { d: "M9.66139 14.6642L14.0119 10.2842L10.9535 7.2002L7.13641 11.0522L4.08984 14.1242L5.72575 15.7922L7.13641 17.2082L8.60635 15.7202", fill: "#FBBC04" }, void 0), _jsx("path", { d: "M17.0465 21.0718L23.922 14.1238L20.8636 11.0518L13.9999 17.9878L10.1828 14.1358L7.13623 17.2078L10.9533 21.0718L10.9415 21.0838L13.9999 23.9998L17.0583 21.0718H17.0465Z", fill: "#34A853" }, void 0), _jsx("path", { d: "M25.9491 12.0604C27.3717 10.6204 28.1896 8.41238 27.9644 6.18038C27.6325 2.79638 24.8585 0.252379 21.4919 0.0243788C19.37 -0.119621 17.4258 0.684379 16.0507 2.07638L10.9534 7.20038L13.9999 10.2844L19.0973 5.14838C19.7137 4.53638 20.5673 4.24838 21.4445 4.39238C22.5825 4.57238 23.5071 5.52038 23.6612 6.67238C23.7798 7.53638 23.4953 8.37638 22.8907 8.98838L20.8636 11.0524L23.922 14.1244L25.9491 12.0604Z", fill: "#4285F4" }, void 0)] }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0_7589_17318" }, { children: _jsx("rect", { width: "28", height: "24", fill: "white" }, void 0) }), void 0) }, void 0)] }), void 0));
};
export default GoogleFitIcon;
