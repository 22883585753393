var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import clsx from 'clsx';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { TEAM_PUBLIC_FIELDS } from 'common/graphql/queries/team/team-public-fields';
import { USER_TOURNAMENT_TEAM } from 'common/graphql/queries/team/user-tournament-team';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import TeamPageContent from './components/TeamPageContent/TeamPageContent';
import TeamProfilePageHeader from './components/TeamProfilePageHeader/TeamProfilePageHeader';
var TeamPage = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var name = _a.name;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var currentUserId = userProfile === null || userProfile === void 0 ? void 0 : userProfile.id;
    var t = useTranslation().t;
    var isAdmin = !!((_c = (_b = userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles) === null || _b === void 0 ? void 0 : _b.map(function (role) { return role.name; })) === null || _c === void 0 ? void 0 : _c.filter(function (roleName) { return ['Admin', 'SuperAdmin'].includes(roleName); })[0]);
    var _k = useQuery(TEAM_PUBLIC_FIELDS, {
        variables: {
            name: name
        }
    }), data = _k.data, loading = _k.loading, error = _k.error;
    var _l = useLazyQuery(USER_TOURNAMENT_TEAM), getUserTournamentTeam = _l[0], _m = _l[1], userTournamentTeamData = _m.data, userTournamentTeamDataLoading = _m.loading;
    var teamPublicData = data === null || data === void 0 ? void 0 : data.team;
    var userTournamentTeam = userTournamentTeamData === null || userTournamentTeamData === void 0 ? void 0 : userTournamentTeamData.userTournamentTeam;
    var dispatch = useDispatch();
    var onTeamNotFoundMessage = function () {
        dispatch(openSnackbar({
            message: t('Команда не найдена'),
            severity: SnackbarSeverity.ERROR
        }));
    };
    useEffect(function () {
        var _a, _b;
        if (teamPublicData && currentUserId) {
            getUserTournamentTeam({
                variables: {
                    tournamentId: (_b = (_a = teamPublicData.tournaments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id,
                    userId: currentUserId
                }
            });
        }
    }, [data]);
    useEffect(function () {
        if (error) {
            history.goBack();
            onTeamNotFoundMessage();
        }
    }, [error]);
    if (loading || userTournamentTeamDataLoading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(PageSimple, { className: "tw-event-page-container", breadcrumbs: _jsx(Breadcrumbs, {}, void 0), header: _jsx("div", __assign({ className: clsx('tw-page-container-shadow') }, { children: teamPublicData && (_jsx(TeamProfilePageHeader, { teamPublicData: teamPublicData, isUserTeamParticipant: userTournamentTeam ? true : false }, void 0)) }), void 0), content: teamPublicData ? (((!((_f = (_e = (_d = teamPublicData.tournaments) === null || _d === void 0 ? void 0 : _d[0].sportEvent) === null || _e === void 0 ? void 0 : _e.privacy) === null || _f === void 0 ? void 0 : _f.isOpenEvent) && !userTournamentTeam) ||
            (((_j = (_h = (_g = teamPublicData.tournaments) === null || _g === void 0 ? void 0 : _g[0].sportEvent) === null || _h === void 0 ? void 0 : _h.privacy) === null || _j === void 0 ? void 0 : _j.isPrivate) && !userTournamentTeam)) &&
            !isAdmin ? null : (_jsx(TeamPageContent, { teamPublicData: teamPublicData }, void 0))) : (_jsx("p", { children: "\u041A\u043E\u043C\u0430\u043D\u0434\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430" }, void 0)), innerScroll: false }, void 0));
};
export default TeamPage;
