import * as Yup from 'yup';
var contactValidationSchema = Yup.object().shape({
    text: Yup.string()
        .trim()
        .min(1, 'Слишком короткий текст ссылки. Пожалуйста, введите от 1 до 128 символов')
        .max(128, 'Слишком длинный текст ссылки. Пожалуйста, введите от 1 до 128 символов')
        .required('Не указан текст ссылки'),
    url: Yup.string()
        .min(1, 'Слишком короткая ссылка. Пожалуйста, введите от 1 до 128 символов')
        .max(128, 'Слишком длинная ссылка. Пожалуйста, введите от 1 до 128 символов')
        .required('Не указано ссылка')
});
export default contactValidationSchema;
