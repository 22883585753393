var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var InstagramContact = function (props) {
    return (_jsx("svg", __assign({ version: "1.0", xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 90.000000 90.000000", preserveAspectRatio: "xMidYMid meet" }, props, { children: _jsxs("g", __assign({ transform: "translate(0.000000,90.000000) scale(0.100000,-0.100000)", stroke: "none" }, { children: [_jsx("path", { d: "M215 791 c-22 -10 -52 -31 -67 -47 -52 -56 -58 -86 -58 -296 0 -165\n3 -199 19 -233 23 -51 79 -101 128 -114 51 -14 377 -14 428 0 52 15 119 82\n134 134 14 51 14 377 0 428 -13 49 -63 105 -114 128 -34 16 -68 19 -235 19\n-169 0 -200 -3 -235 -19z m470 -131 c0 -18 -6 -26 -23 -28 -24 -4 -38 18 -28\n44 3 9 15 14 28 12 17 -2 23 -10 23 -28z m-155 -52 c132 -67 132 -249 0 -316\n-56 -28 -104 -28 -160 0 -132 67 -132 249 0 316 24 12 60 22 80 22 20 0 56\n-10 80 -22z" }, void 0), _jsx("path", { d: "M405 561 c-45 -20 -70 -60 -70 -112 0 -42 5 -53 33 -81 28 -28 39\n-33 82 -33 43 0 54 5 82 33 28 28 33 39 33 82 0 42 -5 54 -31 81 -33 33 -92\n46 -129 30z" }, void 0)] }), void 0) }), void 0));
};
export default InstagramContact;
