import { useCallback, useState } from "react";
var useOnScreen = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.root, root = _c === void 0 ? null : _c, _d = _b.rootMargin, rootMargin = _d === void 0 ? "0px" : _d, _e = _b.threshold, threshold = _e === void 0 ? 0 : _e;
    var _f = useState(), observer = _f[0], setOserver = _f[1];
    var _g = useState(false), isIntersecting = _g[0], setIntersecting = _g[1];
    var measureRef = useCallback(function (node) {
        if (node) {
            var observer_1 = new IntersectionObserver(function (_a) {
                var entry = _a[0];
                setIntersecting(entry.isIntersecting);
            }, { root: root, rootMargin: rootMargin, threshold: threshold });
            observer_1.observe(node);
            setOserver(observer_1);
        }
    }, [root, rootMargin, threshold]);
    return { measureRef: measureRef, isIntersecting: isIntersecting, observer: observer };
};
export default useOnScreen;
