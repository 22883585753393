var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLazyQuery, useQuery } from '@apollo/client';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import clsx from 'clsx';
import { FeatureTypeIds } from 'common/const/feature-ids.const';
import { TournamentFeatureActionTarget } from 'common/enums/tournament/tournament-feature-action-target.enum';
import { TournamentFeatureActionType } from 'common/enums/tournament/tournament-feature-action-type.enum';
import { TEAM } from 'common/graphql/queries/team/team';
import { TOURNAMEN_FEATURE_RO_ACTION } from 'common/graphql/queries/tournament/perform-tournament-feature-read-only-action';
import DocsHelper from 'components/DocsHelper/DocsHelper';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BoostersInfo from './BoostersInfo';
import CoinsInfo from './CoinsInfo';
import PointsInfo from './PointsInfo';
var TeamPageTournamentFeatureVirtualPointsContent = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var tournamentId = _a.tournamentId, team = _a.team, virtualPointsFeature = _a.virtualPointsFeature, isTournamentEnded = _a.isTournamentEnded, currentUserId = _a.currentUserId;
    var t = useTranslation().t;
    var _s = useState(false), openTooltip = _s[0], setOpenTooltip = _s[1];
    var _t = useQuery(TEAM, {
        variables: {
            id: team.id,
            showTournamentFeatureData: true
        }
    }), data = _t.data, loading = _t.loading;
    var _u = useLazyQuery(TOURNAMEN_FEATURE_RO_ACTION), getUserVirtualPointsSummary = _u[0], _v = _u[1], userVirtualPointsSummary = _v.data, userVirtualPointsSummaryLoading = _v.loading;
    var _w = useLazyQuery(TOURNAMEN_FEATURE_RO_ACTION), getTeamVirtualPointsSummary = _w[0], _x = _w[1], teamVirtualPointsSummary = _x.data, teamVirtualPointsSummaryLoading = _x.loading;
    useEffect(function () {
        if (team.areYouParticipant)
            getUserVirtualPointsSummary({
                variables: {
                    performTournamentFeatureActionData: {
                        actionTarget: TournamentFeatureActionTarget.User,
                        actionTargetId: currentUserId,
                        actionType: TournamentFeatureActionType.VirtualPointsGetSummary,
                        tournamentId: tournamentId,
                        featureId: FeatureTypeIds.virtualPoints
                    }
                }
            });
        getTeamVirtualPointsSummary({
            variables: {
                performTournamentFeatureActionData: {
                    actionTarget: TournamentFeatureActionTarget.Team,
                    actionTargetId: team.id,
                    actionType: TournamentFeatureActionType.VirtualPointsGetSummary,
                    tournamentId: tournamentId,
                    featureId: FeatureTypeIds.virtualPoints
                }
            }
        });
    }, [data]);
    var tournamentTeamFeatureData = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.team) === null || _b === void 0 ? void 0 : _b.tournamentTeamFeatureData) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.data;
    var teamCoins = (tournamentTeamFeatureData === null || tournamentTeamFeatureData === void 0 ? void 0 : tournamentTeamFeatureData.coins) || 0;
    var currentActiveBooster = tournamentTeamFeatureData === null || tournamentTeamFeatureData === void 0 ? void 0 : tournamentTeamFeatureData.currentActiveBooster;
    var tomorrowActiveBooster = tournamentTeamFeatureData === null || tournamentTeamFeatureData === void 0 ? void 0 : tournamentTeamFeatureData.tomorrowActiveBooster;
    var teamActionData = (_f = (_e = teamVirtualPointsSummary === null || teamVirtualPointsSummary === void 0 ? void 0 : teamVirtualPointsSummary.performTournamentFeatureReadOnlyAction) === null || _e === void 0 ? void 0 : _e.actionData) === null || _f === void 0 ? void 0 : _f.actionResult;
    var teamTotalPoints = (teamActionData === null || teamActionData === void 0 ? void 0 : teamActionData.totalPoints) || 0;
    var teamPointsPerSport = (teamActionData === null || teamActionData === void 0 ? void 0 : teamActionData.pointsPerSport) || [];
    var userActionData = (_h = (_g = userVirtualPointsSummary === null || userVirtualPointsSummary === void 0 ? void 0 : userVirtualPointsSummary.performTournamentFeatureReadOnlyAction) === null || _g === void 0 ? void 0 : _g.actionData) === null || _h === void 0 ? void 0 : _h.actionResult;
    var userTotalPoints = (userActionData === null || userActionData === void 0 ? void 0 : userActionData.totalPoints) || 0;
    var userPointsPerSport = (userActionData === null || userActionData === void 0 ? void 0 : userActionData.pointsPerSport) || [];
    var tournamentDateDayBefore = ((_l = (_k = (_j = team === null || team === void 0 ? void 0 : team.tournaments) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.datesSettings) === null || _l === void 0 ? void 0 : _l.startDate)
        ? new Date((_p = (_o = (_m = team === null || team === void 0 ? void 0 : team.tournaments) === null || _m === void 0 ? void 0 : _m[0]) === null || _o === void 0 ? void 0 : _o.datesSettings) === null || _p === void 0 ? void 0 : _p.startDate)
        : null;
    tournamentDateDayBefore && tournamentDateDayBefore.setDate(tournamentDateDayBefore.getDate() - 1);
    var isBoosterSellOpen = tournamentDateDayBefore
        ? new Date() > tournamentDateDayBefore && !isTournamentEnded
        : false;
    var isCanBuyBoosters = ((team === null || team === void 0 ? void 0 : team.areYouCaptain) && tournamentDateDayBefore && isBoosterSellOpen) || false;
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx("div", __assign({ className: clsx('h-full tw-page-container-shadow pb-10 min-h-128') }, { children: _jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-10 items-center justify-start width-full" }, { children: [_jsx("p", __assign({ className: "text-center text-grey" }, { children: ((_q = virtualPointsFeature === null || virtualPointsFeature === void 0 ? void 0 : virtualPointsFeature.config) === null || _q === void 0 ? void 0 : _q.boostersEnabled)
                                ? t('Турнир c виртуальными очками и бустерами')
                                : t('Турнир c виртуальными очками') }), void 0), _jsx(DocsHelper, __assign({ id: "b7233a24-1a76-4504-b565-c306086f3efb" }, { children: _jsx(InfoOutlinedIcon, { onClick: function () { return setOpenTooltip(!openTooltip); }, fontSize: "small" }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex xs:flex-col md:flex-row w-full xs:space-y-20 md:space-y-0 md:justify-between pt-20" }, { children: [_jsx(PointsInfo, { team: team, teamTotalPoints: teamTotalPoints, userTotalPoints: userTotalPoints, teamPointsPerSport: teamPointsPerSport, userPointsPerSport: userPointsPerSport, virtualPointsFeature: virtualPointsFeature }, void 0), ((_r = virtualPointsFeature === null || virtualPointsFeature === void 0 ? void 0 : virtualPointsFeature.config) === null || _r === void 0 ? void 0 : _r.boostersEnabled) && (_jsxs(_Fragment, { children: [_jsx(CoinsInfo, { team: team, teamCoins: teamCoins, virtualPointsFeature: virtualPointsFeature, isTournamentEnded: isTournamentEnded }, void 0), _jsx(BoostersInfo, { team: team, teamCoins: teamCoins, isBoosterSellOpen: isBoosterSellOpen, isTournamentEnded: isTournamentEnded, isCanBuyBoosters: isCanBuyBoosters, currentActiveBooster: currentActiveBooster, tomorrowActiveBooster: tomorrowActiveBooster, tournamentDateDayBefore: tournamentDateDayBefore }, void 0)] }, void 0))] }), void 0)] }), void 0) }), void 0));
};
export default TeamPageTournamentFeatureVirtualPointsContent;
