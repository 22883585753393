var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { StandingsParticipantType } from 'common/enums/standings/standings-participant-type.enum';
import { useTranslation } from 'react-i18next';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import TournamentNominationWinnersGroup from './TournamentNominationWinnersGroup';
export var TournamentNominationGoalReachedWinnersInfo = function (_a) {
    var _b, _c;
    var isPreview = _a.isPreview, tournamentNominationWinners = _a.tournamentNominationWinners, standingsParticipantType = _a.standingsParticipantType, isShowAllWinners = _a.isShowAllWinners, nomination = _a.nomination, handleClose = _a.handleClose;
    var currentUserId = (_b = useReactiveVar(userProfileDataVar).userProfile) === null || _b === void 0 ? void 0 : _b.id;
    var t = useTranslation().t;
    var currentWinner = (_c = tournamentNominationWinners.winners.filter(function (winner) { return winner.user && winner.user.id === currentUserId; })) === null || _c === void 0 ? void 0 : _c[0];
    return (_jsxs("div", __assign({ className: "px-8 pt-5 dark:text-white" }, { children: [isPreview && _jsx("p", __assign({ className: "text-gray font-semibold pb-5" }, { children: t('Предварительные результаты') }), void 0), _jsxs("p", { children: [t('Цель достигли'), " ", tournamentNominationWinners.participantWinnersCount, " ", t('из2'), ' ', tournamentNominationWinners.totalParticipantsCount, ' ', standingsParticipantType === StandingsParticipantType.Team
                        ? t('команд') + '.'
                        : standingsParticipantType === StandingsParticipantType.SportClub
                            ? t('клубов') + '.'
                            : t('участников') + '.'] }, void 0), currentWinner && _jsx("p", __assign({ className: "text-green" }, { children: t('Вы также достигли свою цель, поздравляем!') }), void 0), currentWinner && currentWinner.diplomaLinks && currentWinner.diplomaLinks.length > 0 && (_jsx("div", __assign({ className: "flex flex-col space-y-10" }, { children: currentWinner.diplomaLinks.map(function (diplomaLink) {
                    return (_jsx("div", { children: _jsx("a", __assign({ href: diplomaLink.link, target: "_blank", className: "tw-link-btn", download: true }, { children: t(diplomaLink.text) }), void 0) }, diplomaLink.text));
                }) }), void 0)), isShowAllWinners && nomination && handleClose && (_jsx("div", __assign({ className: "flex flex-col space-y-5 pt-10" }, { children: _jsx(TournamentNominationWinnersGroup, { nomination: nomination, winners: tournamentNominationWinners.winners, standingsParticipantType: standingsParticipantType, handleClose: handleClose }, void 0) }), void 0))] }), void 0));
};
