var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PagesConfig } from 'main/pages/PagesConfig';
import { Redirect } from 'react-router-dom';
import Utils from '../utils/Utils';
var routeConfigs = __spreadArray([], PagesConfig, true);
var routes = __spreadArray(__spreadArray([], Utils.generateRoutesFromConfigs(routeConfigs), true), [
    {
        path: '/',
        exact: true,
        component: function () { return _jsx(Redirect, { to: "/profile" }, void 0); }
    },
    {
        component: function () { return _jsx(Redirect, { to: "/" }, void 0); }
    },
], false);
export default routes;
