var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Link from '@mui/material/Link';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Timer from '../Timer/Timer';
var ResendCodeLink = function (props) {
    var t = useTranslation().t;
    var initialMinute = props.initialMinute, initialSeconds = props.initialSeconds, callBackFunction = props.callBackFunction;
    var _a = useState(false), timer = _a[0], setTimer = _a[1];
    useEffect(function () {
        if (initialSeconds > 0 || initialMinute > 0) {
            setTimer(true);
        }
    }, [initialMinute, initialSeconds]);
    var handleClick = function () {
        setTimer(true);
        callBackFunction();
    };
    return (_jsx("div", __assign({ className: "dark:text-white" }, { children: timer ? (_jsx(Timer, { initialMinute: initialMinute, initialSeconds: initialSeconds, callBackFunction: function () { return setTimer(false); }, description: t('Отправить код повторно можно через') }, void 0)) : (_jsx(Link, __assign({ href: "#", onClick: handleClick }, { children: t('Отправить код повторно') }), void 0)) }), void 0));
};
export default ResendCodeLink;
