var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery, useQuery } from '@apollo/client';
import { NOMINATION } from 'common/graphql/queries/nomination/nomination';
import { TOURNAMENT_NOMINATION_WINNERS } from 'common/graphql/queries/tournament/tournament-nomination-winners';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import TournamentNominationListItem from './TournamentNominationListItem';
var TournamentNominationsGroup = function (_a) {
    var _b;
    var tournamentNominations = _a.tournamentNominations, standings = _a.standings, handleClose = _a.handleClose;
    var _c = useState(undefined), isPreview = _c[0], setIsPreview = _c[1];
    var _d = useQuery(NOMINATION, {
        variables: {
            id: tournamentNominations[0].nominationId
        }
    }), data = _d.data, loading = _d.loading;
    var _e = useLazyQuery(TOURNAMENT_NOMINATION_WINNERS), getTournamentNominationWinners = _e[0], _f = _e[1], tournamentNominationWinnersData = _f.data, tnw_loading = _f.loading;
    var nomination = data === null || data === void 0 ? void 0 : data.nomination;
    var tournamentNominationWinners = tournamentNominationWinnersData === null || tournamentNominationWinnersData === void 0 ? void 0 : tournamentNominationWinnersData.tournamentNominationWinners;
    useEffect(function () {
        if (nomination) {
            if (tournamentNominations.map(function (o) { return o.isShowWinners; }).reduce(function (p, c) { return p || c; }) &&
                new Date() > new Date(standings.activitySettings.activityEndDate)) {
                setIsPreview(false);
            }
            else {
                setIsPreview(true);
            }
        }
    }, [nomination]);
    useEffect(function () {
        if (isPreview !== undefined) {
            getTournamentNominationWinners({
                variables: {
                    tournamentId: tournamentNominations[0].tournamentId,
                    nominationId: tournamentNominations[0].nominationId,
                    isPreview: isPreview
                }
            });
        }
    }, [isPreview]);
    if (loading || tnw_loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return nomination &&
        tournamentNominationWinners &&
        tournamentNominationWinners.length > 0 &&
        isPreview !== undefined ? (_jsx("div", __assign({ className: "flex flex-col space-y-40" }, { children: (_b = _.orderBy(tournamentNominationWinners, function (o) { return o.tournamentNomination.viewOrder; }, 'asc')) === null || _b === void 0 ? void 0 : _b.map(function (tournamentNominationWinner) {
            return (_jsx(TournamentNominationListItem, { tournamentNominationWinners: tournamentNominationWinner, nomination: nomination, standingsParticipantType: standings.participantType, isPreview: isPreview, handleClose: handleClose }, tournamentNominationWinner.tournamentNomination.id));
        }) }), void 0)) : null;
};
export default TournamentNominationsGroup;
