var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { DialogActions, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { CREATE_SPORT_CLUB } from 'common/graphql/mutations/sportClub/createSportClub';
import { SPORT_CLUBS } from 'common/graphql/queries/sportClub/sportClubs';
import { USER } from 'common/graphql/queries/user/user';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import Button from 'components/Buttons/Button';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var CreateClubDialog = function (_a) {
    var handleClose = _a.handleClose;
    var defaultFormState = { name: '' };
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var createClub = function (data) {
        return useMutation(CREATE_SPORT_CLUB, {
            variables: {
                createSportClubData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [SPORT_CLUBS, USER]
        });
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(createClub, handleClose, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        //Todo
        handleExecuteMutation(__assign(__assign({}, data), { sportsIds: ['0928af54-3c15-48f7-8192-a57b3f1b2a77'], isPrivate: false }));
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: userValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Создать клуб') }), void 0), _jsx(TextField, { className: "", label: t('Name1'), name: "name", value: formik.values['name'], onChange: handleChangeWrapper, error: Boolean(formik.errors['name']), helperText: formik.errors['name'] }, void 0)] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm" }, { children: t('Создать') }), void 0)] }), void 0)] }), void 0));
};
export default CreateClubDialog;
