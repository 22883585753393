var teamSettingsNavigationConfig = [
    {
        id: 'team-profile',
        title: 'Профайл команды',
        handle: 'team-profile'
    },
    {
        id: 'team-participation-settings',
        title: 'Управление участниками',
        handle: 'team-participation-settings'
    },
    {
        id: 'team-contacts-settings',
        title: 'Контакты команды',
        handle: 'team-contacts-settings'
    },
    {
        id: 'team-tournament-feature-settings',
        title: 'Настройки турнирных фич',
        handle: 'team-tournament-feature-settings'
    },
];
export default teamSettingsNavigationConfig;
