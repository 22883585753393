import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import JoinTeamByLink from './JoinTeamByLink';
import TeamPage from './TeamPage';
export var teamPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/team/:name',
            component: function (props) {
                var name = props.match.params.name;
                return _jsx(TeamPage, { name: name }, void 0);
            }
        },
        {
            path: '/public-join-team-link/:teamId',
            component: function (props) {
                var teamId = props.match.params.teamId;
                return _jsx(JoinTeamByLink, { teamId: teamId }, void 0);
            }
        },
        {
            path: '/private-join-team-link/:teamId/:joinLinkString',
            component: function (props) {
                var joinLinkString = props.match.params.joinLinkString;
                var teamId = props.match.params.teamId;
                return _jsx(JoinTeamByLink, { teamId: teamId, joinLinkString: joinLinkString }, void 0);
            }
        },
    ]
};
