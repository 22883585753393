import { URL } from 'common/enums/common/url.enum';
import apiService from './apiService';
var authService = {
    getAccountData: function (params) { return apiService.GET("".concat(URL.AUTH_URL, "/getAccountData"), params); },
    loginByEmail: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/email"), payload, null); },
    forgotPassword: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/start-password-change"), payload, null); },
    resetPasswordVerification: function (params) {
        return apiService.GET("".concat(URL.VERIFICATION_URL, "/email/valid-verification-exists"), params);
    },
    saveNewPassword: function (payload) {
        return apiService.POST("".concat(URL.AUTH_URL, "/complete-password-change"), payload, null);
    },
    googleSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/google"), payload, null); },
    vkSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/vk"), payload, null); },
    yandexSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/yandex"), payload, null); },
    telegramSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/telegram"), payload, null); },
    stravaSignIn: function (payload) { return apiService.POST("".concat(URL.AUTH_URL, "/strava"), payload, null); }
};
export default authService;
