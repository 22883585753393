import * as Actions from '../../actions/auth';

const initialState = {
    accountData: {
        currentEmail: null,
        isVerifiedEmail: false,
        socialLogin: {
            vkAccount: false,
            googleAccount: false,
            stravaAccount: false,
        }
    },
    success: false,
    error: {
        email: null,
        password: null,
        checkPassword: null,
    }
};

const login = function (state = initialState, action) {
    switch (action.type) {
        case Actions.LOGIN_SUCCESS: {
            return {
                ...state,
                success: true,
            }
        }
        case Actions.LOGIN_ERROR: {
            return {
                success: false,
                error: action.payload
            };
        }
        case Actions.ACCOUNT_DATA: {
            return {
                ...state,
                accountData: {
                    ...action.payload
                }
            }
        }
        case Actions.INVALID_EMAIL_OR_PASSWORD_ERROR: {
            return {
                ...state,
                error: action.payload
            }
        }
        default: {
            return state
        }
    }
};

export default login;