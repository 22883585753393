import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { USER } from 'common/graphql/queries/user/user';
import { useUpdateUserMutation } from 'common/hooks/useUpdateUserMutation';
import { useTranslation } from 'react-i18next';
import inMemoryJwt from 'utils/inMemoryJwt';
import SettingsForm from '../components/SettingsForm/SettingsForm';
import SettingsGroup from '../components/SettingsGroup/SettingsGroup';
import displayPreferencesGroupsConfig from './displayPreferencesGroupsConfig';
var DisplayPreferences = function () {
    var _a;
    var t = useTranslation().t;
    var user = (_a = useQuery(USER, {
        skip: !inMemoryJwt.getToken(),
        fetchPolicy: 'cache-only'
    }).data) === null || _a === void 0 ? void 0 : _a.user;
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: t('Настройки отображения') }, void 0), content: displayPreferencesGroupsConfig.length > 0 &&
            displayPreferencesGroupsConfig.map(function (settingsGroup) { return (_jsxs("div", { children: [_jsx(SettingsGroup, { settingsGroup: settingsGroup, data: user, handleChangeData: useUpdateUserMutation }, void 0), _jsx(Divider, { light: true }, void 0)] }, settingsGroup.id)); }) }, void 0));
};
export default DisplayPreferences;
