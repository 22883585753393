var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VirtualPointsBoosterTypesDescriptionReadableRu, VirtualPointsBoosterTypesProfilePhotos, VirtualPointsBoosterTypesReadableRu, } from 'common/enums/feature/virtual-points-booster-types.enum';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import BuyBoosterButton from './BuyBoosterButton';
var BoosterWithPriceListItem = function (_a) {
    var teamId = _a.teamId, tournamentId = _a.tournamentId, boosterName = _a.boosterName, price = _a.price, teamCoins = _a.teamCoins, isCanBuy = _a.isCanBuy;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "flex items-center w-full space-x-10" }, { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsx(UserAvatar, { style: "xs:w-68 xs:h-68 xs:text-20 md:w-88 md:h-88 md:text-24", firstName: boosterName, profilePhoto: { url: VirtualPointsBoosterTypesProfilePhotos[boosterName] } }, void 0), isCanBuy && (_jsx(BuyBoosterButton, { teamId: teamId, tournamentId: tournamentId, boosterName: boosterName, price: price, teamCoins: teamCoins }, void 0))] }), void 0), _jsxs("div", __assign({ className: "w-full h-full flex flex-col justify-start" }, { children: [_jsx("div", __assign({ className: "flex xs:text-14 md:text-16 font-semibold px-8 py-2 cursor-pointer dark:text-white" }, { children: t(VirtualPointsBoosterTypesReadableRu[boosterName]) }), void 0), _jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row sm:space-x-5 sm:space-y-0 xs:space-y-5 xs:space-x-0 px-8 py-2" }, { children: [_jsxs("div", __assign({ className: "w-96 xs:text-12 sm:text-14 text-gray" }, { children: [t('Описание'), ":\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: t(VirtualPointsBoosterTypesDescriptionReadableRu[boosterName]) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex space-x-5 px-8 py-2 items-center" }, { children: [_jsxs("div", __assign({ className: "flex flex-col w-96 xs:text-12 sm:text-14 text-gray" }, { children: [_jsx("p", { children: t('Стоимость') }, void 0), _jsx("p", { children: '(' + t('монет') + ')' }, void 0)] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: price }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default BoosterWithPriceListItem;
