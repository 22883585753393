var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql, useMutation } from '@apollo/client';
import { USER_ACTIVITIES_QUEUE_REQUESTS } from 'common/graphql/queries/activities/user-activities-queue-requests';
export var PULL_USER_ACTIVITIES = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation pullUserActivities($userId: String!, $fromDate: DateTime, $beforeDate: DateTime) {\n\t\tpullUserActivities(userId: $userId, fromDate: $fromDate, beforeDate: $beforeDate)\n\t}\n"], ["\n\tmutation pullUserActivities($userId: String!, $fromDate: DateTime, $beforeDate: DateTime) {\n\t\tpullUserActivities(userId: $userId, fromDate: $fromDate, beforeDate: $beforeDate)\n\t}\n"])));
export function usePullActivitiesMutation(variables) {
    return useMutation(PULL_USER_ACTIVITIES, {
        variables: __assign({}, variables),
        refetchQueries: [
            {
                query: USER_ACTIVITIES_QUEUE_REQUESTS,
                variables: {
                    userId: variables === null || variables === void 0 ? void 0 : variables.userId,
                    limit: 3
                }
            },
        ]
    });
}
var templateObject_1;
