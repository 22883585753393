var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@apollo/client';
import { UPDATE_TOURNAMENT } from 'common/graphql/mutations/tournament/update-tournament';
import { TOURNAMENT } from 'common/graphql/queries/tournament/tournament';
export function useUpdateTournamentMutation(tournamentVariables) {
    var mainSettingsFields = ['displayName', 'description', 'standingsType'];
    var tournamentData = {
        mainSettings: {}
    };
    var tournamentId = tournamentVariables === null || tournamentVariables === void 0 ? void 0 : tournamentVariables.id;
    var _loop_1 = function (key) {
        var _a, _b;
        var isMainSettings = mainSettingsFields.filter(function (s) { return s === key; });
        // ключи
        if (isMainSettings.length > 0) {
            tournamentData = __assign(__assign({}, tournamentData), { mainSettings: __assign(__assign({}, tournamentData.mainSettings), (_a = {}, _a[key] = tournamentVariables[key], _a)) });
        }
        else {
            tournamentData = __assign(__assign({}, tournamentData), (_b = {}, _b[key] = tournamentVariables[key], _b));
        }
    };
    for (var key in tournamentVariables) {
        _loop_1(key);
    }
    return useMutation(UPDATE_TOURNAMENT, {
        variables: {
            updateTournamentData: __assign({}, tournamentData)
        },
        refetchQueries: [
            {
                query: TOURNAMENT,
                variables: {
                    id: tournamentId,
                    showBasicFields: true,
                    showMainSettings: true
                }
            },
        ]
    });
}
