var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Actions from '../../actions/base';
import navigationConfig from '../../../config/navigationConfig';
import bottomNavigationConfig from '../../../config/bottomNavigationConfig';
var initialState = {
    navigation: navigationConfig,
    bottomNavigation: bottomNavigationConfig
};
var navigation = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case Actions.GET_NAVIGATION: {
            return state;
        }
        case Actions.SET_NAVIGATION: {
            return __spreadArray([], action.navigation, true);
        }
        case Actions.RESET_NAVIGATION: {
            return state;
        }
        default: {
            return state;
        }
    }
};
export default navigation;
