var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import TelegramIconLogin from 'assets/logos/TelegramIconLogin';
import { appHost, appScheme } from 'common/appURLSetup';
import { telegramBotId } from 'common/socialClientIdsSetup';
import * as queryString from 'querystring';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { telegramSignIn } from 'store/actions/auth';
var TelegramSignIn = function (props) {
    var dispatch = useDispatch();
    useEffect(function () {
        if (props.history.location.pathname === '/login/telegram/') {
            var handleLogin = function (telegramData) {
                dispatch(telegramSignIn(telegramData));
            };
            var queryObj = queryString.parse(location.hash);
            if (queryObj && queryObj['#tgAuthResult']) {
                var telegramUserData = JSON.parse(atob(queryObj['#tgAuthResult']));
                handleLogin(telegramUserData);
            }
        }
    }, [props.location.search, props.history]);
    var handleRedirect = function () {
        var origin = "".concat(appScheme, "://").concat(appHost);
        var redirectTo = "".concat(appScheme, "://").concat(appHost, "/login/telegram/");
        window.location.href = "https://oauth.telegram.org/auth?bot_id=".concat(telegramBotId, "&origin=").concat(origin, "&request_access=write&return_to=").concat(redirectTo);
    };
    return (_jsx(IconButton, __assign({ sx: { width: '6rem', height: '6rem' }, onClick: handleRedirect }, { children: _jsx(TelegramIconLogin, {}, void 0) }), void 0));
};
export default withRouter(TelegramSignIn);
