export default function TextField(theme) {
    return {
        MuiTextField: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    marginTop: '0.6rem',
                    marginBottom: '0.6rem'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: theme.palette.background.textField
                },
                input: {
                    // padding: '1.4rem',
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 100px transparent inset',
                        WebkitBackgroundClip: 'text'
                    }
                }
            }
        }
    };
}
