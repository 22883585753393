var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { NOTIFICATION_DISPATCH_INFO_LIST } from 'common/graphql/queries/notification/notification-dispatch-info-list';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import _ from 'lodash';
import NotificationTemplateListItemItem from './NotificationDispatchInfoListItem';
var NotificationDispatchInfoList = function () {
    var _a = useQuery(NOTIFICATION_DISPATCH_INFO_LIST), data = _a.data, loading = _a.loading;
    var notificationDispatchInfoList = data === null || data === void 0 ? void 0 : data.notificationDispatchInfoList;
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx("div", __assign({ className: "flex flex-col space-y-10 pt-20" }, { children: notificationDispatchInfoList && (notificationDispatchInfoList === null || notificationDispatchInfoList === void 0 ? void 0 : notificationDispatchInfoList.length) > 0 ? (_jsx("div", { children: _.orderBy(notificationDispatchInfoList, function (o) { return o.updatedAt; }, 'desc').map(function (notificationDispatchInfoItem) {
                return (_jsx(NotificationTemplateListItemItem, { notificationDispatchInfo: notificationDispatchInfoItem }, notificationDispatchInfoItem.id));
            }) }, void 0)) : (_jsx("div", { children: "\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0438 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B" }, void 0)) }), void 0));
};
export default NotificationDispatchInfoList;
