var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@apollo/client';
import { USER } from 'common/graphql/queries/user/user';
import { useEffect } from 'react';
import { userSportClubDataVar } from 'utils/apollo/reactivities/userSportClubDataVar';
function useGetUserSportClubData() {
    var _a, _b, _c;
    var data = useQuery(USER, {
        variables: {
            showSportClub: true,
            showSportClubPermissions: true
        }
    }).data;
    var userSportClub = (_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.sportClub;
    var userSportClubId = (_c = (_b = data === null || data === void 0 ? void 0 : data.user) === null || _b === void 0 ? void 0 : _b.sportClub) === null || _c === void 0 ? void 0 : _c.id;
    var setUserSportClubDataVar = function () {
        var _a, _b;
        userSportClubDataVar(__assign(__assign({}, userSportClubDataVar()), { sportClub: userSportClub || null, userOwnedSportClubId: userSportClub && ((_a = data === null || data === void 0 ? void 0 : data.user.clubPermissions) === null || _a === void 0 ? void 0 : _a.isOwner) ? userSportClub.id : null, userAdministeredClubId: userSportClub && ((_b = data === null || data === void 0 ? void 0 : data.user.clubPermissions) === null || _b === void 0 ? void 0 : _b.isAdmin) ? userSportClub === null || userSportClub === void 0 ? void 0 : userSportClub.id : null }));
    };
    useEffect(function () {
        if (userSportClubId) {
            setUserSportClubDataVar();
        }
    }, [userSportClubId]);
    return { userSportClub: userSportClub };
}
export default useGetUserSportClubData;
