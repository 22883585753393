import React from 'react';
import * as Actions from 'store/actions';
import {useDispatch} from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';

function NavbarMobileToggleButton(props) {
    const dispatch = useDispatch();

    return (
        <MenuIcon className={props.className}
                  onClick={() => dispatch(Actions.navbarToggleMobile())}
                  color="inherit"
        />
    );
}


export default NavbarMobileToggleButton;