var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { DialogActions } from '@mui/material';
import { red } from '@mui/material/colors';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next';
var UserNotTeamMemberDialog = function (_a) {
    var handleClose = _a.handleClose;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320 items-center" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Вы не в команде!') }), void 0), _jsxs("div", __assign({ className: "text-16 text-center pb-20 dark:text-white" }, { children: [_jsx(WarningAmberIcon, { sx: { color: red[600] } }, void 0), _jsx("p", { children: t('notTeamMemberT1') }, void 0), _jsx("p", { children: t('notTeamMemberT2') }, void 0)] }), void 0), _jsx(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: _jsx(Button, __assign({ onClick: function () {
                        handleClose();
                    }, type: "primary sm" }, { children: t('Все понятно') }), void 0) }), void 0)] }), void 0));
};
export default UserNotTeamMemberDialog;
