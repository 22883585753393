import Utils from '../../../utils/Utils';
export var GET_NAVIGATION = '[NAVIGATION] GET NAVIGATION';
export var SET_NAVIGATION = '[NAVIGATION] SET NAVIGATION';
export var RESET_NAVIGATION = '[NAVIGATION] RESET NAVIGATION';
export function getNavigation() {
    return {
        type: GET_NAVIGATION
    };
}
export function setNavigation(navigation) {
    return {
        type: SET_NAVIGATION,
        navigation: navigation
    };
}
export function resetNavigation() {
    return {
        type: RESET_NAVIGATION
    };
}
export function appendNavigationItem(item, parentId) {
    return function (dispatch, getState) {
        var navigation = getState().fuse.navigation;
        return dispatch({
            type: SET_NAVIGATION,
            navigation: Utils.appendNavItem(navigation, item, parentId)
        });
    };
}
export function prependNavigationItem(item, parentId) {
    return function (dispatch, getState) {
        var navigation = getState().fuse.navigation;
        return dispatch({
            type: SET_NAVIGATION,
            navigation: Utils.prependNavItem(navigation, item, parentId)
        });
    };
}
export function updateNavigationItem(id, item) {
    return function (dispatch, getState) {
        var navigation = getState().fuse.navigation;
        return dispatch({
            type: SET_NAVIGATION,
            navigation: Utils.updateNavItem(navigation, id, item)
        });
    };
}
export function removeNavigationItem(id) {
    return function (dispatch, getState) {
        var navigation = getState().fuse.navigation;
        return dispatch({
            type: SET_NAVIGATION,
            navigation: Utils.removeNavItem(navigation, id)
        });
    };
}
