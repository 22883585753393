var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Nominations } from 'common/enums/nomination/nominations.enum';
import { IsGoalReachedCell } from 'components/BasicTable/celComponents/IsGoalReachedCell';
import HelperFunctions from 'utils/HelperFunctions';
var TournamentNominationWinnerStandingsDataConverted = function (_a) {
    var nomination = _a.nomination, standingsData = _a.standingsData;
    var data = nomination.name === Nominations.earliestGoalReached
        ? "".concat(standingsData.daysTook, " \u0434\u043D\u0435\u0439")
        : nomination.name === Nominations.largestDistance
            ? "".concat(standingsData.data, " \u043A\u043C")
            : nomination.name === Nominations.lowestTempo
                ? "".concat(HelperFunctions.getReadableTempo(standingsData.data), " \u043C\u0438\u043D/\u043A\u043C")
                : nomination.name === Nominations.largestPositiveElevation
                    ? "".concat(standingsData.data, " \u043C")
                    : "".concat(standingsData.data);
    return nomination.name === Nominations.earliestGoalReached ? (_jsx(IsGoalReachedCell, { value: {
            goal: standingsData.goal,
            reachedAt: standingsData.reachedAt,
            isReached: true,
            currentValue: standingsData.goal,
            left: 0
        }, tooltipValueOverride: data }, void 0)) : typeof standingsData.data === 'object' ? null : (_jsx("p", __assign({ className: "max-w-96" }, { children: data }), void 0));
};
export default TournamentNominationWinnerStandingsDataConverted;
