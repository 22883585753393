var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, TextField } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { SYSTEM_CONFIG_PROPERTY } from 'common/graphql/queries/system-config/system-config-property';
import SynchronizeButton from 'components/Buttons/SynchronizeButton';
import UploadFilesButton from 'components/Buttons/UploadFilesButton';
import DocsHelper from 'components/DocsHelper/DocsHelper';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import Timer from 'components/Timer/Timer';
import { enUS, ru } from 'date-fns/locale';
import _ from 'lodash';
import PolarBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/PolarBind';
import StravaBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/StravaBind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import HelperFunctions from 'utils/HelperFunctions';
import i18n from 'utils/i18n/i18n';
var SelectActivitiesSourceForm = function (_a) {
    var _b, _c, _d, _e;
    var userId = _a.userId, polarAccountId = _a.polarAccountId, stravaAccountId = _a.stravaAccountId, setLoadingActivities = _a.setLoadingActivities, userActivitiesQueueRequests = _a.userActivitiesQueueRequests;
    var t = useTranslation().t;
    var covertTime = HelperFunctions.toHoursAndMinutes;
    var _f = useState(false), openSyncActivitiesTooltip = _f[0], setOpenSyncActivitiesTooltip = _f[1];
    var _g = useState(false), openUploadGPXTooltip = _g[0], setOpenUploadGPXTooltip = _g[1];
    var _h = useState(new Date()), syncFromDate = _h[0], setSyncFromDate = _h[1];
    var _j = useState(new Date()), syncBeforeDate = _j[0], setSyncBeforeDate = _j[1];
    var _k = useQuery(SYSTEM_CONFIG_PROPERTY, {
        fetchPolicy: 'network-only',
        variables: {
            property: 'IMPORT_ACTIVITIES_SYNC_TYPE'
        }
    }), syncType = _k.data, syncTypeLoading = _k.loading;
    var _l = useQuery(SYSTEM_CONFIG_PROPERTY, {
        fetchPolicy: 'network-only',
        variables: {
            property: 'IMPORT_ACTIVITIES_FULL_DOWNLOAD_FROM_DATE'
        }
    }), fullLoadFrom = _l.data, fullLoadFromLoading = _l.loading;
    var _m = useLazyQuery(SYSTEM_CONFIG_PROPERTY), getSystemConfigProperty = _m[0], systemConfigProperty = _m[1].data;
    useEffect(function () {
        var _a;
        if (((_a = syncType === null || syncType === void 0 ? void 0 : syncType.systemConfigProperty) === null || _a === void 0 ? void 0 : _a.value) &&
            (syncType.systemConfigProperty.value === 'fromDate' || syncType.systemConfigProperty.value === 'daysBefore')) {
            getSystemConfigProperty({
                variables: {
                    property: syncType.systemConfigProperty.value === 'fromDate'
                        ? 'IMPORT_ACTIVITIES_FROM_DATE'
                        : 'IMPORT_ACTIVITIES_DAYS_BEFORE'
                }
            });
        }
    }, [syncType]);
    var lastManualSyncDate = _.max(userActivitiesQueueRequests === null || userActivitiesQueueRequests === void 0 ? void 0 : userActivitiesQueueRequests.map(function (o) { return o.createdAt; }));
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var currentUserId = userProfile === null || userProfile === void 0 ? void 0 : userProfile.id;
    var isMy = userId === currentUserId;
    var isAdmin = !!((_c = (_b = userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles) === null || _b === void 0 ? void 0 : _b.map(function (role) { return role.name; })) === null || _c === void 0 ? void 0 : _c.filter(function (roleName) { return ['Admin', 'Operator', 'SuperAdmin'].includes(roleName); })[0]);
    var _o = useState(isAdmin ? new Date('2017-01-01') : new Date(new Date().setDate(new Date().getDate() - 90))), minDate = _o[0], setMinDate = _o[1];
    useEffect(function () {
        var _a, _b;
        if (((_a = syncType === null || syncType === void 0 ? void 0 : syncType.systemConfigProperty) === null || _a === void 0 ? void 0 : _a.value) && ((_b = systemConfigProperty === null || systemConfigProperty === void 0 ? void 0 : systemConfigProperty.systemConfigProperty) === null || _b === void 0 ? void 0 : _b.value)) {
            setMinDate(syncType.systemConfigProperty.value === 'fromDate'
                ? new Date(systemConfigProperty.systemConfigProperty.value)
                : new Date(new Date().setDate(new Date().getDate() - parseInt(systemConfigProperty.systemConfigProperty.value))));
        }
    }, [systemConfigProperty]);
    var _p = useState(lastManualSyncDate &&
        new Date(lastManualSyncDate) > new Date(new Date().setDate(new Date().getDate() - 1)) &&
        !isAdmin), timer = _p[0], setTimer = _p[1];
    if (syncTypeLoading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex flex-col dark:text-white" }, { children: [(isAdmin || isMy) && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex py-8" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [t('Синхронизировать все тренировки из источника'), "\u00A0"] }), void 0), _jsx(DocsHelper, __assign({ id: "d687fa7a-2725-41d7-8be2-b390b4cc71c1" }, { children: _jsx(InfoOutlinedIcon, { onClick: function () { return setOpenSyncActivitiesTooltip(!openSyncActivitiesTooltip); }, fontSize: "small" }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex flex-col items-center py-8" }, { children: (polarAccountId || stravaAccountId) && timer ? (_jsx("div", { children: lastManualSyncDate && (_jsx(Timer, { initialHours: covertTime((new Date(new Date(lastManualSyncDate).setDate(new Date(lastManualSyncDate).getDate() + 1)).getTime() -
                                            new Date().getTime()) /
                                            1000).h, initialMinute: covertTime((new Date(new Date(lastManualSyncDate).setDate(new Date(lastManualSyncDate).getDate() + 1)).getTime() -
                                            new Date().getTime()) /
                                            1000).m, initialSeconds: covertTime((new Date(new Date(lastManualSyncDate).setDate(new Date(lastManualSyncDate).getDate() + 1)).getTime() -
                                            new Date().getTime()) /
                                            1000).s, callBackFunction: function () { return setTimer(false); }, description: t('Повторная ручная синхронизация доступна через') }, void 0)) }, void 0)) : (_jsxs("div", __assign({ className: "flex flex-col space-y-10 items-center" }, { children: [((_d = fullLoadFrom === null || fullLoadFrom === void 0 ? void 0 : fullLoadFrom.systemConfigProperty) === null || _d === void 0 ? void 0 : _d.value) && (_jsxs("div", __assign({ className: "flex flex-col space-y-10 pb-20 items-center" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-5" }, { children: [_jsx("div", { children: _jsx(WarningAmberIcon, { sx: { color: yellow[600] } }, void 0) }, void 0), _jsxs("div", { children: [t('Полная загрузка тренировок с'), ":"] }, void 0)] }), void 0), _jsx("div", { children: new Date((_e = fullLoadFrom === null || fullLoadFrom === void 0 ? void 0 : fullLoadFrom.systemConfigProperty) === null || _e === void 0 ? void 0 : _e.value).toLocaleString() }, void 0)] }), void 0)), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns, locale: i18n.language === 'en' ? enUS : ru }, { children: _jsx(DatePicker, { value: syncFromDate, minDate: minDate, maxDate: new Date(), onChange: function (date) {
                                                    date && setSyncFromDate(date);
                                                }, label: t('Синхронизировать с даты'), renderInput: function (params) { return _jsx(TextField, __assign({}, params), void 0); } }, void 0) }), void 0), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns, locale: i18n.language === 'en' ? enUS : ru }, { children: _jsx(DatePicker, { value: syncBeforeDate, minDate: minDate, maxDate: new Date(), onChange: function (date) {
                                                    date && setSyncBeforeDate(date);
                                                }, label: t('Синхронизировать до'), renderInput: function (params) { return _jsx(TextField, __assign({}, params), void 0); } }, void 0) }), void 0), _jsx(SynchronizeButton, { userId: userId, fromDate: syncFromDate, beforeDate: syncBeforeDate, isTriggeredByAdmin: isAdmin }, void 0)] }), void 0)) }), void 0)] }, void 0)), !polarAccountId && (_jsxs("div", __assign({ className: "flex justify-between text-sm items-center py-8" }, { children: [_jsx("div", { children: t('Аккаунт Polar не подключен') }, void 0), isMy && (_jsx(Box, __assign({ sx: { color: function (theme) { return theme.palette.primary.main; } } }, { children: _jsx(PolarBind, { text: t('Подключить') }, void 0) }), void 0))] }), void 0)), !stravaAccountId && (_jsxs("div", __assign({ className: "flex justify-between text-sm items-center py-8" }, { children: [_jsx("div", { children: t('Аккаунт Strava не подключен') }, void 0), isMy && (_jsx(Box, __assign({ sx: { color: function (theme) { return theme.palette.primary.main; } } }, { children: _jsx(StravaBind, {}, void 0) }), void 0))] }), void 0))] }), void 0), isMy && (_jsxs("div", __assign({ className: "flex flex-col items-center pt-32 dark:text-white" }, { children: [_jsxs("div", __assign({ className: "flex py-8" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [t('Загрузить GPX файлы тренировок'), "\u00A0"] }), void 0), _jsx(DocsHelper, __assign({ id: "9d976230-060f-453b-b911-b9dc25f612ee" }, { children: _jsx(InfoOutlinedIcon, { onClick: function () { return setOpenUploadGPXTooltip(!openUploadGPXTooltip); }, fontSize: "small" }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex p-8" }, { children: _jsx(UploadFilesButton, { setLoadingFiles: setLoadingActivities, accept: "text/xml, .gpx", multiple: true }, void 0) }), void 0)] }), void 0))] }, void 0));
};
export default SelectActivitiesSourceForm;
