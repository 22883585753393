var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { NotificationTypes } from 'common/enums/notification/notification-types.enum';
import { USER_NOTIFICATION_SETTINGS } from 'common/graphql/queries/user/user-notification-settings';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import SettingsForm from '../components/SettingsForm/SettingsForm';
import UserNotificationSettingsListItem from './UserNotificationSettingsListItem';
var UserNotificationSettings = function () {
    var _a = useState('Notification' in window && Notification.permission === 'granted'), isBrowserNotificationsAllowed = _a[0], setIsBrowserNotificationsAllowed = _a[1];
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _b = useQuery(USER_NOTIFICATION_SETTINGS), data = _b.data, loading = _b.loading;
    var userNotificationSettings = data === null || data === void 0 ? void 0 : data.userNotificationSettings;
    var convertData = function (userNotificationSettings) {
        var distinctSubTypesIds = _.uniq(userNotificationSettings.map(function (o) { return o.subType.id; }));
        var summary = [];
        var _loop_1 = function (distinctSubTypesId) {
            var byEmail = userNotificationSettings.filter(function (o) { return o.subType.id === distinctSubTypesId && o.type.type === NotificationTypes.byEmail; })[0];
            var popUpUi = userNotificationSettings.filter(function (o) { return o.subType.id === distinctSubTypesId && o.type.type === NotificationTypes.popUpUi; })[0];
            summary.push({
                subTypeId: distinctSubTypesId,
                subTypeDescription: userNotificationSettings.filter(function (o) { return o.subType.id === distinctSubTypesId; })[0]
                    .subType.description,
                statusPerType: {
                    byEmail: byEmail && {
                        id: byEmail.id,
                        enabled: byEmail.enabled
                    },
                    popUpUi: popUpUi && {
                        id: popUpUi.id,
                        enabled: popUpUi.enabled
                    }
                }
            });
        };
        for (var _i = 0, distinctSubTypesIds_1 = distinctSubTypesIds; _i < distinctSubTypesIds_1.length; _i++) {
            var distinctSubTypesId = distinctSubTypesIds_1[_i];
            _loop_1(distinctSubTypesId);
        }
        return _.orderBy(summary, function (o) { return o.subTypeId; });
    };
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: t('Настройки уведомлений') }, void 0), content: _jsxs(_Fragment, { children: [_jsx(Divider, { light: true }, void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-20 items-start max-w-480" }, { children: [!('Notification' in window) && (_jsx("p", { children: t('Ваш браузер не поддерживает оповещения. Вы все равно будете их получать, если включите, но без всплывающих уведомлений.') }, void 0)), 'Notification' in window && !isBrowserNotificationsAllowed && (_jsxs("p", { children: [t('Для того, чтобы получать уведомления в браузере, их нужно разрешить.'), ' ', _jsx("span", __assign({ className: "tw-link-btn", onClick: function () {
                                        if ('Notification' in window) {
                                            if (Notification.permission !== 'granted') {
                                                Notification.requestPermission()
                                                    .then(function (permission) {
                                                    if (permission === 'granted') {
                                                        setIsBrowserNotificationsAllowed(true);
                                                    }
                                                    if (Notification.permission !== 'granted') {
                                                        dispatch(openSnackbar({
                                                            message: t("\u0423\u0432\u0435\u0434\u043E\u043C\u043B\u0435\u043D\u0438\u044F \u0432 \u0431\u0440\u0430\u0443\u0437\u0435\u0440\u0435 \u0437\u0430\u043F\u0440\u0435\u0449\u0435\u043D\u044B."),
                                                            severity: SnackbarSeverity.ERROR
                                                        }));
                                                    }
                                                })["catch"](function (reason) {
                                                    console.log(reason);
                                                });
                                            }
                                        }
                                    } }, { children: t('Нажмите') }), void 0), ",", ' ', t('чтобы мы спросили у вас разрешение на включение оповещение, затем нажмите "Разрешить".')] }, void 0)), 'Notification' in window && isBrowserNotificationsAllowed && (_jsx("p", { children: t('Уведомления в браузере разрешены.') }, void 0)), userNotificationSettings &&
                            userNotificationSettings.length > 0 &&
                            convertData(userNotificationSettings).map(function (userNotificationSettingsGrouped) {
                                return (_jsx(UserNotificationSettingsListItem, { userNotificationSettingsGrouped: userNotificationSettingsGrouped }, userNotificationSettingsGrouped.subTypeId));
                            })] }), void 0)] }, void 0) }, void 0));
};
export default UserNotificationSettings;
