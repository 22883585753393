var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import ReadOnlyEditor from 'components/DraftEditor/ReadOnlyEditor/ReadOnlyEditor';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
export var FinishingConditionStatus = function (_a) {
    var value = _a.value;
    var isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    var t = useTranslation().t;
    var _b = useState(false), openTooltip = _b[0], setOpenTooltip = _b[1];
    return (_jsx(Tooltip, __assign({ onOpen: function () { return setOpenTooltip(true); }, onMouseLeave: function () { return isMobile && setOpenTooltip(false); }, onClose: function () { return setOpenTooltip(false); }, open: openTooltip, placement: "top-start", enterTouchDelay: 0, leaveTouchDelay: 10000, leaveDelay: 500, title: _jsx(Fragment, { children: _jsx("div", __assign({ className: "flex flex-col space-y-10" }, { children: value.conditionStates.map(function (conditionState) {
                    return (_jsxs("div", __assign({ className: "flex flex-col space-y-5" }, { children: [_jsx("div", __assign({ className: "xs:min-w-80 md:min-w-92 xs:text-12 sm:text-14" }, { children: _jsx(ReadOnlyEditor, { value: conditionState.description }, void 0) }), void 0), _jsxs("div", __assign({ className: "flex items-center space-x-10" }, { children: [_jsxs("div", __assign({ className: "xs:min-w-80 md:min-w-92 xs:text-12 sm:text-14 text-gray" }, { children: [t('Статус'), ":"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840" }, { children: conditionState.isSatisfied ? (_jsx("div", __assign({ className: "flex text-green items-center" }, { children: t('Выполнено') }), void 0)) : (_jsx("div", __assign({ className: "flex text-red items-center" }, { children: t('Не выполнено') }), void 0)) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex items-center space-x-10" }, { children: [_jsxs("div", __assign({ className: "xs:min-w-80 md:min-w-92 xs:text-12 sm:text-14 text-gray" }, { children: [t('Дата проверки'), ":"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840" }, { children: new Date(conditionState.verificationDate).toLocaleString() }), void 0)] }), void 0)] }), conditionState.description));
                }) }), void 0) }, void 0) }, { children: value.conditionStates.map(function (o) { return o.isSatisfied; }).reduce(function (prev, cur) { return prev && cur; }) ? (_jsx("div", __assign({ className: "flex text-green justify-center" }, { children: "\u0423\u0441\u043B\u043E\u0432\u0438\u044F \u0432\u044B\u043F\u043E\u043B\u043D\u0435\u043D\u044B" }), void 0)) : (_jsx("div", __assign({ className: "flex text-red justify-center" }, { children: "\u0423\u0441\u043B\u043E\u0432\u0438\u044F \u043D\u0435 \u0432\u044B\u043F\u043E\u043B\u043D\u0435\u043D\u044B" }), void 0)) }), void 0));
};
