var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ImpulseTextLogo from 'assets/icons/menu/impulse-text-logo';
import Main from 'assets/icons/menu/Main';
var NavigationLogo = function (_a) {
    var navbarOpen = _a.navbarOpen;
    return (_jsxs("div", __assign({ className: "flex items-center justify-start md:justify-center h-56 min-h-56 xs:ml-14 md:ml-0 relative" }, { children: [_jsx("div", __assign({ className: "h-36 text-icon-lt" }, { children: _jsx(Main, { width: "36", height: "36" }, void 0) }), void 0), navbarOpen && (_jsx("div", __assign({ className: "mt-8 ml-16 text-icon-lt" }, { children: _jsx(ImpulseTextLogo, {}, void 0) }), void 0))] }), void 0));
};
export default NavigationLogo;
