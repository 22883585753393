import { jsx as _jsx } from "react/jsx-runtime";
import { appHost, appScheme } from 'common/appURLSetup';
import { yandexClientId } from 'common/socialClientIdsSetup';
import JoinAccountRequestDialog from '../JoinAccountRequestDialog/JoinAccountRequestDialog';
var YandexJoinAccountRequestDialog = function () {
    var handleRedirect = function () {
        var cbLink = "".concat(appScheme, "://").concat(appHost, "/login/yandex/");
        window.location.href = "https://oauth.yandex.ru/authorize?client_id=".concat(yandexClientId, "\n\t&redirect_uri=").concat(cbLink, "&response_type=code");
    };
    var handleJoinYandexAccount = function () {
        sessionStorage.setItem('join', 'email');
        handleRedirect();
    };
    return _jsx(JoinAccountRequestDialog, { handleJoin: handleJoinYandexAccount }, void 0);
};
export default YandexJoinAccountRequestDialog;
