var useAdministrateStandingsData = function () {
    var convertStandingsColumns = function (standingsColumns) {
        return Array.from(new Set(standingsColumns === null || standingsColumns === void 0 ? void 0 : standingsColumns.map(function (field) {
            return {
                id: field.id,
                columnOrder: field.columnOrder,
                columnName: field.columnName,
                name: field.name,
                sport: field.sport,
                isCollapsed: field.isCollapsed,
                properties: field.properties
            };
        })));
    };
    // const createTournamentDefaultData: TournamentDefaultState = {
    // 	name: '',
    // 	description: '',
    // 	activityType: Sports.Run,
    // 	locationId: null,
    // 	startDate: new Date(new Date().setMinutes(0)),
    // 	endDate: new Date(new Date(Date.now() + 3600 * 1000 * 24).setMinutes(0)),
    // 	registrationStartDate: new Date(new Date().setMinutes(0)),
    // 	registrationEndDate: new Date(new Date(Date.now() + 3600 * 1000 * 24).setMinutes(0)),
    // 	tournamentStandingsTableSettings: {
    // 		tournamentStandingsColumnsProps: [],
    // 		defaultSortBy: 'Kilometers',
    // 		orderParam: OrderParams.desc,
    // 	},
    // 	criteria: '',
    // 	criteriaDescription: '',
    // 	profilePhotoText: '',
    // 	profilePhoto: null,
    // 	active: false,
    // 	visible: false,
    // 	archived: false,
    // };
    // const convertTournamentStandingsColumns = (tournamentStandingsColumns: TournamentStandingsColumnDTO[]) => {
    // 	return Array.from(
    // 		new Set(
    // 			tournamentStandingsColumns.map((field) => {
    // 				return { id: field.id, columnOrder: field.columnOrder };
    // 			}),
    // 		),
    // 	);
    // };
    // const convertTournamentDataToTournamentCreateInput = (tournament?: TournamentDefaultState) => {
    // 	let tournamentCreateInput: TournamentCreateInput | undefined;
    // 	if (tournament && tournament?.id) {
    // 		const { active, archived, visible, profilePhoto, ...updatedData } = tournament;
    // 		tournamentCreateInput = {
    // 			...updatedData,
    // 			profilePhoto: profilePhoto instanceof File ? profilePhoto : null,
    // 			tournamentStandingsTableSettings: {
    // 				...tournament.tournamentStandingsTableSettings,
    // 				tournamentStandingsColumnsProps: convertTournamentStandingsColumns(
    // 					tournament.tournamentStandingsTableSettings.tournamentStandingsColumnsProps,
    // 				),
    // 			},
    // 		};
    // 		return tournamentCreateInput;
    // 	}
    // };
    return { convertStandingsColumns: convertStandingsColumns };
};
export default useAdministrateStandingsData;
