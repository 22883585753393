var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import UserAvatar from 'components/UserAvatar/UserAvatar';
import JoinTeamButton from 'main/pages/TeamPage/JoinTeamButton';
import { useTranslation } from 'react-i18next';
import history from 'store/history';
var TournamentTeamListItem = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var team = _a.team, currentUserId = _a.currentUserId, isUserTeamParticipant = _a.isUserTeamParticipant, handleClose = _a.handleClose;
    var t = useTranslation().t;
    var isSportEventRegistrationOpen = ((_e = (_d = (_c = (_b = team === null || team === void 0 ? void 0 : team.tournaments) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.sportEvent) === null || _d === void 0 ? void 0 : _d.datesSettings) === null || _e === void 0 ? void 0 : _e.registrationStartDate) &&
        ((_j = (_h = (_g = (_f = team === null || team === void 0 ? void 0 : team.tournaments) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.sportEvent) === null || _h === void 0 ? void 0 : _h.datesSettings) === null || _j === void 0 ? void 0 : _j.registrationEndDate)
        ? new Date() > new Date((_o = (_m = (_l = (_k = team === null || team === void 0 ? void 0 : team.tournaments) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.sportEvent) === null || _m === void 0 ? void 0 : _m.datesSettings) === null || _o === void 0 ? void 0 : _o.registrationStartDate) &&
            new Date() < new Date((_s = (_r = (_q = (_p = team === null || team === void 0 ? void 0 : team.tournaments) === null || _p === void 0 ? void 0 : _p[0]) === null || _q === void 0 ? void 0 : _q.sportEvent) === null || _r === void 0 ? void 0 : _r.datesSettings) === null || _s === void 0 ? void 0 : _s.registrationEndDate)
        : false;
    return (_jsxs("div", __assign({ className: "flex items-center w-full space-x-10" }, { children: [_jsx(UserAvatar, { style: "xs:w-68 xs:h-68 xs:text-20 md:w-88 md:h-88 md:text-24", firstName: team.displayName || team.name, profilePhoto: team.profilePhoto }, void 0), _jsxs("div", __assign({ className: "w-full h-full flex flex-col justify-start" }, { children: [_jsx("div", __assign({ onClick: function () {
                            history.push({
                                pathname: "/team/".concat(team.name)
                            });
                            handleClose && handleClose();
                        }, className: "flex xs:text-14 md:text-16 font-semibold px-8 py-2 cursor-pointer dark:text-white" }, { children: team.displayName || team.name }), void 0), !team.areYouParticipant && (_jsx("div", __assign({ className: "text-grey px-8 py-2" }, { children: team.participationSettings.isPrivate === true
                            ? t('Приватная команда')
                            : t('Публичная команда') }), void 0)), team.areYouParticipant && (_jsx("div", __assign({ className: "text-grey xs:text-12 sm:text-14 px-8 py-2" }, { children: t('Ваша команда') }), void 0)), team.usersCount !== undefined && team.usersCount > 0 && (_jsxs("div", __assign({ className: "flex space-x-5 px-8 py-2 items-center" }, { children: [_jsxs("div", __assign({ className: "xs:w-72 md:w-80 xs:text-12 sm:text-14 text-gray" }, { children: [t('Участников'), ":\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: team.usersCount }), void 0)] }), void 0))] }), void 0), team.participationSettings.isPrivate === false &&
                !isUserTeamParticipant &&
                isSportEventRegistrationOpen && _jsx(JoinTeamButton, { userId: currentUserId, team: team }, void 0)] }), void 0));
};
export default TournamentTeamListItem;
