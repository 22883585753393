import { makeVar } from '@apollo/client';
export var ThemeMode;
(function (ThemeMode) {
    ThemeMode["unknown"] = "unknown";
    ThemeMode["light"] = "light";
    ThemeMode["dark"] = "dark";
})(ThemeMode || (ThemeMode = {}));
export var displayPreferencesVar = makeVar({
    themeMode: ThemeMode.unknown
});
