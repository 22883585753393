var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@apollo/client';
import { UPDATE_STANDINGS } from 'common/graphql/mutations/standings/update-standings';
import { STANDINGS } from 'common/graphql/queries/standings/standings';
export function useUpdateStandingsMutation(standingsVariables) {
    var _a;
    var standingsData = {};
    var standingsId = standingsVariables === null || standingsVariables === void 0 ? void 0 : standingsVariables.id;
    for (var key in standingsVariables) {
        standingsData = __assign(__assign({}, standingsData), (_a = {}, _a[key] = standingsVariables[key], _a));
    }
    return useMutation(UPDATE_STANDINGS, {
        variables: {
            updateStandingsData: __assign({}, standingsData)
        },
        refetchQueries: [
            {
                query: STANDINGS,
                variables: {
                    id: standingsId,
                    showBasicFields: true
                }
            },
        ]
    });
}
