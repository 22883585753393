var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
var UserEmailSetting = function (_a) {
    var email = _a.email, isVerified = _a.isVerified;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var openVerifyUserEmailDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.VerifyUserEmailDialog,
            dialogProps: { email: email }
        }));
    };
    return (_jsxs("div", __assign({ className: "flex md:flex-row xs:flex-col md:space-x-5 md:space-y-0 xs:space-x-0 xs:space-y-5 items-center" }, { children: [_jsx(Typography, __assign({ className: "[overflow-wrap:anywhere]", display: "inline", sx: { fontSize: '1.4rem' } }, { children: email }), void 0), email && isVerified && (_jsx(Typography, __assign({ className: "[overflow-wrap:anywhere]", display: "inline", sx: { fontSize: '1.2rem', color: green[500] } }, { children: t('Подтверждён') }), void 0)), email && !isVerified && (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ className: "[overflow-wrap:anywhere]", display: "inline", sx: { fontSize: '1.2rem', color: red[500] } }, { children: [t('Ожидает подтверждения'), "."] }), void 0), _jsx("div", __assign({ className: "tw-link-btn", onClick: openVerifyUserEmailDialog }, { children: t('Подтвердить email') }), void 0)] }, void 0)), !email && (_jsx(Typography, __assign({ className: "[overflow-wrap:anywhere]", display: "inline", sx: { fontSize: '1.2rem', color: red[500] } }, { children: t('Не задана') }), void 0))] }), void 0));
};
export default UserEmailSetting;
