import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { STANDINGS_USERS } from 'common/graphql/queries/standings/standings-user';
import { useEffect, useState } from 'react';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
// если userId не указан, то берется id текущего пользователя
function UseIsUserStandingsParticipant(standingsId, userId) {
    var _a;
    var currentUserId = (_a = useReactiveVar(userProfileDataVar).userProfile) === null || _a === void 0 ? void 0 : _a.id;
    var _b = useState(), isUserStandingsParticipant = _b[0], setIsUserStandingsParticipant = _b[1];
    var _c = useLazyQuery(STANDINGS_USERS), getStandingsUsers = _c[0], standingsUsersData = _c[1].data;
    useEffect(function () {
        if ((userId || currentUserId) && standingsId) {
            getStandingsUsers({
                variables: { standingsId: standingsId, userId: userId || currentUserId, withDeleted: true }
            });
        }
    }, [userId, currentUserId, standingsId]);
    useEffect(function () {
        if (standingsUsersData) {
            standingsUsersData.standingsUsers.length > 0
                ? setIsUserStandingsParticipant(true)
                : setIsUserStandingsParticipant(false);
        }
    }, [standingsUsersData]);
    return { isUserStandingsParticipant: isUserStandingsParticipant };
}
export default UseIsUserStandingsParticipant;
