var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation } from '@apollo/client';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { CREATE_NOTIFICATIONN_INPUT } from 'common/graphql/mutations/notification/create-notification-input';
import { NOTIFICATION_INPUTS } from 'common/graphql/queries/notification/notification-inputs';
import { NOTIFICATION_SUBTYPES } from 'common/graphql/queries/notification/notification-subtypes';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import supportRequestSchema from 'common/validationSchemas/supportRequestSchema';
import { useFormik } from 'formik';
import linkifyHtml from 'linkify-html';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var NotificationInputAddNew = function () {
    var _a;
    var _b = useState(''), selectedNotificationSubTypeName = _b[0], setSelectedNotificationSubTypeName = _b[1];
    var _c = useState(undefined), selectedNotificationSubType = _c[0], setSelectedNotificationSubType = _c[1];
    var _d = useState([]), notificationSubTypeOptions = _d[0], setNotificationSubTypeOptions = _d[1];
    var _e = useState([]), notificationSubTypes = _e[0], setNotificationSubTypes = _e[1];
    var _f = useState(''), selectedNotificationTemplateName = _f[0], setSelectedNotificationTemplateName = _f[1];
    var _g = useState(undefined), selectedNotificationTemplate = _g[0], setSelectedNotificationTemplate = _g[1];
    var _h = useState([]), notificationTemplateOptions = _h[0], setNotificationTemplateOptions = _h[1];
    var _j = useState([]), notificationTemplates = _j[0], setNotificationTemplates = _j[1];
    var _k = useState(false), isAddNew = _k[0], setIsAddNew = _k[1];
    var _l = useState({}), defaultFormState = _l[0], setDefaultFormState = _l[1];
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _m = useLazyQuery(NOTIFICATION_SUBTYPES), getNotificationSubTypes = _m[0], _o = _m[1], notificationSubTypesData = _o.data, notificationSubTypesDataLoading = _o.loading;
    var createNotificationInput = function (data) {
        return useMutation(CREATE_NOTIFICATIONN_INPUT, {
            variables: {
                createNotificationInputData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: NOTIFICATION_INPUTS
                },
            ]
        });
    };
    useEffect(function () {
        getNotificationSubTypes({
            variables: {
                isSystemOne: false
            }
        });
    }, [isAddNew]);
    useEffect(function () {
        var _a, _b;
        if ((_a = notificationSubTypesData === null || notificationSubTypesData === void 0 ? void 0 : notificationSubTypesData.notificationSubTypes) === null || _a === void 0 ? void 0 : _a.length) {
            setNotificationSubTypeOptions((_b = notificationSubTypesData === null || notificationSubTypesData === void 0 ? void 0 : notificationSubTypesData.notificationSubTypes) === null || _b === void 0 ? void 0 : _b.map(function (o) { return o.name; }));
            setNotificationSubTypes(notificationSubTypesData === null || notificationSubTypesData === void 0 ? void 0 : notificationSubTypesData.notificationSubTypes);
        }
    }, [notificationSubTypesData]);
    useEffect(function () {
        if (selectedNotificationSubTypeName) {
            var valueObj = notificationSubTypes.filter(function (o) { return o.name === selectedNotificationSubTypeName; })[0];
            setSelectedNotificationSubType(valueObj);
            var templates = _.uniqBy(__spreadArray([valueObj.defaultTemplate], valueObj.templates, true), 'id');
            setNotificationTemplates(templates);
            setNotificationTemplateOptions(templates.map(function (o) { return o.name; }));
        }
    }, [selectedNotificationSubTypeName]);
    useEffect(function () {
        var _a;
        if (selectedNotificationTemplateName) {
            var valueObj = notificationTemplates.filter(function (o) { return o.name === selectedNotificationTemplateName; })[0];
            setSelectedNotificationTemplate(valueObj);
            var userTemplateVariables = valueObj && ((_a = valueObj.requiredTemplateVariables) === null || _a === void 0 ? void 0 : _a.length) > 0
                ? valueObj.requiredTemplateVariables.filter(function (o) { return !o.resolvedBySystem; })
                : [];
            setDefaultFormState({
                name: '',
                inputData: __assign({}, Object.fromEntries(userTemplateVariables.map(function (o) {
                    return [o.name, o.defaultValueRu || ''];
                }))),
                inputDataEn: __assign({}, Object.fromEntries(userTemplateVariables.map(function (o) {
                    return [o.name, o.defaultValueEn || ''];
                })))
            });
        }
    }, [selectedNotificationTemplateName]);
    var handleReset = function () {
        setIsAddNew(false);
        formik.resetForm();
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: "\u0418\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435 \u0434\u043B\u044F \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u044F \u0441\u043E\u0437\u0434\u0430\u043D\u044B \u0443\u0441\u043F\u0435\u0448\u043D\u043E",
            severity: SnackbarSeverity.SUCCESS
        }));
        handleReset();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(createNotificationInput, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        var _a;
        // console.log(data);
        var options = { defaultProtocol: 'https' };
        ((_a = data === null || data === void 0 ? void 0 : data.inputData) === null || _a === void 0 ? void 0 : _a.html) && (data.inputData.html = linkifyHtml(data.inputData.html, options));
        handleExecuteMutation(__assign({ notificationSubTypeId: selectedNotificationSubType === null || selectedNotificationSubType === void 0 ? void 0 : selectedNotificationSubType.id }, data));
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var formik = useFormik({
        enableReinitialize: true,
        initialValues: __assign({}, defaultFormState),
        validationSchema: supportRequestSchema.pick(Object.keys(defaultFormState)),
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsx(Box, __assign({ sx: { width: '100%' } }, { children: isAddNew ? (_jsxs("form", __assign({ className: "flex flex-col space-y-10", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx(Autocomplete, { value: selectedNotificationSubTypeName, filterOptions: function (x) { return x; }, options: notificationSubTypeOptions, disableClearable: false, renderInput: function (params) { return (_jsx(TextField, __assign({ name: "address", label: 'Выберите подтип оповещения' }, params), void 0)); }, freeSolo: true, onChange: function (event, newValue) {
                        setSelectedNotificationSubTypeName(newValue || '');
                    }, loading: notificationSubTypesDataLoading }, void 0), selectedNotificationSubType && (_jsx(TextField, { className: "", label: "\u041F\u0440\u0438\u0434\u0443\u043C\u0430\u0439\u0442\u0435 \u0443\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u043E\u0435 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u043D\u044B\u043C \u0434\u0430\u043D\u043D\u044B\u043C", name: "name", value: formik.values['name'] || '', onChange: handleChangeWrapper, error: Boolean(formik.errors['name']), helperText: formik.errors['name'] }, void 0)), selectedNotificationSubType && (_jsx(Autocomplete, { value: selectedNotificationTemplateName, filterOptions: function (x) { return x; }, options: notificationTemplateOptions, disableClearable: false, renderInput: function (params) { return (_jsx(TextField, __assign({ name: "address", label: 'Выберите шаблон' }, params), void 0)); }, freeSolo: true, onChange: function (event, newValue) {
                        setSelectedNotificationTemplateName(newValue || '');
                    } }, void 0)), selectedNotificationTemplate &&
                    ((_a = selectedNotificationTemplate.requiredTemplateVariables) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsx("p", { children: "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u043F\u0435\u0440\u0435\u043C\u0435\u043D\u043D\u044B\u0435 (RU)" }, void 0), selectedNotificationTemplate.requiredTemplateVariables
                            .filter(function (templateVariable) { return !templateVariable.resolvedBySystem; })
                            .map(function (templateVariable) {
                            var _a, _b;
                            return (_jsx(TextField, { className: "w-full", label: templateVariable.name, autoFocus: true, name: (_a = formik.values.inputData) === null || _a === void 0 ? void 0 : _a[templateVariable.name], value: ((_b = formik.values.inputData) === null || _b === void 0 ? void 0 : _b[templateVariable.name]) || '', onChange: function (e) {
                                    formik.setFieldError("inputData.".concat(templateVariable.name), undefined);
                                    formik.setFieldValue("inputData.".concat(templateVariable.name), e.target.value);
                                }, error: Boolean(formik.errors[templateVariable.name]), helperText: formik.errors[templateVariable.name] }, templateVariable.id));
                        }), _jsx("p", { children: "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u043F\u0435\u0440\u0435\u043C\u0435\u043D\u043D\u044B\u0435 (EN)" }, void 0), selectedNotificationTemplate.requiredTemplateVariables
                            .filter(function (templateVariable) { return !templateVariable.resolvedBySystem; })
                            .map(function (templateVariable) {
                            var _a, _b;
                            return (_jsx(TextField, { className: "w-full", label: templateVariable.name, autoFocus: true, name: (_a = formik.values.inputDataEn) === null || _a === void 0 ? void 0 : _a[templateVariable.name], value: ((_b = formik.values.inputDataEn) === null || _b === void 0 ? void 0 : _b[templateVariable.name]) || '', onChange: function (e) {
                                    formik.setFieldError("inputDataEn.".concat(templateVariable.name), undefined);
                                    formik.setFieldValue("inputDataEn.".concat(templateVariable.name), e.target.value);
                                }, error: Boolean(formik.errors[templateVariable.name]), helperText: formik.errors[templateVariable.name] }, templateVariable.id));
                        })] }), void 0)), _jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(Button, __assign({ type: "reset", color: "secondary", onClick: function () { return handleReset(); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)] }), void 0)) : (_jsx(Button, __assign({ type: "submit", onClick: function () { return setIsAddNew(true); } }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0)) }), void 0));
};
export default NotificationInputAddNew;
