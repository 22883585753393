import { activityPageConfig } from './ActivityPage/activityPageConfig';
import { activityValidationPageConfig } from './ActivityValidationPage/activityValidationPageConfig';
import { administrationPagesConfig } from './Administration/administrationPagesConfig';
import { athletesSearchPageConfig } from './AthletesSearchPage/athletesSearchPageConfig';
import { clubPageConfig } from './ClubPage/clubPageConfig';
import { clubSettingsPageConfig } from './ClubSettingsPage/clubSettingsPageConfig';
import { clubsPageConfig } from './ClubsPage/clubsPageConfig';
import { clubsStatisticsPageConfig } from './ClubsStatisticsPage/clubsStatisticsPageConfig';
import { donationPageConfig } from './DonationPage/donationPageConfig';
import { errorPageConfig } from './ErrorPage/errorPageConfig';
import { forgotPasswordPageConfig } from './ForgotPasswordPage/forgotPasswordPageConfig';
import { loginPageConfig } from './LoginPage/loginPageConfig';
import { logoutPageConfig } from './LogoutPage/logoutPageConfig';
import { mainPageConfig } from './MainPage/mainPageConfig';
import { notificationsUnsubscribePageConfig } from './NotificationsUnsubscribePage/notificationsUnsubscribePageConfig';
import { orderPageConfig } from './OrderPage/orderPageConfig';
import { organizationPageConfig } from './OrganizationPage/organizationPageConfig';
import { organizationSettingsPageConfig } from './OrganizationSettingsPage/organizationSettingsPageConfig';
import { privacyPolicyPageConfig } from './PrivacyPolicyPage/privacyPolicyPageConfig';
import { registerPageConfig } from './RegisterPage/registerPageConfig';
import { resetPasswordPageConfig } from './ResetPasswordPage/resetPasswordPageConfig';
import { settingsPageConfig } from './SettingsPage/settingsPageConfig';
import { sportEventPageConfig } from './SportEventPage/sportEventPageConfig';
import { sportEventSettingsPageConfig } from './SportEventSettingsPage/sportEventSettingsPageConfig';
import { sportEventsPageConfig } from './SportEventsPage/sportEventsPageConfig';
import { standingsListPageConfig } from './StandingsListPage/standingsListPageConfig';
import { standingsPageConfig } from './StandingsPage/standingsPageConfig';
import { standingsParticipantInfoPageConfig } from './StandingsParticipantInfo/standingsParticipantInfoPageConfig';
import { standingsSettingsPageConfig } from './StandingsSettingsPage/standingsSettingsPageConfig';
import { statsPageConfig } from './StatsPage/statsPageConfig';
import { supportRequestPageConfig } from './SupportRequestPage/supportRequestPageConfig';
import { supportRequestsPageConfig } from './SupportRequestsPage/supportRequestsPageConfig';
import { teamPageConfig } from './TeamPage/teamPageConfig';
import { teamSettingsPageConfig } from './TeamSettingsPage/teamSettingsPageConfig';
import { tournamentSettingsPageConfig } from './TournamentSettingsPage/tournamentSettingsPageConfig';
import { userNotificationPageConfig } from './UserNotificationPage/userNotificationPageConfig';
import { userNotificationsPageConfig } from './UserNotificationsPage/userNotificationsPageConfig';
import { userProfilePageConfig } from './UserProfilePage/userProfilePageConfig';
export var PagesConfig = [
    activityPageConfig,
    activityValidationPageConfig,
    administrationPagesConfig,
    athletesSearchPageConfig,
    clubPageConfig,
    clubsStatisticsPageConfig,
    clubSettingsPageConfig,
    clubsPageConfig,
    errorPageConfig,
    forgotPasswordPageConfig,
    loginPageConfig,
    notificationsUnsubscribePageConfig,
    userNotificationsPageConfig,
    userNotificationPageConfig,
    logoutPageConfig,
    mainPageConfig,
    privacyPolicyPageConfig,
    registerPageConfig,
    resetPasswordPageConfig,
    settingsPageConfig,
    statsPageConfig,
    standingsPageConfig,
    standingsSettingsPageConfig,
    standingsListPageConfig,
    standingsParticipantInfoPageConfig,
    supportRequestPageConfig,
    supportRequestsPageConfig,
    //tournamentStandingsConfig,
    teamPageConfig,
    teamSettingsPageConfig,
    userProfilePageConfig,
    donationPageConfig,
    orderPageConfig,
    sportEventsPageConfig,
    sportEventPageConfig,
    sportEventSettingsPageConfig,
    tournamentSettingsPageConfig,
    organizationPageConfig,
    organizationSettingsPageConfig,
];
