var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createRef, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { connect } from 'react-redux';
import MobileDetect from 'mobile-detect';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
var md = new MobileDetect(window.navigator.userAgent);
var isMobile = md.mobile();
var handlerNameByEvent = {
    'ps-scroll-y': 'onScrollY',
    'ps-scroll-x': 'onScrollX',
    'ps-scroll-up': 'onScrollUp',
    'ps-scroll-down': 'onScrollDown',
    'ps-scroll-left': 'onScrollLeft',
    'ps-scroll-right': 'onScrollRight',
    'ps-y-reach-start': 'onYReachStart',
    'ps-y-reach-end': 'onYReachEnd',
    'ps-x-reach-start': 'onXReachStart',
    'ps-x-reach-end': 'onXReachEnd'
};
Object.freeze(handlerNameByEvent);
var useStyles = makeStyles(function (theme) { return ({
    root: {}
}); });
var Scrollbars = React.forwardRef(function Scrollbars(props, ref) {
    ref = ref || createRef();
    var ps = useRef(null);
    var handlerByEvent = useRef(new Map());
    var classes = useStyles();
    var customScrollbars = props.customScrollbars;
    var location = useHistory().location;
    var hookUpEvents = useCallback(function () {
        Object.keys(handlerNameByEvent).forEach(function (key) {
            var callback = props[handlerNameByEvent[key]];
            if (callback) {
                var handler = function () { return callback(ref.current); };
                handlerByEvent.current.set(key, handler);
                ref.current.addEventListener(key, handler, false);
            }
        });
        // eslint-disable-next-line
    }, [ref]);
    var unHookUpEvents = useCallback(function () {
        Object.keys(handlerByEvent.current).forEach(function (value, key) {
            if (ref.current) {
                ref.current.removeEventListener(key, value, false);
            }
        });
        handlerByEvent.current.clear();
    }, [ref]);
    var destroyPs = useCallback(function () {
        // console.info("destroy::ps");
        unHookUpEvents();
        if (!ps.current) {
            return;
        }
        ps.current.destroy();
        ps.current = null;
    }, [unHookUpEvents]);
    var createPs = useCallback(function () {
        // console.info("create::ps");
        if (isMobile || !ref || ps.current) {
            return;
        }
        ps.current = new PerfectScrollbar(ref.current, props.option);
        hookUpEvents();
    }, [hookUpEvents, props.option, ref]);
    useEffect(function () {
        function updatePs() {
            if (!ps.current) {
                return;
            }
            ps.current.update();
        }
        updatePs();
    });
    useEffect(function () {
        customScrollbars ? createPs() : destroyPs();
    }, [createPs, customScrollbars, destroyPs]);
    useEffect(function () {
        function scrollToTop() {
            ref.current.scrollTop = 0;
        }
        if (props.scrollToTopOnChildChange) {
            scrollToTop();
        }
    }, [props.children, props.scrollToTopOnChildChange, ref]);
    useEffect(function () {
        return function () {
            destroyPs();
        };
    }, [destroyPs]);
    // console.info('render::ps');
    return (_jsx("div", __assign({ id: props.id, className: clsx(classes.root, props.className), style: (props.customScrollbars && (props.enable || true) && !isMobile) ?
            {
                position: 'relative',
                overflow: 'hidden'
            } : {}, ref: ref }, { children: props.children }), void 0));
});
function mapStateToProps(_a) {
    var base = _a.base;
    return {
        customScrollbars: base.settings.current.customScrollbars
    };
}
Scrollbars.propTypes = {
    onScrollY: PropTypes.func,
    onScrollX: PropTypes.func,
    onScrollUp: PropTypes.func,
    onScrollDown: PropTypes.func,
    onScrollLeft: PropTypes.func,
    onScrollRight: PropTypes.func,
    onYReachStart: PropTypes.func,
    onYReachEnd: PropTypes.func,
    onXReachStart: PropTypes.func,
    onXReachEnd: PropTypes.func,
    scrollToTopOnChildChange: PropTypes.bool
};
Scrollbars.defaultProps = {
    className: '',
    enable: true,
    scrollToTopOnChildChange: false,
    option: {
        wheelPropagation: true
    },
    ref: undefined,
    onScrollY: undefined,
    onScrollX: undefined,
    onScrollUp: undefined,
    onScrollDown: undefined,
    onScrollLeft: undefined,
    onScrollRight: undefined,
    onYReachStart: undefined,
    onYReachEnd: undefined,
    onXReachStart: undefined,
    onXReachEnd: undefined
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(React.memo(Scrollbars));
