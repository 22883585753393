var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@apollo/client';
import { UPDATE_TEAM } from 'common/graphql/mutations/team/update-team';
import { TEAM } from 'common/graphql/queries/team/team';
export function useUpdateTeamMutation(teamVariables) {
    var _a;
    var profilePhoto;
    var teamData = {};
    var teamId = teamVariables === null || teamVariables === void 0 ? void 0 : teamVariables.id;
    for (var key in teamVariables) {
        if (key !== 'profilePhoto' && key !== 'removeProfilePhoto') {
            // ключи
            teamData = __assign(__assign({}, teamData), (_a = {}, _a[key] = teamVariables[key], _a));
        }
    }
    if (teamVariables === null || teamVariables === void 0 ? void 0 : teamVariables.profilePhoto) {
        profilePhoto = teamVariables.profilePhoto;
    }
    if (teamVariables === null || teamVariables === void 0 ? void 0 : teamVariables.removeProfilePhoto) {
        profilePhoto = null;
    }
    return useMutation(UPDATE_TEAM, {
        variables: __assign({ updateTeamData: __assign({}, teamData) }, (typeof profilePhoto !== undefined && { profilePhoto: profilePhoto })),
        refetchQueries: [
            {
                query: TEAM,
                variables: {
                    id: teamId,
                    showBasicFields: true
                }
            },
        ]
    });
}
