var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
export var SportClubUserStatus = function (_a) {
    var value = _a.value;
    var isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    var _b = useState(false), openTooltip = _b[0], setOpenTooltip = _b[1];
    return value.isActive ? (_jsx("div", __assign({ className: "sportClubUserStatus flex justify-center" }, { children: "\u0410\u043A\u0442\u0438\u0432\u043D\u044B\u0439" }), void 0)) : value.leavedAt ? (_jsx(Tooltip, __assign({ onOpen: function () { return setOpenTooltip(true); }, onMouseLeave: function () { return isMobile && setOpenTooltip(false); }, onClose: function () { return setOpenTooltip(false); }, open: openTooltip, placement: "top-start", enterTouchDelay: 0, leaveTouchDelay: 10000, leaveDelay: 500, title: _jsx(Fragment, { children: _jsxs("div", __assign({ className: "flex items-center space-x-10" }, { children: [_jsx("div", __assign({ className: "min-w-60 xs:text-12 sm:text-14 text-gray" }, { children: "\u041F\u043E\u043A\u0438\u043D\u0443\u043B:" }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840" }, { children: new Date(value.leavedAt).toLocaleString() }), void 0)] }), void 0) }, void 0) }, { children: _jsx("div", __assign({ className: "flex text-red justify-center" }, { children: "\u041F\u043E\u043A\u0438\u043D\u0443\u043B \u043A\u043B\u0443\u0431" }), void 0) }), void 0)) : (_jsx("div", __assign({ className: "flex text-red justify-center" }, { children: "\u041F\u043E\u043A\u0438\u043D\u0443\u043B \u043A\u043B\u0443\u0431" }), void 0));
};
