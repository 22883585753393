var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Box, TextField } from '@mui/material';
import { Gender } from 'common/enums/common/gender';
import { useTranslation } from 'react-i18next';
var GenderSelect = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'gender' : _b, value = _a.value, errorMessage = _a.errorMessage, pluralFieldLabel = _a.pluralFieldLabel, disableClearable = _a.disableClearable, handleChange = _a.handleChange;
    var t = useTranslation().t;
    var options = [
        { label: pluralFieldLabel ? t('Женщины') : t('Женский'), value: Gender.FEMALE },
        { label: pluralFieldLabel ? t('Мужчины') : t('Мужской'), value: Gender.MALE },
    ];
    var data = options.find(function (option) { return option.value === value; }) || null;
    return (_jsx(Autocomplete, { options: options, value: data || null, disableClearable: disableClearable, renderInput: function (params) { return (_jsx(TextField, __assign({ name: name, error: !!errorMessage, helperText: errorMessage, label: t('Пол') }, params), void 0)); }, onChange: function (_event, value) {
            if ((value === null || value === void 0 ? void 0 : value.value) || !disableClearable) {
                handleChange(name, value === null || value === void 0 ? void 0 : value.value);
            }
        }, renderOption: function (props, option) { return (_jsx(Box, __assign({ component: "li", sx: { '& > img': { mr: 2, flexShrink: 0 } } }, props, { children: option.label }), void 0)); } }, void 0));
};
export default GenderSelect;
