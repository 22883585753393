import { Gender } from 'common/enums/common/gender';
var accountGroupsConfig = [
    {
        id: 'changeProfilePhoto-group',
        value: 'changeProfilePhoto-group',
        settingsGroupParams: [
            {
                id: 'changeUserPhotoGroupParams',
                values: [
                    {
                        id: 'profilePhoto',
                        value: 'profilePhoto',
                        title: 'Фото пользователя',
                        type: 'photo-upload',
                        dependentFields: ['firstName']
                    },
                ],
                type: 'accordion'
            },
        ]
    },
    {
        id: 'id-group',
        value: 'id-group',
        title: 'Ваш ID',
        settingsGroupParams: [
            {
                id: 'idGroupParams',
                values: [
                    {
                        id: 'id',
                        value: 'id',
                        title: 'ID',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: false
            },
        ]
    },
    {
        id: 'name-group',
        value: 'name-group',
        title: 'Имя',
        settingsGroupParams: [
            {
                id: 'nameGroupParams',
                values: [
                    {
                        id: 'firstName',
                        value: 'firstName',
                        title: 'Имя',
                        type: 'textField'
                    },
                    {
                        id: 'lastName',
                        value: 'lastName',
                        title: 'Фамилия',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'user-name-group',
        value: 'user-name-group',
        title: 'Никнейм',
        settingsGroupParams: [
            {
                id: 'userNameGroupParams',
                values: [
                    {
                        id: 'userName',
                        value: 'userName',
                        title: 'Имя',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'about-group',
        value: 'about-group',
        title: 'О себе',
        settingsGroupParams: [
            {
                id: 'aboutGroupParams',
                values: [
                    {
                        id: 'about',
                        value: 'about',
                        title: 'О себе',
                        type: 'textEditor',
                        textFieldIsMultiline: true
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'email-group',
        value: 'email-group',
        title: 'Эл. почта',
        settingsGroupParams: [
            {
                id: 'emailGroupParams',
                values: [
                    {
                        id: 'email',
                        value: 'email',
                        title: 'Эл. почта',
                        type: 'email',
                        dependentFields: ['isVerifiedEmail']
                    },
                ],
                type: 'accordion',
                editable: true,
                isOwnForm: true
            },
        ]
    },
    {
        id: 'birthDate-group',
        value: 'birthDate-group',
        title: 'Дата рождения',
        settingsGroupParams: [
            {
                id: 'birthDateGroupParams',
                values: [
                    {
                        id: 'birthDate',
                        value: 'birthDate',
                        title: 'Дата Рождения',
                        type: 'dateField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'gender-group',
        value: 'gender-group',
        title: 'Пол',
        settingsGroupParams: [
            {
                id: 'genderGroupParams',
                values: [
                    {
                        id: 'gender',
                        value: 'gender',
                        title: 'Пол',
                        type: 'selectField',
                        selectValues: [
                            {
                                value: Gender.FEMALE,
                                text: 'Женский'
                            },
                            {
                                value: Gender.MALE,
                                text: 'Мужской'
                            },
                        ]
                    },
                ],
                type: 'accordion',
                editable: false
            },
        ]
    },
    {
        id: 'location-group',
        value: 'location-group',
        title: 'Город',
        settingsGroupParams: [
            {
                id: 'location',
                type: 'accordion',
                values: [
                    {
                        id: 'location',
                        value: 'location',
                        title: 'Location',
                        type: 'location'
                    },
                ],
                editable: true
            },
        ]
    },
    {
        id: 'changePassword-group',
        value: 'changePassword-group',
        title: 'Пароль',
        settingsGroupParams: [
            {
                id: 'changePasswordGroupParams',
                values: [
                    {
                        id: 'oldPassword',
                        value: 'oldPassword',
                        title: 'Текущий пароль',
                        type: 'passwordField'
                    },
                    {
                        id: 'password',
                        value: 'password',
                        title: 'Новый пароль',
                        type: 'passwordField'
                    },
                    {
                        id: 'confirmPassword',
                        value: 'confirmPassword',
                        title: 'Подтвердите новый пароль',
                        type: 'passwordField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
];
export default accountGroupsConfig;
