var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { SPORT_EVENT } from 'common/graphql/queries/sportEvent/sportEvent';
import { useUpdateSportEventMutation } from 'common/hooks/sportEvent/useUpdateSportEventMutation';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import SettingsForm from 'main/pages/SettingsPage/components/SettingsForm/SettingsForm';
import SettingsGroup from 'main/pages/SettingsPage/components/SettingsGroup/SettingsGroup';
import sportEventProfileGroupsConfig from './sportEventProfileGroupsConfig';
var SportEventProfile = function (_a) {
    var sportEventId = _a.sportEventId;
    var _b = useQuery(SPORT_EVENT, {
        variables: {
            id: sportEventId,
            showBasicFields: true,
            showMainSettings: true
        }
    }), data = _b.data, loading = _b.loading;
    //конвертация необходима из-за вложенности mainSettings и др.полей
    var convertData = function (sportEvent) {
        var sportEventData = {};
        if (sportEvent) {
            var mainSettings = sportEvent.mainSettings, visibilitySettings = sportEvent.visibilitySettings, fields = __rest(sportEvent, ["mainSettings", "visibilitySettings"]);
            sportEventData = __assign(__assign(__assign({}, fields), mainSettings), visibilitySettings);
        }
        return sportEventData;
    };
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: "\u041F\u0440\u043E\u0444\u0430\u0439\u043B \u0441\u043E\u0431\u044B\u0442\u0438\u044F" }, void 0), content: sportEventProfileGroupsConfig.length > 0 &&
            sportEventProfileGroupsConfig.map(function (settingsGroup) { return (_jsxs("div", { children: [_jsx(SettingsGroup, { settingsGroup: settingsGroup, data: convertData(data === null || data === void 0 ? void 0 : data.sportEvent), handleChangeData: useUpdateSportEventMutation }, void 0), _jsx(Divider, { light: true }, void 0)] }, settingsGroup.id)); }) }, void 0));
};
export default SportEventProfile;
