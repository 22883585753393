import { createTheme } from '@mui/material/styles';
import { dark } from './colors';
import { lightBlue, red } from '@mui/material/colors';
import qs from 'qs';
import _ from '../utils/lodash';
/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
    customScrollbars: true,
    theme: {
        main: 'default',
        /*         navbar: 'default',
                toolbar: 'default',   */
        navbar: 'mainThemeDark',
        toolbar: 'mainThemeLight',
    }
};

export function getParsedQuerySettings() {
    const parsedQueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (parsedQueryString && parsedQueryString.defaultSettings) {
        return JSON.parse(parsedQueryString.defaultSettings);
    }
    return {}

    // Generating route params from settings
    /*const settings = qs.stringify({
        defaultSettings: JSON.stringify(defaultSettings, {strictNullHandling: true})
    });
    console.info(settings);*/
}

/**
 * THEME DEFAULTS
 */
export const defaultThemeOptions = {
    typography: { 
        fontFamily: ['Poppins', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        useNextVariants: true,
        suppressDeprecationWarnings: true
    },
};

export const mustHaveThemeOptions = {
    typography: {
        htmlFontSize: 10,
        fontSize: 14,
        body1: {
          fontSize: '1.4rem',
        },
        body2: {
          fontSize: '1.4rem',
        },
      },
};

export const defaultThemes = {
    /* default: {
        palette: {
            type: 'light',
            primary: dark,
            secondary: {
                light: lightBlue[400],
                main: lightBlue[600],
                dark: lightBlue[700]
            },
            error: red
        },
        status: {
            danger: 'orange'
        }
    },
    defaultDark: {
        palette: {
            type: 'dark',
            primary: dark,
            secondary: {
                light: lightBlue[400],
                main: lightBlue[600],
                dark: lightBlue[700]
            },
            error: red
        },
        status: {
            danger: 'orange'
        }
    } */
};

export function extendThemeWithMixins(obj) {
    const theme = createTheme(obj);
    return {
        border: (width = 1) => ({
            borderWidth: width,
            borderStyle: 'solid',
            borderColor: theme.palette.divider
        }),
        borderLeft: (width = 1) => ({
            borderLeftWidth: width,
            borderStyle: 'solid',
            borderColor: theme.palette.divider
        }),
        borderRight: (width = 1) => ({
            borderRightWidth: width,
            borderStyle: 'solid',
            borderColor: theme.palette.divider
        }),
        borderTop: (width = 1) => ({
            borderTopWidth: width,
            borderStyle: 'solid',
            borderColor: theme.palette.divider
        }),
        borderBottom: (width = 1) => ({
            borderBottomWidth: width,
            borderStyle: 'solid',
            borderColor: theme.palette.divider
        })
    }
}

export function mainThemeVariations(theme) {
    return {
/*         mainThemeDark1: createTheme(
            _.merge({},
                defaultThemeOptions,
                theme,
                { palette: { type: 'dark1' }, ...mustHaveThemeOptions }
            )),

        mainThemeLight1: createTheme(
            _.merge({},
                defaultThemeOptions,
                theme,
                { palette: { type: 'light1' }, ...mustHaveThemeOptions }
            )) */
    }
}