var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UserLanguage } from 'common/enums/user/user-language.enum';
import { USER } from 'common/graphql/queries/user/user';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { UPDATE_USER } from 'common/hooks/useUpdateUserMutation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var LanguageSelect = function (_a) {
    var userId = _a.userId, currentLang = _a.currentLang;
    var options = [
        { label: 'Русский', value: UserLanguage.Ru },
        { label: 'English', value: UserLanguage.En },
    ];
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var errorDetails = _a.errorDetails, code = _a.code;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var updateUser = function (data) {
        return useMutation(UPDATE_USER, {
            variables: {
                updateUserData: {
                    id: userId,
                    lang: data
                }
            },
            refetchQueries: [
                {
                    query: USER
                },
            ],
            awaitRefetchQueries: true
        });
    };
    var handleExecuteMutation = useDynamicMutation(updateUser, onSuccess, onError).handleExecuteMutation;
    var handleChangeLanguage = function (event) {
        var value = event.target.value;
        handleExecuteMutation(value);
        i18n.changeLanguage(value === UserLanguage.En ? 'en' : 'ru');
    };
    var value = options.find(function (option) { return option.value === currentLang; });
    return (_jsxs(FormControl, __assign({ className: "w-full" }, { children: [_jsx(InputLabel, { children: t('Язык') }, void 0), _jsx(Select, __assign({ label: t('Язык'), value: (value === null || value === void 0 ? void 0 : value.value) || '', onChange: handleChangeLanguage, input: _jsx(OutlinedInput, { className: "\n\t\tbg-bg-container-inner-lt \n\t\tdark:bg-bg-container-inner-dk  \n\t\tdark:text-white", label: t('Язык') }, void 0) }, { children: options.map(function (option) { return (_jsx(MenuItem, __assign({ value: option.value }, { children: option.label }), option.value)); }) }), void 0)] }), void 0));
};
export default LanguageSelect;
