var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setResetPasswordSuccess } from "store/actions";
import { Link } from 'react-router-dom';
import { useEffect } from "react";
var LinkDeliveryNotificationForm = function (_a) {
    var email = _a.email;
    var dispatch = useDispatch();
    useEffect(function () {
        return function () {
            dispatch(setResetPasswordSuccess());
        };
    }, []);
    return (_jsx(_Fragment, { children: email &&
            _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '32rem' } }, { children: [_jsx(Box, __assign({ sx: { mb: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' } }, { children: _jsx(Typography, __assign({ sx: { fontSize: { xs: '2rem', mb: '2rem' }, fontWeight: 500, lineHeight: 1 } }, { children: "\u0417\u0430\u0431\u044B\u043B\u0438 \u043F\u0430\u0440\u043E\u043B\u044C?" }), void 0) }), void 0), _jsxs(Box, __assign({ sx: { width: '32rem' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsx(Typography, __assign({ sx: { fontSize: { xs: '1.6rem' }, fontWeight: 400, lineHeight: 1, textAlign: 'center', mb: '1rem' } }, { children: "\u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u0441\u0441\u044B\u043B\u043A\u0443 \u0434\u043B\u044F \u0432\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F \u0434\u043E\u0441\u0442\u0443\u043F\u0430 \u043D\u0430 \u0432\u0430\u0448 \u0430\u0434\u0440\u0435\u0441 \u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u043E\u0439 \u043F\u043E\u0447\u0442\u044B:" }), void 0), _jsx(Typography, __assign({ sx: { fontSize: { xs: '1.6rem' }, fontWeight: 400, lineHeight: 1, textAlign: 'center' } }, { children: email }), void 0)] }), void 0), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', mt: '2.5rem' } }, { children: [_jsx(Box, __assign({ sx: { width: '32rem' } }, { children: _jsx(Divider, {}, void 0) }), void 0), _jsx(Box, __assign({ sx: { display: 'flex', justifyContent: 'center', mt: '1rem' } }, { children: _jsx(Link, __assign({ to: "/login" }, { children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043A \u0432\u0445\u043E\u0434\u0443 \u0432 \u0441\u0438\u0441\u0442\u0435\u043C\u0443" }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }, void 0));
};
export default LinkDeliveryNotificationForm;
