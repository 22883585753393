var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import HelperFunctions from 'utils/HelperFunctions';
var SportEventUserTeamItem = function (_a) {
    var name = _a.name, profilePhotoUrl = _a.profilePhotoUrl, displayName = _a.displayName, handleClose = _a.handleClose;
    return (_jsxs("div", __assign({ className: "flex md:max-w-840 items-center hover:cursor-pointer", onClick: function () {
            history.push("/team/".concat(name));
            handleClose();
        } }, { children: [_jsx(UserAvatar, { style: "h-28 w-28", profilePhoto: { url: profilePhotoUrl || '' }, firstName: name }, void 0), _jsx("div", __assign({ className: "dark:text-white xs:text-12 md:text-14 px-8 py-2" }, { children: displayName || name }), void 0)] }), void 0));
};
var SportEventUserListItem = function (_a) {
    var sportEventUser = _a.sportEventUser, isTeamTournament = _a.isTeamTournament, mesureRef = _a.mesureRef, handleClose = _a.handleClose;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    var getAge = HelperFunctions.getAge, getGender = HelperFunctions.getGender;
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var isMy = (sportEventUser === null || sportEventUser === void 0 ? void 0 : sportEventUser.userId) === (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id);
    return (_jsx("div", __assign({ className: "flex xs:p-8 md:p-10 \n                xs:mb-8 md:mb-10 decoration-0", ref: mesureRef }, { children: _jsxs("div", __assign({ className: "flex items-center w-full" }, { children: [_jsx(UserAvatar, { style: "xs:w-68 xs:h-68 xs:text-20 md:w-88 md:h-88 md:text-24", profilePhoto: { url: sportEventUser.profilePhotoUrl || '' }, firstName: sportEventUser.firstName }, void 0), _jsxs("div", __assign({ className: "w-full flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row justify-between" }, { children: [_jsxs("div", __assign({ onClick: function () {
                                        history.push({
                                            pathname: '/profile/' + sportEventUser.userName,
                                            state: { fromAthletesSearchPage: true }
                                        });
                                        handleClose();
                                    }, className: "xs:text-14 md:text-16 font-semibold px-8 py-2 dark:text-white cursor-pointer" }, { children: [sportEventUser.firstName, " ", sportEventUser.lastName] }), void 0), _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: isMy && _jsx("div", { children: t('Это вы') }, void 0) }), void 0)] }), void 0), sportEventUser.birthDate && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: [t('Возраст'), ":"] }), void 0), _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: getAge(sportEventUser.birthDate, i18n.language) }), void 0)] }), void 0)), sportEventUser.gender && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: [t('Пол'), ":"] }), void 0), _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: getGender(sportEventUser.gender, i18n.language) }), void 0)] }), void 0)), sportEventUser.sportClubId && (_jsxs("div", __assign({ className: "flex items-center pt-8" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: [t('Клуб'), ":"] }), void 0), _jsxs("div", __assign({ className: "flex md:max-w-840 items-center hover:cursor-pointer", onClick: function () {
                                        history.push("/club/".concat(sportEventUser.sportClubUrl));
                                    } }, { children: [_jsx(UserAvatar, { style: "h-28 w-28", profilePhoto: { url: sportEventUser.sportClubProfilePhotoUrl || '' }, firstName: sportEventUser.sportClubName }, void 0), _jsxs("div", __assign({ className: "pl-4 dark:text-white" }, { children: ["\u00A0", sportEventUser.sportClubName] }), void 0)] }), void 0)] }), void 0)), isTeamTournament && sportEventUser.teams && sportEventUser.teams.length > 0 && (_jsxs("div", __assign({ className: "flex items-center pt-8" }, { children: [_jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: sportEventUser.teams.length > 1 ? t('Команды') + ':' : t('Команда') + ':' }), void 0), _jsx("div", __assign({ className: "flex flex-col space-y-10" }, { children: sportEventUser.teams.map(function (team) {
                                        return (_jsx(SportEventUserTeamItem, { name: team.name, displayName: team.displayName, profilePhotoUrl: team.profilePhotoUrl, handleClose: handleClose }, team.id));
                                    }) }), void 0)] }), void 0))] }), void 0)] }), void 0) }), void 0));
};
export default SportEventUserListItem;
