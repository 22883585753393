var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Checkbox, FormControlLabel, IconButton, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
var DraggableTournamentStangingsColumnsList = function (_a) {
    var fields = _a.fields, onChangeOrder = _a.onChangeOrder, deleteField = _a.deleteField, editField = _a.editField, onIsCollapsedChange = _a.onIsCollapsedChange;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('md'));
    var onDragEnd = function (_a) {
        var destination = _a.destination, source = _a.source;
        // dropped outside the list
        if (!destination)
            return;
        var columnOrder = source.index + 1;
        var destinationIndex = destination.index + 1;
        onChangeOrder && onChangeOrder(columnOrder, destinationIndex);
    };
    return (_jsx(DragDropContext, __assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "list", type: "list" }, { children: function (provided, snapshot) { return (_jsxs("div", __assign({}, provided.droppableProps, { ref: provided.innerRef, className: "flex flex-col container" }, { children: [fields.map(function (field, index) { return (_jsx(Draggable, __assign({ draggableId: field.id, index: index }, { children: function (provided, snapshot) { return (_jsxs(Card, __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { className: clsx(snapshot.isDragging ? 'shadow-lg' : 'shadow', 'flex justify-between p-4 sm:p-8 rounded-8 mb-2'), square: true }, { children: [_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx(IconButton, __assign({ sx: {
                                                color: function (theme) {
                                                    return !snapshot.isDragging
                                                        ? theme.palette.icon.secondary
                                                        : theme.palette.icon.action;
                                                }
                                            } }, { children: _jsx(DragIndicatorIcon, {}, void 0) }), void 0), _jsx("div", __assign({ className: "flex items-center justify-center min-w-24 font-medium" }, { children: field.columnOrder }), void 0), _jsxs("div", __assign({ className: "flex flex-col items-start ml-8" }, { children: [_jsx("div", __assign({ className: "font-medium" }, { children: field.columnName }), void 0), _jsx("div", { children: field.name }, void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex items-center" }, { children: [isMobile ? (_jsx(Checkbox, { checked: field.isCollapsed, onChange: function (e) {
                                                onIsCollapsedChange &&
                                                    onIsCollapsedChange(field.id, e.target.checked);
                                            } }, void 0)) : (_jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { checked: field.isCollapsed, onChange: function (e) {
                                                    onIsCollapsedChange &&
                                                        onIsCollapsedChange(field.id, e.target.checked);
                                                } }, void 0), label: "\u0421\u0432\u0435\u0440\u043D\u0443\u0442\u044C \u0432 \u043C\u043E\u0431\u0438\u043B\u044C\u043D\u043E\u0439 \u0432\u0435\u0440\u0441\u0438\u0438" }, void 0)), _jsx("div", __assign({ className: "flex items-center" }, { children: field && (_jsx(IconButton, __assign({ onClick: function () { return editField && editField(field); } }, { children: _jsx(EditIcon, {}, void 0) }), void 0)) }), void 0), _jsx("div", __assign({ className: "flex items-center" }, { children: !field.defaultValue && (_jsx(IconButton, __assign({ onClick: function () { return deleteField && deleteField(field); } }, { children: _jsx(ClearIcon, {}, void 0) }), void 0)) }), void 0)] }), void 0)] }), void 0)); } }), field.id)); }), provided.placeholder] }), void 0)); } }), void 0) }), void 0));
};
export default DraggableTournamentStangingsColumnsList;
