var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { DialogActions } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(makeAspectCrop({
        unit: '%',
        width: 90
    }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}
function useDebounceEffect(fn, waitTime, deps) {
    useEffect(function () {
        var t = setTimeout(function () {
            fn.apply(undefined, deps ? __spreadArray([], deps, true) : []);
        }, waitTime);
        return function () {
            clearTimeout(t);
        };
    }, deps);
}
function canvasPreview(image, canvas, crop, scale, rotate) {
    if (scale === void 0) { scale = 1; }
    if (rotate === void 0) { rotate = 0; }
    return __awaiter(this, void 0, void 0, function () {
        var ctx, scaleX, scaleY, pixelRatio, cropX, cropY, rotateRads, centerX, centerY;
        return __generator(this, function (_a) {
            ctx = canvas.getContext('2d');
            if (!ctx) {
                throw new Error('No 2d context');
            }
            scaleX = image.naturalWidth / image.width;
            scaleY = image.naturalHeight / image.height;
            pixelRatio = window.devicePixelRatio;
            // const pixelRatio = 1
            canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
            canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
            ctx.scale(pixelRatio, pixelRatio);
            ctx.imageSmoothingQuality = 'high';
            cropX = crop.x * scaleX;
            cropY = crop.y * scaleY;
            rotateRads = rotate * (Math.PI / 180);
            centerX = image.naturalWidth / 2;
            centerY = image.naturalHeight / 2;
            ctx.save();
            // 5) Move the crop origin to the canvas origin (0,0)
            ctx.translate(-cropX, -cropY);
            // 4) Move the origin to the center of the original position
            ctx.translate(centerX, centerY);
            // 3) Rotate around the origin
            ctx.rotate(rotateRads);
            // 2) Scale the image
            ctx.scale(scale, scale);
            // 1) Move the center of the image to the origin (0,0)
            ctx.translate(-centerX, -centerY);
            ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
            ctx.restore();
            return [2 /*return*/];
        });
    });
}
var ProfilePhotoCrop = function (_a) {
    var id = _a.id, handleChangeData = _a.handleChangeData, handleClose = _a.handleClose, editPhotoMenuClose = _a.editPhotoMenuClose;
    var _b = useState(false), isCompressingFile = _b[0], setIsCompressingFile = _b[1];
    var _c = useState(''), imgSrc = _c[0], setImgSrc = _c[1];
    var previewCanvasRef = useRef(null);
    var imgRef = useRef(null);
    var blobUrlRef = useRef('');
    var _d = useState(), crop = _d[0], setCrop = _d[1];
    var _e = useState(), completedCrop = _e[0], setCompletedCrop = _e[1];
    var _f = useState(''), fileName = _f[0], setFileName = _f[1];
    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.
            var reader_1 = new FileReader();
            reader_1.addEventListener('load', function () { var _a; return setImgSrc(((_a = reader_1.result) === null || _a === void 0 ? void 0 : _a.toString()) || ''); });
            var file = e.target.files[0];
            reader_1.readAsDataURL(file);
            setFileName(file.name);
        }
    }
    function onImageLoad(e) {
        var _a = e.currentTarget, width = _a.width, height = _a.height;
        setCrop(centerAspectCrop(width, height, 1));
    }
    useDebounceEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if ((completedCrop === null || completedCrop === void 0 ? void 0 : completedCrop.width) && (completedCrop === null || completedCrop === void 0 ? void 0 : completedCrop.height) && imgRef.current && previewCanvasRef.current) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
            }
            return [2 /*return*/];
        });
    }); }, 100, [completedCrop]);
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
        handleClose();
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Фото успешно обновлено',
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
        editPhotoMenuClose();
    };
    var _g = useDynamicMutation(handleChangeData, onSuccess, onError), handleExecuteMutation = _g.handleExecuteMutation, loading = _g.loading;
    function handleSave() {
        return __awaiter(this, void 0, void 0, function () {
            var image, previewCanvas, MAX_SIZE, ratio, offscreen, ctx, blob, file, options, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsCompressingFile(true);
                        image = imgRef.current;
                        previewCanvas = previewCanvasRef.current;
                        if (!image || !previewCanvas || !completedCrop) {
                            onError({ errorDetails: 'Дождитесь пока фото будет обрезано' });
                            throw new Error('Crop canvas does not exist');
                        }
                        MAX_SIZE = 1024;
                        ratio = MAX_SIZE / Math.max(previewCanvas.width, previewCanvas.height);
                        offscreen = new OffscreenCanvas(ratio < 1 ? previewCanvas.width * ratio : completedCrop.width, ratio < 1 ? previewCanvas.height * ratio : completedCrop.height);
                        ctx = offscreen.getContext('2d');
                        if (!ctx) {
                            onError({
                                errorDetails: 'У вас устаревший браузер, попробуйте выполнить те же действия в другом браузере.'
                            });
                            throw new Error('No 2d context');
                        }
                        ctx.drawImage(previewCanvas, 0, 0, previewCanvas.width, previewCanvas.height, 0, 0, offscreen.width, offscreen.height);
                        return [4 /*yield*/, offscreen.convertToBlob({
                                type: 'image/png',
                                quality: 0.5
                            })];
                    case 1:
                        blob = _a.sent();
                        if (blobUrlRef.current) {
                            URL.revokeObjectURL(blobUrlRef.current);
                        }
                        blobUrlRef.current = URL.createObjectURL(blob);
                        blob.name = fileName;
                        file = blob;
                        if (!(file.size >= 1000000)) return [3 /*break*/, 5];
                        options = {
                            maxSizeMB: 1,
                            maxWidthOrHeight: 1920,
                            useWebWorker: true
                        };
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, imageCompression(file, options)];
                    case 3:
                        file = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 5];
                    case 5:
                        handleExecuteMutation({
                            id: id,
                            profilePhoto: file
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    var loadingButtonText = loading ? t('Сохраняем фото...') : isCompressingFile ? t('Сжимаем файл...') : 'Zzzz';
    return (_jsxs("div", __assign({ className: "flex flex-col space-y-20" }, { children: [_jsx("input", { type: "file", accept: "image/*", className: "dark:text-white", onChange: onSelectFile }, void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [!!imgSrc && (_jsx(ReactCrop, __assign({ crop: crop, onChange: function (c) { return setCrop(c); }, onComplete: function (c) { return setCompletedCrop(c); }, circularCrop: true, aspect: 1, minWidth: 124, minHeight: 124 }, { children: _jsx("img", { ref: imgRef, src: imgSrc, onLoad: onImageLoad }, void 0) }), void 0)), completedCrop && (_jsx("div", { children: _jsx("canvas", { ref: previewCanvasRef, style: {
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height
                            } }, void 0) }, void 0))] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), loading || isCompressingFile ? (_jsxs("div", __assign({ className: "flex flex-col space-y-5" }, { children: [_jsx(LoadingButton, __assign({ size: "small", loading: true, variant: "outlined", disabled: true }, { children: _jsx("span", { children: loadingButtonText }, void 0) }), void 0), _jsx("p", __assign({ className: "dark:text-white" }, { children: loadingButtonText }), void 0)] }), void 0)) : (_jsx(Button, __assign({ type: "primary sm", onClick: handleSave }, { children: t('Сохранить') }), void 0))] }), void 0)] }), void 0));
};
export default ProfilePhotoCrop;
