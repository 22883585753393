var standingsGroupsConfig = [
    {
        id: 'id-group',
        value: 'id-group',
        title: 'ID',
        settingsGroupParams: [
            {
                id: 'id-group',
                values: [
                    {
                        id: 'id',
                        value: 'id',
                        title: 'ID',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: false
            },
        ]
    },
    {
        id: 'name-Standings',
        value: 'name-Standings',
        title: 'Уникальное имя',
        settingsGroupParams: [
            {
                id: 'nameStandingsGroupParams',
                values: [
                    {
                        id: 'name',
                        value: 'name',
                        title: 'Уникальное имя',
                        type: 'textField',
                        textFieldIsMultiline: false
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'displayName-group',
        value: 'displayName-group',
        title: 'Отображаемое название',
        settingsGroupParams: [
            {
                id: 'displayNameGroupParams',
                values: [
                    {
                        id: 'displayName',
                        value: 'displayName',
                        title: 'Отображаемое название',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'overrideDisplayNameInFilter-group',
        value: 'overrideDisplayNameInFilter-group',
        title: 'Отображаемое имя в фильтре',
        settingsGroupParams: [
            {
                id: 'overrideDisplayNameInFilterGroupParams',
                values: [
                    {
                        id: 'overrideDisplayNameInFilter',
                        value: 'overrideDisplayNameInFilter',
                        title: 'Отображаемое имя в фильтре',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'description-Standings',
        value: 'description-Standings',
        title: 'Описание',
        settingsGroupParams: [
            {
                id: 'descriptionStandingsGroupParams',
                values: [
                    {
                        id: 'description',
                        value: 'description',
                        title: 'Описание',
                        type: 'textEditor',
                        textFieldIsMultiline: true
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'participantType-Standings',
        value: 'participantType-Standings',
        title: 'Тип участника',
        settingsGroupParams: [
            {
                id: 'participantTypeStandingsGroupParams',
                values: [
                    {
                        id: 'participantType',
                        value: 'participantType',
                        title: 'Тип участника',
                        type: 'textEditor'
                    },
                ],
                type: 'accordion',
                editable: false
            },
        ]
    },
    {
        id: 'parentType-Standings',
        value: 'parentType-Standings',
        title: 'Родительская сущность',
        settingsGroupParams: [
            {
                id: 'parentTypeStandingsGroupParams',
                values: [
                    {
                        id: 'parentType',
                        value: 'parentType',
                        title: 'Родительская сущность',
                        type: 'textEditor'
                    },
                ],
                type: 'accordion',
                editable: false
            },
        ]
    },
];
export default standingsGroupsConfig;
