import * as colors from '@mui/material/colors';
import authRoles from 'common/authRoles';
import { DateRangeFilterItems } from 'common/enums/components/date-range-filter-items.enum';
import _ from './lodash';

class EventEmitter {
	constructor() {
		this.events = {};
	}

	_getEventListByName(eventName) {
		if (typeof this.events[eventName] === 'undefined') {
			this.events[eventName] = new Set();
		}
		return this.events[eventName];
	}

	on(eventName, fn) {
		this._getEventListByName(eventName).add(fn);
	}

	once(eventName, fn) {
		const self = this;

		const onceFn = function (...args) {
			self.removeListener(eventName, onceFn);
			fn.apply(self, args);
		};
		this.on(eventName, onceFn);
	}

	emit(eventName, ...args) {
		this._getEventListByName(eventName).forEach(
			function (fn) {
				fn.apply(this, args);
			}.bind(this),
		);
	}

	removeListener(eventName, fn) {
		this._getEventListByName(eventName).delete(fn);
	}
}

class Utils {
	static generateRoutesFromConfigs(configs) {
		let allRoutes = [];
		configs.forEach((config) => {
			allRoutes = [...allRoutes, ...this.setRoutes(config)];
		});
		return allRoutes;
	}

	static setRoutes(config) {
		let routes = [...config.routes];

		if (config.settings || config.auth) {
			routes = routes.map((route) => {
				let auth = Array.isArray(config.auth) ? [...config.auth] : undefined;
				auth = route.auth ? [...auth, ...route.auth] : auth;
				return {
					...route,
					settings: { ...config.settings, ...route.settings },
					auth,
				};
			});
		}

		return [...routes];
	}

	static findById(o, id) {
		//Early return
		if (o.id === id) {
			return o;
		}
		let result, p;
		for (p in o) {
			if (o.hasOwnProperty(p) && typeof o[p] === 'object') {
				result = this.findById(o[p], id);
				if (result) {
					return result;
				}
			}
		}
		return result;
	}

	static getFlatNavigation(navigationItems, flatNavigation) {
		flatNavigation = flatNavigation ? flatNavigation : [];
		for (const navItem of navigationItems) {
			if (navItem.type === 'subheader') {
				continue;
			}

			if (navItem.type === 'item') {
				flatNavigation.push({
					id: navItem.id,
					title: navItem.title,
					type: navItem.type,
					icon: navItem.icon || false,
					url: navItem.url,
					auth: navItem.auth || null,
				});

				continue;
			}

			if (navItem.type === 'collapse' || navItem.type === 'group') {
				if (navItem.children) {
					this.getFlatNavigation(navItem.children, flatNavigation);
				}
			}
		}

		return flatNavigation;
	}

	static getCookie(name) {
		if (!document.cookie) {
			return null;
		}
		const xsrfCookies = document.cookie
			.split(';')
			.map((c) => c.trim())
			.filter((c) => c.startsWith(`${name}=`));
		if (xsrfCookies.length === 0) {
			return null;
		}
		return decodeURIComponent(xsrfCookies[0].split('=')[1]);
	}

	static randomMatColor(hue) {
		hue = hue ? hue : '400';
		const mainColors = [
			'red',
			'pink',
			'purple',
			'deepPurple',
			'indigo',
			'blue',
			'lightBlue',
			'cyan',
			'teal',
			'green',
			'lightGreen',
			'lime',
			'yellow',
			'amber',
			'orange',
			'deepOrange',
		];
		const randomColor = mainColors[Math.floor(Math.random() * mainColors.length)];
		return colors[randomColor][hue];
	}

	static difference(object, base) {
		function changes(object, base) {
			return _.transform(object, function (result, value, key) {
				if (!_.isEqual(value, base[key])) {
					result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
				}
			});
		}

		return changes(object, base);
	}

	static EventEmitter = EventEmitter;

	static updateNavItem(nav, id, item) {
		return nav.map((_item) => {
			if (_item.id === id) {
				return _.merge({}, _item, item);
			}

			if (_item.children) {
				return _.merge({}, _item, {
					children: this.updateNavItem(_item.children, id, item),
				});
			} else {
				return _.merge({}, _item);
			}
		});
	}

	static removeNavItem(nav, id) {
		return nav
			.map((_item) => {
				if (_item.id === id) {
					return null;
				}

				if (_item.children) {
					return _.merge({}, _.omit(_item, ['children']), {
						children: this.removeNavItem(_item.children, id),
					});
				} else {
					return _.merge({}, _item);
				}
			})
			.filter((s) => s);
	}

	static prependNavItem(nav, item, parentId) {
		if (!parentId) {
			return [item, ...nav];
		}

		return nav.map((_item) => {
			if (_item.id === parentId && _item.children) {
				return {
					_item,
					children: [item, ..._item.children],
				};
			}

			if (_item.children) {
				return _.merge({}, _item, {
					children: this.prependNavItem(_item.children, item, parentId),
				});
			} else {
				return _.merge({}, _item);
			}
		});
	}

	static appendNavItem(nav, item, parentId) {
		if (!parentId) {
			return [...nav, item];
		}

		return nav.map((_item) => {
			if (_item.id === parentId && _item.children) {
				return {
					_item,
					children: [..._item.children, item],
				};
			}

			if (_item.children) {
				return _.merge({}, _item, {
					children: this.appendNavItem(_item.children, item, parentId),
				});
			} else {
				return _.merge({}, _item);
			}
		});
	}

	static hasPermission(authArr, userRole) {
		/**
		 * If auth array is not defined
		 * Pass and allow
		 */
		if (authArr === null || authArr === undefined) {
			// console.info("auth is null || undefined:", authArr);
			return true;
		} else if (authArr.length === 0) {
			/**
			 * if auth array is empty means,
			 * allow only user role is guest (null or empty[])
			 */
			// console.info("auth is empty[]:", authArr);
			return !userRole || userRole.length === 0;
		} else {
			/**
			 * Check if user has grants
			 */
			// console.info("auth arr:", authArr);
			/*
			Check if user role is array,
			*/
			if (userRole && Array.isArray(userRole) && typeof userRole[0] === 'object') {
				const roles = [];
				userRole.forEach(({ name }) => {
					//interface Role
					roles.push(name);
				});
				return authArr.some((r) => roles.indexOf(r) >= 0);
			} else if (userRole && Array.isArray(userRole)) {
				return authArr.some((r) => userRole.indexOf(r) >= 0);
			}

			/*
			Check if user role is string,
			*/
			return authArr.includes(userRole);
		}
	}

	static isAdmin(userRole, targetRoles) {
		if (userRole && Array.isArray(userRole) && typeof userRole[0] === 'object') {
			const roles = [];
			userRole.forEach(({ name }) => {
				//interface Role
				roles.push(name);
			});
			const adminRoles = authRoles[targetRoles];
			const isHasPermission = adminRoles.some((r) => roles.indexOf(r) >= 0);
			return isHasPermission;
		}
	}

	static deletePropertyPath(obj, path) {
		if (!obj || !path) {
			return;
		}

		if (typeof path === 'string') {
			path = path.split('.');
		}

		for (var i = 0; i < path.length - 1; i++) {
			obj = obj[path[i]];

			if (typeof obj === 'undefined') {
				return;
			}
		}

		delete obj[path.pop()];
	}

	static findPathByKey(ob, key) {
		const path = [];
		const keyExists = (obj) => {
			if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
				return false;
			} else if (obj.hasOwnProperty(key)) {
				return true;
			} else if (Array.isArray(obj)) {
				let parentKey = path.length ? path.pop() : '';

				for (let i = 0; i < obj.length; i++) {
					path.push(`${parentKey}[${i}]`);
					const result = keyExists(obj[i], key);
					if (result) {
						return result;
					}
					path.pop();
				}
			} else {
				for (const k in obj) {
					path.push(k);
					const result = keyExists(obj[k], key);
					if (result) {
						return result;
					}
					path.pop();
				}
			}
			return false;
		};

		keyExists(ob);

		return path.join('.');
	}

	static findPathByKeyAndValue(ob, key, value, initialPath) {
		const path = [];
		const keyExists = (obj) => {
			if (!obj || (typeof obj !== 'object' && !Array.isArray(obj)) || (Array.isArray(obj) && !obj.length)) {
				path.pop();
				return false;
			} else if (obj.hasOwnProperty(key) && obj[key] === value) {
				return true;
			} else if (Array.isArray(obj)) {
				let parentKey = path.length ? path.pop() : '';

				for (let i = 0; i < obj.length; i++) {
					path.push(`${parentKey}[${i}]`);
					const result = keyExists(obj[i], key);
					if (result) {
						return result;
					}
					path.pop();
				}
			} else {
				for (const k in obj) {
					path.push(k);
					const result = keyExists(obj[k], key);
					if (result) {
						return result;
					}
				}
			}

			return false;
		};

		const isKeyExist = keyExists(ob);

		if (isKeyExist) {
			initialPath && path.unshift(initialPath);
			return path;
		} else {
			return;
		}
		//return isKeyExist && path.join(".") || undefined;
	}

	static convertDistanceToKm(distance) {
		return parseFloat((distance / 1000).toFixed(2));
	}

	/**
	 * Получить темп
	 * @param distance дистанция в км
	 * @param movingTime  дистанция в секундах
	 * @returns темп в мин/км
	 */
	static getTempo(distance, movingTime) {
		if (!distance) return 0;
		if (!movingTime) return 0;
		return (movingTime / 60 / (distance / 1000)).toFixed(2);
	}

	static getDateWithRuMonth(date, lang) {
		date = new Date(date);

		const months =
			lang === 'en'
				? [
						'January',
						'February',
						'March',
						'April',
						'May',
						'June',
						'July',
						'August',
						'Septemper',
						'October',
						'November',
						'December',
				  ]
				: [
						'января',
						'февраля',
						'марта',
						'апреля',
						'мая',
						'июня',
						'июля',
						'августа',
						'сентября',
						'октября',
						'ноября',
						'декабря',
				  ];

		const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

		const month = date.getMonth();

		return `${day} ${months[month]} ${date.getFullYear()}`;
	}

	static getDateRangeOfWeek(weekNo, year) {
		let d1 = new Date(year, 0, 1);
		const numOfdaysPastSinceLastMonday = d1.getDay() - 1;
		d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
		d1.setDate(d1.getDate() + 7 * (weekNo - this.getWeekNumber(d1)));
		const dateStart =
			d1.getFullYear() +
			'-' +
			(d1.getMonth() + 1 > 9 ? d1.getMonth() + 1 : '0' + (d1.getMonth() + 1)) +
			'-' +
			(d1.getDate() > 9 ? d1.getDate() : '0' + d1.getDate());
		const labels = [];
		const days = [];
		const daysOfWeek = 'пн вт ср чт пт сб вс';
		for (const dayOfWeek of daysOfWeek.split(' ')) {
			days.push(
				d1.getFullYear() +
					'-' +
					(d1.getMonth() + 1 > 9 ? d1.getMonth() + 1 : '0' + (d1.getMonth() + 1)) +
					'-' +
					(d1.getDate() > 9 ? d1.getDate() : '0' + d1.getDate()),
			);
			labels.push(`${d1.getDate()} ${dayOfWeek}`);
			d1.setDate(d1.getDate() + 1);
		}
		d1.setDate(d1.getDate() - 1);
		const dateEnd =
			d1.getFullYear() +
			'-' +
			(d1.getMonth() + 1 > 9 ? d1.getMonth() + 1 : '0' + (d1.getMonth() + 1)) +
			'-' +
			(d1.getDate() > 9 ? d1.getDate() : '0' + d1.getDate());
		return {
			dateStart,
			dateEnd,
			days,
			labels,
		};
	}

	static convertDateToHoursAndMinutes = (startDate) => {
		let date = new Date(startDate);
		let hours = date.getUTCHours() > 9 ? date.getUTCHours() : `0${date.getUTCHours()}`;
		const mins = date.getUTCMinutes() > 9 ? date.getUTCMinutes() : `0${date.getUTCMinutes()}`;

		return `${hours}:${mins}`;
	};

	//	range:RangeFilter
	static isDateInDateRange = (date, range) => {
		//let date = new Date(date);
	};

	//temporary crutch to search both in lowerCase and upperCase
	static prepareSearchInputValue = (v) => {
		let s = '';
		let i = v.length;
		while (i--) {
			let l = v[i];
			let r = new RegExp('[A-zА-яёЁ]');

			if (r.test(l)) {
				l = '(' + l.toLowerCase() + '|' + l.toUpperCase() + ')';
			}
			s = l + s;
		}
		return s.replace(/\s/g, '%');
	};

	//range: RangeFilter
	static getFirstAndLastDateByRange = (range) => {
		let firstDay, lastDay;
		if (range.dateRange === DateRangeFilterItems.year && range.year) {
			firstDay = new Date(Date.UTC(range.year, 0, 1));
			lastDay = new Date(Date.UTC(range.year, 11, 31));
		}
		if (range.dateRange === DateRangeFilterItems.month && (range.month || range.month === 0) && range.year) {
			firstDay = new Date(Date.UTC(range.year, range.month, 1));
			lastDay = new Date(Date.UTC(range.year, range.month + 1, 0));
		}
		if (
			(range.dateRange === DateRangeFilterItems.day || range.dateRange === DateRangeFilterItems.period) &&
			range.period?.startDate &&
			range.period?.endDate
		) {
			firstDay = new Date(
				Date.UTC(
					new Date(range.period.startDate).getFullYear(),
					new Date(range.period.startDate).getMonth(),
					new Date(range.period.startDate).getDate(),
					0,
					0,
					0,
				),
			);
			lastDay = new Date(
				Date.UTC(
					new Date(range.period.endDate).getFullYear(),
					new Date(range.period.endDate).getMonth(),
					new Date(range.period.endDate).getDate(),
					23,
					59,
					59,
				),
			);
		}
		return {
			firstDay,
			lastDay,
		};
	};

	static EventEmitter = EventEmitter;
}

export default Utils;

/*type Config = {
	settings: Array<any>;
	auth: Array<any>;
	routes: Array<any>;
};*/
