var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import history from 'store/history';
var SportEventTournamentListItem = function (_a) {
    var _b, _c;
    var tournament = _a.tournament;
    var onPreviewButtonClick = function () {
        history.push("/tournament-settings/".concat(tournament.id));
    };
    return (_jsx("div", __assign({ className: "flex border-1 solid rounded-8 xs:p-8 md:p-10 \n                xs:mb-8 md:mb-10 decoration-0 border-border-lt dark:border-border-dk" }, { children: _jsxs("div", __assign({ className: "flex flex-col space-y-20 w-full" }, { children: [_jsxs("div", __assign({ className: "flex  flex-row items-center justify-between pr-10" }, { children: [_jsx("div", __assign({ className: "xs:text-14 md:text-16 font-semibold px-8 py-2" }, { children: tournament.mainSettings.displayName }), void 0), _jsx("div", __assign({ className: "flex flex-row items-center max-w-288" }, { children: _jsx(Button, __assign({ type: "button", onClick: onPreviewButtonClick }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row xs:space-x-0 xs:space-y-20 sm:space-y-0 sm:space-x-20" }, { children: [_jsxs("div", __assign({ className: "flex flex-col px-8" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Тип турнира:', "\u00A0"] }), void 0), _jsx("div", { children: tournament.mainSettings.standingsType }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Виды спорта:', "\u00A0"] }), void 0), _jsx("div", { children: ((_b = tournament.sports) === null || _b === void 0 ? void 0 : _b.length) > 0
                                                ? tournament.sports.map(function (sport) { return sport.displayName; }).join(', ')
                                                : '-' }, void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-col px-8" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Даты проведения:', "\u00A0"] }), void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-5 pt-10" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Дата начала:', "\u00A0"] }), void 0), _jsxs("div", { children: [" ", new Date(tournament.datesSettings.startDate).toLocaleString()] }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Дата окончания:', "\u00A0"] }), void 0), _jsxs("div", { children: [" ", new Date(tournament.datesSettings.endDate).toLocaleString()] }, void 0)] }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-col px-8" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Подключенные турнирные фичи:', "\u00A0"] }), void 0), _jsx("div", { children: tournament.features && ((_c = tournament.features) === null || _c === void 0 ? void 0 : _c.length) > 0
                                        ? tournament.features.map(function (feature) { return feature.displayName; }).join(', ')
                                        : '-' }, void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default SportEventTournamentListItem;
