var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SocialTypes } from 'common/enums/user/social-types.enum';
import { useTranslation } from 'react-i18next';
import GoogleFitConnect from './GoogleFitConnect';
import SocialUnBind from './SocialUnBind';
var GoogleFitSettings = function (_a) {
    var userProfileGoogleSocialSettings = _a.userProfileGoogleSocialSettings;
    var t = useTranslation().t;
    var isEnoughScope = (userProfileGoogleSocialSettings === null || userProfileGoogleSocialSettings === void 0 ? void 0 : userProfileGoogleSocialSettings.scope)
        ? [
            'https://www.googleapis.com/auth/fitness.activity.read',
            'https://www.googleapis.com/auth/fitness.heart_rate.read',
            'https://www.googleapis.com/auth/fitness.location.read',
        ].every(function (scope) { var _a; return (_a = userProfileGoogleSocialSettings.scope) === null || _a === void 0 ? void 0 : _a.includes(scope); })
        : false;
    return isEnoughScope ? (_jsxs("div", __assign({ className: "flex flex-col space-y-10 items-center" }, { children: [(userProfileGoogleSocialSettings === null || userProfileGoogleSocialSettings === void 0 ? void 0 : userProfileGoogleSocialSettings.isDownloadFitnessData) ? (_jsx("p", { children: t('Аккаунт подключен, включен импорт тренировок') }, void 0)) : (_jsx("p", { children: t('Аккаунт подключен, импорт тренировок выключен') }, void 0)), (userProfileGoogleSocialSettings === null || userProfileGoogleSocialSettings === void 0 ? void 0 : userProfileGoogleSocialSettings.isInError) && (_jsx("p", __assign({ className: "text-red" }, { children: t('Произошла ошибка доступа к вашим Google Fit данным.') }), void 0)), (userProfileGoogleSocialSettings === null || userProfileGoogleSocialSettings === void 0 ? void 0 : userProfileGoogleSocialSettings.errorMessage) && (_jsx("p", __assign({ className: "text-red" }, { children: t(userProfileGoogleSocialSettings === null || userProfileGoogleSocialSettings === void 0 ? void 0 : userProfileGoogleSocialSettings.errorMessage) }), void 0)), _jsx("div", __assign({ className: "w-full flex items-center justify-end" }, { children: _jsx("div", __assign({ className: "w-full flex items-center justify-end" }, { children: _jsx(SocialUnBind, { socialType: SocialTypes.Google, googleFitUnbindOnly: true }, void 0) }), void 0) }), void 0)] }), void 0)) : (_jsx(GoogleFitConnect, {}, void 0));
};
export default GoogleFitSettings;
