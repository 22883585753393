import { jsx as _jsx } from "react/jsx-runtime";
import GoogleFitIcon from 'assets/logos/GoogleFitIcon';
import GoogleIcon from 'assets/logos/GoogleIcon';
import PolarIcon from 'assets/logos/PolarIcon';
import StravaIcon from 'assets/logos/StravaIcon';
import VKIcon from 'assets/logos/VKIcon';
import YandexIcon from 'assets/logos/YandexIcon';
var connectionsGroupsConfig = [
    {
        id: 'google_group',
        value: 'google_group',
        title: 'Google',
        logo: _jsx(GoogleIcon, {}, void 0),
        settingsGroupParams: [
            {
                id: 'googleGroupParams',
                values: [
                    {
                        id: 'googleAccountId',
                        value: 'googleSettings.accountId',
                        title: 'Google',
                        type: 'textField'
                    },
                    {
                        id: 'email',
                        value: 'googleSettings.email',
                        title: 'GoogleEmail',
                        type: 'textField'
                    },
                ],
                type: 'connection',
                editable: true
            },
        ]
    },
    {
        id: 'vk_group',
        value: 'vk_group',
        title: 'ВКонтакте',
        logo: _jsx(VKIcon, { fill: "#5181b8" }, void 0),
        settingsGroupParams: [
            {
                id: 'vkGroupParams',
                values: [
                    {
                        id: 'vkAccountId',
                        value: 'vkSettings.accountId',
                        title: 'ВКонтакте',
                        type: 'textField'
                    },
                    {
                        id: 'email',
                        value: 'vkSettings.email',
                        title: 'VkEmail',
                        type: 'textField'
                    },
                ],
                type: 'connection',
                editable: true
            },
        ]
    },
    {
        id: 'yandex_group',
        value: 'yandex_group',
        title: 'Яндекс',
        logo: _jsx(YandexIcon, {}, void 0),
        settingsGroupParams: [
            {
                id: 'yandexGroupParams',
                values: [
                    {
                        id: 'yandexAccountId',
                        value: 'yandexSettings.accountId',
                        title: 'Яндекс',
                        type: 'textField'
                    },
                    {
                        id: 'email',
                        value: 'yandexSettings.email',
                        title: 'YandexEmail',
                        type: 'textField'
                    },
                ],
                type: 'connection',
                editable: true
            },
        ]
    },
    {
        id: 'strava_group',
        value: 'strava_group',
        title: 'Strava',
        logo: _jsx(StravaIcon, {}, void 0),
        settingsGroupParams: [
            {
                id: 'stravaGroupParams',
                values: [
                    {
                        id: 'stravaAccountId',
                        value: 'stravaSettings',
                        title: 'Strava',
                        type: 'objectsArray'
                    },
                ],
                type: 'connection',
                editable: true
            },
        ]
    },
    {
        id: 'polar_group',
        value: 'polar_group',
        title: 'Polar',
        logo: _jsx(PolarIcon, {}, void 0),
        settingsGroupParams: [
            {
                id: 'polarGroupParams',
                values: [
                    {
                        id: 'polarAccountId',
                        value: 'polarSettings',
                        title: 'Polar',
                        type: 'objectsArray'
                    },
                ],
                type: 'connection',
                editable: true
            },
        ]
    },
    {
        id: 'googleFit_group',
        value: 'googleFit_group',
        title: 'GoogleFit',
        logo: _jsx(GoogleFitIcon, {}, void 0),
        settingsGroupParams: [
            {
                id: 'googleFitGroupParams',
                values: [
                    {
                        id: 'googleFit',
                        value: 'googleSettings',
                        title: 'Google',
                        type: 'textField'
                    },
                ],
                type: 'connection',
                editable: true
            },
        ]
    },
];
export default connectionsGroupsConfig;
