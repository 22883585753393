import { VirtualPointsBoosterTypes } from 'common/enums/feature/virtual-points-booster-types.enum';
import { VirtualPointsCoinsGetMethods } from 'common/enums/feature/virtual-points-get-methods.enum';
import { Sports } from 'common/enums/sport/sports.enum';
import * as Yup from 'yup';
var virtualPointsValidationSchema = Yup.object().shape({
    pointsPerSportTypeMappings: Yup.array()
        .of(Yup.object().shape({
        sport: Yup.mixed().oneOf(Object.values(Sports)).required('Вид спорта'),
        points: Yup.number()
            .min(1, 'Значение от 1 до 100000')
            .max(100000, 'Значение от 1 до 100000')
            .required('Необходимо задать значение')
    }))
        .required('Не задан маппинг'),
    boostersEnabled: Yup.boolean().required(),
    boostersConfig: Yup.object()
        .shape({
        coinsConfig: Yup.object()
            .shape({
            initialCoinsForUser: Yup.number()
                .optional()
                .when('boostersEnabled', {
                is: true,
                then: Yup.number()
                    .min(0, 'Значение от 0 до 100000')
                    .max(100000, 'Значение от 0 до 100000')
                    .required('Нужно задать значение')
            }),
            coinsGetMethod: Yup.mixed()
                .oneOf(Object.values(VirtualPointsCoinsGetMethods))
                .optional()
                .when('boostersEnabled', {
                is: true,
                then: Yup.mixed().required('Необходимо задать cпособ получения монет')
            }),
            coinPrice: Yup.number()
                .min(0, 'Значение от 1 до 100000')
                .max(100000, 'Значение от 1 до 100000')
                .optional(),
            coinsPerReceiving: Yup.number()
                .min(0, 'Значение от 1 до 100000')
                .max(100000, 'Значение от 1 до 100000')
                .optional()
                .when('coinsGetMethod', {
                is: VirtualPointsCoinsGetMethods.onSchedule,
                then: Yup.number().required('Необходимо задать кол-во монет получаемых за раз')
            }),
            coinsReceivingSchedule: Yup.string()
                .optional()
                .when('coinsGetMethod', {
                is: VirtualPointsCoinsGetMethods.onSchedule,
                then: Yup.string().required('Необходимо задать расписание')
            })
        })
            .nullable()["default"]({})
            .optional()
            .when('boostersEnabled', {
            is: true,
            then: Yup.object().required()
        }),
        boostersEffectiveTimeConfig: Yup.object()
            .shape({
            isTriggeredOnSchedule: Yup.boolean()
                .optional()
                .when('boostersEnabled', {
                is: true,
                then: Yup.object().required('Необходимо задать значение')
            }),
            effectStartSchedule: Yup.string()
                .optional()
                .when('boostersEnabled', {
                is: true,
                then: Yup.object().required('Необходимо задать значение')
            }),
            effectDurationMinutes: Yup.number()
                .min(0, 'Значение от 1 до 100000')
                .max(100000, 'Значение от 1 до 100000')
                .optional()
                .when('boostersEnabled', {
                is: true,
                then: Yup.object().required('Необходимо задать значение')
            })
        })
            .nullable()["default"]({})
            .optional()
            .when('boostersEnabled', {
            is: true,
            then: Yup.object().required('Необходимо задать значение')
        }),
        boostersPricesConfigs: Yup.array()
            .of(Yup.object().shape({
            boosterName: Yup.mixed()
                .oneOf(Object.values(VirtualPointsBoosterTypes))
                .required('Тип бустера'),
            price: Yup.number()
                .min(1, 'Значение от 1 до 100000')
                .max(100000, 'Значение от 1 до 100000')
                .required('Необходимо задать значение')
        }))
            .nullable()["default"]([])
            .optional()
            .when('boostersEnabled', {
            is: true,
            then: Yup.array().required('Необходимо задать значение')
        }),
        boosterPriceModificatorsEnabled: Yup.boolean().optional(),
        boosterPriceModificators: Yup.array()
            .of(Yup.object().shape({
            usersCount: Yup.number()
                .min(1, 'Значение от 1 до 1000')
                .max(1000, 'Значение от 1 до 1000')
                .required('Необходимо задать значение'),
            priceModificator: Yup.number()
                .min(0.1, 'Значение от 0.1 до 1000')
                .max(1000, 'Значение от 1 до 1000')
                .required('Необходимо задать значение')
        }))
            .optional()
    })
        .nullable()["default"]({})
        .optional()
});
export default virtualPointsValidationSchema;
