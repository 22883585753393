var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import history from 'store/history';
var SportEventOwnerListItem = function (_a) {
    var _b;
    var ownerType = _a.ownerType, sportClub = _a.sportClub, organization = _a.organization, handleClose = _a.handleClose;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "flex items-center w-full" }, { children: [_jsx(UserAvatar, { style: "xs:w-68 xs:h-68 xs:text-20 md:w-88 md:h-88 md:text-24", firstName: ownerType === 'SportClub' ? sportClub === null || sportClub === void 0 ? void 0 : sportClub.name : organization === null || organization === void 0 ? void 0 : organization.name, profilePhoto: ownerType === 'SportClub' ? (_b = sportClub === null || sportClub === void 0 ? void 0 : sportClub.mainSettings) === null || _b === void 0 ? void 0 : _b.profilePhoto : organization === null || organization === void 0 ? void 0 : organization.profilePhoto }, void 0), _jsxs("div", __assign({ className: "w-full h-full flex flex-col justify-start" }, { children: [_jsx("div", __assign({ onClick: function () {
                            history.push({
                                pathname: ownerType === 'SportClub'
                                    ? '/club/' + (sportClub === null || sportClub === void 0 ? void 0 : sportClub.url)
                                    : '/organization/' + (organization === null || organization === void 0 ? void 0 : organization.url)
                            });
                            handleClose();
                        }, className: "flex xs:text-14 md:text-16 font-semibold px-8 py-2 cursor-pointer dark:text-white" }, { children: ownerType === 'SportClub' ? sportClub === null || sportClub === void 0 ? void 0 : sportClub.name : organization === null || organization === void 0 ? void 0 : organization.name }), void 0), _jsx("div", __assign({ className: "text-grey px-8 py-2" }, { children: ownerType === 'SportClub' ? t('Клуб') : t('Организация') }), void 0)] }), void 0)] }), void 0));
};
export default SportEventOwnerListItem;
