var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultSettings } from 'store/actions';
import _ from 'utils/lodash';
function NavbarFoldedToggleButton(props) {
    var dispatch = useDispatch();
    var settings = useSelector(function (_a) {
        var base = _a.base;
        return base.settings.current;
    });
    return (_jsx(IconButton, __assign({ className: props.className, onClick: function () {
            dispatch(setDefaultSettings(_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded)));
        } }, { children: _jsx(ArrowForwardIosRoundedIcon, { className: "w-8 h-8" }, void 0) }), void 0));
}
export default NavbarFoldedToggleButton;
