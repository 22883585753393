var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { InputAdornment, TextField, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
var PasswordField = function (_a) {
    var id = _a.id, title = _a.title, value = _a.value, onChange = _a.onChange, isError = _a.isError, helperText = _a.helperText, name = _a.name;
    var _b = useState(false), showPassword = _b[0], setShowPassword = _b[1];
    var handleClickShowPassword = function () { return setShowPassword(!showPassword); };
    var handleMouseDownPassword = function () { return setShowPassword(!showPassword); };
    return (_jsx(TextField, { id: id, type: showPassword ? 'text' : 'password', label: title, name: name, value: value || '', onChange: onChange, error: isError, helperText: helperText, InputProps: {
            endAdornment: (_jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword }, { children: showPassword ? _jsx(Visibility, {}, void 0) : _jsx(VisibilityOff, {}, void 0) }), void 0) }), void 0))
        } }, void 0));
};
export default PasswordField;
