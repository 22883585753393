var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var CREATE_STANDINGS_COLUMNS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation createStandingsColumns($createStandingsColumnsData: CreateStandingsColumnsInput!) {\n\t\tcreateStandingsColumns(createStandingsColumnsData: $createStandingsColumnsData) {\n\t\t\tid\n\t\t\tcolumnId\n\t\t\tname\n      columnName\n\t\t}\n\t}\n"], ["\n\tmutation createStandingsColumns($createStandingsColumnsData: CreateStandingsColumnsInput!) {\n\t\tcreateStandingsColumns(createStandingsColumnsData: $createStandingsColumnsData) {\n\t\t\tid\n\t\t\tcolumnId\n\t\t\tname\n      columnName\n\t\t}\n\t}\n"])));
var templateObject_1;
// createStandingsColumnsData: {
//   standingsId: "67f8e0b0-d51f-4cb7-a0b2-2473e1699e1f"
//   standingsColumns: [
//     {
//       columnId: "0a573c78-2172-449e-af6b-624787c74227"
//       columnName: "Имя участника"
//       properties: [{ name: userDisplayNameType, value: "firstAndLastName"}]
//     },
//     {
//       columnId: "6f4f7486-ebd1-47c7-916b-17b15b33760a"
//       columnName: "Дистанция, км"
//       sport: Run
//       properties: [{ name: unit, value: "km" }]
//     }
//   ]
// }
