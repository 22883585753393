var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tab, Tabs } from '@mui/material';
import TabPanel from 'components/TabPanel/TabPanel';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NotificationDispatchInfo from './NotificationDispatchInfo/NotificationDispatchInfo';
import { NotificationInputs } from './NotificationInputs/NotificationInputs';
import NotificationSubTypes from './NotificationSubTypes/NotificationSubTypes';
import NotificationTemplates from './NotificationTemplates/NotificationTemplates';
import NotificationTemplateVariables from './NotificationTemplateVariables/NotificationTemplateVariables';
var NotificationManagementPageContent = function () {
    var history = useHistory();
    var _a = useLocation(), search = _a.search, pathname = _a.pathname;
    var searchParams = new URLSearchParams(search);
    var useQueryParams = function () {
        return useMemo(function () { return new URLSearchParams(search); }, [search]);
    };
    var query = useQueryParams();
    var tab = query.get('tab') || undefined;
    var _b = useState(tab ? parseInt(tab) : 0), tabValue = _b[0], setTabValue = _b[1];
    var handleChangeTab = function (_event, tabValue) {
        setTabValue(tabValue);
        searchParams.set('tab', tabValue.toString());
        history.push({
            pathname: pathname,
            search: searchParams.toString()
        });
    };
    return (_jsxs("div", __assign({ className: "w-full" }, { children: [_jsx("div", __assign({ className: "flex" }, { children: _jsx(Box, __assign({ sx: { width: '100%' } }, { children: _jsxs(Tabs, __assign({ sx: { height: '6.4rem' }, value: tabValue, onChange: handleChangeTab, variant: "scrollable" }, { children: [_jsx(Tab, { sx: { height: '6.4rem' }, label: "\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0438" }, void 0), _jsx(Tab, { sx: { height: '6.4rem' }, label: "\u0414\u0430\u043D\u043D\u044B\u0435 \u0434\u043B\u044F \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439" }, void 0), _jsx(Tab, { sx: { height: '6.4rem' }, label: "\u041F\u043E\u0434\u0442\u0438\u043F\u044B" }, void 0), _jsx(Tab, { sx: { height: '6.4rem' }, label: "\u0428\u0430\u0431\u043B\u043E\u043D\u044B" }, void 0), _jsx(Tab, { sx: { height: '6.4rem' }, label: "\u041F\u0435\u0440\u0435\u043C\u0435\u043D\u043D\u044B\u0435" }, void 0)] }), void 0) }), void 0) }), void 0), _jsxs(Box, __assign({ sx: { width: '100%' } }, { children: [_jsx(TabPanel, __assign({ value: tabValue, index: 0 }, { children: _jsx(NotificationDispatchInfo, {}, void 0) }), void 0), _jsx(TabPanel, __assign({ value: tabValue, index: 1 }, { children: _jsx(NotificationInputs, {}, void 0) }), void 0), _jsx(TabPanel, __assign({ value: tabValue, index: 2 }, { children: _jsx(NotificationSubTypes, {}, void 0) }), void 0), _jsx(TabPanel, __assign({ value: tabValue, index: 3 }, { children: _jsx(NotificationTemplates, {}, void 0) }), void 0), _jsx(TabPanel, __assign({ value: tabValue, index: 4 }, { children: _jsx(NotificationTemplateVariables, {}, void 0) }), void 0)] }), void 0)] }), void 0));
};
export default NotificationManagementPageContent;
