import history from 'store/history';
import ActivityDetailsDialog from './SpecificDialog/ActivityDetailsDialog/ActivityDetailsDialog';
import ActivityValidationChangeLevelDialog from './SpecificDialog/ActivityValidationChangeLevelDialog/ActivityValidationChangeLevelDialog';
import AddActivitiesDialog from './SpecificDialog/AddActivitiesDialog/AddActivitiesDialog';
import AddActivityAttachments from './SpecificDialog/AddActivityAttachments/AddActivityAttachments';
import AddClubParticipantRoleDialog from './SpecificDialog/AddClubParticipantRoleDialog/AddClubParticipantRoleDialog';
import AddStandingsColumnDialog from './SpecificDialog/AddStandingsColumnDialog/AddStandingsColumnDialog';
import AddTraningSourceDialog from './SpecificDialog/AddTraningSourceDialog/AddTraningSourceDialog';
import AddTrainingSourceSuccessInfoDialog from './SpecificDialog/AddTraningSourceSuccessInfoDialog/AddTraningSourceSuccessInfoDialog';
import ChangeUserLanguageDialog from './SpecificDialog/ChangeUserLanguageDialog/ChangeUserLanguageDialog';
import ConfirmActivityValidationDialog from './SpecificDialog/ConfirmActivityValidationDialog/ConfirmActivityValidationDialog';
import CreateClubDialog from './SpecificDialog/CreateClubDialog/CreateClubDialog';
import CreateSportClubSocialLinkDialog from './SpecificDialog/CreateSportClubSocialLinkDialog/CreateSportClubSocialLinkDialog';
import CreateSportEventDialog from './SpecificDialog/CreateSportEventDialog/CreateSportEventDialog';
import CreateSportEventTournamentDialog from './SpecificDialog/CreateSportEventTournamentDialog/CreateSportEventTournamentDialog';
import CreateStandingsSportDialog from './SpecificDialog/CreateStandingsSportDialog/CreateStandingsSportDialog';
import CreateSupportRequestDialog from './SpecificDialog/CreateSupportRequestDialog/CreateSupportRequestDialog';
import CreateTeamContactDialog from './SpecificDialog/CreateTeamContactDialog/CreateTeamContactDialog';
import CreateTournamentFeatureDialog from './SpecificDialog/CreateTournamentFeatureDialog/CreateTournamentFeatureDialog';
import CreateTournamentStandingsDialog from './SpecificDialog/CreateTournamentStandingsDialog/CreateTournamentStandingsDialog';
import CreateTournamentTeamDialogDialog from './SpecificDialog/CreateTournamentTeamDialog/CreateTournamentTeamDialog';
import DeleteActivityDialog from './SpecificDialog/DeleteActivityDialog/DeleteActivityDialog';
import { default as DeleteSportClubSocialLinkDialog } from './SpecificDialog/DeleteSportClubSocialLinkDialog/DeleteSportClubSocialLinkDialog';
import DeleteStandingsSportRequestDialog from './SpecificDialog/DeleteStandingsSportRequestDialog/DeleteStandingsSportRequestDialog';
import DeleteTeamContactDialog from './SpecificDialog/DeleteTeamContactDialog/DeleteTeamContactDialog';
import DeleteUserAddressRequestDialog from './SpecificDialog/DeleteUserAddressRequestDialog/DeleteUserAddressRequestDialog';
import EditStandingsColumnDialog from './SpecificDialog/EditStandingsColumnDialog/EditStandingsColumnDialog';
import ExpelUserRequestDialog from './SpecificDialog/ExpelUserRequestDialog/ExpelUserRequestDialog';
import FillUserProfileDialog from './SpecificDialog/FillUserProfileDialog/FillUserProfileDialog';
import ImageZoomDialog from './SpecificDialog/ImageZoomDialog/ImageZoomDialog';
import JoinClubApplyForMememberShipRequestDialog from './SpecificDialog/JoinClubApplyForMememberShipRequestDialog/JoinClubApplyForMememberShipRequestDialog';
import JoinClubByInvitationLinkRequestDialog from './SpecificDialog/JoinClubByInvitationLinkRequestDialog/JoinClubByInvitationLinkRequestDialog';
import JoinClubRequestDialog from './SpecificDialog/JoinClubRequestDialog/JoinClubRequestDialog';
import JoinTeamRequestDialog from './SpecificDialog/JoinTeamRequestDialog/JoinTeamRequestDialog';
import LeaveClubRequestDialog from './SpecificDialog/LeaveClubRequestDialog/LeaveClubRequestDialog';
import ProfilePhotoCrop from './SpecificDialog/ProfilePhotoCrop/ProfilePhotoCrop';
import RefreshInvitationLinkRequestDialog from './SpecificDialog/RefreshInvitationLinkRequestDialog/RefreshInvitationLinkRequestDialog';
import RequestActivityValidationDialog from './SpecificDialog/RequestActivityValidationDialog/RequestActivityValidationDialog';
import SportClubDetailsDialog from './SpecificDialog/SportClubDetailsDialog/SportClubDetailsDialog';
import SportEventDetailsDialog from './SpecificDialog/SportEventDetailsDialog/SportEventDetailsDialog';
import SportEventOwnersDetailsDialog from './SpecificDialog/SportEventOwnersDetailsDialog/SportEventOwnersDetailsDialog';
import SportEventUsersDialog from './SpecificDialog/SportEventUsersDialog/SportEventTeamUsersDialog';
import StandingsDetailsDialog from './SpecificDialog/StandingsDetailsDialog/StandingsDetailsDialog';
import TeamTournamentFeatureConfiguration from './SpecificDialog/TeamTournamentFeatureConfiguration/TeamTournamentFeatureConfiguration';
import TournamentFeatureVirtualPointsDialog from './SpecificDialog/TournamentFeatureVirtualPointsDialog/TournamentFeatureVirtualPointsDialog';
import TournamentNominationsDialog from './SpecificDialog/TournamentNominationsDialog/TournamentNominationsDialog';
import TournamentTeamsDialog from './SpecificDialog/TournamentTeamsDialog/TournamentTeamsDialog';
import TournamentTeamUsersDialog from './SpecificDialog/TournamentTeamUsersDialog/TournamentTeamUsersDialog';
import UpdateActivityDialog from './SpecificDialog/UpdateActivityDialog/UpdateActivityDialog';
import UpdateActivityDuplicatesDialog from './SpecificDialog/UpdateActivityDuplicatesDialog/UpdateActivityDuplicatesDialog';
import UserActivitiesSourceErrorDialog from './SpecificDialog/UserActivitiesSourceErrorDialog/UserActivitiesSourceErrorDialog';
import UserMessageDialog from './SpecificDialog/UserMessageDialog/UserMessageDialog';
import UserNotTeamMember from './SpecificDialog/UserNotTeamMember/UserNotTeamMember';
import UserProfileDetailsDialog from './SpecificDialog/UserProfileDetailsDialog/UserProfileDetailsDialog';
import UserTournamentsAndRewardsDialog from './SpecificDialog/UserTournamentsAndRewardsDialog/UserTournamentsAndRewardsDialog';
import VerifyUserEmailDialog from './SpecificDialog/VerifyUserEmailDialog/VerifyUserEmailDialog';
import VirtualPointsBuyBoosterRequestDialog from './SpecificDialog/VirtualPointsBuyBoosterRequestDialog/VirtualPointsBuyBoosterRequestDialog';
import VirtualPointsBuyBoostersDialog from './SpecificDialog/VirtualPointsBuyBoostersDialog/VirtualPointsBuyBoostersDialog';
import VirtualPointsBuyTeamCoinsDialog from './SpecificDialog/VirtualPointsBuyTeamCoinsDialog/VirtualPointsBuyTeamCoinsDialog';
import VKJoinAccountRequestDialog from './SpecificDialog/VKJoinAccountRequestDialog/VKJoinAccountRequestDialog';
import YandexJoinAccountRequestDialog from './SpecificDialog/YandexJoinAccountRequestDialog/YandexJoinAccountRequestDialog';
export var SpecificDialogsConfig = {
    ADD_TRAINING_SOURCE: {
        dialog: AddTraningSourceDialog,
        name: 'AddTraningSourceDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        },
        beforeClose: function () { return localStorage.setItem('isShownAddTraningSourceDialog', 'true'); }
    },
    ADD_TRAINING_SOURCE_SUCCESS_INFO: {
        dialog: AddTrainingSourceSuccessInfoDialog,
        name: 'AddTrainingSourceSuccessInfoDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    ADD_ACTIVITIES: {
        dialog: AddActivitiesDialog,
        name: 'AddActivitiesDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    ADD_ACTIVITY_ATTACHMENTS: {
        dialog: AddActivityAttachments,
        name: 'AddActivityAttachments',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    ACTIVITY_VALIDATION_CHANGE_LEVEL_DIALOG: {
        dialog: ActivityValidationChangeLevelDialog,
        name: 'ActivityValidationChangeLevelDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'xs:h-full sm:h-640 xs:max-w-full xs:w-full sm:w-auto min-w-320 md:max-h-840 overflow-auto'
        },
        beforeClose: function () { }
    },
    ADD_CLUB_PARTICIPANT_ROLE: {
        dialog: AddClubParticipantRoleDialog,
        name: 'AddClubParticipantRoleDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    ADD_STANDINGS_COLUMN: {
        dialog: AddStandingsColumnDialog,
        name: 'AddStandingsColumnDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    ACTIVITY_DETAILS: {
        dialog: ActivityDetailsDialog,
        name: 'ActivityDetailsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CREATE_SUPPORT_REQUEST: {
        dialog: CreateSupportRequestDialog,
        name: 'CreateSupportRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    CHANGE_USER_LANGUAGE_DIALOG: {
        dialog: ChangeUserLanguageDialog,
        name: 'ChangeUserLanguageDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        }
    },
    DELETE_USER_ADDRESS_REQUEST_DIALOG: {
        dialog: DeleteUserAddressRequestDialog,
        name: 'DeleteUserAddressRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    UPDATE_ACTIVITY: {
        dialog: UpdateActivityDialog,
        name: 'UpdateActivityDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    DELETE_ACTIVITY: {
        dialog: DeleteActivityDialog,
        name: 'DeleteActivityDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        },
        beforeClose: function () { }
    },
    UPDATE_ACTIVITY_DUPLICATES: {
        dialog: UpdateActivityDuplicatesDialog,
        name: 'UpdateActivityDuplicatesDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    REQUEST_ACTIVITY_VALIDATION: {
        dialog: RequestActivityValidationDialog,
        name: 'RequestActivityValidationDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CONFIRM_ACTIVITY_VALIDATION: {
        dialog: ConfirmActivityValidationDialog,
        name: 'ConfirmActivityValidationDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        },
        beforeClose: function () { }
    },
    CREATE_CLUB: {
        dialog: CreateClubDialog,
        name: 'CreateClubDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CREATE_SPORT_EVENT: {
        dialog: CreateSportEventDialog,
        name: 'CreateSportEventDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CREATE_SPORT_EVENT_TOURNAMENT: {
        dialog: CreateSportEventTournamentDialog,
        name: 'CreateSportEventTournamentDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CREATE_TOURNAMENT_STANDINGS: {
        dialog: CreateTournamentStandingsDialog,
        name: 'CreateTournamentStandingsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CREATE_TOURNAMENT_TEAM: {
        dialog: CreateTournamentTeamDialogDialog,
        name: 'CreateTournamentTeamDialogDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CREATE_TOURNAMENT_FEATURE: {
        dialog: CreateTournamentFeatureDialog,
        name: 'CreateTournamentFeatureDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CREATE_STANDINGS_SPORT: {
        dialog: CreateStandingsSportDialog,
        name: 'CreateStandingsSportDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    DELETE_STANDINGS_SPORT_REQUEST: {
        dialog: DeleteStandingsSportRequestDialog,
        name: 'DeleteStandingsSportRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    TOURNAMENT_FEATURE_VIRTUAL_POINTS: {
        dialog: TournamentFeatureVirtualPointsDialog,
        name: 'TournamentFeatureVirtualPointsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CREATE_SPORT_CLUB_SOCIAL_LINK: {
        dialog: CreateSportClubSocialLinkDialog,
        name: 'CreateSportClubSocialLinkDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    CREATE_TEAM_CONTACT: {
        dialog: CreateTeamContactDialog,
        name: 'CreateTeamContactDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    DELETE_SPORT_CLUB_SOCIAL_LINK: {
        dialog: DeleteSportClubSocialLinkDialog,
        name: 'DeleteSportClubSocialLinkDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    DELETE_TEAM_CONTACT: {
        dialog: DeleteTeamContactDialog,
        name: 'DeleteTeamContactDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    FILL_USER_PROFILE: {
        dialog: FillUserProfileDialog,
        name: 'FillUserProfileDialog',
        settings: {
            closeButton: false,
            disableBackdropClick: true,
            disableEscapeKeyDown: true
        }
    },
    VK_JOIN_ACCOUNT_REQUEST: {
        dialog: VKJoinAccountRequestDialog,
        name: 'VKJoinAccountRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { return history.push('/login'); }
    },
    CLUB_JOIN_REQUEST: {
        dialog: JoinClubRequestDialog,
        name: 'JoinClubRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        }
    },
    JOIN_CLUB_APPLY_FOR_MEMBERSHIP_REQUEST: {
        dialog: JoinClubApplyForMememberShipRequestDialog,
        name: 'JoinClubApplyForMememberShipRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        }
    },
    CLUB_LEAVE_REQUEST: {
        dialog: LeaveClubRequestDialog,
        name: 'LeaveClubRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        }
    },
    YANDEX_JOIN_ACCOUNT_REQUEST: {
        dialog: YandexJoinAccountRequestDialog,
        name: 'YandexJoinAccountRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { return history.push('/login'); }
    },
    IMAGE_ZOOM_DIALOG: {
        dialog: ImageZoomDialog,
        name: 'ImageZoomDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    REFRESH_INVITATION_LINK_REQUEST: {
        dialog: RefreshInvitationLinkRequestDialog,
        name: 'RefreshInvitationLinkRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    SPORT_CLUB_DETAILS_DIALOG: {
        dialog: SportClubDetailsDialog,
        name: 'SportClubDetailsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        }
    },
    SPORT_EVENT_DETAILS_DIALOG: {
        dialog: SportEventDetailsDialog,
        name: 'SportEventDetailsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    STANDINGS_DETAILS_DIALOG: {
        dialog: StandingsDetailsDialog,
        name: 'StandingsDetailsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        }
    },
    SPORT_EVENT_OWNERS_DETAILS_DIALOG: {
        dialog: SportEventOwnersDetailsDialog,
        name: 'SportEventOwnersDetailsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    SPORT_EVENT_USERS_DIALOG: {
        dialog: SportEventUsersDialog,
        name: 'SportEventUsersDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    TOURNAMENT_NOMINATIONS_DIALOG: {
        dialog: TournamentNominationsDialog,
        name: 'TournamentNominationsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    TOURNAMENT_TEAMS_DIALOG: {
        dialog: TournamentTeamsDialog,
        name: 'TournamentTeamsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    TOURNAMENT_TEAM_USERS_DIALOG: {
        dialog: TournamentTeamUsersDialog,
        name: 'TournamentTeamUsersDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    TEAM_TOURNAMENT_FEATURE_CONFIGURATION: {
        dialog: TeamTournamentFeatureConfiguration,
        name: 'TeamTournamentFeatureConfiguration',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    EXPEL_USER_REQUEST: {
        dialog: ExpelUserRequestDialog,
        name: 'ExpelUserRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    EDIT_STANDINGS_COLUMN: {
        dialog: EditStandingsColumnDialog,
        name: 'EditStandingsColumnDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        },
        beforeClose: function () { }
    },
    JOIN_CLUB_BY_INVITATION_LINK_REQUEST: {
        dialog: JoinClubByInvitationLinkRequestDialog,
        name: 'JoinClubByInvitationLinkRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    JOIN_TEAM_REQUEST: {
        dialog: JoinTeamRequestDialog,
        name: 'JoinTeamRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    VERIFY_USER_EMAIL: {
        dialog: VerifyUserEmailDialog,
        name: 'VerifyUserEmailDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false,
            size: 'h-auto w-auto min-w-320 max-h-640 overflow-auto'
        }
    },
    VIRTUAL_POINTS_BUY_TEAM_COINS: {
        dialog: VirtualPointsBuyTeamCoinsDialog,
        name: 'VirtualPointsBuyTeamCoinsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    VIRTUAL_POINTS_BUY_BOOSTER_REQUEST: {
        dialog: VirtualPointsBuyBoosterRequestDialog,
        name: 'VirtualPointsBuyBoosterRequestDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    VIRTUAL_POINTS_BUY_BOOSTERS: {
        dialog: VirtualPointsBuyBoostersDialog,
        name: 'VirtualPointsBuyBoostersDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    USER_PROFILE_DETAILS_DIALOG: {
        dialog: UserProfileDetailsDialog,
        name: 'UserProfileDetailsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    USER_ACTIVITIES_SOURCE_ERROR: {
        dialog: UserActivitiesSourceErrorDialog,
        name: 'UserActivitiesSourceErrorDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    USER_TOURNAMENTS_AND_REWARDS_DIALOG: {
        dialog: UserTournamentsAndRewardsDialog,
        name: 'UserTournamentsAndRewardsDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    USER_MESSAGE_DIALOG: {
        dialog: UserMessageDialog,
        name: 'UserMessageDialog',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    USER_NOT_TEAM_MEMBER_ALERT_DIALOG: {
        dialog: UserNotTeamMember,
        name: 'UserNotTeamMember',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    },
    PROFILE_PHOTO_CROP_DIALOG: {
        dialog: ProfilePhotoCrop,
        name: 'ProfilePhotoCrop',
        settings: {
            closeButton: true,
            disableBackdropClick: false,
            disableEscapeKeyDown: false
        }
    }
};
