var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { TOURNAMENT } from 'common/graphql/queries/tournament/tournament';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
import TournamentStandingsListItem from './TournamentStandingsListItem';
var TournamentStandingsSettingsPage = function (_a) {
    var _b;
    var tournamentId = _a.tournamentId;
    var _c = useQuery(TOURNAMENT, {
        variables: {
            id: tournamentId,
            showDatesSettings: true,
            showStandings: true
        }
    }), data = _c.data, loading = _c.loading;
    var standings = (_b = data === null || data === void 0 ? void 0 : data.tournament) === null || _b === void 0 ? void 0 : _b.standings;
    var dispatch = useDispatch();
    var openCreateTournamentStandingsDialog = function () {
        var _a, _b, _c, _d;
        dispatch(openDialog({
            dialogType: DialogTypes.CreateTournamentStandings,
            dialogProps: {
                tournamentId: tournamentId,
                tournamentStartDate: (_b = (_a = data === null || data === void 0 ? void 0 : data.tournament) === null || _a === void 0 ? void 0 : _a.datesSettings) === null || _b === void 0 ? void 0 : _b.startDate,
                tournamentEndDate: (_d = (_c = data === null || data === void 0 ? void 0 : data.tournament) === null || _c === void 0 ? void 0 : _c.datesSettings) === null || _d === void 0 ? void 0 : _d.endDate
            }
        }));
    };
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u044B\u043C\u0438 \u0442\u0430\u0431\u043B\u0438\u0446\u0430\u043C\u0438" }), void 0) }), void 0), content: _jsxs("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: [_jsx("div", __assign({ className: "max-w-288" }, { children: _jsx(Button, __assign({ type: "button", onClick: openCreateTournamentStandingsDialog }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u0443\u044E \u0442\u0430\u0431\u043B\u0438\u0446\u0443" }), void 0) }), void 0), standings && (standings === null || standings === void 0 ? void 0 : standings.length) > 0 && (_jsx("div", __assign({ className: "flex flex-col space-y-10" }, { children: standings.map(function (st) {
                        return _jsx(TournamentStandingsListItem, { standings: st }, st.id);
                    }) }), void 0))] }), void 0), innerScroll: false }, void 0));
};
export default TournamentStandingsSettingsPage;
