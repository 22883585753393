import { InMemoryCache } from '@apollo/client';
import { athletesSearchPageVar } from './reactivities/athletesSearchPageVar';
import { clubSettingsPageVar } from './reactivities/clubSettingsPageVar';
import { clubsPageVar } from './reactivities/clubsPageVar';
import { standingsPageVar } from './reactivities/standingsPageVar';
import { statsPageVar } from './reactivities/statsPageVar';
import { tournamentPageVar } from './reactivities/tournamentPageVar';
import { tournamentStandingsPageVar } from './reactivities/tournamentStandingsPageVar';
import { userActivitiesDataVar } from './reactivities/userActivities';
import { userProfileDataVar } from './reactivities/userProfileDataVar';
import { userProfilePageVar } from './reactivities/userProfilePageVar';
import { userSportClubDataVar } from './reactivities/userSportClubDataVar';
export var cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                notifications: {
                    keyArgs: false,
                    merge: function (existing, incoming, _a) {
                        var _b = _a.args.offset, offset = _b === void 0 ? 0 : _b;
                        var merged = existing ? existing.slice(0) : [];
                        for (var i = 0; i < incoming.length; ++i) {
                            merged[offset + i] = incoming[i];
                        }
                        return merged;
                    }
                },
                athletesSearchPage: {
                    read: function () {
                        return athletesSearchPageVar();
                    }
                },
                clubsPage: {
                    read: function () {
                        return clubsPageVar();
                    }
                },
                clubSettings: {
                    read: function () {
                        return clubSettingsPageVar();
                    }
                },
                statsPage: {
                    read: function () {
                        return statsPageVar();
                    }
                },
                standingsPage: {
                    read: function () {
                        return standingsPageVar();
                    }
                },
                tournamentPage: {
                    read: function () {
                        return tournamentPageVar();
                    }
                },
                tournamentStandingsPage: {
                    read: function () {
                        return tournamentStandingsPageVar();
                    }
                },
                userProfileData: {
                    read: function () {
                        return userProfileDataVar();
                    }
                },
                userProfilePage: {
                    read: function () {
                        return userProfilePageVar();
                    }
                },
                userSportClubData: {
                    read: function () {
                        return userSportClubDataVar();
                    }
                },
                displayPreferences: {
                    read: function () {
                        return userProfileDataVar();
                    }
                },
                userActivities: {
                    read: function () {
                        return userActivitiesDataVar();
                    }
                }
            }
        },
        User: {
            fields: {
                mainSettings: {
                    merge: true
                }
            }
        }
    }
});
