import { merge } from 'lodash';
import Button from './Button';
import TextField from './TextField';
import Link from './Link';
import Dialog from './Dialog';
import Autocomplete from './Autocomplete';
import List from './List';
export default function componentsOverrides(theme) {
    return merge({}, Autocomplete(theme), Button(theme), TextField(theme), Link(theme), List(theme), Dialog(theme));
}
