var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TournamentNominationsGroup from './components/TournamentNominationsGroup';
var TournamentNominationsDialog = function (_a) {
    var tournamentDisplayName = _a.tournamentDisplayName, tournamentNominations = _a.tournamentNominations, standings = _a.standings, handleClose = _a.handleClose;
    var t = useTranslation().t;
    var distinctTNs = _.uniqWith(_.orderBy(tournamentNominations, 'viewOrder', 'asc').map(function (o) {
        return {
            tournamentId: o.tournamentId,
            nominationId: o.nominationId
        };
    }), function (a, b) { return a.tournamentId === b.tournamentId && a.nominationId === b.nominationId; });
    return (tournamentNominations === null || tournamentNominations === void 0 ? void 0 : tournamentNominations.length) > 0 ? (_jsxs("div", __assign({ className: "flex flex-col space-y-5 p-0 pb-20" }, { children: [_jsxs("div", __assign({ className: "tw-dialog-header" }, { children: [t('Номинации турнира'), " ", tournamentDisplayName] }), void 0), _jsx("div", __assign({ className: "flex flex-col space-y-40" }, { children: distinctTNs.map(function (distinctTN) {
                    return (_jsx(TournamentNominationsGroup, { tournamentNominations: tournamentNominations.filter(function (tn) {
                            return tn.tournamentId === distinctTN.tournamentId &&
                                tn.nominationId === distinctTN.nominationId;
                        }), standings: standings, handleClose: handleClose }, "".concat(distinctTN.tournamentId, "_").concat(distinctTN.nominationId)));
                }) }), void 0)] }), void 0)) : null;
};
export default TournamentNominationsDialog;
