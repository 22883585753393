import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { SPORT_EVENT_PUBLIC_DATA } from 'common/graphql/queries/sportEvent/sportEventPublicData';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import history from 'store/history';
import { JoinSportEvent } from '../SportEventsPage/components/JoinSportEvent';
var JoinEventByLink = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var sportEventId = _a.sportEventId;
    var t = useTranslation().t;
    var _j = useLazyQuery(SPORT_EVENT_PUBLIC_DATA), getSportEventData = _j[0], _k = _j[1], data = _k.data, loading = _k.loading, error = _k.error;
    var isCanJoinEventPublic = (((_c = (_b = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _b === void 0 ? void 0 : _b.privacy) === null || _c === void 0 ? void 0 : _c.isOpenEvent) === false || ((_e = (_d = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _d === void 0 ? void 0 : _d.privacy) === null || _e === void 0 ? void 0 : _e.isPrivate) === true) &&
        !((_f = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _f === void 0 ? void 0 : _f.areYouParticipant)
        ? false
        : true;
    useEffect(function () {
        if (sportEventId) {
            getSportEventData({
                variables: {
                    id: sportEventId
                }
            });
        }
    }, [sportEventId]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (data === null || data === void 0 ? void 0 : data.sportEvent.areYouParticipant) {
            sportEventAlreadyJoined((_a = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _a === void 0 ? void 0 : _a.url);
        }
        if (!isCanJoinEventPublic && !(data === null || data === void 0 ? void 0 : data.sportEvent.areYouParticipant) && ((_b = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _b === void 0 ? void 0 : _b.url)) {
            cannotJoinAsNoPublic((_c = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _c === void 0 ? void 0 : _c.url);
        }
        if (isCanJoinEventPublic && ((_d = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _d === void 0 ? void 0 : _d.id) && !((_f = (_e = data === null || data === void 0 ? void 0 : data.sportEvent.tournaments) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.id)) {
            sportEventTournamentNotFound((_g = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _g === void 0 ? void 0 : _g.url);
        }
    }, [data]);
    useEffect(function () {
        if (error) {
            sportEventNotFound();
        }
    }, [error]);
    var dispatch = useDispatch();
    var sportEventNotFound = function () {
        history.push('/events/search');
        dispatch(openSnackbar({
            message: t('Событие не найдено'),
            severity: SnackbarSeverity.WARNING
        }));
    };
    var sportEventAlreadyJoined = function (url) {
        history.push("/event/".concat(url));
    };
    var cannotJoinAsNoPublic = function (url) {
        history.push("/event/".concat(url));
        dispatch(openSnackbar({
            message: 'Невозможно присоединиться к приватному событию по публичной ссылке',
            severity: SnackbarSeverity.ERROR
        }));
    };
    var sportEventTournamentNotFound = function (url) {
        history.push("/event/".concat(url));
        dispatch(openSnackbar({
            message: 'Турнир события не найден, обратитесь к администратору',
            severity: SnackbarSeverity.ERROR
        }));
    };
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return data && (data === null || data === void 0 ? void 0 : data.sportEvent) && ((_h = (_g = data === null || data === void 0 ? void 0 : data.sportEvent.tournaments) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.id) && !(data === null || data === void 0 ? void 0 : data.sportEvent.areYouParticipant) ? (_jsx(JoinSportEvent, { sportEvent: data.sportEvent }, void 0)) : null;
};
export default JoinEventByLink;
