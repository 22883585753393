var clubRulesGroupsConfig = [
    {
        id: 'club-rules-acceptenceCondition',
        value: 'club-rules-acceptenceCondition',
        title: 'Условия принятия',
        settingsGroupParams: [
            {
                id: 'clubRulesAcceptenceConditionParams',
                values: [
                    {
                        id: 'acceptenceCondition',
                        value: 'acceptenceCondition',
                        title: 'Условия принятия',
                        type: 'textEditor'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'club-rules-participationCondition',
        value: 'club-rules-participationCondition',
        title: 'Условия участия',
        settingsGroupParams: [
            {
                id: 'clubRulesParticipationConditionParams',
                values: [
                    {
                        id: 'participationCondition',
                        value: 'participationCondition',
                        title: 'Условия участия',
                        type: 'textEditor'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'club-rules-exclusionCondition',
        value: 'club-rules-exclusionCondition',
        title: 'Условия исключения',
        settingsGroupParams: [
            {
                id: 'clubRulesExclusionConditionParams',
                values: [
                    {
                        id: 'exclusionCondition',
                        value: 'exclusionCondition',
                        title: 'Условия исключения',
                        type: 'textEditor'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
];
export default clubRulesGroupsConfig;
