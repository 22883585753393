var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from '@apollo/client';
import { DialogActions, FormControl, InputLabel, MenuItem, OutlinedInput, Select, } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { Sports } from 'common/enums/sport/sports.enum';
import { CREATE_STANDINGS_SPORT } from 'common/graphql/mutations/standings/create-standings-sport';
import { SPORTS } from 'common/graphql/queries/sports/sports';
import { STANDINGS } from 'common/graphql/queries/standings/standings';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var CreateStandingsSportDialog = function (_a) {
    var _b;
    var standingsId = _a.standingsId, standingsSports = _a.standingsSports, handleClose = _a.handleClose;
    var _c = useQuery(SPORTS), sportsData = _c.data, loading = _c.loading;
    var _d = useState(''), sportId = _d[0], setSportId = _d[1];
    var standingsSportIds = (standingsSports === null || standingsSports === void 0 ? void 0 : standingsSports.map(function (sport) { return sport.id; })) || [];
    var filteredSports = ((_b = sportsData === null || sportsData === void 0 ? void 0 : sportsData.sports) === null || _b === void 0 ? void 0 : _b.filter(function (sport) { return !standingsSportIds.includes(sport.id) && sport.name !== Sports.Unknown; })) ||
        [];
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Вид спорта успешно добавлен!',
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var createStandingsSport = function (data) {
        return useMutation(CREATE_STANDINGS_SPORT, {
            variables: {
                createStandingsSportData: {
                    sportId: sportId,
                    standingsId: standingsId
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: STANDINGS,
                    variables: {
                        id: standingsId,
                        showSports: true
                    }
                },
            ]
        });
    };
    var handleExecuteMutation = useDynamicMutation(createStandingsSport, onSuccess, onError).handleExecuteMutation;
    var handleSportChange = function (event) {
        var value = event.target.value;
        setSportId(value);
    };
    var handleSubmit = function () {
        handleExecuteMutation({});
    };
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (filteredSports === null || filteredSports === void 0 ? void 0 : filteredSports.length) > 0 ? (_jsxs("div", { children: [_jsx("div", __assign({ className: "pt-20 pb-20" }, { children: _jsxs(FormControl, __assign({ className: "w-full" }, { children: [_jsx(InputLabel, { children: "\u0412\u0438\u0434 \u0441\u043F\u043E\u0440\u0442\u0430" }, void 0), _jsx(Select, __assign({ label: "\u0422\u0438\u043F \u0437\u0430\u0447\u0451\u0442\u0430", value: sportId, onChange: handleSportChange, input: _jsx(OutlinedInput, { className: "\n\t\t\t\t\t\t\tbg-bg-container-inner-lt \n\t\t\t\t\t\t\tdark:bg-bg-container-inner-dk  \n\t\t\t\t\t\t\tdark:text-white", label: "\u0412\u0438\u0434 \u0441\u043F\u043E\u0440\u0442\u0430" }, void 0) }, { children: filteredSports.map(function (sport) { return (_jsx(MenuItem, __assign({ value: sport.id }, { children: sport.displayName }), sport.id)); }) }), void 0)] }), void 0) }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm", onClick: function () {
                            handleSubmit();
                        } }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0)] }), void 0)] }, void 0)) : (_jsx("p", __assign({ className: "dark:text-white" }, { children: "\u0412\u0441\u0435 \u0432\u0438\u0434\u044B \u0441\u043F\u043E\u0440\u0442\u0430 \u0443\u0436\u0435 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u044B \u0432 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u0443\u044E \u0442\u0430\u0431\u043B\u0438\u0446\u0443" }), void 0));
};
export default CreateStandingsSportDialog;
