var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { IconButton, ListItem, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Actions from 'store/actions/base';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import Utils from '../../../utils/Utils';
import NavLinkAdapter from '../../NavLinkAdapter/NavLinkAdapter';
var BG_COLOR_HOVER_ACTIVE = '#404040';
var COLOR_LIST_ITEM = '#a6a6a6';
var COLOR_ACTIVE_LIST_ITEM = 'white';
var useStyles = makeStyles(function () { return ({
    item: {
        height: 40,
        width: 'calc(100% - 20px)',
        '& .list-item-icon': {
            borderRadius: '6px',
            padding: '10px 10px',
            left: 12,
            marginRight: '4px',
            color: COLOR_LIST_ITEM,
            transition: 'background-color 0.3s linear'
        },
        '& .pill-wrap': {
            width: '8px',
            height: 40,
            position: 'absolute',
            '& .pill-wrap-item': {
                borderRadius: '0 10px 10px 0',
                width: '4px',
                transition: 'height 0.1s linear'
            }
        },
        '&.active': {
            pointerEvents: 'none',
            transition: 'border-radius .17s cubic-bezier(0.4,0.0,0.2,1)'
        },
        '&.active , &:hover': {
            '& .list-item-icon': {
                color: COLOR_ACTIVE_LIST_ITEM,
                backgroundColor: BG_COLOR_HOVER_ACTIVE
            },
            '& .pill-wrap-item': {
                height: 20,
                background: BG_COLOR_HOVER_ACTIVE
            }
        }
    },
    navbarFolded: {
        opacity: 0,
        display: 'none'
    }
}); });
var NavVerticalItem = function (props) {
    var _a, _b;
    var item = props.item, active = props.active, navbarOpen = props.navbarOpen, nestedLevel = props.nestedLevel, addUserName = props.addUserName;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    //34
    var itemPadding = nestedLevel > 0 ? 18 + nestedLevel * 16 + 'px' : '12px';
    var classes = useStyles(props);
    var dispatch = useDispatch();
    var t = useTranslation().t;
    if (!Utils.hasPermission(item.auth, userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles)) {
        return null;
    }
    return (_jsxs(ListItem, __assign({ id: item.id, button: true, component: NavLinkAdapter, to: addUserName ? item.url + "/".concat(userProfile === null || userProfile === void 0 ? void 0 : userProfile.userName) : item.url, activeClassName: "active", className: clsx('nav-item pl-0', classes.item, active), onClick: function () { return dispatch(Actions.navbarCloseMobile()); }, exact: item.exact }, { children: [nestedLevel === 0 && (_jsx("div", __assign({ className: clsx('pill-wrap flex items-center') }, { children: _jsx("div", { className: clsx('pill-wrap-item flex') }, void 0) }), void 0)), _jsx("div", __assign({ className: clsx('flex grow ', !navbarOpen && nestedLevel > 0 && classes.navbarFolded) }, { children: _jsx(Tooltip, __assign({ title: (!navbarOpen && t(item.title)) || '', placement: "right-end" }, { children: _jsxs(IconButton, __assign({ className: clsx('list-item-icon flex justify-start grow ', 
                        //	!navbarOpen && !!!active && 'border-1 border-solid border-mainGrey',
                        navbarOpen && ' max-w-256 w-256', (_b = (_a = item.icon) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.className) }, { children: [item.icon, _jsx(Typography, __assign({ className: clsx('list-item-title truncate ...', !navbarOpen && classes.navbarFolded), sx: {
                                    paddingLeft: itemPadding,
                                    fontWeight: 500,
                                    transition: 'opacity 500ms, max-height 300ms'
                                } }, { children: t(item.title) }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0));
};
export default React.memo(NavVerticalItem);
