var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { TextField } from '@mui/material';
import { appHost, appScheme } from 'common/appURLSetup';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { OrderTypes } from 'common/enums/order/order-types.enum';
import { CREATE_ORDER } from 'common/graphql/mutations/order/createOrder';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import orderValidationSchema from 'common/validationSchemas/orderValidationSchema';
import Button from 'components/Buttons/Button';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var VirtualPointsBuyTeamCoinsDialog = function (_a) {
    var teamDisplayName = _a.teamDisplayName, teamName = _a.teamName, teamId = _a.teamId, tournamentId = _a.tournamentId, coinPrice = _a.coinPrice;
    var defaultFormState = {
        type: OrderTypes.VirtualPointsBuyCoins,
        amount: 10
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var handleRedirect = function (redirectLink) {
        window.location.href = redirectLink;
    };
    var dispatch = useDispatch();
    var createOrder = function (data) {
        return useMutation(CREATE_ORDER, {
            variables: {
                createOrderInput: __assign({}, data)
            }
        });
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: "\u041F\u0435\u0440\u0435\u0430\u0434\u0440\u0435\u0441\u0430\u0446\u0438\u044F \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u043E\u043F\u043B\u0430\u0442\u044B...",
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var convertInput = function (formInput) {
        return {
            amount: parseInt((formInput.amount * 100).toFixed()),
            type: formInput.type,
            dataForPostProcessing: {
                teamId: teamId,
                tournamentId: tournamentId
            },
            successUrl: "".concat(appScheme, "://").concat(appHost, "/order/success?returnTo=/team/").concat(teamName),
            failUrl: "".concat(appScheme, "://").concat(appHost, "/order/fail?returnTo=/team/").concat(teamName)
        };
    };
    var _b = useDynamicMutation(createOrder, onSuccess, onError), handleExecuteMutation = _b.handleExecuteMutation, loading = _b.loading, data = _b.data;
    var handleSubmit = function (data) {
        handleExecuteMutation(convertInput(data));
    };
    useEffect(function () {
        var _a, _b;
        if ((_a = data === null || data === void 0 ? void 0 : data.createOrder) === null || _a === void 0 ? void 0 : _a.paymentUrl) {
            handleRedirect((_b = data === null || data === void 0 ? void 0 : data.createOrder) === null || _b === void 0 ? void 0 : _b.paymentUrl);
        }
    }, [data]);
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: orderValidationSchema.pick(Object.keys(defaultFormState)),
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsxs("div", __assign({ className: "sm:text-16 xs:text-14 dark:text-white" }, { children: ["\u041A\u0443\u043F\u0438\u0442\u044C \u043C\u043E\u043D\u0435\u0442 \u0434\u043B\u044F \u043A\u043E\u043C\u0430\u043D\u0434\u044B ", teamDisplayName] }), void 0), _jsxs("form", __assign({ className: "flex flex-row items-center space-x-10", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx(TextField, { className: "", label: "\u0421\u0443\u043C\u043C\u0430 \u0432 \u0440\u0443\u0431\u043B\u044F\u0445", name: "amount", value: formik.values['amount'], onChange: handleChangeWrapper, error: Boolean(formik.errors['amount']), helperText: formik.errors['amount'] }, void 0), _jsx(Button, __assign({ type: "primary sm", isSubmitButton: true }, { children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C" }), void 0)] }), void 0), _jsxs("p", __assign({ className: "dark:text-white" }, { children: ["\u0411\u0443\u0434\u0435\u0442 \u043D\u0430\u0447\u0438\u0441\u043B\u0435\u043D\u043E ", coinPrice * (formik.values['amount'] || 0), " \u043C\u043E\u043D\u0435\u0442"] }), void 0)] }), void 0));
};
export default VirtualPointsBuyTeamCoinsDialog;
