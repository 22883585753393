export var TeamPaidParticipationTypes;
(function (TeamPaidParticipationTypes) {
    /**
     * Фиксированная стоимость участия в команде
     */
    TeamPaidParticipationTypes["fixedPrice"] = "fixedPrice";
    /**
     * Выставляется минимальная и максимальная стоимость участия - можно выбрать любую между
     */
    TeamPaidParticipationTypes["minMaxPrice"] = "minMaxPrice";
    /**
     * Список возможных стоимостей участия
     */
    TeamPaidParticipationTypes["descretePrices"] = "descretePrices";
})(TeamPaidParticipationTypes || (TeamPaidParticipationTypes = {}));
