var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@apollo/client';
import { CREATE_TOURNAMENT_PARTICIPANT } from 'common/graphql/mutations/tournament/createTournamentParticipant';
export function useJoinTournamentMutation(variables) {
    return useMutation(CREATE_TOURNAMENT_PARTICIPANT, {
        variables: {
            createTournamentParticipantData: __assign({}, variables)
        }
    });
}
