var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery } from '@apollo/client';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { USERS_SEARCH } from 'common/graphql/queries/users/users-search';
import { useEffect, useState } from 'react';
import Utils from 'utils/Utils';
var icon = _jsx(CheckBoxOutlineBlankIcon, { fontSize: "small" }, void 0);
var checkedIcon = _jsx(CheckBoxIcon, { fontSize: "small" }, void 0);
export var UserAutocomplete = function (_a) {
    var setSelectedOptions = _a.setSelectedOptions;
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    var _c = useState(''), searchInput = _c[0], setSearchInput = _c[1];
    var _d = useLazyQuery(USERS_SEARCH), getUsers = _d[0], _e = _d[1], data = _e.data, loading = _e.loading, error = _e.error;
    useEffect(function () {
        if (searchInput) {
            var delayDebounceFn_1 = setTimeout(function () {
                getUsers({
                    variables: {
                        searchQuery: Utils.prepareSearchInputValue(searchInput) || '',
                        limit: 100,
                        offset: 0
                    }
                });
            }, 500);
            return function () { return clearTimeout(delayDebounceFn_1); };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);
    useEffect(function () {
        if (data) {
            setOptions(data.usersSearch);
        }
    }, [data]);
    return (_jsx(Autocomplete, { multiple: true, disableCloseOnSelect: true, options: options, getOptionLabel: function (option) {
            return typeof option === 'object' ? "".concat(option.lastName, " ").concat(option.firstName, " (").concat(option.userName, ")") : option;
        }, renderInput: function (params) { return (_jsx(TextField, __assign({ name: "user", label: 'Начните вводить имя пользователя' }, params), void 0)); }, renderOption: function (props, option, _a) {
            var selected = _a.selected;
            var optionProps = __rest(props, []);
            return (_jsxs("li", __assign({}, optionProps, { children: [_jsx(Checkbox, { icon: icon, checkedIcon: checkedIcon, style: { marginRight: 8 }, checked: selected }, void 0), typeof option === 'object'
                        ? "".concat(option.lastName, " ").concat(option.firstName, " (").concat(option.userName, ")")
                        : option] }), option.userId));
        }, freeSolo: true, onChange: function (event, value) {
            if (typeof value === 'object')
                setSelectedOptions(value);
        }, onInputChange: function (_event, evalue) {
            setSearchInput(evalue);
        }, loading: loading }, void 0));
};
