import { useApolloClient } from '@apollo/client';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { refreshToken } from 'store/actions/auth';
import history from 'store/history';
import jwtService from './jwtService';

const Auth = (props) => {
	const { children } = props;
	const dispatch = useDispatch();
	const [userData, setUserData] = useState({
		isChecked: false,
	});
	const client = useApolloClient();

	useEffect(() => {
		jwtService.on('onLogin', () => {
			setUserData({
				isChecked: true,
			});
		});

		jwtService.on('onAutoLogin', (isLogin) => {
			dispatch(refreshToken()).then(() => {
				setUserData({
					isChecked: true,
				});
			});
		});

		jwtService.on('onLogout', () => {
			client.cache.reset();
			history.push('/');
		});

		jwtService.init();
	}, []);

	return <>{userData.isChecked && <>{children}</>}</>;
};
export default Auth;
