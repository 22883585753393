var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SearchIcon from '@mui/icons-material/Search';
import { Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
var ParticipantNameSearchField = function (_a) {
    var participantNameInput = _a.participantNameInput, setParticipantNameSearchFieldInput = _a.setParticipantNameSearchFieldInput;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "flex flex-row items-center tw-user-name-search-field bg-bg-container-inner-lt dark:bg-bg-container-inner-dk border-0 shadow xs:w-full md:w-auto" }, { children: [_jsx(SearchIcon, { className: "h-20 w-20" }, void 0), _jsx(Input, { className: "pl-8 h-30", placeholder: t('Поиск'), disableUnderline: true, fullWidth: true, value: participantNameInput, inputProps: {
                    'aria-label': 'Search'
                }, onChange: function (ev) { return setParticipantNameSearchFieldInput(ev); } }, void 0)] }), void 0));
};
export default ParticipantNameSearchField;
