var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from '@apollo/client';
import { UPDATE_SPORT_EVENT } from 'common/graphql/mutations/sportEvent/updateSportEvent';
import { SPORT_EVENT } from 'common/graphql/queries/sportEvent/sportEvent';
export function useUpdateSportEventMutation(sportEventVariables) {
    var profilePhoto;
    var mainSettingsFields = ['displayName', 'description', 'additionalInfo', 'landingPageUrl'];
    var visibilitySettings = ['visible'];
    var sportEventData = {
        mainSettings: {}
    };
    var sportEventId = sportEventVariables === null || sportEventVariables === void 0 ? void 0 : sportEventVariables.id;
    var _loop_1 = function (key) {
        var _a, _b, _c;
        if (key !== 'profilePhoto' && key !== 'removeProfilePhoto') {
            var isMainSettings = mainSettingsFields.filter(function (s) { return s === key; });
            var isVisibilitySettings = visibilitySettings.filter(function (s) { return s === key; });
            // ключи
            if (isMainSettings.length > 0) {
                sportEventData = __assign(__assign({}, sportEventData), { mainSettings: __assign(__assign({}, sportEventData.mainSettings), (_a = {}, _a[key] = sportEventVariables[key], _a)) });
            }
            else if (isVisibilitySettings.length > 0) {
                sportEventData = __assign(__assign({}, sportEventData), { mainSettings: __assign(__assign({}, sportEventData.mainSettings), { rules: __assign(__assign({}, sportEventData.mainSettings.rules), (_b = {}, _b[key] = sportEventVariables[key], _b)) }) });
            }
            else {
                sportEventData = __assign(__assign({}, sportEventData), (_c = {}, _c[key] = sportEventVariables[key], _c));
            }
        }
    };
    for (var key in sportEventVariables) {
        _loop_1(key);
    }
    if (sportEventVariables === null || sportEventVariables === void 0 ? void 0 : sportEventVariables.profilePhoto) {
        profilePhoto = sportEventVariables.profilePhoto;
    }
    if (sportEventVariables === null || sportEventVariables === void 0 ? void 0 : sportEventVariables.removeProfilePhoto) {
        profilePhoto = null;
    }
    return useMutation(UPDATE_SPORT_EVENT, {
        variables: __assign({ updateSportEventData: __assign({}, sportEventData) }, (typeof profilePhoto !== undefined && { profilePhoto: profilePhoto })),
        refetchQueries: [
            {
                query: SPORT_EVENT,
                variables: {
                    id: sportEventId,
                    showBasicFields: true,
                    showMainSettings: true,
                    showDatesSettings: true,
                    showSports: true,
                    showVisibilitySettings: true
                }
            },
        ]
    });
}
