import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery, useReactiveVar } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { SPORT_CLUB } from 'common/graphql/queries/sportClub/sportClub';
import useGetUserSportClubData from 'common/hooks/useGetUserSportClubData';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import PageCarded from 'components/PageLayouts/carded/PageCarded';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
import { userSportClubDataVar } from 'utils/apollo/reactivities/userSportClubDataVar';
import SidebarContent from '../../layouts/shared-components/SidebarContent/SidebarContent';
import clubSettingsNavigationConfig from './clubSettingsNavigationConfig';
var ClubSettingsPage = function (props) {
    var dispatch = useDispatch();
    var clubId = useParams().clubId;
    var userSportClub = useGetUserSportClubData().userSportClub;
    var _a = useReactiveVar(userSportClubDataVar), userOwnedSportClubId = _a.userOwnedSportClubId, userAdministeredClubId = _a.userAdministeredClubId;
    var _b = useState(undefined), isUserCanAdministrateClub = _b[0], setIsUserCanAdministrateClub = _b[1];
    var _c = useQuery(SPORT_CLUB, {
        variables: {
            id: clubId
        }
    }), data = _c.data, loading = _c.loading;
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.sportClub.id) {
            if (userOwnedSportClubId === data.sportClub.id || userAdministeredClubId === data.sportClub.id) {
                setIsUserCanAdministrateClub(true);
            }
            else {
                setIsUserCanAdministrateClub(false);
            }
        }
    }, [data, userOwnedSportClubId, userAdministeredClubId]);
    useEffect(function () {
        if (isUserCanAdministrateClub === false) {
            dispatch(openSnackbar({
                message: 'У вас нет прав на управление этим клубом',
                severity: SnackbarSeverity.ERROR
            }));
        }
    }, [isUserCanAdministrateClub]);
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(PageCarded, { classes: {
            root: 'w-full',
            header: 'hidden',
            topBg: 'hidden',
            content: 'flex flex-col',
            sidebar: 'xs:px-8 md:px-12 xl:px-24'
        }, breadcrumbs: _jsx(Breadcrumbs, {}, void 0), content: isUserCanAdministrateClub && _jsx(_Fragment, { children: props.children }, void 0), rightSidebarContent: isUserCanAdministrateClub && (_jsx(SidebarContent, { navigationConfigItems: clubSettingsNavigationConfig, mainPathname: 'club-settings/' + clubId }, void 0)), mobileSidebarContent: true }, void 0));
};
export default ClubSettingsPage;
