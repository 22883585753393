var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { displayPreferencesVar, ThemeMode } from 'utils/apollo/reactivities/displayPreferencesVar';
var CheckboxSettingsParam = function (props) {
    var id = props.id, fieldName = props.fieldName, isChecked = props.isChecked;
    var _a = useState(isChecked), checked = _a[0], setChecked = _a[1];
    var themeMode = useReactiveVar(displayPreferencesVar).themeMode;
    useEffect(function () {
        setChecked(themeMode === ThemeMode.dark);
    }, [themeMode]);
    var handleChangeData = function () {
        displayPreferencesVar(__assign(__assign({}, displayPreferencesVar()), { themeMode: themeMode === ThemeMode.dark ? ThemeMode.light : ThemeMode.dark }));
    };
    return (_jsx(FormControl, __assign({ component: "fieldset" }, { children: _jsx(FormGroup, __assign({ "aria-label": "position", row: true }, { children: _jsx(Checkbox, { sx: { padding: 0 }, checked: !!checked, onChange: function (e) { return handleChangeData(); }, name: "" }, void 0) }), void 0) }), void 0));
};
export default CheckboxSettingsParam;
