var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import axios from 'axios';
import { requestParamsFactory } from 'utils/requestParamsFactory';
var ResponseError = /** @class */ (function (_super) {
    __extends(ResponseError, _super);
    function ResponseError(error) {
        var _this = _super.call(this, error.errorCode) || this;
        _this.errorCode = error.errorCode;
        return _this;
    }
    return ResponseError;
}(Error));
function request(url, params) {
    return axios(url, params)
        .then(function (response) {
        var _a;
        if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.errorCode) {
            throw new ResponseError(response.data);
        }
        return response.data;
    })["catch"](function (error) {
        var message = error.message, config = error.config;
        var errorMessage = "An error occured. ".concat(message, " \n URL: ").concat(config === null || config === void 0 ? void 0 : config.url);
        error.message = errorMessage;
        throw error;
    });
}
var apiService = {
    GET: function (url, params) { return request(url, params || requestParamsFactory.GET()); },
    POST: function (url, payload, params) {
        return request(url, params || requestParamsFactory.POST(payload));
    },
    PUT: function (url, payload, params) { return request(url, params || requestParamsFactory.PUT(payload)); },
    DELETE: function (url, payload, params) {
        return request(url, params || requestParamsFactory.DELETE(payload));
    }
};
export default apiService;
