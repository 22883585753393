import { jsx as _jsx } from "react/jsx-runtime";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import clsx from 'clsx';
import Image from 'mui-image';
var ImageZoomDialog = function (_a) {
    var imageUrl = _a.imageUrl;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return _jsx(Image, { className: clsx(isMobile && 'max-w-328 max-h-328 object-contain'), src: imageUrl }, void 0);
};
export default ImageZoomDialog;
