var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DialogActions } from '@mui/material';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import history from 'store/history';
var UserActivitiesSourceErrorDialog = function (_a) {
    var sourceNames = _a.sourceNames, errorMessages = _a.errorMessages, handleClose = _a.handleClose;
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    return sourceNames.length > 0 ? (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320 space-y-5" }, { children: [_jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Ошибка при подключении источника тренировок') }), void 0) }), void 0), _jsxs("div", __assign({ className: "text-16 text-left dark:text-white" }, { children: [i18n.language === 'en'
                        ? "An error has occurred while connecting to activities sources. Please follow instructions from error message and try to fix problem on your own. If you need help, please "
                        : "\u041F\u0440\u0438 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u0438 ".concat((sourceNames === null || sourceNames === void 0 ? void 0 : sourceNames.length) > 1 ? 'источников' : 'источника', " \u0442\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043E\u043A\n\t\t\t\t").concat(sourceNames.join(', '), " \u043F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430. \u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0438\u0441\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043E\u0448\u0438\u0431\u043A\u0443 \u0441\u0430\u043C\u043E\u0441\u0442\u043E\u044F\u0442\u0435\u043B\u044C\u043D\u043E, \u0441\u043B\u0435\u0434\u0443\u044F \u043F\u043E\u0434\u0441\u043A\u0430\u0437\u043A\u0435\n\t\t\t\t\u0438\u0437 \u0442\u0435\u043A\u0441\u0442\u0430 \u043E\u0448\u0438\u0431\u043A\u0438. \u0415\u0441\u043B\u0438 \u043F\u043E\u043D\u0430\u0434\u043E\u0431\u0438\u0442\u0441\u044F \u043F\u043E\u043C\u043E\u0449\u044C, "), _jsxs("a", __assign({ className: "tw-link-btn", onClick: function () {
                            history.push("/support");
                            handleClose();
                        } }, { children: [t('обратитесь в поддержку'), "."] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-5 pt-10" }, { children: [_jsx("div", __assign({ className: "text-gray" }, { children: (errorMessages === null || errorMessages === void 0 ? void 0 : errorMessages.length) > 1 ? t('Ошибки') + ":" : t('Ошибка') + ":" }), void 0), _jsx("div", __assign({ className: "pt-5 dark:text-white" }, { children: errorMessages.join('. ') }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-5 pt-10 pb-10" }, { children: [_jsx("div", __assign({ className: "tw-link-btn", onClick: function () {
                            history.push("/settings/connections");
                            handleClose();
                        } }, { children: t('Перейти в настройки') }), void 0), _jsx("div", __assign({ className: "tw-link-btn", onClick: function () {
                            history.push("/support");
                            handleClose();
                        } }, { children: t('Создать обращение в поддержку') }), void 0)] }), void 0), _jsx(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: _jsx(Button, __assign({ onClick: function () {
                        handleClose();
                    }, type: "primary sm" }, { children: t('Игнорировать') }), void 0) }), void 0)] }), void 0)) : null;
};
export default UserActivitiesSourceErrorDialog;
