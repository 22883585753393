var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery } from '@apollo/client';
import { Autocomplete, Box, TextField } from '@mui/material';
import { COLUMNS } from 'common/graphql/queries/standings/columns';
import { useEffect, useState } from 'react';
var StandingsColumnSelect = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'columnType' : _b, value = _a.value, defaultValue = _a.defaultValue, errorMessage = _a.errorMessage, disableClearable = _a.disableClearable, handleChange = _a.handleChange, standingsParticipantType = _a.standingsParticipantType, standingsParentType = _a.standingsParentType;
    var _c = useState([]), allColumnsList = _c[0], setAllColumnsList = _c[1];
    var _d = useState(null), fieldValue = _d[0], setFieldValue = _d[1];
    var _e = useLazyQuery(COLUMNS), getStandingsColumnsList = _e[0], _f = _e[1], loading = _f.loading, data = _f.data;
    useEffect(function () {
        if (value && allColumnsList.length > 0) {
            var val = allColumnsList.find(function (column) { return column.name === value.name; });
            val && setFieldValue(val);
            val && value.columnOrder && handleChange(name, val);
        }
        else if (defaultValue && allColumnsList.length > 0) {
            handleChange(name, defaultValue);
        }
    }, [value, defaultValue, allColumnsList]);
    useEffect(function () {
        var _a;
        if ((data === null || data === void 0 ? void 0 : data.columns) && (data === null || data === void 0 ? void 0 : data.columns.length) > 0) {
            var filteredColumnsList = (_a = data === null || data === void 0 ? void 0 : data.columns) === null || _a === void 0 ? void 0 : _a.filter(function (column) {
                return column.participantTypes.find(function (participantType) { return participantType === standingsParticipantType; });
            }).filter(function (column) {
                if (column.parentTypes.length > 0) {
                    return column.parentTypes.find(function (parentType) { return parentType === standingsParentType; });
                }
                else {
                    return true;
                }
            });
            setAllColumnsList(filteredColumnsList);
        }
    }, [data]);
    useEffect(function () {
        if (allColumnsList.length === 0) {
            getStandingsColumnsList();
        }
    }, [allColumnsList]);
    return (_jsx(Autocomplete, { options: allColumnsList || [], value: fieldValue || null, disableClearable: disableClearable, renderInput: function (params) { return (_jsx(TextField, __assign({ name: name, error: !!errorMessage, helperText: errorMessage, label: 'Тип столбца' }, params), void 0)); }, onChange: function (_event, column) {
            if ((column === null || column === void 0 ? void 0 : column.id) || !disableClearable) {
                handleChange(name, column);
            }
        }, isOptionEqualToValue: function (option, value) { return option.id === value.id; }, getOptionLabel: function (option) { return option.displayName || option.columnName || ''; }, renderOption: function (props, option) { return (_jsx(Box, __assign({ component: "li", sx: { '& > img': { mr: 2, flexShrink: 0 } } }, props, { children: option.displayName }), void 0)); } }, void 0));
};
export default StandingsColumnSelect;
