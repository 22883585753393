var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DialogActions, Typography } from '@mui/material';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next';
var ConfirmActivityValidationDialog = function (_a) {
    var handleConfirm = _a.handleConfirm, handleClose = _a.handleClose;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: "\u0410\u043A\u0442\u0438\u0432\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0432\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u044E" }), void 0), _jsx(Typography, __assign({ className: "text-16 text-center pb-20 dark:text-white" }, { children: "\u041F\u043E\u0441\u043B\u0435 \u0430\u043A\u0442\u0438\u0432\u0430\u0446\u0438\u0438 \u0432\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u0438 \u0431\u0443\u0434\u0435\u0442 \u043D\u0435\u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u0432\u043D\u0435\u0441\u0442\u0438 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F. \u0417\u0430\u0447\u0435\u0442 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F \u0432 \u0441\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u044E\u0449\u0438\u0445 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u044B\u0445 \u0442\u0430\u0431\u043B\u0438\u0446\u0430\u0445 \u0431\u0443\u0434\u0435\u0442 \u0438\u0437\u043C\u0435\u043D\u0435\u043D \u0441\u043E\u0433\u043B\u0430\u0441\u043D\u043E \u0442\u0435\u043A\u0443\u0449\u0438\u043C \u0434\u0430\u043D\u043D\u044B\u043C \u0432\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u0438." }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ onClick: function () {
                            handleConfirm();
                            handleClose();
                        }, type: "primary sm" }, { children: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C" }), void 0)] }), void 0)] }), void 0));
};
export default ConfirmActivityValidationDialog;
