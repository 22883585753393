var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { DELETE_STANDINGS_COLUMNS } from 'common/graphql/mutations/standings/delete-standings-columns';
import { UPDATE_STANDINGS_COLUMNS } from 'common/graphql/mutations/standings/update-standings-columns';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import _ from 'lodash';
import DraggableTournamentStangingsColumnsList from 'main/pages/Administration/TournamentPage/components/DraggableTournamentStangingsColumnsList/DraggableTournamentStangingsColumnsList';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
var StandingsColumnsList = function (_a) {
    var standingsId = _a.standingsId, standingsColumns = _a.standingsColumns, setStandingsColumns = _a.setStandingsColumns, standingsParticipantType = _a.standingsParticipantType, standingsParentType = _a.standingsParentType, standingsSports = _a.standingsSports;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var updateStandingsColumns = function (data) {
        return useMutation(UPDATE_STANDINGS_COLUMNS, {
            variables: {
                updateStandingsColumnsData: {
                    standingsId: standingsId,
                    standingsColumns: data
                }
            },
            awaitRefetchQueries: true
        });
    };
    var deleteStandingsColumns = function (data) {
        return useMutation(DELETE_STANDINGS_COLUMNS, {
            variables: {
                ids: [data],
                standingsId: standingsId
            },
            awaitRefetchQueries: true
        });
    };
    var onSuccess = function (message) {
        dispatch(openSnackbar({
            message: message,
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(updateStandingsColumns, function () { return onSuccess(t('Данные сохранены')); }, onError).handleExecuteMutation;
    var handleExecuteDeleteColumnMutation = useDynamicMutation(deleteStandingsColumns, function () { return onSuccess('Столбец успешно удален'); }).handleExecuteMutation;
    var handleChangeOrder = function (standingsColumns, columnOrder, destination) {
        var modifiedStandingsColumns = standingsColumns &&
            standingsColumns.length > 0 &&
            standingsColumns
                .map(function (column) {
                if (column.columnOrder === columnOrder)
                    return __assign(__assign({}, column), { columnOrder: destination });
                if (column.columnOrder === destination) {
                    return __assign(__assign({}, column), { columnOrder: columnOrder > destination ? column.columnOrder + 1 : column.columnOrder - 1 });
                }
                if ((column.columnOrder &&
                    columnOrder > destination &&
                    column.columnOrder < columnOrder &&
                    column.columnOrder > destination) ||
                    (column.columnOrder &&
                        columnOrder < destination &&
                        column.columnOrder > columnOrder &&
                        column.columnOrder < destination)) {
                    return __assign(__assign({}, column), { columnOrder: columnOrder > destination ? column.columnOrder + 1 : column.columnOrder - 1 });
                }
                return column;
            })
                .sort(function (a, b) { return a.columnOrder - b.columnOrder; });
        return modifiedStandingsColumns || [];
    };
    var onChangeOrder = function (columnOrder, destination) {
        if (standingsColumns) {
            var modifiedStandingsColumns = handleChangeOrder(standingsColumns, columnOrder, destination);
            var convertedForSaveModifiedStandingsColumns = modifiedStandingsColumns.length > 0 &&
                modifiedStandingsColumns.map(function (column) {
                    return { id: column.id, columnOrder: column.columnOrder };
                });
            setStandingsColumns(handleChangeOrder(standingsColumns, columnOrder, destination));
            handleExecuteMutation(convertedForSaveModifiedStandingsColumns);
        }
    };
    var onIsCollapsedChange = function (columnId, isCollapsed) {
        handleExecuteMutation([
            {
                id: columnId,
                isCollapsed: isCollapsed
            },
        ]);
        var standingsColumn = _.find(standingsColumns, { id: columnId });
        var index = _.findIndex(standingsColumns, { id: columnId });
        // Replace item at index using native splice
        if (standingsColumn)
            standingsColumns.splice(index, 1, __assign(__assign({}, standingsColumn), { isCollapsed: isCollapsed }));
        setStandingsColumns(standingsColumns);
    };
    var deleteStandingsColumn = function (deletedStandingsColumn) {
        var modifiedStandingsColumns = standingsColumns && standingsColumns.length > 0
            ? standingsColumns
                .filter(function (column) {
                return column.columnOrder !== deletedStandingsColumn.columnOrder;
            })
                .map(function (column) {
                var orderNumber = column.columnOrder;
                if (orderNumber &&
                    deletedStandingsColumn.columnOrder &&
                    orderNumber > deletedStandingsColumn.columnOrder) {
                    var clonedObject = __assign({}, column);
                    clonedObject = __assign(__assign({}, clonedObject), { columnOrder: orderNumber - 1 });
                    return clonedObject;
                }
                return column;
            })
                .sort(function (a, b) { return a.columnOrder - b.columnOrder; })
            : [];
        setStandingsColumns(modifiedStandingsColumns);
        handleExecuteDeleteColumnMutation(deletedStandingsColumn.id);
    };
    var onEditStandingsColumnButtonClick = function (column) {
        if (column) {
            dispatch(openDialog({
                dialogType: DialogTypes.EditStandingsColumn,
                dialogProps: {
                    standingsParticipantType: standingsParticipantType,
                    standingsParentType: standingsParentType,
                    standingsSports: standingsSports,
                    standingsId: standingsId,
                    column: column
                }
            }));
        }
    };
    return (_jsx("div", { children: standingsColumns.length > 0 && (_jsx(DraggableTournamentStangingsColumnsList, { fields: standingsColumns.sort(function (a, b) { return a.columnOrder - b.columnOrder; }), onIsCollapsedChange: onIsCollapsedChange, onChangeOrder: onChangeOrder, deleteField: deleteStandingsColumn, editField: onEditStandingsColumnButtonClick }, void 0)) }, void 0));
};
export default StandingsColumnsList;
