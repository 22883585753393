import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import UserNotificationPage from './UserNotificationsPage';
export var userNotificationPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/notification/:notificationId',
            component: function (props) {
                var notificationId = props.match.params.notificationId;
                return _jsx(UserNotificationPage, { notificationId: notificationId }, void 0);
            }
        },
    ]
};
