var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Sports } from 'common/enums/sport/sports.enum';
import { useTranslation } from 'react-i18next';
var SportSelect = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'sport' : _b, value = _a.value, _c = _a.label, label = _c === void 0 ? 'Вид спорта' : _c, handleChange = _a.handleChange;
    var t = useTranslation().t;
    return (_jsxs(FormControl, __assign({ className: "w-full" }, { children: [_jsx(InputLabel, { children: t(label) }, void 0), _jsxs(Select, __assign({ required: true, value: value, onChange: function (_event, value) {
                    if (value) {
                        handleChange(name, value.props.value);
                    }
                }, label: t(label) }, { children: [_jsx(MenuItem, __assign({ value: Sports.Run }, { children: t('Бег') }), void 0), _jsx(MenuItem, __assign({ value: Sports.Ride }, { children: t('Велосипед') }), void 0), _jsx(MenuItem, __assign({ value: Sports.Swimming }, { children: t('Плавание') }), void 0), _jsx(MenuItem, __assign({ value: Sports.Walking }, { children: t('Ходьба') }), void 0), _jsx(MenuItem, __assign({ value: Sports.Skiing }, { children: t('Лыжи/Сноуборд') }), void 0)] }), void 0)] }), void 0));
};
export default SportSelect;
