import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import { Redirect } from 'react-router-dom';
import TournamentDatesSettingsPage from './tournamentDatesSettings/TournamentDatesSettingsPage';
import TournamentFeaturesSettingsPage from './tournamentFeaturesSettings/TournamentFeaturesSettingsPage';
import TournamentParticipantionSettingsPage from './tournamentParticipationSettings/TournamentParticipationSettingsPage';
import TournamentProfile from './tournamentProfile/TournamentProfile';
import TournamentSettingsPage from './TournamentSettingsPage';
import TournamentSportsSettingsPage from './tournamentSportsSettings/TournamentSportsSettingsPage';
import TournamentStandingsSettingsPage from './tournamentStandingsSettings/TournamentStandingsSettingsPage';
export var tournamentSettingsPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: ['/tournament-settings/:tournamentId/tournament-feature-settings'],
            component: function (props) {
                var tournamentId = props.match.params.tournamentId;
                return (_jsx(TournamentSettingsPage, { children: _jsx(TournamentFeaturesSettingsPage, { tournamentId: tournamentId }, void 0) }, void 0));
            }
        },
        {
            path: ['/tournament-settings/:tournamentId/tournament-standings-settings'],
            component: function (props) {
                var tournamentId = props.match.params.tournamentId;
                return (_jsx(TournamentSettingsPage, { children: _jsx(TournamentStandingsSettingsPage, { tournamentId: tournamentId }, void 0) }, void 0));
            }
        },
        {
            path: ['/tournament-settings/:tournamentId/tournament-participation-settings'],
            component: function (props) {
                var tournamentId = props.match.params.tournamentId;
                return (_jsx(TournamentSettingsPage, { children: _jsx(TournamentParticipantionSettingsPage, { tournamentId: tournamentId }, void 0) }, void 0));
            }
        },
        {
            path: ['/tournament-settings/:tournamentId/tournament-sports-settings'],
            component: function (props) {
                var tournamentId = props.match.params.tournamentId;
                return (_jsx(TournamentSettingsPage, { children: _jsx(TournamentSportsSettingsPage, { tournamentId: tournamentId }, void 0) }, void 0));
            }
        },
        {
            path: ['/tournament-settings/:tournamentId/tournament-dates-settings'],
            component: function (props) {
                var tournamentId = props.match.params.tournamentId;
                return (_jsx(TournamentSettingsPage, { children: _jsx(TournamentDatesSettingsPage, { tournamentId: tournamentId }, void 0) }, void 0));
            }
        },
        {
            path: ['/tournament-settings/:tournamentId/tournament-profile'],
            component: function (props) {
                var tournamentId = props.match.params.tournamentId;
                return (_jsx(TournamentSettingsPage, { children: _jsx(TournamentProfile, { tournamentId: tournamentId }, void 0) }, void 0));
            }
        },
        {
            path: '/tournament-settings/:tournamentId',
            component: function (props) {
                var tournamentId = props.match.params.tournamentId;
                return _jsx(Redirect, { to: "/tournament-settings/".concat(tournamentId, "/tournament-profile") }, void 0);
            }
        },
        {
            path: '/tournament-settings',
            component: function (props) {
                var tournamentId = props.match.params.tournamentId;
                return _jsx(Redirect, { to: "/tournament-settings/".concat(tournamentId, "/tournament-profile") }, void 0);
            }
        },
    ]
};
