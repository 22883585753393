import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { ORGANIZATION } from 'common/graphql/queries/organization/organization';
import { useUpdateOrganizationMutation } from 'common/hooks/organization/useUpdateOrganizationMutation';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import SettingsForm from 'main/pages/SettingsPage/components/SettingsForm/SettingsForm';
import SettingsGroup from 'main/pages/SettingsPage/components/SettingsGroup/SettingsGroup';
import organizationProfileGroupsConfig from './organizationProfileGroupsConfig';
var OrganizationProfile = function (_a) {
    var organizationId = _a.organizationId;
    var _b = useQuery(ORGANIZATION, {
        variables: {
            id: organizationId
        }
    }), data = _b.data, loading = _b.loading;
    if (loading)
        return _jsx(CircularProgressMui, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: "\u041F\u0440\u043E\u0444\u0430\u0439\u043B \u043E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u0438" }, void 0), content: organizationProfileGroupsConfig.length > 0 &&
            organizationProfileGroupsConfig.map(function (settingsGroup) { return (_jsxs("div", { children: [_jsx(SettingsGroup, { settingsGroup: settingsGroup, data: data === null || data === void 0 ? void 0 : data.organization, handleChangeData: useUpdateOrganizationMutation }, void 0), _jsx(Divider, { light: true }, void 0)] }, settingsGroup.id)); }) }, void 0));
};
export default OrganizationProfile;
