import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import { Redirect } from 'react-router-dom';
import OrganizationProfile from './OrganizationProfile/OrganizationProfile';
import OrganizationSettingsPage from './OrganizationSettingsPage';
export var organizationSettingsPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: ['/organization-settings/:organizationId/organization-profile'],
            component: function (props) {
                var organizationId = props.match.params.organizationId;
                return (_jsx(OrganizationSettingsPage, { children: _jsx(OrganizationProfile, { organizationId: organizationId }, void 0) }, void 0));
            }
        },
        {
            path: '/organization-settings/:organizationId',
            component: function (props) {
                var organizationId = props.match.params.organizationId;
                return _jsx(Redirect, { to: "/organization-settings/".concat(organizationId, "/organization-profile") }, void 0);
            }
        },
        {
            path: '/organization-settings',
            component: function (props) {
                var organizationId = props.match.params.organizationId;
                return _jsx(Redirect, { to: "/organization-settings/".concat(organizationId, "/organization-profile") }, void 0);
            }
        },
    ]
};
