var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation } from '@apollo/client';
import DownloadIcon from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Tooltip } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { DELETE_NOTIFICATION_TEMPLATE } from 'common/graphql/mutations/notification/delete-notification-template';
import { GET_FILE_URL } from 'common/graphql/queries/file/get-file-url';
import { NOTIFICATION_TEMPLATES } from 'common/graphql/queries/notification/notification-templates';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
var NotificationTemplateListItem = function (_a) {
    var _b, _c, _d, _e, _f;
    var notificationTemplate = _a.notificationTemplate;
    var dispatch = useDispatch();
    var _g = useLazyQuery(GET_FILE_URL), getFileUrl = _g[0], data = _g[1].data;
    useEffect(function () {
        var _a;
        if ((_a = notificationTemplate.templateFile) === null || _a === void 0 ? void 0 : _a.id) {
            getFileUrl({
                variables: {
                    id: notificationTemplate.templateFile.id
                }
            });
        }
    }, [notificationTemplate]);
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Шаблон удалён',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var errorDetails = _a.errorDetails, code = _a.code;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var deleteNotificationTemplate = function (data) {
        return useMutation(DELETE_NOTIFICATION_TEMPLATE, {
            variables: {
                id: data
            },
            refetchQueries: [
                {
                    query: NOTIFICATION_TEMPLATES
                },
            ]
        });
    };
    var handleExecuteMutation = useDynamicMutation(deleteNotificationTemplate, onSuccess, onError).handleExecuteMutation;
    return (_jsx("div", __assign({ className: "flex border-1 solid rounded-8 xs:p-8 md:p-10 \n                xs:mb-8 md:mb-10 decoration-0 border-border-lt dark:border-border-dk" }, { children: _jsxs("div", __assign({ className: "flex flex-col space-y-20 w-full" }, { children: [_jsxs("div", __assign({ className: "flex  flex-row items-center justify-between pr-10 w-full" }, { children: [_jsx("div", __assign({ className: "xs:text-14 md:text-16 font-semibold px-8 py-2 cursor-pointer underline" }, { children: _jsx(Link, __assign({ to: "/administration/notification-template/".concat(notificationTemplate.id), className: "text-black dark:text-white" }, { children: notificationTemplate.name }), void 0) }), void 0), data && (_jsx(Button, __assign({ endIcon: _jsx(DownloadIcon, {}, void 0), href: "".concat(data.getFileUrl) }, { children: "\u0421\u043A\u0430\u0447\u0430\u0442\u044C" }), void 0)), (notificationTemplate.subTypes === undefined || ((_b = notificationTemplate.subTypes) === null || _b === void 0 ? void 0 : _b.length) === 0) &&
                            (notificationTemplate.mainSubTypes === undefined ||
                                ((_c = notificationTemplate.mainSubTypes) === null || _c === void 0 ? void 0 : _c.length) === 0) && (_jsx("div", __assign({ className: "flex flex-row items-center" }, { children: _jsx(Button, __assign({ type: "button", color: "error", onClick: function () {
                                    handleExecuteMutation(notificationTemplate.id);
                                } }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }), void 0) }), void 0))] }), void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [notificationTemplate.createdAt && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Дата создания:', "\u00A0"] }), void 0), _jsx("div", { children: new Date(notificationTemplate.createdAt).toLocaleString() }, void 0)] }), void 0)), notificationTemplate.updatedAt && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Дата обновления:', "\u00A0"] }), void 0), _jsx("div", { children: new Date(notificationTemplate.updatedAt).toLocaleString() }, void 0)] }), void 0))] }), void 0), _jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row xs:space-x-0 xs:space-y-20 sm:space-y-0 sm:space-x-20" }, { children: [_jsxs("div", __assign({ className: "flex flex-col px-8" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Описание:', "\u00A0"] }), void 0), _jsx("div", { children: notificationTemplate.description }, void 0)] }), void 0), ((_d = notificationTemplate.mainSubTypes) === null || _d === void 0 ? void 0 : _d.length) > 0 && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Используется по умолчанию для подтипов:', "\u00A0"] }), void 0), _jsx("div", { children: notificationTemplate.mainSubTypes.map(function (subType) {
                                                return _jsx("p", { children: subType.name }, void 0);
                                            }) }, void 0)] }), void 0)), ((_e = notificationTemplate.subTypes) === null || _e === void 0 ? void 0 : _e.length) > 0 && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Может использоваться для подтипов:', "\u00A0"] }), void 0), _jsx("div", { children: notificationTemplate.subTypes.map(function (subType) {
                                                return _jsx("p", { children: subType.name }, void 0);
                                            }) }, void 0)] }), void 0))] }), void 0), _jsx("div", __assign({ className: "flex flex-col px-8" }, { children: ((_f = notificationTemplate.requiredTemplateVariables) === null || _f === void 0 ? void 0 : _f.length) > 0 && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-grey" }, { children: ['Переменные:', "\u00A0"] }), void 0), _jsx("div", { children: notificationTemplate.requiredTemplateVariables.map(function (variable) {
                                            return (_jsx(Tooltip, __assign({ title: variable.description }, { children: _jsxs("div", __assign({ className: "flex flex-row space-x-5" }, { children: [_jsx("p", { children: variable.name }, void 0), variable.resolvedBySystem && (_jsx("p", __assign({ className: "text-grey text-12" }, { children: "(\u0437\u0430\u043F\u043E\u043B\u044F\u043D\u0435\u0442\u0441\u044F \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0438)" }), void 0)), _jsx(InfoOutlinedIcon, { className: "h-20 w-20 show-full-info-button mr-8", color: "primary" }, void 0)] }), void 0) }), void 0));
                                        }) }, void 0)] }), void 0)) }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default NotificationTemplateListItem;
