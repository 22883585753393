var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { DialogActions } from '@mui/material';
import { FeatureTypeIds } from 'common/const/feature-ids.const';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { VirtualPointsBoosterTypesReadableRu, } from 'common/enums/feature/virtual-points-booster-types.enum';
import { TournamentFeatureActionTarget } from 'common/enums/tournament/tournament-feature-action-target.enum';
import { TournamentFeatureActionType } from 'common/enums/tournament/tournament-feature-action-type.enum';
import { TOURNAMEN_FEATURE_ACTION } from 'common/graphql/mutations/tournament/perform-tournament-feature-action';
import { TEAM } from 'common/graphql/queries/team/team';
import { TOURNAMEN_FEATURE_RO_ACTION } from 'common/graphql/queries/tournament/perform-tournament-feature-read-only-action';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var VirtualPointsBuyBoosterRequestDialog = function (_a) {
    var tournamentId = _a.tournamentId, teamId = _a.teamId, boosterName = _a.boosterName, price = _a.price, teamCoins = _a.teamCoins, handleClose = _a.handleClose;
    var isEnoughCoins = teamCoins >= price;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t("\u0411\u0443\u0441\u0442\u0435\u0440 \u043A\u0443\u043F\u043B\u0435\u043D!"),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var buyBooster = function (data) {
        return useMutation(TOURNAMEN_FEATURE_ACTION, {
            variables: {
                performTournamentFeatureActionData: {
                    actionTarget: TournamentFeatureActionTarget.Team,
                    actionTargetId: teamId,
                    actionType: TournamentFeatureActionType.VirtualPointsBuyTeamBooster,
                    tournamentId: tournamentId,
                    featureId: FeatureTypeIds.virtualPoints,
                    actionData: {
                        boosters: [
                            {
                                boosterName: boosterName,
                                amount: 1
                            },
                        ]
                    }
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TOURNAMEN_FEATURE_RO_ACTION,
                    variables: {
                        performTournamentFeatureActionData: {
                            actionTarget: TournamentFeatureActionTarget.Team,
                            actionTargetId: teamId,
                            actionType: TournamentFeatureActionType.VirtualPointsGetSummary,
                            tournamentId: tournamentId,
                            featureId: FeatureTypeIds.virtualPoints
                        }
                    }
                },
                {
                    query: TEAM,
                    variables: {
                        id: teamId,
                        showTournamentFeatureData: true
                    }
                },
            ]
        });
    };
    var handleExecuteMutation = useDynamicMutation(buyBooster, onSuccess, onError).handleExecuteMutation;
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Купить бустер') }), void 0), _jsxs("div", __assign({ className: "text-16 text-center pb-20 dark:text-white" }, { children: [t('Вы действительно хотите купить бустер'), " (t", VirtualPointsBoosterTypesReadableRu[boosterName], "),", ' ', t('потратив'), " ", price, " ", t('командных монет'), " ?"] }), void 0), !isEnoughCoins && (_jsxs("p", __assign({ className: "text-red text-center" }, { children: [t('У вас недостаточно монет для покупки бустера'), ". ", t('Не хватает ёще'), " ", price - teamCoins, ' ', t('монет'), "."] }), void 0)), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ onClick: function () {
                            handleExecuteMutation({});
                        }, type: "primary sm", disabled: !isEnoughCoins }, { children: t('Купить') }), void 0)] }), void 0)] }), void 0));
};
export default VirtualPointsBuyBoosterRequestDialog;
