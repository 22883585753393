var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
var VirtualPointsBuyTeamCoinsButton = function (_a) {
    var team = _a.team, coinPrice = _a.coinPrice;
    var dispatch = useDispatch();
    var handleClick = function () {
        var _a, _b;
        dispatch(openDialog({
            dialogType: DialogTypes.VirtualPointsBuyTeamCoins,
            dialogProps: {
                teamId: team.id,
                teamName: team.name,
                teamDisplayName: team === null || team === void 0 ? void 0 : team.displayName,
                tournamentId: (_b = (_a = team === null || team === void 0 ? void 0 : team.tournaments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id,
                coinPrice: coinPrice
            }
        }));
    };
    return (_jsx(Button, __assign({ onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            handleClick();
        }, type: "button" }, { children: "\u041A\u0443\u043F\u0438\u0442\u044C" }), void 0));
};
export default VirtualPointsBuyTeamCoinsButton;
