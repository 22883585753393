var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
var NotificationNotRead = function () {
    var t = useTranslation().t;
    var _a = useState(false), openValidationStatusTooltip = _a[0], setOpenValidationStatusTooltip = _a[1];
    return (_jsx(Tooltip, __assign({ onMouseOver: function () { return setOpenValidationStatusTooltip(true); }, onMouseLeave: function () { return setOpenValidationStatusTooltip(false); }, onClose: function () { return setOpenValidationStatusTooltip(false); }, open: openValidationStatusTooltip, title: t('Уведомление не прочитано') || '', leaveTouchDelay: 10000, leaveDelay: 700 }, { children: _jsx(CircleIcon, { style: { fontSize: '14' }, sx: { color: yellow[600] } }, void 0) }), void 0));
};
export default NotificationNotRead;
