var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { DialogActions, Typography } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { DELETE_STANDINGS_SPORT } from 'common/graphql/mutations/standings/delete-standings-sport';
import { STANDINGS } from 'common/graphql/queries/standings/standings';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var DeleteStandingsSportRequestDialog = function (_a) {
    var standingsId = _a.standingsId, sportId = _a.sportId, sportDisplayName = _a.sportDisplayName, handleClose = _a.handleClose;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Вид спорта удалён.',
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var deleteStandingsSport = function (data) {
        return useMutation(DELETE_STANDINGS_SPORT, {
            variables: {
                deleteStandingsSportData: {
                    sportId: sportId,
                    standingsId: standingsId
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: STANDINGS,
                    variables: {
                        id: standingsId,
                        showSports: true
                    }
                },
            ]
        });
    };
    var handleExecuteMutation = useDynamicMutation(deleteStandingsSport, onSuccess, onError).handleExecuteMutation;
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320" }, { children: [_jsxs(Typography, __assign({ className: "text-16 text-center pb-20 dark:text-white" }, { children: ["\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B, \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u0432\u0438\u0434 \u0441\u043F\u043E\u0440\u0442\u0430 ", sportDisplayName, " \u0438\u0437 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u043E\u0439 \u0442\u0430\u0431\u043B\u0438\u0446\u044B? \u0412\u0441\u0435 \u0442\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043A\u0438 \u044D\u0442\u043E\u0433\u043E \u0432\u0438\u0434\u0430 \u0441\u043F\u043E\u0440\u0442\u0430 \u0431\u0443\u0434\u0443\u0442 \u0443\u0434\u0430\u043B\u0435\u043D\u044B \u0438\u0437 \u0437\u0430\u0447\u0451\u0442\u0430."] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ onClick: function () {
                            handleExecuteMutation({});
                        }, type: "primary sm" }, { children: "\u0414\u0430, \u0443\u0434\u0430\u043B\u0438\u0442\u044C" }), void 0)] }), void 0)] }), void 0));
};
export default DeleteStandingsSportRequestDialog;
