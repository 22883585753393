var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
var Breadcrumbs = function (_a) {
    var link = _a.link, name = _a.name, _b = _a.goBack, goBack = _b === void 0 ? true : _b, onClick = _a.onClick;
    var history = useHistory();
    var t = useTranslation().t;
    return (_jsx(Link, __assign({ to: link || '', className: "block w-max cursor-pointer", onClick: function (e) {
            if (onClick) {
                e.preventDefault();
                onClick();
            }
            else if (goBack) {
                e.preventDefault();
                history.goBack();
            }
        } }, { children: _jsxs("div", __assign({ className: "flex items-center text-grey hover:underline" }, { children: [_jsx(ArrowBackIcon, { className: "\n                xs:h-12 xs:w-12 \n                md:h-12 md:w-12\n                lg:h-20 lg:w-20 mr-4\n     " }, void 0), name || t('Назад')] }), void 0) }), void 0));
};
export default Breadcrumbs;
