var clubSettingsNavigationConfig = [
    {
        id: 'club-profile',
        title: 'Профайл клуба',
        handle: 'club-profile'
    },
    {
        id: 'club-rules',
        title: 'Правила клуба',
        handle: 'club-rules'
    },
    {
        id: 'club-contacts',
        title: 'Контакты клуба',
        handle: 'club-contacts'
    },
    {
        id: 'club-participants',
        title: 'Управление участниками',
        handle: 'club-participants'
    },
];
export default clubSettingsNavigationConfig;
