var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
var Button = function (_a) {
    var onClick = _a.onClick, children = _a.children, type = _a.type, className = _a.className, isSubmitButton = _a.isSubmitButton, disabled = _a.disabled;
    return (_jsx("button", __assign({ className: clsx(className, "tw-btn ".concat(type)), type: isSubmitButton ? 'submit' : 'button', onClick: onClick, disabled: disabled }, { children: children }), void 0));
};
export default Button;
