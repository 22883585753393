var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation } from '@apollo/client';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { CREATE_USER_ADDRESS } from 'common/graphql/mutations/user/address/create-user-address';
import { USER_ADDRESS_SEARCH } from 'common/graphql/queries/user/address/user-address-search';
import { USER_ADDRESSES } from 'common/graphql/queries/user/address/user-addresses';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var UserAddressAddNew = function () {
    var t = useTranslation().t;
    var _a = useState(false), error = _a[0], setError = _a[1];
    var _b = useState(null), helperText = _b[0], setHelperText = _b[1];
    var _c = useState(''), value = _c[0], setValue = _c[1];
    var _d = useState([]), options = _d[0], setOptions = _d[1];
    var _e = useState([]), optionObjects = _e[0], setOptionObjects = _e[1];
    var _f = useState(''), addressSearchInput = _f[0], setAddressSearchInput = _f[1];
    var _g = useState(''), query = _g[0], setQuery = _g[1];
    var _h = useState(false), isAddNew = _h[0], setIsAddNew = _h[1];
    var dispatch = useDispatch();
    var _j = useLazyQuery(USER_ADDRESS_SEARCH), getUserAddressSearchResults = _j[0], _k = _j[1], data = _k.data, loading = _k.loading;
    var createUserAddress = function (data) {
        return useMutation(CREATE_USER_ADDRESS, {
            variables: {
                createUserAddressData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER_ADDRESSES
                },
            ]
        });
    };
    useEffect(function () {
        error && setError(false);
        helperText && setHelperText(null);
        var delayDebounceFn = setTimeout(function () {
            setQuery(addressSearchInput);
        }, 300);
        return function () { return clearTimeout(delayDebounceFn); };
    }, [addressSearchInput]);
    useEffect(function () {
        if (query)
            getUserAddressSearchResults({
                fetchPolicy: 'network-only',
                variables: {
                    query: query
                }
            });
    }, [query]);
    useEffect(function () {
        var _a, _b;
        if ((_a = data === null || data === void 0 ? void 0 : data.userAddressSearch) === null || _a === void 0 ? void 0 : _a.length) {
            setOptions((_b = data === null || data === void 0 ? void 0 : data.userAddressSearch) === null || _b === void 0 ? void 0 : _b.map(function (o) { return o.value; }));
            setOptionObjects(data === null || data === void 0 ? void 0 : data.userAddressSearch);
        }
    }, [data]);
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t("\u0410\u0434\u0440\u0435\u0441 \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D"),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(createUserAddress, onSuccess, onError).handleExecuteMutation;
    var handleSave = function () {
        var _a;
        var optionObject = (_a = optionObjects.filter(function (o) { return o.value === value; })) === null || _a === void 0 ? void 0 : _a[0];
        if (!optionObject.data.city) {
            setError(true);
            setHelperText(t('Не задан город'));
        }
        if (!optionObject.data.house) {
            setError(true);
            setHelperText(t('Не задан дом'));
        }
        if (optionObject.data.house && optionObject.data.city) {
            handleExecuteMutation({
                value: value
            });
            setIsAddNew(false);
            setValue('');
        }
    };
    return (_jsx(Box, __assign({ sx: { width: '100%' } }, { children: isAddNew ? (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx(Autocomplete, { value: value, filterOptions: function (x) { return x; }, options: options, disableClearable: false, renderInput: function (params) { return (_jsx(TextField, __assign({ name: "address", error: error, helperText: helperText, label: t('Введите адрес') }, params), void 0)); }, freeSolo: true, onInputChange: function (_event, evalue) {
                        setAddressSearchInput(evalue);
                    }, onChange: function (event, newValue) {
                        setValue(newValue);
                    } }, void 0), _jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(Button, __assign({ type: "reset", color: "secondary", onClick: function () { return setIsAddNew(false); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit", onClick: function () { return handleSave(); } }, { children: t('Сохранить') }), void 0)] }), void 0)] }), void 0)) : (_jsx(Button, __assign({ type: "submit", onClick: function () { return setIsAddNew(true); } }, { children: t('Добавить') }), void 0)) }), void 0));
};
export default UserAddressAddNew;
