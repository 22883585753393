var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import NavigationItems from '../../../components/NavigationItems/NavigationItems';
import NavbarFoldedToggleButton from './NavbarFoldedToggleButton';
import NavigationLogo from './NavigationLogo';
function Navigation(props) {
    var navigation = useSelector(function (_a) {
        var base = _a.base;
        return base.navigation.navigation;
    });
    var bottomNavigation = useSelector(function (_a) {
        var base = _a.base;
        return base.navigation.bottomNavigation;
    });
    var settings = useSelector(function (_a) {
        var base = _a.base;
        return base.settings.current;
    });
    var isNavbarFolded = settings.layout.config.navbar.folded;
    return (_jsxs("div", __assign({ className: clsx('group navigation h-full flex flex-col') }, { children: [_jsxs("div", { children: [_jsx(NavigationLogo, { navbarOpen: props.navbarOpen }, void 0), _jsx(NavbarFoldedToggleButton, { className: clsx('tw-navbar-folded-toggle-button', !isNavbarFolded && 'unfolded') }, void 0)] }, void 0), _jsx("div", __assign({ className: 'h-full w-full flex md:pt-8' }, { children: _jsx(NavigationItems, { className: clsx('navigation', props.className), navigation: navigation, bottomNavigation: bottomNavigation, layout: props.layout, dense: props.dense, navbarOpen: props.navbarOpen }, void 0) }), void 0)] }), void 0));
}
Navigation.defaultProps = {
    layout: 'vertical'
};
export default Navigation;
