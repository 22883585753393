var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { USER } from 'common/graphql/queries/user/user';
import { useUpdateUserMutation } from 'common/hooks/useUpdateUserMutation';
import { useTranslation } from 'react-i18next';
import inMemoryJwt from 'utils/inMemoryJwt';
import SettingsForm from '../components/SettingsForm/SettingsForm';
import SettingsGroup from '../components/SettingsGroup/SettingsGroup';
import accountGroupsConfig from './accountGroupsConfig';
var Account = function () {
    var _a;
    var t = useTranslation().t;
    var user = (_a = useQuery(USER, {
        skip: !inMemoryJwt.getToken(),
        variables: {
            showMainSettings: true,
            showVerified: true
        }
    }).data) === null || _a === void 0 ? void 0 : _a.user;
    //конвертация необходима из-за вложенности mainSettings и др.полей
    var convertUserData = function (user) {
        var userData = {};
        if (user) {
            var mainSettings = user.mainSettings, verified = user.verified, userInfo = __rest(user, ["mainSettings", "verified"]);
            userData = __assign(__assign(__assign({}, userInfo), mainSettings), { isVerifiedEmail: verified.email });
        }
        return userData;
    };
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: t('Учетная запись') }, void 0), content: accountGroupsConfig.length > 0 &&
            accountGroupsConfig.map(function (settingsGroup) { return (_jsxs("div", { children: [_jsx(SettingsGroup, { settingsGroup: settingsGroup, data: convertUserData(user), handleChangeData: useUpdateUserMutation }, void 0), _jsx(Divider, { light: true }, void 0)] }, settingsGroup.id)); }) }, void 0));
};
export default Account;
