var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar } from '@mui/material';
import clsx from 'clsx';
var UserAvatar = function (props) {
    var profilePhoto = props.profilePhoto, firstName = props.firstName, style = props.style, onClick = props.onClick;
    return (_jsx(Avatar, __assign({ className: clsx(style || 'w-40 h-40 text-18'), src: profilePhoto === null || profilePhoto === void 0 ? void 0 : profilePhoto.url, onClick: onClick }, { children: firstName && !!!profilePhoto ? firstName[0] : 'IU' }), void 0));
};
export default UserAvatar;
