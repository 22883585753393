var displayPreferencesGroupsConfig = [
    {
        id: 'changeDisplayMode',
        value: 'changeDisplayMode-group',
        title: 'Темная тема',
        settingsGroupParams: [
            {
                id: 'changeDisplayModeGroupParams',
                values: [
                    {
                        id: 'displayMode',
                        value: 'displayMode',
                        type: 'checkbox',
                        title: 'Темная тема'
                    },
                ],
                type: 'accordion'
            },
        ]
    },
];
export default displayPreferencesGroupsConfig;
