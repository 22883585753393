var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function StravaIcon(props) {
    return (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", version: "1.1" }, props, { children: [_jsx("path", { d: "M 15.386719 17.945312 L 13.296875 13.828125 L 10.230469 13.828125 L 15.386719 24 L 20.539062 13.828125 L 17.46875 13.828125", fill: "#f9b797" }, void 0), _jsx("path", { d: "M 10.460938 8.230469 L 13.296875 13.828125 L 17.46875 13.828125 L 10.460938 0 L 3.460938 13.828125 L 7.632812 13.828125", fill: "#f05222" }, void 0)] }), void 0));
}
export default StravaIcon;
