var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import HelperFunctions from 'utils/HelperFunctions';
var TournamentTeamUserListItem = function (_a) {
    var teamUser = _a.teamUser, mesureRef = _a.mesureRef, handleClose = _a.handleClose;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var getAge = HelperFunctions.getAge, getGender = HelperFunctions.getGender;
    var isMy = (teamUser === null || teamUser === void 0 ? void 0 : teamUser.userId) === (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id);
    return (_jsx("div", __assign({ className: "flex xs:p-8 md:p-10 \n                xs:mb-8 md:mb-10 decoration-0", ref: mesureRef }, { children: _jsxs("div", __assign({ className: "flex items-center w-full" }, { children: [_jsx(UserAvatar, { style: "xs:w-68 xs:h-68 xs:text-20 md:w-88 md:h-88 md:text-24", profilePhoto: { url: teamUser.profilePhotoUrl || '' }, firstName: teamUser.firstName }, void 0), _jsxs("div", __assign({ className: "w-full flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row justify-between" }, { children: [_jsxs("div", __assign({ onClick: function () {
                                        history.push({
                                            pathname: '/profile/' + teamUser.userName,
                                            state: { fromAthletesSearchPage: true }
                                        });
                                        handleClose();
                                    }, className: "xs:text-14 md:text-16 font-semibold px-8 py-2 dark:text-white cursor-pointer" }, { children: [teamUser.firstName, " ", teamUser.lastName] }), void 0), _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: isMy && _jsx("div", { children: t('Это вы') }, void 0) }), void 0)] }), void 0), teamUser.isCaptain && (_jsx("div", __assign({ className: "flex text-green xs:text-12 md:text-14 px-8 py-2" }, { children: t('Капитан команды') }), void 0)), teamUser.birthDate && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: [t('Возраст'), ":"] }), void 0), _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: getAge(teamUser.birthDate, i18n.language) }), void 0)] }), void 0)), teamUser.gender && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: [t('Пол'), ":"] }), void 0), _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: getGender(teamUser.gender, i18n.language) }), void 0)] }), void 0)), teamUser.sportClubId && (_jsxs("div", __assign({ className: "flex items-center pt-8" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2" }, { children: [t('Клуб'), ":"] }), void 0), _jsxs("div", __assign({ className: "flex md:max-w-840 items-center hover:cursor-pointer", onClick: function () {
                                        history.push("/club/".concat(teamUser.sportClubUrl));
                                    } }, { children: [_jsx(UserAvatar, { style: "h-28 w-28", profilePhoto: { url: teamUser.sportClubProfilePhotoUrl || '' }, firstName: teamUser.sportClubName }, void 0), _jsxs("div", __assign({ className: "pl-4 dark:text-white" }, { children: ["\u00A0", teamUser.sportClubName] }), void 0)] }), void 0)] }), void 0))] }), void 0)] }), void 0) }), void 0));
};
export default TournamentTeamUserListItem;
