import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import { Redirect } from 'react-router-dom';
import StandingsActivitySettings from './StandingsActivitySettings/StandingsActivitySettings';
import StandingsColumnsSettings from './StandingsColumnsSettings/StandingsColumnsSettings';
import StandingsFrozenDataSettings from './StandingsFrozenDataSettings/StandingsFrozenDataSettings';
import StandingsMainSettings from './StandingsMainSettings/StandingsMainSettings';
import StandingsPrivacySettings from './StandingsPrivacySettings/StandingsPrivacySettings';
import StandingsSettingsPage from './StandingsSettingsPage';
import StandingsSportSettings from './StandingsSportSettings/StandingsSportSettings';
export var standingsSettingsPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/standings-settings/:standingsId/freeze',
            component: function (props) {
                var standingsId = props.match.params.standingsId;
                return (_jsx(StandingsSettingsPage, { children: _jsx(StandingsFrozenDataSettings, { standingsId: standingsId }, void 0) }, void 0));
            }
        },
        {
            path: '/standings-settings/:standingsId/privacy',
            component: function (props) {
                var standingsId = props.match.params.standingsId;
                return (_jsx(StandingsSettingsPage, { children: _jsx(StandingsPrivacySettings, { standingsId: standingsId }, void 0) }, void 0));
            }
        },
        {
            path: '/standings-settings/:standingsId/sports-settings',
            component: function (props) {
                var standingsId = props.match.params.standingsId;
                return (_jsx(StandingsSettingsPage, { children: _jsx(StandingsSportSettings, { standingsId: standingsId }, void 0) }, void 0));
            }
        },
        {
            path: '/standings-settings/:standingsId/activity-settings',
            component: function (props) {
                var standingsId = props.match.params.standingsId;
                return (_jsx(StandingsSettingsPage, { children: _jsx(StandingsActivitySettings, { standingsId: standingsId }, void 0) }, void 0));
            }
        },
        {
            path: '/standings-settings/:standingsId/columns',
            component: function (props) {
                var standingsId = props.match.params.standingsId;
                return (_jsx(StandingsSettingsPage, { children: _jsx(StandingsColumnsSettings, { standingsId: standingsId }, void 0) }, void 0));
            }
        },
        {
            path: '/standings-settings/:standingsId/main',
            component: function (props) {
                var standingsId = props.match.params.standingsId;
                return (_jsx(StandingsSettingsPage, { children: _jsx(StandingsMainSettings, { standingsId: standingsId }, void 0) }, void 0));
            }
        },
        {
            path: '/standings-settings/:standingsId',
            component: function (props) {
                var standingsId = props.match.params.standingsId;
                return _jsx(Redirect, { to: "/standings-settings/".concat(standingsId, "/main") }, void 0);
            }
        },
        {
            path: '/standings-settings',
            component: function (props) {
                var standingsId = props.match.params.standingsId;
                return _jsx(Redirect, { to: "/standings-settings/".concat(standingsId, "/main") }, void 0);
            }
        },
    ]
};
