var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
function TelegramIconLogin(props) {
    return (_jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 60 60", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M12 0C5.37258 0 0 5.37258 0 12V48C0 54.6274 5.37258 60 12 60H48C54.6274 60 60 54.6274 60 48V12C60 5.37258 54.6274 0 48 0H12ZM32.4411 17.2984C28.6795 18.8717 21.1615 22.128 9.88728 27.0673C8.05652 27.7994 7.09748 28.5155 7.01017 29.2158C6.86263 30.3993 8.33652 30.8654 10.3436 31.5C10.6166 31.5863 10.8995 31.6757 11.1895 31.7705C13.1641 32.416 15.8203 33.1711 17.2012 33.2011C18.4537 33.2283 19.8518 32.709 21.3952 31.6433C31.9291 24.4931 37.3668 20.879 37.7081 20.8011C37.949 20.7461 38.2827 20.677 38.5089 20.8791C38.735 21.0813 38.7128 21.464 38.6888 21.5667C38.5428 22.1926 32.7572 27.6013 29.7632 30.4003C28.8299 31.2728 28.1678 31.8918 28.0324 32.0331C27.7293 32.3498 27.4203 32.6493 27.1233 32.9372C25.2888 34.7155 23.9131 36.049 27.1995 38.2267C28.7787 39.2732 30.0424 40.1385 31.3032 41.0019C32.6801 41.9447 34.0534 42.8852 35.8302 44.0564C36.2829 44.3548 36.7152 44.6647 37.1363 44.9666C38.7387 46.1152 40.1782 47.1472 41.9567 46.9827C42.9902 46.887 44.0576 45.9099 44.5997 42.9956C45.8809 36.1084 48.3993 21.1857 48.9813 15.0365C49.0323 14.4977 48.9682 13.8082 48.9167 13.5056C48.8651 13.2029 48.7575 12.7716 48.3662 12.4524C47.9029 12.0743 47.1875 11.9946 46.8676 12.0003C45.413 12.026 43.1813 12.8064 32.4411 17.2984Z", fill: "#E3BC00" }, void 0) }), void 0));
}
export default TelegramIconLogin;
