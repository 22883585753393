var sportEventProfileGroupsConfig = [
    {
        id: 'changeProfilePhoto-group',
        value: 'changeProfilePhoto-group',
        settingsGroupParams: [
            {
                id: 'changeSportEventProfilePhotoGroupParams',
                values: [
                    {
                        id: 'profilePhoto',
                        value: 'profilePhoto',
                        title: 'Фото события',
                        type: 'photo-upload',
                        dependentFields: ['name']
                    },
                ],
                type: 'accordion'
            },
        ]
    },
    {
        id: 'id-group',
        value: 'id-group',
        title: 'ID',
        settingsGroupParams: [
            {
                id: 'id-group',
                values: [
                    {
                        id: 'id',
                        value: 'id',
                        title: 'ID',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: false
            },
        ]
    },
    {
        id: 'name-group',
        value: 'name-group',
        title: 'Название',
        settingsGroupParams: [
            {
                id: 'nameGroupParams',
                values: [
                    {
                        id: 'displayName',
                        value: 'displayName',
                        title: 'Название',
                        type: 'textField'
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'description-SportEvent',
        value: 'description-SportEvent',
        title: 'О событии',
        settingsGroupParams: [
            {
                id: 'descriptionSportEventGroupParams',
                values: [
                    {
                        id: 'description',
                        value: 'description',
                        title: 'Информация о событии',
                        type: 'textEditor',
                        textFieldIsMultiline: true
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'additionalInfo-SportEvent',
        value: 'additionalInfo-SportEvent',
        title: 'Информация',
        settingsGroupParams: [
            {
                id: 'additionalInfoSportEventGroupParams',
                values: [
                    {
                        id: 'additionalInfo',
                        value: 'additionalInfo',
                        title: 'Дополнительная информация о событии',
                        type: 'textEditor',
                        textFieldIsMultiline: true
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'Url-SportEvent',
        value: 'Url-SportEvent',
        title: 'Уникальное имя для URL',
        settingsGroupParams: [
            {
                id: 'UrlSportEventGroupParams',
                values: [
                    {
                        id: 'url',
                        value: 'url',
                        title: 'Уникальное имя для URL',
                        type: 'textField',
                        textFieldIsMultiline: false
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
    {
        id: 'landingPageUrl-SportEvent',
        value: 'landingPageUrl-SportEvent',
        title: 'URL лендинга',
        settingsGroupParams: [
            {
                id: 'landingPageUrlSportEventGroupParams',
                values: [
                    {
                        id: 'landingPageUrl',
                        value: 'landingPageUrl',
                        title: 'URL лендинга',
                        type: 'textField',
                        textFieldIsMultiline: false
                    },
                ],
                type: 'accordion',
                editable: true
            },
        ]
    },
];
export default sportEventProfileGroupsConfig;
