import { jsx as _jsx } from "react/jsx-runtime";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import authRoles from 'common/authRoles';
var bottomNavigationConfig = [
    {
        id: 'administration',
        title: 'Администрирование',
        icon: _jsx(AdminPanelSettingsIcon, {}, void 0),
        type: 'item',
        url: "/administration",
        auth: authRoles.staff,
        children: [
            {
                id: 'validation',
                title: 'Валидация тренировок',
                type: 'item',
                url: "/administration/activity-validation",
                children: []
            },
        ]
    },
    {
        id: 'settings',
        title: 'Настройки',
        icon: _jsx(SettingsIcon, {}, void 0),
        type: 'item',
        url: '/settings',
        children: []
    },
];
export default bottomNavigationConfig;
