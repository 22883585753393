var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { NotificationSubTypeListItemEditMode } from './NotificationSubTypeListItemEditMode';
import { NotificationSubTypeListItemReadMode } from './NotificationSubTypeListItemReadMode';
var NotificationSubTypeListItemItem = function (_a) {
    var notificationSubType = _a.notificationSubType;
    var _b = useState(false), isEditMode = _b[0], setIsEditMode = _b[1];
    return (_jsx("div", __assign({ className: "flex border-1 solid rounded-8 xs:p-8 md:p-10 \n                xs:mb-8 md:mb-10 decoration-0 border-border-lt dark:border-border-dk " }, { children: isEditMode ? (_jsx(NotificationSubTypeListItemEditMode, { notificationSubType: notificationSubType, setIsEditMode: setIsEditMode }, void 0)) : (_jsx(NotificationSubTypeListItemReadMode, { notificationSubType: notificationSubType, setIsEditMode: setIsEditMode }, void 0)) }), void 0));
};
export default NotificationSubTypeListItemItem;
