var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useReactiveVar } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { NotificationTypes } from 'common/enums/notification/notification-types.enum';
import { NOTIFICATIONS } from 'common/graphql/queries/notification/notifications';
import CircularProgressMui from 'components/Loading/CircularProgresMui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import UserRecentNotificationsPopoverContentNotificationListItem from './UserRecentNotificationsPopoverContentNotificationListItem';
var UserRecentNotificationsPopoverContent = function (_a) {
    var _b;
    var handleMenuClose = _a.handleMenuClose;
    var t = useTranslation().t;
    var currentUserId = (_b = useReactiveVar(userProfileDataVar).userProfile) === null || _b === void 0 ? void 0 : _b.id;
    var _c = useQuery(NOTIFICATIONS, {
        variables: {
            userId: currentUserId,
            type: NotificationTypes.popUpUi,
            limit: 5,
            offset: 0
        },
        fetchPolicy: 'network-only'
    }), data = _c.data, loading = _c.loading;
    return (_jsxs("div", __assign({ className: "m-10 w-320" }, { children: [_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("p", __assign({ className: "text-center text-grey" }, { children: t('Недавние уведомления') }), void 0), _jsx(IconButton, __assign({ className: "h-16 w-16 p-0", onClick: function () {
                            handleMenuClose();
                        } }, { children: _jsx(CloseIcon, { style: { width: 12, height: 12 } }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex items-center justify-center min-h-120 pt-5" }, { children: loading ? (_jsx(CircularProgressMui, {}, void 0)) : (_jsxs("div", __assign({ className: "flex flex-col w-full space-y-10" }, { children: [data && data.notifications && data.notifications.length === 0 && (_jsx("p", __assign({ className: "text-grey" }, { children: t('Здесь пока ничего нет') }), void 0)), data &&
                            data.notifications &&
                            data.notifications.length > 0 &&
                            data.notifications.slice(0, 5).map(function (notification) {
                                return (_jsx(UserRecentNotificationsPopoverContentNotificationListItem, { notification: notification, handleMenuClose: handleMenuClose }, notification.id));
                            })] }), void 0)) }), void 0), _jsxs("div", __assign({ className: "flex flex-col space-y-10 w-full items-center pt-5" }, { children: [_jsx(Link, __assign({ className: "tw-link-btn", to: "/notifications", onClick: function () {
                            handleMenuClose();
                        } }, { children: t('Смотреть все уведомления') }), void 0), _jsx(Link, __assign({ className: "text-grey text-12 underline cursor-pointer", to: "/settings/notifications", onClick: function () {
                            handleMenuClose();
                        } }, { children: t('Настройки уведомлений') }), void 0)] }), void 0)] }), void 0));
};
export default UserRecentNotificationsPopoverContent;
