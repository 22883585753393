import * as Yup from 'yup';
var supportRequestSchema = Yup.object().shape({
    description: Yup.string().trim().min(1).max(256, 'longDescription1to256').required('Не указано описание'),
    text: Yup.string()
        .trim()
        .min(8, 'Сообщение слишком короткое')
        .max(4096, 'Превышена допустимая длина сообщения')
        .required('Не заполнено сообщение')
});
export default supportRequestSchema;
