var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelperFunctions from 'utils/HelperFunctions';
var getSportNameRu = HelperFunctions.getSportNameRu;
var ToolTipSportPoints = function (_a) {
    var sport = _a.sport, points = _a.points;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx("div", __assign({ className: "xs:min-w-80 md:min-w-92 xs:text-12 sm:text-14 text-gray" }, { children: t(getSportNameRu(sport)) }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840" }, { children: points.toFixed(1) }), void 0)] }), void 0));
};
var PointsPerSportToolTip = function (_a) {
    var totalPoints = _a.totalPoints, pointsPerSport = _a.pointsPerSport;
    var _b = useState(false), openTooltip = _b[0], setOpenTooltip = _b[1];
    var isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    return (_jsx(Tooltip, __assign({ onOpen: function () { return setOpenTooltip(true); }, onMouseLeave: function () { return isMobile && setOpenTooltip(false); }, onClose: function () { return setOpenTooltip(false); }, open: openTooltip, placement: "top-start", enterTouchDelay: 0, leaveTouchDelay: 10000, leaveDelay: 700, title: _jsx(Fragment, { children: pointsPerSport.map(function (obj) {
                return _jsx(ToolTipSportPoints, { sport: obj.sport, points: obj.points }, obj.sport);
            }) }, void 0) }, { children: _jsx("div", { children: totalPoints.toFixed(1) }, void 0) }), void 0));
};
export default PointsPerSportToolTip;
