var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery } from '@apollo/client';
import { Autocomplete, Box, TextField } from '@mui/material';
import { LOCATION_SEARCH } from 'common/graphql/queries/location/location-search';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Utils from 'utils/Utils';
var LocationSearchAutoComplete = function (_a) {
    var onChange = _a.onChange;
    var t = useTranslation().t;
    var _b = useState(''), inputValue = _b[0], setInputValue = _b[1];
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var _d = useLazyQuery(LOCATION_SEARCH), getLocations = _d[0], _e = _d[1], locationData = _e.data, loading = _e.loading;
    useEffect(function () {
        if (inputValue) {
            getLocations({
                variables: {
                    searchQuery: Utils.prepareSearchInputValue(inputValue) || '',
                    limit: 10,
                    offset: 0
                }
            });
        }
    }, [inputValue]);
    useEffect(function () {
        if (locationData && locationData.locationSearch) {
            setOptions(__spreadArray([], locationData.locationSearch.map(function (o) {
                return {
                    label: o.result,
                    value: o.id
                };
            }), true));
        }
    }, [locationData]);
    return (_jsx(Autocomplete, { filterOptions: function (x) { return x; }, options: options, disableClearable: false, noOptionsText: t('Город не найден'), renderInput: function (params) { return (_jsx(TextField, __assign({ name: "location", 
            // error={!!errorMessage}
            // helperText={errorMessage}
            label: t('Город') }, params), void 0)); }, onChange: onChange, onInputChange: function (e, v) { return setInputValue(v); }, inputValue: inputValue, renderOption: function (props, option) { return (_jsx(Box, __assign({ component: "li", sx: { '& > img': { mr: 2, flexShrink: 0 } } }, props, { children: option.label }), void 0)); } }, void 0));
};
export default LocationSearchAutoComplete;
