var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, Divider, FormControlLabel, TextField } from '@mui/material';
import TelegramContact from 'assets/icons/contacts/telegram';
import VKContact from 'assets/icons/contacts/VK';
import WhatsAppContact from 'assets/icons/contacts/whatsapp';
import clsx from 'clsx';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { SocialLinkTypes } from 'common/enums/sport-club/social-link-types.enum';
import { TeamContactTypes } from 'common/enums/team/team-contact-types.enum';
import { UPDATE_TEAM_CONTACT } from 'common/graphql/mutations/team/update-team-contact';
import { TEAM_CONTACTS } from 'common/graphql/queries/team/team-contacts';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import contactValidationSchema from 'common/validationSchemas/contactValidationSchema copy';
import Button from 'components/Buttons/Button';
import TeamContactTypeField from 'components/Fields/TeamContactTypeField';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { openDialog, openSnackbar } from 'store/actions';
var TeamContactData = function (_a) {
    var teamContact = _a.teamContact, teamId = _a.teamId;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var _b = useState(false), edit = _b[0], setEdit = _b[1];
    var updateTeamContact = function (data) {
        return useMutation(UPDATE_TEAM_CONTACT, {
            variables: {
                updateTeamContactData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TEAM_CONTACTS,
                    variables: {
                        teamId: teamId
                    }
                },
            ]
        });
    };
    var openDeleteTeamContactDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.DeleteTeamContact,
            dialogProps: {
                teamId: teamId,
                teamSocialLinkId: teamContact.id
            }
        }));
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Данные контакта успешно изменены.',
            severity: SnackbarSeverity.SUCCESS
        }));
        setEdit(false);
    };
    var handleExecuteMutation = useDynamicMutation(updateTeamContact, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        //Todo
        handleExecuteMutation(__assign({}, data));
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var defaultFormState = {
        id: teamContact.id,
        type: teamContact.type || SocialLinkTypes.Telegram,
        text: teamContact.text || '',
        url: teamContact.url || '',
        isPrivate: teamContact.isPrivate || false
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: contactValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: function () { return setEdit(false); },
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("div", __assign({ className: clsx('flex max-w-full w-full flex-col') }, { children: [_jsxs("div", __assign({ className: clsx('flex w-full justify-between py-14') }, { children: [_jsxs("div", __assign({ className: clsx('flex xs:items-start md:items-center xs:flex-col md:flex-row overflow-hidden') }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [teamContact.type === TeamContactTypes.Telegram && (_jsx(TelegramContact, { className: "dark:fill-white" }, void 0)), teamContact.type === TeamContactTypes.VK && _jsx(VKContact, { className: "dark:fill-white" }, void 0), teamContact.type === TeamContactTypes.Whatsapp && (_jsx(WhatsAppContact, { className: "dark:fill-white" }, void 0)), _jsx(Link, __assign({ className: "tw-club-contact-link font-semibold text-14 break-words", to: { pathname: teamContact.url }, target: "_blank" }, { children: teamContact.text }), void 0)] }), void 0), _jsxs("div", __assign({ className: "xs:pl-28 md:pl-4 break-words" }, { children: ["url: ", teamContact.url] }), void 0), teamContact.isPrivate && _jsx("p", __assign({ className: "xs:pl-28 md:pl-4 text-red" }, { children: "\u041F\u0440\u0438\u0432\u0430\u0442\u043D\u0430\u044F" }), void 0)] }), teamContact.id), _jsxs("div", __assign({ className: "flex" }, { children: [_jsx(EditIcon, { className: "mr-12 cursor-pointer hover:fill-icon-lt-active", onClick: function () { return setEdit(!edit); } }, void 0), _jsx(DeleteIcon, { className: "cursor-pointer hover:fill-icon-lt-active", onClick: function () {
                                    openDeleteTeamContactDialog();
                                } }, void 0)] }), void 0)] }), teamContact.id), edit && (_jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs("div", __assign({ className: clsx('flex  xs:max-w-full sm:max-w-256 flex-col py-8') }, { children: [_jsxs("div", __assign({ className: clsx('flex  xs:max-w-full sm:max-w-256 flex-col py-8') }, { children: [_jsx(TextField, { className: "", label: "\u0422\u0435\u043A\u0441\u0442", name: "text", value: formik.values['text'], onChange: handleChangeWrapper, error: Boolean(formik.errors['text']), helperText: formik.errors['text'], required: true }, void 0), _jsx(TextField, { className: "", label: "URL", name: "url", value: formik.values['url'], onChange: handleChangeWrapper, error: Boolean(formik.errors['url']), helperText: formik.errors['url'], required: true }, void 0), _jsx(TeamContactTypeField, { name: "type", label: "\u041C\u0435\u0441\u0441\u0435\u0434\u0436\u0435\u0440", value: formik.values['type'], isError: Boolean(formik.errors['type']), helperText: formik.errors['type'], onChange: formik.setFieldValue, required: true }, void 0), _jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { checked: formik.values['isPrivate'] || false, onChange: function (e) {
                                            formik.setFieldError('isPrivate', undefined);
                                            formik.setFieldValue('isPrivate', e.target.checked);
                                        } }, void 0), label: "\u0421\u0441\u044B\u043B\u043A\u0430 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u0430\u044F, \u0432\u0438\u0434\u044F\u0442 \u0442\u043E\u043B\u044C\u043A\u043E \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0438 \u043A\u043E\u043C\u0430\u043D\u0434\u044B" }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex justify-end" }, { children: [_jsx(Button, __assign({ className: "mr-8", type: "reset", onClick: function () { return setEdit(false); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "primary sm", isSubmitButton: true }, { children: t('Сохранить') }), void 0)] }), void 0)] }), void 0) }), void 0)), _jsx(Divider, { light: true }, void 0)] }), void 0));
};
export default TeamContactData;
