var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Card, CardContent } from "@mui/material";
import { useSelector } from "react-redux";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LinkDeliveryNotificationForm from "./LinkDeliveryNotificationForm/LinkDeliveryNotificationForm";
import LoginLayout from "main/layouts/layout/components/LoginLayout";
var ForgotPasswordPage = function () {
    var resetPassword = useSelector(function (_a) {
        var auth = _a.auth;
        return auth.resetPassword;
    });
    var height = (resetPassword.isVisible && resetPassword.email) ? '23rem' : '36rem';
    return (_jsx(LoginLayout, { children: _jsx(Card, __assign({ sx: {
                width: { xs: '100%', sm: '38rem', md: '40rem', lg: '42rem' },
                height: { xs: '100%', sm: height },
                marginRight: { lg: '10rem' },
                marginTop: { sm: '5rem' },
                borderRadius: { xs: 0, sm: '1rem' },
                backgroundColor: function (theme) { return theme.palette.background.opacityContent; }
            } }, { children: _jsx(CardContent, __assign({ sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    m: '2rem'
                } }, { children: !(resetPassword.isVisible && resetPassword.email) ? (_jsx(ForgotPasswordForm, {}, void 0)) : (_jsx(LinkDeliveryNotificationForm, { email: resetPassword.email }, void 0)) }), void 0) }), void 0) }, void 0));
};
export default ForgotPasswordPage;
