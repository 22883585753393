import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PageCarded from 'components/PageLayouts/carded/PageCarded';
import SidebarContent from 'main/layouts/shared-components/SidebarContent/SidebarContent';
import { useParams } from 'react-router-dom';
import standingsSettingsNavigationConfig from './standingsSettingsNavigationConfig';
import { useReactiveVar } from '@apollo/client';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
var StandingsSettingsPage = function (props) {
    var _a, _b;
    var standingsId = useParams().standingsId;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    var isAdmin = !!((_b = (_a = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.roles) === null || _a === void 0 ? void 0 : _a.map(function (role) { return role.name; })) === null || _b === void 0 ? void 0 : _b.filter(function (roleName) { return ['Admin', 'Operator', 'SuperAdmin'].includes(roleName); })[0]);
    return (_jsx(PageCarded, { classes: {
            root: 'w-full',
            header: 'hidden',
            topBg: 'hidden',
            content: 'flex flex-col',
            sidebar: 'xs:px-8 md:px-12 xl:px-24'
        }, breadcrumbs: _jsx(Breadcrumbs, {}, void 0), content: isAdmin && _jsx(_Fragment, { children: props.children }, void 0), rightSidebarContent: isAdmin && (_jsx(SidebarContent, { navigationConfigItems: standingsSettingsNavigationConfig, mainPathname: 'standings-settings/' + standingsId }, void 0)), mobileSidebarContent: true }, void 0));
};
export default StandingsSettingsPage;
