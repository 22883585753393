import { createTheme } from '@mui/material/styles';
import componentsOverrides from 'config/overrides';
import {
	defaultSettings,
	defaultThemeOptions,
	defaultThemes,
	extendThemeWithMixins,
	getParsedQuerySettings,
	mainThemeVariations,
	mustHaveThemeOptions,
} from '../../../config/defaultSettings';
import SettingsConfig from '../../../config/settingsConfig';
import ThemesConfig from '../../../config/themesConfig';
import LayoutConfigs from '../../../main/layouts/LayoutConfigs';
import _ from '../../../utils/lodash';
import * as Actions from '../../actions/base/settings.actions';

const initialSettings = getInitialSettings();
const initialThemes = getInitialThemes();

const initialState = {
	initial: initialSettings,
	defaults: _.merge({}, initialSettings),
	current: _.merge({}, initialSettings),
	themes: initialThemes,
	...getThemeOptions(initialThemes, initialSettings),
};

const settings = function (state = initialState, action) {
	switch (action.type) {
		case Actions.SET_SETTINGS: {
			const newSettings = _.merge(
				{},
				state.current,
				action?.value?.layout?.style && {
					layout: { config: LayoutConfigs[action.value.layout.style].defaults },
				},
				action.value,
			);
			const themes =
				newSettings.theme.main !== state.current.theme.main
					? { ...state.themes, ...updateMainThemeVariations(newSettings.theme.main) }
					: state.themes;
			return {
				...state,
				current: newSettings,
				themes,
				...getThemeOptions(themes, newSettings),
			};
		}
		case Actions.SET_INITIAL_SETTINGS: {
			return _.merge({}, initialState);
		}
		case Actions.SET_DEFAULT_SETTINGS: {
			const newSettings = _.merge(
				{},
				state.defaults,
				action?.value?.layout?.style && {
					layout: { config: LayoutConfigs[action.value.layout.style].defaults },
				},
				action.value,
			);
			const themes =
				newSettings.theme.main !== state.defaults.theme.main
					? { ...state.themes, ...updateMainThemeVariations(newSettings.theme.main) }
					: state.themes;
			return {
				...state,
				defaults: _.merge({}, newSettings),
				current: _.merge({}, newSettings),
				themes,
				...getThemeOptions(themes, newSettings),
			};
		}
		case Actions.RESET_DEFAULT_SETTINGS: {
			const themes = { ...state.themes, ...updateMainThemeVariations(state.defaults.theme.main) };
			return {
				...state,
				defaults: _.merge({}, state.defaults),
				current: _.merge({}, state.defaults),
				themes,
				...getThemeOptions(themes, state.defaults),
			};
		}
		default: {
			return state;
		}
	}
};

export default settings;

/**
 * SETTINGS
 */
function getInitialSettings() {
	const defaultLayoutStyle = SettingsConfig?.layout?.style || 'layout';
	const layout = {
		style: defaultLayoutStyle,
		config: LayoutConfigs[defaultLayoutStyle].defaults,
	};
	return _.merge({}, defaultSettings, { layout }, SettingsConfig, getParsedQuerySettings());
}

/**
 * THEMES
 */
function getInitialThemes() {
	const themesObj = Object.keys(ThemesConfig).length !== 0 ? ThemesConfig : defaultThemes;

	const themes = Object.assign(
		{},
		...Object.entries(themesObj).map(([key, value]) => {
			const muiTheme = _.merge({}, defaultThemeOptions, value, mustHaveThemeOptions);
			return {
				[key]: createTheme(
					_.merge({}, { components: componentsOverrides(muiTheme) }, muiTheme, {
						mixins: extendThemeWithMixins(muiTheme),
					}),
				),
			};
		}),
	);

	return {
		...themes,
		...mainThemeVariations(themesObj[initialSettings.theme.main]),
	};
}

function updateMainThemeVariations(mainTheme) {
	const themesObj = Object.keys(ThemesConfig).length !== 0 ? ThemesConfig : defaultThemes;
	return mainThemeVariations(themesObj[mainTheme]);
}

function getThemeOptions(themes, settings) {
	return {
		mainTheme: themes[settings.theme.main],
		loginTheme: themes[settings.theme.loginPage],
		/* theme: {  main: 'default', */
		navbarTheme: themes[settings.theme.navbar],
		toolbarTheme: themes[settings.theme.toolbar],
		footerTheme: themes[settings.theme.footer],
		...updateMainThemeVariations(settings.theme.main),
	};
}
