import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import React from 'react';
import JoinClubByLink from './JoinClubByLink';
export var clubPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/club/:clubId',
            component: React.lazy(function () { return import('./ClubPage'); })
        },
        {
            path: '/join-club-link/:clubId/:joinLinkString',
            component: function (props) {
                var joinLinkString = props.match.params.joinLinkString;
                var clubId = props.match.params.clubId;
                return _jsx(JoinClubByLink, { clubId: clubId, joinLinkString: joinLinkString }, void 0);
            }
        },
    ]
};
