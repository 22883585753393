import { jsx as _jsx } from "react/jsx-runtime";
import { appHost, appScheme } from 'common/appURLSetup';
import { vkClientId } from 'common/socialClientIdsSetup';
import JoinAccountRequestDialog from '../JoinAccountRequestDialog/JoinAccountRequestDialog';
var VKJoinAccountRequestDialog = function () {
    var handleRedirect = function () {
        var cbLink = "".concat(appScheme, "://").concat(appHost, "/login/vkontakte/");
        window.location.href = "https://oauth.vk.com/authorize?client_id=".concat(vkClientId, "\n\t&display=popup&redirect_uri=").concat(cbLink, "&scope=email&response_type=code&v=5.130");
    };
    var handleJoinVKAccount = function () {
        sessionStorage.setItem('join', 'email');
        handleRedirect();
    };
    return _jsx(JoinAccountRequestDialog, { handleJoin: handleJoinVKAccount }, void 0);
};
export default VKJoinAccountRequestDialog;
